import React from 'react';
import PropTypes from 'prop-types';

const ContactListItems = (props) => {
  const {
    _id,
    First_Name,
    Last_Name,
    Contact_Type,
    City,
    State,
  } = props.contact;

  const { removeContact, editAContact, viewAContact } = props;

  const deleteTheContact = (e) => {
    removeContact(_id);
  };

  const editTheContact = (e) => {
    editAContact(props.contact);
  };

  const viewTheContact = (e) => {
    viewAContact(props.contact);
  };

  return (
    <tr>
      <td onClick={viewTheContact} className="pointer">
        {First_Name}{' '}
      </td>
      <td onClick={viewTheContact} className="pointer">
        {Last_Name}
      </td>

      <td>{Contact_Type}</td>
      <td className="text-center d-none d-md-table-cell">
        {City}
        {', '}
        {State}
      </td>
      <td className="text-right">
        <i
          className="fa fa-pencil mr-3 text-warning pointer"
          title="Edit User"
          onClick={editTheContact}
          aria-hidden="true"
        ></i>
        <i
          className="fa fa-trash text-danger pointer"
          title="Delete User"
          onClick={deleteTheContact}
          data-toggle="modal"
          data-target="#contactDeleteConfirmationModal"
          aria-hidden="true"
        ></i>
      </td>
    </tr>
  );
};

ContactListItems.propTypes = {
  contact: PropTypes.object.isRequired,
};
export default ContactListItems;
