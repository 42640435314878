import React, { useReducer, useContext } from 'react';
import AlertContext from '../alert/alertContext';
import EmailContext from './emailContext';
import emailReducer from './emailReducer';
import axios from 'axios';

import {
  GET_EMAILS,
  GET_EMAIL_ATTACHMENTS,
  EDIT_EMAIL,
  SAVE_ATTACHMENTS,
  DELETE_EMAIL,
  DELETE_EMAIL_ATTACHMENTS,
  DELETE_EMAIL_ATTACHMENT,
  SAVE_EMAIL,
} from '../types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const EmailState = (props) => {
  const initialstate = {
    emails: [],
    emailLoading: true,
    emailAttachments: null,
  };

  const { setAlert } = useContext(AlertContext);

  const [state, dispatch] = useReducer(emailReducer, initialstate);

  const sendEmail = async (emailData) => {
    try {
      await axios.post('/api/email/', emailData, config);
    } catch (error) {
      setAlert('ERROR SENDING EMAIL', 'danger');
    }

    // if (res.data.attachments !== undefined) {
    //   for (let i = 0; i < res.data.attachments.length; i++) {}
    // }

    // if (res.status === 510) {
    //   setAlert('EMAIL SEND FAILED', 'danger');
    // }
  };

  const saveAttachments = async (data) => {
    const formData = new FormData();
    formData.append('file', data.file, data.fileName);
    try {
      await axios
        .post('/api/email/saveAttachment', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          console.log(response.status);
        });
    } catch (err) {
      setAlert('EMAIL ATTACHMENTS FAILED', 'danger');
    }
  };

  const sendNotifyEmail = async (emailData) => {
    await axios.post('/api/email/assignmentNotify/', emailData, config);
  };

  const editEmail = async (data) => {
    try {
      const res = await axios.put(`/api/email/${data._id}`, data, config);

      dispatch({
        type: EDIT_EMAIL,
        payload: res.data.email,
      });
      if (res.data.attachments !== undefined) {
        for (let i = 0; i < res.data.attachments.length; i++) {
          dispatch({
            type: SAVE_ATTACHMENTS,
            payload: res.data.attachments[i],
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteEmailAttachment = async (e) => {
    // DELETE EMAIL ATTACHMENT FILE FROM SERVER
    await axios
      .delete(`/api/email/emailAttachment/delete/${e}`)
      .catch((error) => {
        console.log(error);
      });

    // DELETE EMAIL ATTACHMENT FROM STATE
    dispatch({
      type: DELETE_EMAIL_ATTACHMENT,
      payload: e,
    });
  };

  const deleteEmail = async (e) => {
    // DELETE EMAIL ATTACHMENT FILE FROM SERVER
    await axios
      .delete(`/api/email/emailAttachments/delete/${e}`)
      .catch((error) => {
        console.log(error);
      });
    // DELETE EMAIL & ATTACHMENT FROM DATABASE AND REMOVE EMAIL FROM STATE
    await axios.delete(`/api/email/${e}`).catch((error) => {
      console.log(error);
    });
    dispatch({
      type: DELETE_EMAIL,
      payload: e,
    });
    // DELETE EMAIL ATTACHMENT FROM STATE
    dispatch({
      type: DELETE_EMAIL_ATTACHMENTS,
      payload: e,
    });
  };

  const getEmails = async (e) => {
    try {
      const res = await axios.get(`/api/email/app/${e}`);
      dispatch({
        type: GET_EMAILS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getEmailAttachments = async (e) => {
    try {
      const res = await axios.get(`/api/email/emailAttachments/${e}`);
      dispatch({
        type: GET_EMAIL_ATTACHMENTS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const saveEmail = async (data) => {
    try {
      const res = await axios.post('/api/email/save', data, config);
      dispatch({
        type: SAVE_EMAIL,
        payload: res.data.email,
      });
      if (res.data.attachments !== undefined) {
        for (let i = 0; i < res.data.attachments.length; i++) {
          dispatch({
            type: SAVE_ATTACHMENTS,
            payload: res.data.attachments[i],
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const editEmailSend = async (data) => {
    const updatedData = {
      recipient_to: data.recipient_to,
      recipient_cc: data.recipient_cc,
      recipient_bcc: data.recipient_bcc,
      email_subject: data.email_subject,
      email_body: data.email_body,
      email_appId: data.email_appId,
      email_sender: data.email_sender,
      email_status: data.email_status,
      files: data.files,
    };
    await axios.put(`/api/email/save/${data._id}`, updatedData, config);
  };

  return (
    <EmailContext.Provider
      value={{
        emails: state.emails,
        emailLoading: state.emailLoading,
        emailAttachments: state.emailAttachments,
        saveEmail,
        editEmailSend,
        editEmail,
        deleteEmail,
        getEmails,
        sendEmail,
        sendNotifyEmail,
        saveAttachments,
        getEmailAttachments,
        deleteEmailAttachment,
      }}
    >
      {props.children}
    </EmailContext.Provider>
  );
};

export default EmailState;
