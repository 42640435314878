import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import AppContext from '../../../context/app/appContext';
import AdminContext from '../../../context/admin/adminContext';
import EmailContext from '../../../context/email/emailContext';
import ContactsContext from '../../../context/contacts/contactsContext';
import EmailTemplateContext from '../../../context/emailTemplates/emailTemplatesContext';
import AuthContext from '../../../context/auth/authContext';
import EmailTextEditor from '../../../utils/EmailTextEditor';
import ReactHtmlParser from 'react-html-parser';
import formatDateTimeSeconds from '../../utils/dateTimeSeconds';
import axios from 'axios';

const AdminAppEmail = (props) => {
  const appContext = useContext(AppContext);
  const adminContext = useContext(AdminContext);
  const authContext = useContext(AuthContext);
  const emailContext = useContext(EmailContext);
  const contactsContext = useContext(ContactsContext);
  const emailTemplateContext = useContext(EmailTemplateContext);
  const { user } = authContext;
  const { startEditing, doneEditing } = adminContext;

  const {
    emailTemplates,
    getTemplates,
    emailTemplatesAttachments,
    clearEmailTemplateAttachments,
    getTemplateAttachments,
    copyFileFromTemplateToEmailAttachment,
  } = emailTemplateContext;
  const {
    saveEmail,
    editEmailSend,
    editEmail,
    deleteEmail,
    getEmails,
    emails,
    emailLoading,
    sendEmail,
    saveAttachments,
    emailAttachments,
    getEmailAttachments,
    deleteEmailAttachment,
  } = emailContext;
  const { app } = appContext;
  const { getContacts, contacts } = contactsContext;
  const [newEmail, setNewEmail] = useState({
    email_body: '',
    recipient_to: '',
    recipient_cc: '',
    recipient_bcc: '',
    email_sender: '',
    email_subject: '',
    email_status: '',
    email_appId: '',
    recipient_to2: '',
    recipient_cc2: '',
    recipient_bcc2: '',
  });
  const [newEmailBody, setNewEmailBody] = useState('');
  const [editedEmailBody, setEditedEmailBody] = useState('');
  const [editedEmail, setEditedEmail] = useState({
    email_body2: '',
    recipient_to2: '',
    recipient_cc2: '',
    recipient_bcc2: '',
    email_sender2: '',
    email_subject2: '',
    email_status2: '',
    email_appId2: '',
  });
  const [editedEmailId, setEditedEmailId] = useState();

  const fileInputRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showEmailBody, setShowEmailBody] = useState(false);
  const [applicationEmails, setApplicationEmails] = useState([]);
  const [deletedSavedFiles, setDeletedSavedFiles] = useState();
  const [savedFiles, setSavedFiles] = useState([]);
  const [ccEmailChips, setCcEmailChips] = useState([]);
  const [toEmailChips, setToEmailChips] = useState([]);
  const [bccEmailChips, setBccEmailChips] = useState([]);
  const [autoCompleteTo, setAutoCompleteTo] = useState([]);
  const [autoCompleteCc, setAutoCompleteCc] = useState([]);
  const [autoCompleteBcc, setAutoCompleteBcc] = useState([]);
  const [showEmailEditor, setShowEmailEditor] = useState(false);
  const [createEmailButton, setCreateEmailButton] = useState(true);
  const [showExistingEmailEditor, setShowExistingEmailEditor] = useState(false);
  const [sortedEmails, setSortedEmails] = useState([]);
  const [templateFiles, setTemplateFiles] = useState([]);
  const [toRequired, setToRequired] = useState(false);
  const [removeEmailId, setRemoveEmailId] = useState(null);

  const { sort } = props;

  const useScroll = () => {
    const htmlElRef = useRef(null);
    const executeScroll = () => {
      window.scrollTo(0, htmlElRef.current.offsetTop);
    };

    return [executeScroll, htmlElRef];
  };

  const [executeScroll, elementToScrollRef] = useScroll();

  useEffect(() => {
    if (user.email_signature !== null) {
      setNewEmailBody(user.email_signature);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTemplateFiles(emailTemplatesAttachments);
  }, [emailTemplatesAttachments]);

  useEffect(() => {
    if (applicationEmails !== null) {
      if (sort === 'Ascending') {
        const emailsSorted = applicationEmails.slice().sort((a, b) => {
          return new Date(a.updatedAt) - new Date(b.updatedAt);
        });
        setSortedEmails(emailsSorted);
      } else {
        const emailsSorted = applicationEmails.slice().sort((a, b) => {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        });
        setSortedEmails(emailsSorted);
      }
    }
    // eslint-disable-next-line
  }, [applicationEmails, props.sort]);

  useEffect(() => {
    if (showEmailEditor) {
      if (isAndroid) {
        document.getElementById('recipient_to').placeholder =
          'Press COMMA after each Email Address';
        document.getElementById('recipient_cc').placeholder =
          'Press COMMA after each Email Address';
        document.getElementById('recipient_bcc').placeholder =
          'Press COMMA after each Email Address';
      } else {
        document.getElementById('recipient_to').placeholder =
          'Press ENTER or COMMA after each Email Address';

        document.getElementById('recipient_cc').placeholder =
          'Press ENTER or COMMA after each Email Address';

        document.getElementById('recipient_bcc').placeholder =
          'Press ENTER or COMMA after each Email Address';
      }
    }
    if (showExistingEmailEditor) {
      if (isAndroid) {
        document.getElementById('recipient_to2').placeholder =
          'Press COMMA after each Email Address';
        document.getElementById(
          'recipient_cc'
        ).placeholder = document.getElementById('recipient_cc2').placeholder =
          'Press COMMA after each Email Address';

        document.getElementById('recipient_bcc2').placeholder =
          'Press COMMA after each Email Address';
      } else {
        document.getElementById('recipient_to2').placeholder =
          'Press ENTER or COMMA after each Email Address';

        document.getElementById('recipient_cc2').placeholder =
          'Press ENTER or COMMA after each Email Address';

        document.getElementById('recipient_bcc2').placeholder =
          'Press ENTER or COMMA after each Email Address';
      }
    }
    // eslint-disable-next-line
  }, [showExistingEmailEditor, showEmailEditor]);

  useEffect(() => {
    if (app !== null) {
      getEmails(app._id);
      getEmailAttachments(app._id);
      getTemplates();
      getContacts();
      setNewEmail({
        email_sender: user.Username,
        email_appId: app._id,
        email_body: '',
        recipient_to: '',
        recipient_cc: '',
        recipient_bcc: '',
        email_subject: '',
        email_status: '',
        recipient_to2: '',
        recipient_cc2: '',
        recipient_bcc2: '',
      });
      setToEmailChips([app.Email]);
    }
    // eslint-disable-next-line
  }, [app]);

  useEffect(() => {
    if (toEmailChips.length === 0) {
      setToRequired(true);
    } else {
      setToRequired(false);
    }
  }, [toEmailChips]);

  useEffect(() => {
    if (
      emails !== null &&
      emailAttachments !== null &&
      emails !== undefined &&
      emailAttachments !== undefined
    ) {
      let appEmails = [];
      for (let i = 0; i < emails.length; i++) {
        const attachments = emailAttachments.filter((attachment) => {
          return attachment.email_id === emails[i]._id;
        });

        const appEmail = {
          _id: emails[i]._id,
          updatedAt: emails[i].updatedAt,
          email_status: emails[i].email_status,
          recipient_to: emails[i].recipient_to,
          recipient_cc: emails[i].recipient_cc,
          recipient_bcc: emails[i].recipient_bcc,
          email_subject: emails[i].email_subject,
          attachments: attachments,
          email_body: emails[i].email_body,
          email_sender: emails[i].email_sender,
        };

        appEmails.push(appEmail);
      }
      setApplicationEmails(appEmails);
    }
  }, [emails, emailAttachments]);

  const showTheBody = (e) => {
    setShowEmailBody((prev) => ({ ...prev, [e]: !prev[e] }));
  };

  const chooseTemplate = (e) => {
    if (e.target.value === '') {
      setNewEmail({
        email_body: '',
        recipient_to: '',
        recipient_cc: '',
        recipient_bcc: '',
        email_sender: '',
        email_subject: '',
        email_status: '',
        email_appId: '',
        recipient_to2: '',
        recipient_cc2: '',
        recipient_bcc2: '',
      });
      if (user.email_signature !== null) {
        setNewEmailBody(user.email_signature);
        setEditedEmailBody(user.email_signature);
      } else {
        setNewEmailBody('');
        setEditedEmailBody('');
      }
    } else {
      let templates = emailTemplates;
      getTemplateAttachments(e.target.value);
      templates = templates.filter((template) => {
        return parseInt(e.target.value) === parseInt(template._id);
      });

      setNewEmail({
        ...newEmail,
        email_subject: templates[0].template_subject,
      });
      document.getElementById('email_subject').value =
        templates[0].template_subject;
      if (user.email_signature !== null) {
        setNewEmailBody(templates[0].template_email + user.email_signature);
        setEditedEmailBody(templates[0].template_email + user.email_signature);
      } else {
        setNewEmailBody(templates[0].template_email);
        setEditedEmailBody(templates[0].template_email);
      }
    }
  };

  var ua = navigator.userAgent.toLowerCase();
  var isAndroid = ua.indexOf('android') > -1;

  const on_change = (e) => {
    setNewEmail({ ...newEmail, [e.target.id]: e.target.value });
  };

  const toAutoComplete = (e) => {
    if (isAndroid) {
      if (e.target.value.indexOf(',') !== -1) {
        var emailAddresses = [];

        if (e.target.id === 'recipient_to') {
          emailAddresses = newEmail.recipient_to.trim();
          setNewEmail({ ...newEmail, [e.target.id]: '' });
          document.getElementById('recipient_to').value = '';
          setAutoCompleteTo([]);
        }
        if (emailAddresses) {
          setToEmailChips([...toEmailChips, emailAddresses]);
        }

        if (e.target.id === 'recipient_to2') {
          emailAddresses = newEmail.recipient_to.trim();
          setNewEmail({ ...newEmail, recipient_to: '' });
          document.getElementById('recipient_to2').value = '';
          setAutoCompleteTo([]);
        }
        if (emailAddresses) {
          setToEmailChips([...toEmailChips, emailAddresses]);
        }
      } else {
        setNewEmail({ ...newEmail, recipient_to: e.target.value });
      }
    } else {
      if (e.target.id === 'recipient_to') {
        setNewEmail({ ...newEmail, [e.target.id]: e.target.value });
      }
      if (e.target.id === 'recipient_to2') {
        setEditedEmail({ ...editedEmail, [e.target.id]: e.target.value });
      }
      if (contacts !== null) {
        const results = contacts.filter((contact) => {
          const regex = new RegExp(`${e.target.value}`, 'gi');
          return (
            contact.First_Name.match(regex) ||
            contact.Last_Name.match(regex) ||
            contact.Email_Address.match(regex)
          );
        });
        setAutoCompleteTo(results);
      }
    }
  };

  const ccAutoComplete = (e) => {
    if (isAndroid) {
      if (e.target.value.indexOf(',') !== -1) {
        var emailAddresses = [];

        if (e.target.id === 'recipient_cc') {
          emailAddresses = newEmail.recipient_cc.trim();
          setNewEmail({ ...newEmail, [e.target.id]: '' });
          document.getElementById('recipient_cc').value = '';
          setAutoCompleteCc([]);
        }
        if (emailAddresses) {
          setCcEmailChips([...ccEmailChips, emailAddresses]);
        }

        if (e.target.id === 'recipient_cc2') {
          emailAddresses = newEmail.recipient_cc.trim();
          setNewEmail({ ...newEmail, recipient_cc: '' });
          document.getElementById('recipient_cc2').value = '';
          setAutoCompleteCc([]);
        }
        if (emailAddresses) {
          setCcEmailChips([...ccEmailChips, emailAddresses]);
        }
      } else {
        setNewEmail({ ...newEmail, recipient_cc: e.target.value });
      }
    } else {
      if (e.target.id === 'recipient_cc') {
        setNewEmail({ ...newEmail, [e.target.id]: e.target.value });
      }
      if (e.target.id === 'recipient_cc2') {
        setEditedEmail({ ...editedEmail, [e.target.id]: e.target.value });
      }

      if (contacts !== null) {
        const results = contacts.filter((contact) => {
          const regex = new RegExp(`${e.target.value}`, 'gi');
          return (
            contact.First_Name.match(regex) ||
            contact.Last_Name.match(regex) ||
            contact.Email_Address.match(regex)
          );
        });
        setAutoCompleteCc(results);
      }
    }
  };
  const bccAutoComplete = (e) => {
    if (isAndroid) {
      if (e.target.value.indexOf(',') !== -1) {
        var emailAddresses = [];

        if (e.target.id === 'recipient_bcc') {
          emailAddresses = newEmail.recipient_bcc.trim();
          setNewEmail({ ...newEmail, [e.target.id]: '' });
          document.getElementById('recipient_bcc').value = '';
          setAutoCompleteBcc([]);
        }
        if (emailAddresses) {
          setBccEmailChips([...bccEmailChips, emailAddresses]);
        }

        if (e.target.id === 'recipient_bcc2') {
          emailAddresses = newEmail.recipient_bcc.trim();
          setNewEmail({ ...newEmail, recipient_bcc: '' });
          document.getElementById('recipient_bcc2').value = '';
          setAutoCompleteBcc([]);
        }
        if (emailAddresses) {
          setBccEmailChips([...bccEmailChips, emailAddresses]);
        }
      } else {
        setNewEmail({ ...newEmail, recipient_bcc: e.target.value });
      }
    } else {
      if (e.target.id === 'recipient_bcc') {
        setNewEmail({ ...newEmail, [e.target.id]: e.target.value });
      }
      if (e.target.id === 'recipient_bcc2') {
        setEditedEmail({ ...editedEmail, [e.target.id]: e.target.value });
      }

      if (contacts !== null) {
        const results = contacts.filter((contact) => {
          const regex = new RegExp(`${e.target.value}`, 'gi');
          return (
            contact.First_Name.match(regex) ||
            contact.Last_Name.match(regex) ||
            contact.Email_Address.match(regex)
          );
        });
        setAutoCompleteBcc(results);
      }
    }
  };

  const selectAutoCompleteTo = (email_address, type) => {
    if (type === 'new') {
      setToEmailChips([...toEmailChips, email_address]);
      setAutoCompleteTo([]);
      setNewEmail({ ...newEmail, recipient_to: '' });
      document.getElementById('recipient_to').value = '';
    }
    if (type === 'edit') {
      setToEmailChips([...toEmailChips, email_address]);
      setAutoCompleteTo([]);
      setEditedEmail({ ...editedEmail, recipient_to2: '' });
      document.getElementById('recipient_to2').value = '';
    }
  };
  const selectAutoCompleteCc = (email_address, type) => {
    if (type === 'new') {
      setCcEmailChips([...ccEmailChips, email_address]);
      setAutoCompleteCc([]);
      setNewEmail({ ...newEmail, recipient_cc: '' });
      document.getElementById('recipient_cc').value = '';
    }
    if (type === 'edit') {
      setCcEmailChips([...ccEmailChips, email_address]);
      setAutoCompleteCc([]);
      setEditedEmail({ ...editedEmail, recipient_cc2: '' });
      document.getElementById('recipient_cc2').value = '';
    }
  };
  const selectAutoCompleteBcc = (email_address, type) => {
    if (type === 'new') {
      setBccEmailChips([...bccEmailChips, email_address]);
      setAutoCompleteBcc([]);
      setNewEmail({ ...newEmail, recipient_bcc: '' });
      document.getElementById('recipient_bcc').value = '';
    }
    if (type === 'edit') {
      setBccEmailChips([...bccEmailChips, email_address]);
      setAutoCompleteBcc([]);
      setEditedEmail({ ...editedEmail, recipient_bcc2: '' });
      document.getElementById('recipient_bcc2').value = '';
    }
  };

  // *  EMAIL CHIPS FOR EMAIL MESSAGES
  const handleKeyDownTO = (evt) => {
    var emailAddresses = [];
    if (['Enter', ','].includes(evt.key)) {
      evt.preventDefault();

      if (evt.target.id === 'recipient_to') {
        emailAddresses = newEmail.recipient_to.trim();
        setNewEmail({ ...newEmail, [evt.target.id]: '' });
        document.getElementById('recipient_to').value = '';
        setAutoCompleteTo([]);
      }
      if (evt.target.id === 'recipient_to2') {
        emailAddresses = editedEmail.recipient_to2.trim();
        setEditedEmail({ ...editedEmail, [evt.target.id]: '' });
        document.getElementById('recipient_to2').value = '';
        setAutoCompleteTo([]);
      }

      if (emailAddresses) {
        setToEmailChips([...toEmailChips, emailAddresses]);
      }
    }
  };

  const handleKeyDownCC = (evt) => {
    if (['Enter', ','].includes(evt.key)) {
      evt.preventDefault();
      var emailAddresses = [];

      if (evt.target.id === 'recipient_cc') {
        emailAddresses = newEmail.recipient_cc.trim();
        setNewEmail({ ...newEmail, [evt.target.id]: '' });
        document.getElementById('recipient_cc').value = '';
      }
      if (evt.target.id === 'recipient_cc2') {
        emailAddresses = editedEmail.recipient_cc2.trim();
        setEditedEmail({ ...editedEmail, [evt.target.id]: '' });
        document.getElementById('recipient_cc2').value = '';
      }

      if (emailAddresses) {
        setCcEmailChips([...ccEmailChips, emailAddresses]);
      }
    }
  };

  const handleKeyDownBCC = (evt) => {
    if (['Enter', ','].includes(evt.key)) {
      evt.preventDefault();
      var emailAddresses = [];

      if (evt.target.id === 'recipient_bcc') {
        emailAddresses = newEmail.recipient_bcc.trim();
        setNewEmail({ ...newEmail, [evt.target.id]: '' });
        document.getElementById('recipient_bcc').value = '';
      }
      if (evt.target.id === 'recipient_bcc2') {
        emailAddresses = editedEmail.recipient_bcc2.trim();
        setEditedEmail({ ...editedEmail, [evt.target.id]: '' });
        document.getElementById('recipient_bcc2').value = '';
      }

      if (emailAddresses) {
        setBccEmailChips([...bccEmailChips, emailAddresses]);
      }
    }
  };

  const handleDeleteTO = (tbr) => {
    setToEmailChips(toEmailChips.filter((email) => email !== tbr));
  };
  const handleDeleteCC = (tbr) => {
    setCcEmailChips(ccEmailChips.filter((email) => email !== tbr));
  };
  const handleDeleteBCC = (tbr) => {
    setBccEmailChips(bccEmailChips.filter((email) => email !== tbr));
  };

  // ! END OF EMAIL CHIPS

  const cancelNewEmailEntry = () => {
    setNewEmail({
      recipient_to: '',
      email_sender: user.Username,
      email_appId: app._id,
      email_body: '',
      recipient_cc: '',
      recipient_bcc: '',
      email_subject: '',
      email_status: '',
      recipient_to2: '',
      recipient_cc2: '',
      recipient_bcc2: '',
    });
    document.getElementById('template_name').value = '';
    document.getElementById('recipient_cc').value = '';
    document.getElementById('recipient_bcc').value = '';
    document.getElementById('email_subject').value = '';
    if (user.email_signature !== null) {
      setNewEmailBody(user.email_signature);
    } else {
      setNewEmailBody('');
    }
    setEditedEmailBody('');
    setSelectedFiles([]);
    fileInputRef.current.value = '';
    setToEmailChips([app.Email]);
    setCcEmailChips([]);
    setBccEmailChips([]);
    setAutoCompleteTo([]);
    setAutoCompleteCc([]);
    setAutoCompleteBcc([]);
    setTemplateFiles([]);
    setCreateEmailButton(true);
    setShowEmailEditor(false);
    doneEditing();
  };

  const newSendEmail = () => {
    // ! CONFIRM TO: EMAIL ADDRESS EXISTS
    if (toEmailChips.length === 0) {
      window.scroll(0, 0);
    } else {
      var fileNames = [];
      // * ***** FUNCTION TO SEND EMAIL *****
      const processEmail = () => {
        const toString = toEmailChips.toString().split(',').join(', ');
        const ccString = ccEmailChips.toString().split(',').join(', ');
        const bccString = bccEmailChips.toString().split(',').join(', ');

        const data = {
          recipient_to: toString,
          recipient_cc: ccString,
          recipient_bcc: bccString,
          email_subject: newEmail.email_subject,
          email_body: newEmailBody,
          email_appId: newEmail.email_appId,
          email_sender: user.Username,
          email_status: 'MESSAGE SENT',
          files: fileNames,
        };
        saveEmail(data);
        setTimeout(() => {
          sendEmail(data);
        }, 9000);
      };
      // * ******* Check for FILE Attachments if no send email

      if (templateFiles.length > 0 || selectedFiles.length > 0) {
        //! ***** PROCESS EMAILS WITH ATTACHMENTS
        var templateAttachmentCount = templateFiles.length;
        var selectedFilesAttachedCount = selectedFiles.length;

        // ! ****************** FUNCTIONS ******************************

        const processSelectedAttachments = (x, selectedFileCount) => {
          const date = Date.now();
          const lastIndex = x.name.lastIndexOf('.') + 1;
          const fName = x.name.slice(0, lastIndex - 1);
          const ext = x.name.slice(lastIndex);
          const newFileName = `${fName}-${date}.${ext}`;

          const attachment = {
            file: x,
            fileName: newFileName,
          };
          saveAttachments(attachment);
          fileNames.push(newFileName);

          if (selectedFileCount === 0) {
            //* ********* FILE ATTACHEMENTS ARE ALL PROCESSED - SEND EMAIL NOW
            processEmail();
          }
        };

        const processTemplateAttachment = (w, templateFileCount) => {
          const date = Date.now();
          const fName = w.fileName.split('-')[0];
          const lastIndex = w.fileName.lastIndexOf('.') + 1;
          const ext = w.fileName.slice(lastIndex);
          const newFileName = `${fName}-${date}.${ext}`;

          const fileToCopy = {
            fileName: w.fileName,
            newFileName: newFileName,
          };

          copyFileFromTemplateToEmailAttachment(fileToCopy);
          // ADD DATABASE ENTRY FOR FILE ATTACHMENT
          fileNames.push(newFileName);

          if (templateFileCount === 0) {
            // Template Attachments done - Move to SelectedFiles
            if (selectedFiles.length === 0) {
              //* ********* NO SELECTED FILES  - SEND EMAIL NOW
              processEmail();
            } else {
              var i;
              for (i = 0; i < selectedFiles.length; i++) {
                selectedFilesAttachedCount = selectedFilesAttachedCount - 1;
                processSelectedAttachments(
                  selectedFiles[i],
                  selectedFilesAttachedCount
                );
              }
            }
          }
        };
        if (templateFiles.length === 0) {
          var r;
          for (r = 0; r < selectedFiles.length; r++) {
            selectedFilesAttachedCount = selectedFilesAttachedCount - 1;
            processSelectedAttachments(
              selectedFiles[r],
              selectedFilesAttachedCount
            );
          }
        } else {
          var x;
          for (x = 0; x < templateFiles.length; x++) {
            templateAttachmentCount = templateAttachmentCount - 1;
            processTemplateAttachment(
              templateFiles[x],
              templateAttachmentCount
            );
          }
        }
      } else {
        //* ***** PROCESS EMAILS WITHOUT ATTACHMENTS
        processEmail();
      }
      // ****** RESETTING THE NEW EMAIL FORM *******
      setNewEmail({
        recipient_to: '',
        email_sender: user.Username,
        email_appId: app._id,
        email_body: '',
        recipient_cc: '',
        recipient_bcc: '',
        email_subject: '',
        email_status: '',
        recipient_to2: '',
        recipient_cc2: '',
        recipient_bcc2: '',
      });
      document.getElementById('template_name').value = '';
      document.getElementById('recipient_cc').value = '';
      document.getElementById('recipient_bcc').value = '';
      document.getElementById('email_subject').value = '';
      if (user.email_signature !== null) {
        setNewEmailBody(user.email_signature);
      } else {
        setNewEmailBody('');
      }
      setEditedEmailBody('');
      setSelectedFiles([]);
      setToEmailChips([app.Email]);
      setCcEmailChips([]);
      setBccEmailChips([]);
      setAutoCompleteTo([]);
      setAutoCompleteCc([]);
      setAutoCompleteBcc([]);
      setTemplateFiles([]);
      fileInputRef.current.value = '';
      setShowEmailEditor(false);
      setCreateEmailButton(true);
      clearEmailTemplateAttachments();
      doneEditing();
    }
  };

  const save_email = (e) => {
    var fileNames = [];
    if (templateFiles.length > 0) {
      // MAKE COPY OF TEMPLATE ATTACHEMENTS TO EMAIL ATTACHMENTS
      var w;
      for (w of templateFiles) {
        const date = Date.now();
        const fName = w.fileName.split('-')[0];
        const lastIndex = w.fileName.lastIndexOf('.') + 1;
        const ext = w.fileName.slice(lastIndex);
        const newFileName = `${fName}-${date}.${ext}`;

        const fileToCopy = {
          fileName: w.fileName,
          newFileName: newFileName,
        };
        copyFileFromTemplateToEmailAttachment(fileToCopy);
        // ADD DATABASE ENTRY FOR FILE ATTACHMENT
        fileNames.push(newFileName);
      }
    }

    var x;
    for (x of selectedFiles) {
      const date = Date.now();
      const lastIndex = x.name.lastIndexOf('.') + 1;
      const fName = x.name.slice(0, lastIndex - 1);
      const ext = x.name.slice(lastIndex);
      // const ext = x.name.split('.')[1];
      const newFileName = `${fName}-${date}.${ext}`;

      const attachment = {
        file: x,
        fileName: newFileName,
      };
      saveAttachments(attachment);
      fileNames.push(newFileName);
    }

    const toString = toEmailChips.toString().split(',').join(', ');
    const ccString = ccEmailChips.toString().split(',').join(', ');
    const bccString = bccEmailChips.toString().split(',').join(', ');

    const data = {
      recipient_to: toString,
      recipient_cc: ccString,
      recipient_bcc: bccString,
      email_subject: newEmail.email_subject,
      email_body: newEmailBody,
      email_appId: newEmail.email_appId,
      email_sender: user.Username,
      email_status: 'SAVED',
      files: fileNames,
    };
    saveEmail(data);

    setNewEmail({
      recipient_to: '',
      email_sender: user.Username,
      email_appId: app._id,
      email_body: '',
      recipient_cc: '',
      recipient_bcc: '',
      email_subject: '',
      email_status: '',
      recipient_to2: '',
      recipient_cc2: '',
      recipient_bcc2: '',
    });

    document.getElementById('template_name').value = '';
    document.getElementById('recipient_cc').value = '';
    document.getElementById('recipient_bcc').value = '';
    document.getElementById('email_subject').value = '';
    if (user.email_signature !== null) {
      setNewEmailBody(user.email_signature);
    } else {
      setNewEmailBody('');
    }
    setEditedEmailBody('');
    setSelectedFiles([]);
    setToEmailChips([app.Email]);
    setCcEmailChips([]);
    setBccEmailChips([]);
    setAutoCompleteTo([]);
    setAutoCompleteCc([]);
    setAutoCompleteBcc([]);
    setTemplateFiles([]);
    fileInputRef.current.value = '';
    setShowEmailEditor(false);
    setCreateEmailButton(true);
    clearEmailTemplateAttachments();
    doneEditing();
  };

  const on_edit = (e) => {
    setEditedEmail({ ...editedEmail, [e.target.id]: e.target.value });
  };

  const cancelEdit = (e) => {
    setEditedEmailId('');
    setEditedEmail('');
    document.getElementById('recipient_to2').value = '';
    document.getElementById('recipient_cc2').value = '';
    document.getElementById('recipient_bcc2').value = '';
    document.getElementById('email_subject2').value = '';
    if (user.email_signature !== null) {
      setNewEmailBody(user.email_signature);
    } else {
      setNewEmailBody('');
    }
    setEditedEmailBody('');
    setSavedFiles([]);
    setSelectedFiles([]);
    setDeletedSavedFiles('');
    setToEmailChips([app.Email]);
    setCcEmailChips([]);
    setBccEmailChips([]);
    setAutoCompleteTo([]);
    setAutoCompleteCc([]);
    setAutoCompleteBcc([]);
    fileInputRef.current.value = '';
    setShowEmailEditor(false);
    setCreateEmailButton(true);
    setShowExistingEmailEditor(false);
    clearEmailTemplateAttachments();
    doneEditing();
  };

  const insertEditEmail = (e) => {
    startEditing();
    const oldEmailData = emails.filter(
      (email) => email._id === parseInt(e.target.id)
    );
    const regex = RegExp(',');

    if (regex.test(oldEmailData[0].recipient_to)) {
      const toAddresses = oldEmailData[0].recipient_to.split(',');
      setToEmailChips(toAddresses);
    } else {
      setToEmailChips([oldEmailData[0].recipient_to]);
    }
    if (oldEmailData[0].recipient_cc.length > 0) {
      if (regex.test(oldEmailData[0].recipient_cc)) {
        const ccAddresses = oldEmailData[0].recipient_cc.split(',');
        setCcEmailChips(ccAddresses);
      } else {
        setCcEmailChips([oldEmailData[0].recipient_cc]);
      }
    } else {
      setCcEmailChips([]);
    }

    if (oldEmailData[0].recipient_bcc.length > 0) {
      if (regex.test(oldEmailData[0].recipient_bcc)) {
        const bccAddresses = oldEmailData[0].recipient_bcc.split(',');
        setBccEmailChips(bccAddresses);
      } else {
        setBccEmailChips([oldEmailData[0].recipient_bcc]);
      }
    } else {
      setBccEmailChips([]);
    }

    setEditedEmail({
      recipient_to2: '',
      recipient_cc2: '',
      recipient_bcc2: '',
      email_subject2: oldEmailData[0].email_subject,
      email_appId2: oldEmailData[0]._id,
      email_sender2: oldEmailData[0].email_sender,
    });
    setEditedEmailBody(oldEmailData[0].email_body);
    setEditedEmailId(parseInt(e.target.id));

    for (let i = 0; i < emailAttachments.length; i++) {
      const attachments = emailAttachments.filter((attachment) => {
        return attachment.email_id === oldEmailData[0]._id;
      });
      if (attachments.length > 0) {
        setSavedFiles(attachments);
      }
    }
    setShowEmailEditor(false);
    setCreateEmailButton(false);
    setShowExistingEmailEditor(true);
    executeScroll();
  };

  const update_email_send = (e) => {
    if (toEmailChips.length === 0) {
      window.scroll(0, 0);
    } else {
      // *  START ---> SAVE NEW FILE ATTACHMENTS
      var fileNames = [];
      var x;
      for (x of selectedFiles) {
        const date = Date.now();
        const lastIndex = x.name.lastIndexOf('.') + 1;
        const fName = x.name.slice(0, lastIndex - 1);
        const ext = x.name.slice(lastIndex);
        // const ext = x.name.split('.')[1];
        const newFileName = `${fName}-${date}.${ext}`;

        const attachment = {
          file: x,
          fileName: newFileName,
        };
        saveAttachments(attachment);
        fileNames.push(newFileName);
      }
      // !  END ----> SAVE NEW FILE ATTACHMENTS

      // * START --->  ADD EXISTING SAVED FILES TO  FILE NAMES ARRAY FOR RECORD UPADATE
      var y;
      for (y of savedFiles) {
        const savedFileNames = y.fileName;

        fileNames.push(savedFileNames);
      }

      // ! END --->  ADD EXISTING SAVED FILES TO  FILE NAMES ARRAY  *************************

      // * START ---> DELETE SAVED FILES THAT WERE REMOVED

      if (deletedSavedFiles !== undefined) {
        if (deletedSavedFiles.length === 1) {
          deleteEmailAttachment(deletedSavedFiles);
        } else if (deletedSavedFiles.length > 1) {
          var z;
          for (z of deletedSavedFiles) {
            deleteEmailAttachment(z);
          }
        }
      }
      // ! END ---> DELETED SAVED FILES THAT WERE REMVOED  *******************
      // * START  --> Edit EMAIL and ATTACHMENTS

      const toString = toEmailChips.toString().split(',').join(', ');
      const ccString = ccEmailChips.toString().split(',').join(', ');
      const bccString = bccEmailChips.toString().split(',').join(', ');

      const data = {
        _id: editedEmailId,
        recipient_to: toString,
        recipient_cc: ccString,
        recipient_bcc: bccString,
        email_subject: editedEmail.email_subject2,
        email_body: editedEmailBody,
        email_appId: app._id,
        email_sender: user.Username,
        email_status: 'MESSAGE SENT FROM SAVE',
        files: fileNames,
      };

      editEmail(data);
      setTimeout(() => {
        editEmailSend(data);
      }, 9000);

      // ! END --> EDIT EMAIL

      // CLEAR EMAIL DATA FROM FORMS

      setEditedEmailId('');
      setEditedEmail('');
      document.getElementById('recipient_to2').value = '';
      document.getElementById('recipient_cc2').value = '';
      document.getElementById('recipient_bcc2').value = '';
      document.getElementById('email_subject2').value = '';
      if (user.email_signature !== null) {
        setNewEmailBody(user.email_signature);
      } else {
        setNewEmailBody('');
      }
      setEditedEmailBody('');
      setSavedFiles([]);
      setSelectedFiles([]);
      setDeletedSavedFiles('');
      setToEmailChips([app.Email]);
      setCcEmailChips([]);
      setBccEmailChips([]);
      setAutoCompleteTo([]);
      setAutoCompleteCc([]);
      setAutoCompleteBcc([]);
      fileInputRef.current.value = '';
      setShowEmailEditor(false);
      setCreateEmailButton(true);
      setShowExistingEmailEditor(false);
      clearEmailTemplateAttachments();
      doneEditing();
    }
  };

  const save_editedEmail = (e) => {
    // *  START ---> SAVE NEW FILE ATTACHMENTS
    var fileNames = [];
    var x;
    for (x of selectedFiles) {
      const date = Date.now();
      const lastIndex = x.name.lastIndexOf('.') + 1;
      const fName = x.name.slice(0, lastIndex - 1);
      const ext = x.name.slice(lastIndex);
      // const ext = x.name.split('.')[1];
      const newFileName = `${fName}-${date}.${ext}`;

      const attachment = {
        file: x,
        fileName: newFileName,
      };
      saveAttachments(attachment);
      fileNames.push(newFileName);
    }
    // !  END ----> SAVE NEW FILE ATTACHMENTS

    // * START --->  ADD EXISTING SAVED FILES TO  FILE NAMES ARRAY FOR RECORD UPADATE
    var y;
    for (y of savedFiles) {
      const savedFileNames = y.fileName;

      fileNames.push(savedFileNames);
    }

    // ! END --->  ADD EXISTING SAVED FILES TO  FILE NAMES ARRAY  *************************

    // * START ---> DELETE SAVED FILES THAT WERE REMOVED

    if (deletedSavedFiles !== undefined) {
      if (deletedSavedFiles.length === 1) {
        deleteEmailAttachment(deletedSavedFiles);
      } else if (deletedSavedFiles.length > 1) {
        var z;
        for (z of deletedSavedFiles) {
          deleteEmailAttachment(z);
        }
      }
    }
    // ! END ---> DELETED SAVED FILES THAT WERE REMVOED  *******************
    // * START  --> Edit EMAIL and ATTACHMENTS
    const toString = toEmailChips.toString().split(',').join(', ');
    const ccString = ccEmailChips.toString().split(',').join(', ');
    const bccString = bccEmailChips.toString().split(',').join(', ');

    const data = {
      _id: editedEmailId,
      recipient_to: toString,
      recipient_cc: ccString,
      recipient_bcc: bccString,
      email_subject: editedEmail.email_subject2,
      email_body: editedEmailBody,
      email_appId: app._id,
      email_sender: user.Username,
      email_status: 'SAVED',
      files: fileNames,
    };

    editEmail(data);

    // ! END --> EDIT EMAIL

    // CLEAR EMAIL DATA FROM FORMS

    setEditedEmailId('');
    setEditedEmail('');
    document.getElementById('recipient_to2').value = '';
    document.getElementById('recipient_cc2').value = '';
    document.getElementById('recipient_bcc2').value = '';
    document.getElementById('email_subject2').value = '';
    if (user.email_signature !== null) {
      setNewEmailBody(user.email_signature);
    } else {
      setNewEmailBody('');
    }
    setEditedEmailBody('');
    setSavedFiles([]);
    setSelectedFiles([]);
    setDeletedSavedFiles('');
    setToEmailChips([app.Email]);
    setCcEmailChips([]);
    setBccEmailChips([]);
    setAutoCompleteTo([]);
    setAutoCompleteCc([]);
    setAutoCompleteBcc([]);
    fileInputRef.current.value = '';
    setShowEmailEditor(false);
    setCreateEmailButton(true);
    setShowExistingEmailEditor(false);
    clearEmailTemplateAttachments();
    doneEditing();
  };

  const downloadAttachment = (id) => {
    axios(`/api/email/attachment/${id}`, {
      method: 'GET',
      responseType: 'blob',
    })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers.content_type,
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ! ****************** DROP ZONE  CODE  *****************

  const preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
      } else {
        files[i]['invalid'] = true;
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        setErrorMessage('File type not permitted');
      }
    }
  };

  const validateFile = (file) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon',
      'application/doc',
      'application/docx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/msword',
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  };

  const fileSize = (size) => {
    if (size === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const removeFile = (name) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== name);
    setSelectedFiles(updatedFiles);
    fileInputRef.current.value = '';
  };
  const removeTemplateFile = (name) => {
    const updatedFiles = templateFiles.filter((file) => file.fileName !== name);
    setTemplateFiles(updatedFiles);
  };

  const removeSavedFile = (name, id) => {
    let deleteFileIdList = [];
    const updatedFiles = savedFiles.filter((file) => file.fileName !== name);
    setSavedFiles(updatedFiles);
    if (deletedSavedFiles === undefined) {
      deleteFileIdList.push(id);
      setDeletedSavedFiles(deleteFileIdList);
    } else {
      deleteFileIdList.push(id);
      var z;
      for (z of deletedSavedFiles) {
        deleteFileIdList.push(z);
      }
      setDeletedSavedFiles(deleteFileIdList);
    }
  };

  //  ********************* DROP ZONE CODE END ***************************

  const toggleCreateEmail = () => {
    startEditing();
    setShowEmailEditor(true);
    setCreateEmailButton(false);
    executeScroll();
  };

  const deleteAEmail = (e) => {
    deleteEmail(e);
  };

  const removeEmail = (e) => {
    setRemoveEmailId(e.target.id);
  };

  const cancelDeletion = () => {
    setRemoveEmailId(null);
  };

  return (
    <Fragment>
      <div className="container-fluid my-3" ref={elementToScrollRef}>
        <div className="row">
          <div className="col-lg-10"></div>
          <div className="col-lg-2">
            {createEmailButton ? (
              <button
                type="button"
                className="btn btn-warning text-dark font-weight-bold"
                onClick={toggleCreateEmail}
              >
                CREATE EMAIL
              </button>
            ) : null}
          </div>
        </div>
        {showEmailEditor ? (
          <div className="container mt-3 bg-white rounded shadow">
            <div className="my-3">
              <h2 className="text-center font-weight-bolder">
                APPLICATION EMAIL EDITOR
              </h2>
            </div>
            <form autoComplete="off" className="m-3">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <select
                      className="form-control"
                      onChange={chooseTemplate}
                      id="template_name"
                    >
                      <option value="">EMAIL TEMPLATES </option>

                      {emailTemplates.map((template) => (
                        <option key={template._id} value={template._id}>
                          {template.template_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-lg-12">
                    <label
                      className="active font-weight-bolder"
                      htmlFor="recipient_to"
                    >
                      {toRequired ? (
                        <div className="text-danger font-weight-bold">
                          ******* REQUIRED ONE TO: RECIPIENT *****
                        </div>
                      ) : null}
                      TO:{' '}
                    </label>
                    {toEmailChips !== 0 ? (
                      toEmailChips.map((email) => (
                        <div key={email} className="email-chip">
                          {email}
                          <button
                            type="button"
                            onClick={() => handleDeleteTO(email)}
                            className="chip-button"
                          >
                            &times;
                          </button>
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      id="recipient_to"
                      type="text"
                      className="form-control"
                      defaultValue={newEmail.recipient_to}
                      onChange={toAutoComplete}
                      onKeyDown={handleKeyDownTO}
                      maxLength="100"
                      autoComplete="off"
                    />

                    <div className="autoComplete">
                      {autoCompleteTo !== null &&
                      newEmail.recipient_to !== '' ? (
                        autoCompleteTo.map((contact) => (
                          <div className="row" key={contact._id}>
                            <div
                              className="option"
                              tabIndex="0"
                              onClick={() =>
                                selectAutoCompleteTo(
                                  contact.Email_Address,
                                  'new'
                                )
                              }
                            >
                              <div className="col-2">{contact.First_Name}</div>
                              <div className="col-2 text-left">
                                {contact.Last_Name}
                              </div>
                              <div className="col-6 text-left">
                                {contact.Email_Address}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div> </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-lg-12">
                    <label
                      className="active font-weight-bolder"
                      htmlFor="recipient_cc"
                    >
                      CC:
                    </label>
                    {ccEmailChips.map((email) => (
                      <div key={email} className="email-chip">
                        {email}
                        <button
                          type="button"
                          onClick={() => handleDeleteCC(email)}
                          className="chip-button"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="col lg-12">
                    <input
                      id="recipient_cc"
                      type="text"
                      className="form-control"
                      placeholder="Press Enter or Comma after each Email Address"
                      defaultValue={newEmail.recipient_cc}
                      onChange={ccAutoComplete}
                      onKeyDown={handleKeyDownCC}
                      maxLength="100"
                      autoComplete="off"
                    />
                    <div className="autoComplete">
                      {autoCompleteCc !== null &&
                      newEmail.recipient_cc !== '' ? (
                        autoCompleteCc.map((contact) => (
                          <div className="row" key={contact._id}>
                            <div
                              className="option"
                              tabIndex="0"
                              onClick={() =>
                                selectAutoCompleteCc(
                                  contact.Email_Address,
                                  'new'
                                )
                              }
                            >
                              <div className="col-2">{contact.First_Name}</div>
                              <div className="col-2 text-left">
                                {contact.Last_Name}
                              </div>
                              <div className="col-6 text-left">
                                {contact.Email_Address}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div> </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-lg-12">
                    <label
                      className="active font-weight-bolder"
                      htmlFor="recipient_bcc"
                    >
                      BCC:
                    </label>
                    {bccEmailChips !== 0 ? (
                      bccEmailChips.map((email) => (
                        <div key={email} className="email-chip">
                          {email}
                          <button
                            type="button"
                            onClick={() => handleDeleteBCC(email)}
                            className="chip-button"
                          >
                            &times;
                          </button>
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="col lg-12">
                    <input
                      id="recipient_bcc"
                      type="text"
                      className="form-control"
                      placeholder="Press Enter or Comma after each Email Address"
                      defaultValue={newEmail.recipient_bcc}
                      onChange={bccAutoComplete}
                      onKeyDown={handleKeyDownBCC}
                      maxLength="100"
                      autoComplete="off"
                    />
                    <div className="autoComplete">
                      {autoCompleteBcc !== null &&
                      newEmail.recipient_bcc !== '' ? (
                        autoCompleteBcc.map((contact) => (
                          <div className="row" key={contact._id}>
                            <div
                              className="option"
                              tabIndex="0"
                              onClick={() =>
                                selectAutoCompleteBcc(
                                  contact.Email_Address,
                                  'new'
                                )
                              }
                            >
                              <div className="col-2">{contact.First_Name}</div>
                              <div className="col-2 text-left">
                                {' '}
                                {contact.Last_Name}
                              </div>
                              <div className="col-6 text-left">
                                {contact.Email_Address}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div> </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-lg-12">
                    <label
                      className="active font-weight-bolder"
                      htmlFor="email_subject"
                    >
                      SUBJECT:
                    </label>
                    <input
                      id="email_subject"
                      type="text"
                      className="form-control"
                      defaultValue={newEmail.email_subject}
                      onChange={on_change}
                      maxLength="100"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <label
                    className="active font-weight-bolder"
                    htmlFor="newEmailBody"
                  >
                    MESSAGE:
                  </label>
                </div>
                <div className="row email-editor">
                  <EmailTextEditor
                    textData={newEmailBody}
                    setTextData={setNewEmailBody}
                  />
                </div>
              </div>
              <div>
                <label className="active font-weight-bolder">
                  ATTACHMENTS:
                </label>
                <div className="file-display-container">
                  {selectedFiles.map((data, i) => (
                    <div className="file-status-bar" key={i}>
                      <span
                        className={`file-name ${
                          data.invalid ? 'file-error' : ''
                        }`}
                      >
                        {data.name}
                      </span>
                      <span className="file-size">({fileSize(data.size)})</span>{' '}
                      {data.invalid && (
                        <span className="file-error-message">
                          ({errorMessage})
                        </span>
                      )}
                      <span
                        className="file-remove ml-2 font-weight-bolder"
                        onClick={() => removeFile(data.name)}
                      >
                        REMOVE
                      </span>
                    </div>
                  ))}
                  {templateFiles.map((data, i) => (
                    <div className="file-status-bar" key={i}>
                      <span
                        className={`file-name ${
                          data.invalid ? 'file-error' : ''
                        }`}
                      >
                        {data.fileName}
                      </span>
                      {data.invalid && (
                        <span className="file-error-message">
                          ({errorMessage})
                        </span>
                      )}
                      <span
                        className="file-remove ml-2 font-weight-bolder"
                        onClick={() => removeTemplateFile(data.fileName)}
                      >
                        REMOVE
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </form>
            <div
              className="drop-container text-center mt-2"
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
              id="uploadFile"
              onClick={fileInputClicked}
            >
              <div className="drop-message">
                <div className="upload-icon"></div>
                Drag & Drop file here or click to select file from computer
              </div>
              <input
                ref={fileInputRef}
                className="file-input"
                type="file"
                multiple
                onChange={filesSelected}
              />
            </div>
            <div className="my-3 row">
              <div className="col-lg-8"></div>
              <div className="col-lg-4">
                <button
                  type="button"
                  className="btn btn-secondary mr-2 mb-2"
                  data-dismiss="modal"
                  onClick={cancelNewEmailEntry}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  onClick={save_email}
                  className="btn btn-background mr-2 mb-2"
                  data-dismiss="modal"
                >
                  SAVE EMAIL
                </button>
                <button
                  type="button"
                  // onClick={add_email}
                  onClick={newSendEmail}
                  className="btn btn-success mb-2"
                  data-dismiss="modal"
                >
                  SEND EMAIL
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {/* EDIT EMAIL FORM  WORKING  */}
        {showExistingEmailEditor ? (
          <div className="container mt-3 bg-white rounded shadow">
            <div className="my-3">
              <h2 className="text-center font-weight-bolder">
                APPLICATION EMAIL EDITOR
              </h2>
            </div>
            <form autoComplete="off" className="m-3">
              <div className="form-group">
                <div className="row">
                  <div className="col-lg-12">
                    {toRequired ? (
                      <div className="text-danger font-weight-bold">
                        ******* REQUIRED ONE TO: RECIPIENT *****
                      </div>
                    ) : null}
                    <label
                      className="active font-weight-bolder"
                      htmlFor="recipient_to2"
                    >
                      TO:
                    </label>
                    {toEmailChips !== 0 ? (
                      toEmailChips.map((email) => (
                        <div key={email} className="email-chip">
                          {email}
                          <button
                            type="button"
                            onClick={() => handleDeleteTO(email)}
                            className="chip-button"
                          >
                            &times;
                          </button>
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      id="recipient_to2"
                      type="text"
                      className="form-control"
                      defaultValue={editedEmail.recipient_to2}
                      onChange={toAutoComplete}
                      onKeyDown={handleKeyDownTO}
                      maxLength="100"
                      autoComplete="off"
                    />

                    <div className="autoComplete">
                      {autoCompleteTo !== null &&
                      editedEmail.recipient_to2 !== '' ? (
                        autoCompleteTo.map((contact) => (
                          <div className="row" key={contact._id}>
                            <div
                              className="option"
                              tabIndex="0"
                              onClick={() =>
                                selectAutoCompleteTo(
                                  contact.Email_Address,
                                  'edit'
                                )
                              }
                            >
                              <div className="col-2">{contact.First_Name}</div>
                              <div className="col-2 text-left">
                                {contact.Last_Name}
                              </div>
                              <div className="col-6 text-left">
                                {contact.Email_Address}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div> </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-lg-12">
                    <label
                      className="active font-weight-bolder"
                      htmlFor="recipient_cc2"
                    >
                      CC:
                    </label>
                    {ccEmailChips.map((email) => (
                      <div key={email} className="email-chip">
                        {email}
                        <button
                          type="button"
                          onClick={() => handleDeleteCC(email)}
                          className="chip-button"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="col lg-12">
                    <input
                      id="recipient_cc2"
                      type="text"
                      className="form-control"
                      placeholder="Press Enter or Comma after each Email Address"
                      defaultValue={editedEmail.recipient_cc2}
                      onChange={ccAutoComplete}
                      onKeyDown={handleKeyDownCC}
                      maxLength="100"
                      autoComplete="off"
                    />
                    <div className="autoComplete">
                      {autoCompleteCc !== null &&
                      editedEmail.recipient_cc2 !== '' ? (
                        autoCompleteCc.map((contact) => (
                          <div className="row" key={contact._id}>
                            <div
                              className="option"
                              tabIndex="0"
                              onClick={() =>
                                selectAutoCompleteCc(
                                  contact.Email_Address,
                                  'edit'
                                )
                              }
                            >
                              <div className="col-2">{contact.First_Name}</div>
                              <div className="col-2 text-left">
                                {contact.Last_Name}
                              </div>
                              <div className="col-6 text-left">
                                {contact.Email_Address}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div> </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-lg-12">
                    <label
                      className="active font-weight-bolder"
                      htmlFor="recipient_bcc2"
                    >
                      BCC:
                    </label>
                    {bccEmailChips !== 0 ? (
                      bccEmailChips.map((email) => (
                        <div key={email} className="email-chip">
                          {email}
                          <button
                            type="button"
                            onClick={() => handleDeleteBCC(email)}
                            className="chip-button"
                          >
                            &times;
                          </button>
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="col lg-12">
                    <input
                      id="recipient_bcc2"
                      type="text"
                      className="form-control"
                      placeholder="Press Enter or Comma after each Email Address"
                      defaultValue={editedEmail.recipient_bcc2}
                      onChange={bccAutoComplete}
                      onKeyDown={handleKeyDownBCC}
                      maxLength="100"
                      autoComplete="off"
                    />
                    <div className="autoComplete">
                      {autoCompleteBcc !== null &&
                      editedEmail.recipient_bcc2 !== '' ? (
                        autoCompleteBcc.map((contact) => (
                          <div className="row" key={contact._id}>
                            <div
                              className="option"
                              tabIndex="0"
                              onClick={() =>
                                selectAutoCompleteBcc(
                                  contact.Email_Address,
                                  'edit'
                                )
                              }
                            >
                              <div className="col-2">{contact.First_Name}</div>
                              <div className="col-2 text-left">
                                {' '}
                                {contact.Last_Name}
                              </div>
                              <div className="col-6 text-left">
                                {contact.Email_Address}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div> </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-lg-12">
                    <label
                      className="active font-weight-bolder"
                      htmlFor="email_subject2"
                    >
                      SUBJECT:
                    </label>
                    <input
                      id="email_subject2"
                      type="text"
                      className="form-control"
                      defaultValue={editedEmail.email_subject2}
                      onChange={on_edit}
                      maxLength="100"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <label
                    className="active font-weight-bolder"
                    htmlFor="newEmailBody"
                  >
                    MESSAGE:
                  </label>
                </div>
                <div className="row">
                  <EmailTextEditor
                    textData={editedEmailBody}
                    setTextData={setEditedEmailBody}
                  />
                </div>
              </div>
              <div>
                <label className="active font-weight-bolder">
                  ATTACHMENTS:
                </label>
                <div className="file-display-container">
                  {selectedFiles.map((data, i) => (
                    <div className="file-status-bar" key={i}>
                      <span
                        className={`file-name ${
                          data.invalid ? 'file-error' : ''
                        }`}
                      >
                        {data.name}
                      </span>
                      <span className="file-size">({fileSize(data.size)})</span>{' '}
                      {data.invalid && (
                        <span className="file-error-message">
                          ({errorMessage})
                        </span>
                      )}
                      <span
                        className="file-remove ml-2 font-weight-bolder"
                        onClick={() => removeFile(data.name)}
                      >
                        REMOVE
                      </span>
                    </div>
                  ))}
                  {savedFiles.map((data, i) => (
                    <div className="file-status-bar" key={i}>
                      <span
                        className={`file-name ${
                          data.invalid ? 'file-error' : ''
                        }`}
                      >
                        {data.fileName}
                      </span>

                      {data.invalid && (
                        <span className="file-error-message">
                          ({errorMessage})
                        </span>
                      )}
                      <span
                        className="file-remove ml-2 font-weight-bolder"
                        onClick={() => removeSavedFile(data.fileName, data._id)}
                      >
                        REMOVE
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </form>
            <div
              className="drop-container text-center mt-2"
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
              id="uploadFile"
              onClick={fileInputClicked}
            >
              <div className="drop-message">
                <div className="upload-icon"></div>
                Drag & Drop file here or click to select file from computer
              </div>
              <input
                ref={fileInputRef}
                className="file-input"
                type="file"
                multiple
                onChange={filesSelected}
              />
            </div>
            <div className="my-3 row">
              <div className="col-lg-8"></div>
              <div className="col-lg-4">
                <button
                  type="button"
                  className="btn btn-secondary mr-2 mb-2"
                  onClick={cancelEdit}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  onClick={save_editedEmail}
                  className="btn btn-background mr-2 mb-2"
                >
                  SAVE EMAIL
                </button>
                <button
                  type="button"
                  onClick={update_email_send}
                  className="btn btn-success mb-2"
                >
                  SEND EMAIL
                </button>
              </div>
            </div>
          </div>
        ) : null}
        {sortedEmails !== null && !emailLoading ? (
          sortedEmails.map((email) => (
            <div className="row m-3" key={email._id}>
              <div className="col-lg-12">
                <div className="card  rounded px-3 bg-foster-theme">
                  <div className="row  mt-2">
                    <div className="card-body bg-light text-dark rounded">
                      <div className="px-3 pt-1">
                        <div className="row">
                          <div className="col-lg-9 p-0">
                            <p className="mb-0">
                              <span className="font-weight-bolder">
                                {' '}
                                DATE:{' '}
                              </span>
                              {formatDateTimeSeconds(email.updatedAt)}
                            </p>
                          </div>
                          <div className="col-lg-3">
                            <p className="mb-0">
                              <span className="font-weight-bolder">
                                {' '}
                                STATUS:{' '}
                              </span>
                              <span className="text-danger font-weight-bold">
                                {email.email_status}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <p className="mb-0">
                            <span className="font-weight-bolder"> TO: </span>
                            <span className="text-danger font-weight-bold">
                              {email.recipient_to}
                            </span>
                          </p>
                        </div>
                        <div className="row">
                          <p className="mb-0">
                            <span className="font-weight-bolder"> CC: </span>
                            <span className="text-danger font-weight-bold">
                              {email.recipient_cc}
                            </span>
                          </p>
                        </div>
                        <div className="row">
                          <p className="mb-0">
                            <span className="font-weight-bolder"> BCC: </span>
                            <span className="text-danger font-weight-bold">
                              {email.recipient_bcc}
                            </span>
                          </p>
                        </div>
                        <div className="row pb-0">
                          <p className="mb-0">
                            <span className="font-weight-bolder">
                              {' '}
                              SUBJECT:{' '}
                            </span>
                            {email.email_subject}
                          </p>
                        </div>
                        <div className="row pb-0">
                          <p className="mb-0">
                            <span className="font-weight-bolder">
                              {' '}
                              ATTACHMENTS:{' '}
                            </span>

                            {email.attachments.length > 0 ? (
                              email.attachments.map((file) => (
                                <span
                                  key={file._id}
                                  className="mx-2 pointer text-danger"
                                  onClick={() => downloadAttachment(file._id)}
                                >
                                  <u>{file.fileName}</u>
                                </span>
                              ))
                            ) : (
                              <span></span>
                            )}
                          </p>
                        </div>
                      </div>
                      <hr />

                      {showEmailBody[email._id] ? (
                        <div className="row  mt-2">
                          <div className="card col-lg-12">
                            <div className="card-body mx-3">
                              <div className="px-3 pt-3">
                                {' '}
                                {ReactHtmlParser(email.email_body)}
                                <div className="row">
                                  <div className="col-lg-10"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => showTheBody(email._id)}
                            className="btn btn-danger text-align-right font-weight-bold text-white mt-2"
                          >
                            HIDE BODY
                          </button>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-lg-3">
                            <button
                              onClick={() => showTheBody(email._id)}
                              className="btn btn-warning text-align-right font-weight-bold text-dark mt-2"
                            >
                              SEE EMAIL
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-8">
                      <p className="mb-0 pl-3 font-weight-bold">
                        FROM: {email.email_sender}
                      </p>
                    </div>
                    <div className="col-lg-4 text-right">
                      <i
                        className="fa fa-pencil font-weight-bold text-warning mr-4 pointer"
                        id={email._id}
                        onClick={insertEditEmail}
                      ></i>
                      <i
                        className="fa fa-trash-o font-weight-bold text-danger pointer"
                        data-toggle="modal"
                        data-target="#emailDeleteConfirmationModal"
                        onClick={removeEmail}
                        id={email._id}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h4>Loading</h4>
        )}
      </div>
      <div
        className="modal fade"
        id="emailDeleteConfirmationModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="emailDeleteConfirmationModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger font-weight-bold">
                DELETE CONFIRMATION
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="font-weight-bold">
                ARE YOU SURE YOU WANT TO DELETE THIS EMAIL?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={cancelDeletion}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  deleteAEmail(removeEmailId);
                  setRemoveEmailId(null);
                }}
                data-dismiss="modal"
              >
                CONFRIM
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminAppEmail;
