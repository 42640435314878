import React, { useContext, Fragment, useState, useEffect } from 'react';
import ZohoContext from '../../../context/zoho/zohoContext';
import AlertContext from '../../../context/alert/alertContext';
import AuthContext from '../../../context/auth/authContext';
import UserContext from '../../../context/user/userContext';
import AdminZohoNotes from './AdminZohoNotes';
import AdminZohoFiles from './AdminZohoFiles';
import AdminZohoFollowUps from './AdminZohoFollowUps';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const formatDateDashed = (string) => {
  if (string === null) {
    return '1970-01-01';
  } else {
    var d = new Date(string);
    var mm = ('0' + (d.getMonth() + 1)).slice(-2);
    var dd = ('0' + (d.getDate() + 1)).slice(-2);
    var yy = d.getFullYear();

    var myDateString = yy + '-' + mm + '-' + dd; //(US)

    return myDateString;
  }
};

const AdminZohoApplication = (props) => {
  const zohoContext = useContext(ZohoContext);
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);
  const { setAlert } = alertContext;
  const { user } = authContext;
  const { users, getUsers } = userContext;
  const { zapp, transferApplication, editZohoApp } = zohoContext;
  const [editedZapp, setEditedZapp] = useState([]);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  const today = formatDate(Date.now());

  const convertApp = () => {
    setAlert('Application transfer submitted', 'success');
    const data = {
      Application_Type: zapp.Application_Type,
      st_Choice: 'Transfer from zoho',
      Owner_Id: user._id,
      Application_Status: 'New Application',
      submit_date: today,
      First_Name: zapp.First_Name,
      Middle_Inital: zapp.Middle_initial,
      Last_Name: zapp.Last_Name,
      CoApp_Full_Name: zapp.CoApp_Full_Name,
      Street: zapp.Street,
      Phone: zapp.Phone,
      Mobile: zapp.Mobile,
      Work: zapp.Work,
      City: zapp.City,
      State: zapp.State,
      Zip_Code: zapp.Zip_Code,
      County: zapp.County,
      Landlord_Name: zapp.Landlord_Name,
      Landlord_Phone: zapp.Landlord_Phone,
      Vet_Name: zapp.Vet_Name,
      Vet_Number: zapp.Vet_Number,
      Reference_1: zapp.Reference_1,
      Reference_2: zapp.Reference_2,
      Reference_1_Phone: zapp.Reference_1_Phone,
      Reference_2_Phone: zapp.Reference_2_Phone,
      Email: zapp.Email,
    };
    transferApplication(data, zapp.Application_ID);
  };

  const onChange = (e) => {
    setEditedZapp({ ...editedZapp, [e.target.id]: e.target.value });
  };

  const submitEdits = () => {
    editZohoApp(zapp.Application_ID, editedZapp);
    window.scroll(0, 0);
    setAlert('APPLICATION UPDATED', 'success');
  };

  const applicationStatusOptions = [
    'Screened - On Hold',
    'Approved - Pending Adoption',
    'Approved - No Adopt',
    'Approved - On Hold',
    'Adopted - F/U on S/N',
    'Screened - No Adopt',
    'Stop Processing',
    'Approved - Foster',
    'Adopted',
    'Adopted - Returned',
    'To Be Deleted',
  ];

  return (
    <Fragment>
      {zapp !== null && zapp !== undefined ? (
        <div className="container ">
          <h2 className="text-center">
            ZOHO APPLICATION - ({zapp.First_Name} {zapp.Last_Name})
          </h2>
          <div className="row">
            <div className="col-lg-10"></div>
            <div className="col-lg-2">
              <button className="btn btn-danger" onClick={convertApp}>
                TRANSFER
              </button>
            </div>
          </div>
          <div className="row">
            <div className="card col-md-7 m-0 p-0">
              <table className="table table-hover table-bordered m-0">
                <tbody>
                  <tr>
                    <th>APPLICANT: </th>
                    <td>
                      {zapp.First_Name} {zapp.Middle_Initial} {zapp.Last_Name}
                    </td>
                  </tr>
                  <tr>
                    <th>CO-APPLICANT: </th>
                    <td>{zapp.CoApp_Full_Name}</td>
                  </tr>
                  <tr>
                    <th>ADDRESS: </th>
                    <td>
                      {zapp.Street}, {zapp.City}, {zapp.State} {zapp.Zip_Code}
                    </td>
                  </tr>
                  <tr>
                    <th>PHONE: </th>
                    <td>{zapp.Phone}</td>
                  </tr>
                  <tr>
                    <th>MOBILE: </th>
                    <td>{zapp.Mobile}</td>
                  </tr>
                  <tr>
                    <th>WORK: </th>
                    <td>{zapp.Work}</td>
                  </tr>
                  <tr>
                    <th>EMAIL: </th>
                    <td>{zapp.Email}</td>
                  </tr>
                  <tr>
                    <th>APP ID: </th>
                    <td>{zapp.Application_ID}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="card col-md-5 m-0 p-0">
              <table className="table table-hover table-bordered table-dark m-0">
                <tbody>
                  <tr>
                    <th>STATUS: </th>
                    <td>
                      <form>
                        <div className="form-group">
                          <select
                            type="select"
                            className="form-control"
                            id="Application_Status"
                            onChange={onChange}
                            defaultValue={zapp.Application_Status}
                          >
                            {applicationStatusOptions !== '' ? (
                              applicationStatusOptions.map((app) => (
                                <option key={app} value={app}>
                                  {app}
                                </option>
                              ))
                            ) : (
                              <option>...Loading</option>
                            )}
                          </select>
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>DOG ADOPTED: </th>
                    <td>
                      <form>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="Dog_Adopted"
                            onChange={onChange}
                            defaultValue={zapp.Dog_Adopted}
                          />
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>ADOPTION DATE: </th>
                    <td>
                      <form>
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control"
                            id="Adoption_Date"
                            onChange={onChange}
                            defaultValue={formatDateDashed(zapp.Adoption_Date)}
                          />
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>MICROCHIP: </th>
                    <td>
                      <form>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="Micro_Chip_Number"
                            onChange={onChange}
                            defaultValue={zapp.Micro_Chip_Number}
                          />
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>MICROCHIP BRAND: </th>
                    <td>
                      <form>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="Micro_Chip_Brand"
                            onChange={onChange}
                            defaultValue={zapp.Micro_Chip_Brand}
                          />
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th>APPLICATION DATE</th>
                    <td>{formatDate(zapp.createdAt)}</td>
                  </tr>
                  <tr>
                    <th> </th>
                    <td>
                      <button className="btn btn-success" onClick={submitEdits}>
                        UPDATE
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="card col-md-12 m-0 p-0">
              <table className="table table-hover table-bordered m-0">
                <tbody>
                  <tr>
                    <th>VET NAME: </th>
                    <td>{zapp.Vet_Name}</td>
                    <th>PHONE: </th>
                    <td>{zapp.Vet_Number}</td>
                  </tr>
                  <tr>
                    <th>LANDLORD: </th>
                    <td>{zapp.Landlord_Name}</td>
                    <th>PHONE: </th>
                    <td>{zapp.Landlord_Phone}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="card col-md-12 m-0 p-0">
              <table className="table table-hover table-bordered m-0">
                <tbody>
                  <tr>
                    <th>DESCRIPTION: </th>
                  </tr>
                  <tr>
                    <td>{zapp.Description}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <AdminZohoNotes
            appID={zapp.Application_ID}
            user={user}
            users={users}
          />
          <AdminZohoFiles
            appID={zapp.Application_ID}
            user={user}
            users={users}
          />
          <AdminZohoFollowUps
            zappID={zapp.Application_ID}
            user={user}
            users={users}
          />
        </div>
      ) : (
        <div>Loading</div>
      )}
    </Fragment>
  );
};

export default AdminZohoApplication;
