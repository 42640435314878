import React, { useEffect, useState } from 'react';
import AdminDashAppList from '../dashboard/AdminDashAppList';
import axios from 'axios';

const AdminDashApps = () => {
  const [newAppsList, setNewAppsList] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;

    axios
      .get(`/api/database/new/new application`, {
        cancelToken: source.token,
      })
      .then((response) => {
        if (mounted) {
          setNewAppsList(response.data);
          mounted = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      source.cancel('Component got unmounted - New Apps');
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className="card shadow col-12 ">
      <h5 className="card-title text-center font-weight-bold mt-2">
        NEW APPLICATIONS
      </h5>
      <div className="card-body">
        <table className="table table-hover table-striped table-borderless table-dark">
          <thead>
            <tr className="d-flex">
              <th className="text-center col-lg-3" scope="col">
                NAME
              </th>

              <th
                className="d-none d-md-block text-center col-lg-2"
                scope="col"
              >
                TYPE
              </th>
              <th className="text-center col-lg-4" scope="col">
                1st Choice
              </th>
              <th className="text-center col-lg-3" scope="col">
                DATE
              </th>
            </tr>
          </thead>

          <tbody>
            {newAppsList !== null && newAppsList !== undefined
              ? newAppsList.map((app) => (
                  <AdminDashAppList app={app} key={app._id} />
                ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminDashApps;
