import React, { useReducer, useContext } from 'react';
import ErrorsContext from './errorsContext';
import errorsReducer from './errorsReducer';
import AlertContext from '../alert/alertContext';
import axios from 'axios';

import {
  DELETE_ERROR_LOG,
  GET_ERROR_LOGS,
  GET_ERROR_LOG,
  CLEAR_ERROR_LOGS_STATE,
} from '../types';

const ErrorsState = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialstate = {
    errorLogs: null,
    errorLog: null,
  };

  const [state, dispatch] = useReducer(errorsReducer, initialstate);

  const getErrorLogs = async (e) => {
    try {
      const res = await axios.get(`/api/errorLogs/`);
      dispatch({
        type: GET_ERROR_LOGS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getErrorLog = async (log) => {
    dispatch({
      type: GET_ERROR_LOG,
      payload: log,
    });
  };

  const deleteErrorLog = async (id) => {
    try {
      const res = await axios.delete(`/api/errorLogs/${id}`);
      setAlert(res.data, 'success');
      dispatch({
        type: DELETE_ERROR_LOG,
        payload: id,
      });
    } catch (error) {}
  };

  const clearErrorLogState = () => {
    dispatch({
      type: CLEAR_ERROR_LOGS_STATE,
    });
  };
  return (
    <ErrorsContext.Provider
      value={{
        errorLogs: state.errorLogs,
        errorLog: state.errorLog,
        getErrorLogs,
        deleteErrorLog,
        getErrorLog,
        clearErrorLogState,
      }}
    >
      {props.children}
    </ErrorsContext.Provider>
  );
};

export default ErrorsState;
