import {
  DELETE_TICKET,
  GET_TICKETS,
  GET_TICKET,
  UPDATE_TICKET,
  UPDATE_TICKET_NOTE,
  GET_TICKET_NOTES,
  GET_TICKET_NOTE,
  DELETE_TICKET_NOTE,
  CREATE_TICKET,
  CREATE_TICKET_NOTE,
  GET_TICKET_FILES,
  UPLOAD_TICKET_FILE,
  DELETE_TICKET_FILE,
} from '../types';

const ticketReducer = (state, action) => {
  switch (action.type) {
    case CREATE_TICKET:
      return {
        ...state,
        tickets: [action.payload, ...state.tickets],
      };
    case CREATE_TICKET_NOTE:
      return {
        ...state,
        tnotes: [action.payload, ...state.tnotes],
      };
    case GET_TICKETS:
      return {
        ...state,
        tickets: action.payload,
        ticketsLoading: false,
      };
    case GET_TICKET_NOTES:
      return {
        ...state,
        tnotes: action.payload,
      };
    case GET_TICKET_FILES:
      return {
        ...state,
        tfiles: action.payload,
      };
    case UPLOAD_TICKET_FILE:
      return {
        ...state,
        tfiles: [action.payload, ...state.tfiles],
      };
    case GET_TICKET:
      return {
        ...state,
        ticket: action.payload,
      };
    case GET_TICKET_NOTE:
      return {
        ...state,
        tnote: action.payload,
      };
    case DELETE_TICKET:
      return {
        ...state,
        tickets: state.tickets.filter(
          (ticket) => ticket._id !== parseInt(action.payload)
        ),
      };
    case DELETE_TICKET_FILE:
      return {
        ...state,
        tfiles: state.tfiles.filter(
          (file) => file._id !== parseInt(action.payload)
        ),
      };
    case DELETE_TICKET_NOTE:
      return {
        ...state,
        tnotes: state.tnotes.filter(
          (note) => note._id !== parseInt(action.payload)
        ),
      };
    case UPDATE_TICKET:
      return {
        ...state,
        tickets: [
          action.payload,
          ...state.tickets.filter(
            (ticket) => ticket._id !== parseInt(action.payload._id)
          ),
        ],
      };
    case UPDATE_TICKET_NOTE:
      return {
        ...state,
        tnotes: [
          action.payload,
          ...state.tnotes.filter(
            (note) => note._id !== parseInt(action.payload._id)
          ),
        ],
      };

    default:
      return state;
  }
};

export default ticketReducer;
