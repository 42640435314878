import React, { useContext, useEffect, useState, useRef } from 'react';
import AppNavbar from '../layout/AppNavbar';
import AppContext from '../../context/app/appContext';
import AdminContext from '../../context/admin/adminContext';
import AdminAppProfile from '../layout/adminApp/AdminAppProfile';
import AdminAppFosterProfile from '../layout/adminApp/AdminAppFosterProfile';
import AdminAppApplication from '../layout/adminApp/AdminAppApplication';
import AdminFosterApplication from '../layout/adminApp/AdminFosterApplication';
import AdminAppTasks from '../layout/adminApp/AdminAppTasks';
import AdminAppFollowUps from '../layout/adminApp/AdminAppFollowUps';
import AdminAppNotes from '../layout/adminApp/AdminAppNotes';
import AdminAppEmail from '../layout/adminApp/AdminAppEmail';
import AdminAppAdoptions from '../layout/adminApp/AdminAppAdoptions';
import AdminAppFiles from '../layout/adminApp/AdminAppFiles';
import AdminAppContractGenerator from '../layout/adminApp/AdminAppContractGenerator';
import '../../css/appProfile.css';
import '../../css/SideNavBar.css';

const AdminApplication = ({ user }) => {
  const appContext = useContext(AppContext);
  const adminContext = useContext(AdminContext);
  const { appID } = adminContext;
  const { getApp, loading, app, appPageID, clearAppState } = appContext;
  const [appPageType, setAppPageType] = useState('AdoptProfile');
  const [displayUpButton, setDisplayUpButton] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [Sort, setSort] = useState({
    notes: 'Ascending',
    email: 'Ascending',
    followUps: 'Ascending',
  });

  useEffect(() => {
    if (app === null || !loading) {
      getApp(appID);
    }

    return () => {
      clearAppState();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (app != null) {
      if (app.Application_Type === 'Foster') {
        if (appPageID === 'profile') {
          setAppPageType('FosterProfile');
        } else if (appPageID === 'application') {
          setAppPageType('FosterApplication');
        } else if (appPageID === 'activities') {
          setAppPageType('activities');
        } else if (appPageID === 'email') {
          setAppPageType('email');
        } else if (appPageID === 'adoptions') {
          setAppPageType('adoptions');
        } else if (appPageID === 'files') {
          setAppPageType('files');
        } else if (appPageID === 'contract') {
          setAppPageType('contract');
        }
      } else {
        if (appPageID === 'profile') {
          setAppPageType('AdoptProfile');
        } else if (appPageID === 'application') {
          setAppPageType('application');
        } else if (appPageID === 'activities') {
          setAppPageType('activities');
        } else if (appPageID === 'notes') {
          setAppPageType('notes');
        } else if (appPageID === 'email') {
          setAppPageType('email');
        } else if (appPageID === 'adoptions') {
          setAppPageType('adoptions');
        } else if (appPageID === 'files') {
          setAppPageType('files');
        } else if (appPageID === 'contract') {
          setAppPageType('contract');
        }
      }
    }
  }, [app, appPageID]);

  const handleScroll = (event) => {
    setScrollPosition(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      setDisplayUpButton(false);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition >= 500) {
      setDisplayUpButton(true);
    } else {
      setDisplayUpButton(false);
    }
  }, [scrollPosition]);

  const scrollToTop = () => {
    window.scroll(0, 0);
  };

  const onChange = (e) => {
    if (Sort[e.target.id] === 'Ascending') {
      setSort({ ...Sort, [e.target.id]: 'Descending' });
    } else {
      setSort({ ...Sort, [e.target.id]: 'Ascending' });
    }
  };

  const useScroll = () => {
    const a = useRef(null);
    const executeScroll = () => {
      window.scrollTo(0, a.current.offsetTop);
    };

    return [executeScroll, a];
  };

  const [scrollToTasks, tasks] = useScroll();
  const [scrollToNotes, notes] = useScroll();
  const [scrollToEmail, email] = useScroll();
  const [scrollToFu, followUps] = useScroll();

  switch (appPageType) {
    case 'AdoptProfile':
      return (
        <div className="container-fluid">
          <div className="row m-0 p-0 ">
            <div className="sideNavBar d-none d-xl-block ">
              <div className="sideNavBarGroup">
                <div className="row text-center sideNavBar-icon">
                  <i
                    className="fa fa-home fa-2x ml-3 mt-2 sideIcon pointer"
                    aria-hidden="true"
                    title="Top of Page"
                    onClick={() => {
                      window.scroll(0, 0);
                    }}
                  ></i>
                </div>
                <div className="row text-center sideNavBar-icon">
                  <i
                    className="fa fa-list fa-2x ml-3  sideIcon pointer"
                    aria-hidden="true"
                    onClick={scrollToTasks}
                    title="Tasks"
                  ></i>
                </div>
                <div className="row text-center sideNavBar-icon">
                  <i
                    className="fa fa-sticky-note fa-2x ml-3  mt-2 sideIcon pointer"
                    aria-hidden="true"
                    onClick={scrollToNotes}
                    title="Notes"
                  ></i>
                </div>
                <div className="row text-center sideNavBar-icon">
                  <i
                    className="fa fa-envelope fa-2x ml-3 mt-2 sideIcon pointer"
                    onClick={scrollToEmail}
                    aria-hidden="true"
                    title="Emails"
                  ></i>
                </div>
                <div className="row text-center sideNavBar-icon">
                  <i
                    className="fa fa-bell fa-2x ml-3 mt-2 sideIcon pointer"
                    aria-hidden="true"
                    onClick={scrollToFu}
                    title="Follow Ups"
                  ></i>
                </div>
              </div>
            </div>
            <div className="profile-cards">
              <AppNavbar />
              <AdminAppProfile />
              <div
                className="mx-2 p-2 mb-2 bg-theme text-light rounded"
                ref={tasks}
              >
                <h3 className="ml-4">TASKS</h3>
              </div>
              <AdminAppTasks />

              <div className="container-fluid section_divider" ref={notes}>
                <div className="row mx-2 bg-theme text-light rounded">
                  <div className="col-lg-10">
                    <h3 className="ml-4 mt-3">NOTES</h3>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group text-right">
                      <select
                        className="form-control mt-3"
                        onChange={onChange}
                        id="notes"
                        value={Sort.notes}
                      >
                        <option>Ascending</option>

                        <option>Descending</option>
                      </select>
                    </div>
                  </div>
                </div>
                <AdminAppNotes sort={Sort.notes} />
              </div>
              <div className="container-fluid section_divider" ref={email}>
                <div className="row mx-2 bg-theme text-light rounded">
                  <div className="col-lg-10">
                    <h3 className="ml-4 mt-3">EMAIL</h3>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group text-right">
                      <select
                        className="form-control mt-3"
                        onChange={onChange}
                        id="email"
                        value={Sort.email}
                      >
                        <option>Ascending</option>

                        <option>Descending</option>
                      </select>
                    </div>
                  </div>
                </div>
                <AdminAppEmail sort={Sort.email} user={user} />
              </div>
              <div className="container-fluid section_divider" ref={followUps}>
                <div className="row mx-2 bg-theme text-light rounded">
                  <div className="col-lg-10">
                    <h3 className="ml-4 mt-3">FOLLOW UPS</h3>
                  </div>
                  <div className="col-lg-2">
                    <div className="form-group text-right">
                      <select
                        className="form-control mt-3"
                        onChange={onChange}
                        id="followUps"
                        value={Sort.followUps}
                      >
                        <option>Ascending</option>

                        <option>Descending</option>
                      </select>
                    </div>
                  </div>
                </div>
                <AdminAppFollowUps sort={Sort.followUps} />
              </div>
              <div className="hoverButton">
                {displayUpButton ? (
                  <div onClick={scrollToTop}>
                    <i
                      className="fa fa-arrow-circle-up fa-3x upArrow"
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
    case 'FosterProfile':
      return (
        <div>
          <div className="container-fluid ">
            <div className="row m-0 p-0 ">
              <div className="sideNavBar d-none d-xl-block ">
                <div className="sideNavBarGroup">
                  <div className="row text-center sideNavBar-icon">
                    <i
                      className="fa fa-home fa-2x ml-3 mt-2 sideIcon pointer"
                      aria-hidden="true"
                      title="Top of Page"
                      onClick={() => {
                        window.scroll(0, 0);
                      }}
                    ></i>
                  </div>
                  <div className="row text-center sideNavBar-icon">
                    <i
                      className="fa fa-list fa-2x ml-3  sideIcon pointer"
                      aria-hidden="true"
                      onClick={scrollToTasks}
                      title="Tasks"
                    ></i>
                  </div>
                  <div className="row text-center sideNavBar-icon">
                    <i
                      className="fa fa-sticky-note fa-2x ml-3  mt-2 sideIcon pointer"
                      aria-hidden="true"
                      onClick={scrollToNotes}
                      title="Notes"
                    ></i>
                  </div>
                  <div className="row text-center sideNavBar-icon">
                    <i
                      className="fa fa-envelope fa-2x ml-3 mt-2 sideIcon pointer"
                      onClick={scrollToEmail}
                      aria-hidden="true"
                      title="Emails"
                    ></i>
                  </div>
                  <div className="row text-center sideNavBar-icon">
                    <i
                      className="fa fa-bell fa-2x ml-3 mt-2 sideIcon pointer"
                      aria-hidden="true"
                      onClick={scrollToFu}
                      title="Follow Ups"
                    ></i>
                  </div>
                </div>
              </div>
              <div className="profile-cards">
                <AppNavbar />
                <AdminAppFosterProfile />
                <div
                  className="mx-2 p-2 mb-2 bg-theme text-light rounded"
                  ref={tasks}
                >
                  <h3 className="ml-4">TASKS</h3>
                </div>
                <AdminAppTasks />
                <div className="container-fluid section_divider">
                  <div
                    className="row mx-2 bg-theme text-light rounded"
                    ref={notes}
                  >
                    <div className="col-lg-10">
                      <h3 className="ml-4 mt-3">NOTES</h3>
                    </div>
                    <div className="col-lg-2">
                      <div className="form-group text-right">
                        <select
                          className="form-control mt-3"
                          onChange={onChange}
                          id="notes"
                          value={Sort.notes}
                        >
                          <option>Ascending</option>

                          <option>Descending</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <AdminAppNotes sort={Sort.notes} />
                </div>
                <div className="container-fluid section_divider" ref={email}>
                  <div className="row mx-2 bg-theme text-light rounded">
                    <div className="col-lg-10">
                      <h3 className="ml-4 mt-3">EMAIL</h3>
                    </div>
                    <div className="col-lg-2">
                      <div className="form-group text-right">
                        <select
                          className="form-control mt-3"
                          onChange={onChange}
                          id="email"
                          value={Sort.email}
                        >
                          <option>Ascending</option>

                          <option>Descending</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <AdminAppEmail sort={Sort.email} />
                </div>
                <div
                  className="container-fluid section_divider"
                  ref={followUps}
                >
                  <div className="row mx-2 bg-theme text-light rounded">
                    <div className="col-lg-10">
                      <h3 className="ml-4 mt-3">FOLLOW UPS</h3>
                    </div>
                    <div className="col-lg-2">
                      <div className="form-group text-right">
                        <select
                          className="form-control mt-3"
                          onChange={onChange}
                          id="followUps"
                          value={Sort.followUps}
                        >
                          <option>Ascending</option>

                          <option>Descending</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <AdminAppFollowUps sort={Sort.followUps} />
                </div>
                <div className="hoverButton">
                  {displayUpButton ? (
                    <div onClick={scrollToTop}>
                      <i
                        className="fa fa-arrow-circle-up fa-3x upArrow"
                        aria-hidden="true"
                      ></i>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case 'application':
      return (
        <div>
          <AppNavbar />
          <AdminAppApplication />
          <div className="hoverButton">
            {displayUpButton ? (
              <div onClick={scrollToTop}>
                <i
                  className="fa fa-arrow-circle-up fa-3x upArrow"
                  aria-hidden="true"
                ></i>
              </div>
            ) : null}
          </div>
        </div>
      );
    case 'FosterApplication':
      return (
        <div>
          <AppNavbar />
          <AdminFosterApplication />
          <div className="hoverButton">
            {displayUpButton ? (
              <div onClick={scrollToTop}>
                <i
                  className="fa fa-arrow-circle-up fa-3x upArrow"
                  aria-hidden="true"
                ></i>
              </div>
            ) : null}
          </div>
        </div>
      );
    case 'activities':
      return (
        <div>
          <div className="container-fluid ">
            <div className="row m-0 p-0 ">
              <div className="sideNavBar d-none d-xl-block ">
                <div className="sideNavBarGroup">
                  <div className="row text-center sideNavBar-icon">
                    <i
                      className="fa fa-home fa-2x ml-3 mt-2 sideIcon pointer"
                      aria-hidden="true"
                      title="Top of Page"
                      onClick={() => {
                        window.scroll(0, 0);
                      }}
                    ></i>
                  </div>
                  <div className="row text-center sideNavBar-icon">
                    <i
                      className="fa fa-list fa-2x ml-3  sideIcon pointer"
                      aria-hidden="true"
                      title="Tasks"
                      onClick={scrollToTasks}
                    ></i>
                  </div>
                  <div className="row text-center sideNavBar-icon">
                    <i
                      className="fa fa-sticky-note fa-2x ml-3  mt-2 sideIcon pointer"
                      aria-hidden="true"
                      onClick={scrollToNotes}
                      title="Notes"
                    ></i>
                  </div>
                  <div className="row text-center sideNavBar-icon">
                    <i
                      className="fa fa-bell fa-2x ml-3 mt-2 sideIcon pointer"
                      aria-hidden="true"
                      onClick={scrollToFu}
                      title="Follow Ups"
                    ></i>
                  </div>
                </div>
              </div>
              <div className="activities">
                <AppNavbar />
                <div className="container-fluid section_divider">
                  <div
                    className="mx-2 p-2 mb-2 bg-theme text-light rounded"
                    ref={tasks}
                  >
                    <h3 className="ml-4">TASKS</h3>
                  </div>
                  <AdminAppTasks />
                </div>
                <div className="container-fluid section_divider" ref={notes}>
                  <div className="row mx-2 bg-theme text-light rounded">
                    <div className="col-lg-10">
                      <h3 className="ml-4 mt-3">NOTES</h3>
                    </div>
                    <div className="col-lg-2">
                      <div className="form-group text-right">
                        <select
                          className="form-control mt-3"
                          onChange={onChange}
                          id="notes"
                          value={Sort.notes}
                        >
                          <option>Ascending</option>

                          <option>Descending</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <AdminAppNotes sort={Sort.notes} />
                </div>
                <div
                  className="container-fluid section_divider"
                  ref={followUps}
                >
                  <div className="row mx-2 bg-theme text-light rounded">
                    <div className="col-lg-10">
                      <h3 className="ml-4 mt-3">FOLLOW UPS</h3>
                    </div>
                    <div className="col-lg-2">
                      <div className="form-group text-right">
                        <select
                          className="form-control mt-3"
                          onChange={onChange}
                          id="followUps"
                          value={Sort.followUps}
                        >
                          <option>Ascending</option>

                          <option>Descending</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <AdminAppFollowUps sort={Sort.followUps} />
                </div>
              </div>
            </div>
            <div className="hoverButton">
              {displayUpButton ? (
                <div onClick={scrollToTop}>
                  <i
                    className="fa fa-arrow-circle-up fa-3x upArrow"
                    aria-hidden="true"
                  ></i>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    case 'notes':
      return (
        <div>
          <AppNavbar />
          <AdminAppNotes />
        </div>
      );
    case 'email':
      return (
        <div>
          <AppNavbar />
          <div className="container-fluid section_divider">
            <div className="row mx-2 bg-theme text-light rounded">
              <div className="col-lg-10">
                <h3 className="ml-4 mt-3">EMAIL</h3>
              </div>
              <div className="col-lg-2">
                <div className="form-group text-right">
                  <select
                    className="form-control mt-3"
                    onChange={onChange}
                    id="email"
                    value={Sort.email}
                  >
                    <option>Ascending</option>

                    <option>Descending</option>
                  </select>
                </div>
              </div>
            </div>
            <AdminAppEmail sort={Sort.email} />
          </div>
        </div>
      );
    case 'adoptions':
      return (
        <div>
          <AppNavbar />
          <AdminAppAdoptions />
        </div>
      );
    case 'files':
      return (
        <div>
          <AppNavbar />
          <AdminAppFiles />
        </div>
      );
    case 'contract':
      return (
        <div>
          <AppNavbar />
          <AdminAppContractGenerator />
        </div>
      );
    default:
      return null;
  }
};

export default AdminApplication;
