import {
  GET_MEDICATION_REMINDERS,
  CREATE_MEDICATION_REMINDER,
  GET_MEDICATION_REMINDER,
  EDIT_MEDICATION_REMINDER,
  DELETE_MEDICATION_REMINDER,
  CLEAR_MEDICATION_REMINDER,
  DELETE_MEDICATION_REMINDER_FROM_STATE,
  DELETE_MEDICATION_REMINDER_BY_DOG,
} from '../types';

const medication_reminderReducer = (state, action) => {
  switch (action.type) {
    case GET_MEDICATION_REMINDERS:
      return {
        ...state,
        med_reminders: action.payload,
      };

    case EDIT_MEDICATION_REMINDER:
      return {
        ...state,
        med_reminders: [
          action.payload,
          ...state.med_reminders.filter(
            (reminder) => reminder._id !== parseInt(action.payload._id)
          ),
        ],
      };

    case DELETE_MEDICATION_REMINDER:
      return {
        ...state,
        med_reminders: state.med_reminders.filter(
          (reminder) => reminder._id !== parseInt(action.payload)
        ),
      };
    case DELETE_MEDICATION_REMINDER_BY_DOG:
      return {
        ...state,
        med_reminders: state.med_reminders.filter(
          (reminder) => reminder.dog !== parseInt(action.payload)
        ),
      };
    case DELETE_MEDICATION_REMINDER_FROM_STATE:
      return {
        ...state,
        med_reminder: null,
        med_reminders: state.med_reminders.filter(
          (reminder) => reminder.medication !== parseInt(action.payload)
        ),
      };

    case GET_MEDICATION_REMINDER:
      return {
        ...state,
        med_reminder: action.payload,
      };

    case CLEAR_MEDICATION_REMINDER:
      return {
        ...state,
        med_reminder: null,
      };

    case CREATE_MEDICATION_REMINDER:
      return {
        ...state,
        med_reminders: [action.payload, ...state.med_reminders],
      };

    default:
      return state;
  }
};

export default medication_reminderReducer;
