import {
  GET_ADOPTIONS,
  UPDATE_ADOPTION,
  DELETE_ADOPTION,
  CREATE_ADOPTION,
} from '../types';

const adoptionReducer = (state, action) => {
  switch (action.type) {
    case GET_ADOPTIONS:
      return {
        ...state,
        adoptions: action.payload,
        adoptionsLoading: false,
      };

    case CREATE_ADOPTION:
      return {
        ...state,
        adoptions: [action.payload, ...state.adoptions],
        adoptionsLoading: false,
      };
    case DELETE_ADOPTION:
      return {
        ...state,
        adoptions: state.adoptions.filter(
          (adoption) => adoption._id !== parseInt(action.payload)
        ),
        adoptionsLoading: false,
      };
    case UPDATE_ADOPTION:
      // THIS NEEDS TO BUILD CORRECTLY
      return {
        ...state,
        adoptions: state.adoptions.map((adoption) =>
          adoption._id === action.payload._id ? action.payload : adoption
        ),
        adoptionsLoading: false,
      };

    default:
      return state;
  }
};

export default adoptionReducer;
