import React, { useReducer, useContext } from 'react';
import Medication_remindersContext from '../medication_reminders/medication_reminderContext';
import MedicationsContext from './medicationsContext';
import axios from 'axios';
import medicationsReducer from './medicationsReducer';

import {
  GET_MEDICATIONS,
  CREATE_MEDICATION,
  GET_MEDICATION,
  EDIT_MEDICATION,
  DELETE_MEDICATION,
  CLEAR_MEDICATION,
} from '../types';

const MedicationsState = (props) => {
  const { createMedReminder, removeReminderFromState } = useContext(
    Medication_remindersContext
  );
  const initialstate = {
    medications: null,
    medication: null,
  };

  const [state, dispatch] = useReducer(medicationsReducer, initialstate);

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Get Meds
  const getMedications = async () => {
    try {
      const res = await axios.get(`/api/medications`);
      dispatch({
        type: GET_MEDICATIONS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Get MedICATIONS BY DOG
  const getMedicationsByDog = async (id) => {
    try {
      const res = await axios.get(`/api/medications/dog/${id}`);
      dispatch({
        type: GET_MEDICATIONS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Create Med
  const createMedication = async (med, reminder) => {
    try {
      const res = await axios.post('/api/medications', med, config);
      dispatch({
        type: CREATE_MEDICATION,
        payload: res.data,
      });

      if (reminder.assigned_to !== undefined) {
        const reminderDetails = {
          reminder: res.data.medication,
          dog: res.data.dog,
          medication: res.data._id,
          due_date: res.data.due_date,
          status: 'pending',
          complete: 'no',
          assigned_to: reminder.assigned_to,
        };

        createMedReminder(reminderDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMedication = async (id) => {
    try {
      const res = await axios.get(`/api/medications/${id}`);
      dispatch({
        type: GET_MEDICATION,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const editMedication = async (data) => {
    try {
      const res = await axios.put(`/api/medications/${data._id}`, data, config);
      dispatch({
        type: EDIT_MEDICATION,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const clearMedication = () => {
    dispatch({
      type: CLEAR_MEDICATION,
    });
  };

  const deleteMedication = (id) => {
    try {
      axios.delete(`/api/medications/${id}`);
      dispatch({
        type: DELETE_MEDICATION,
        payload: id,
      });
      removeReminderFromState(id);
    } catch (err) {
      console.log(err);
    }
  };

  const updateMedicationRemindersFlags = async (id) => {
    try {
      const res = await axios.put(`/api/medications/dog/${id}`);
      dispatch({
        type: GET_MEDICATIONS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MedicationsContext.Provider
      value={{
        medications: state.medications,
        medication: state.medication,
        createMedication,
        editMedication,
        deleteMedication,
        getMedication,
        getMedications,
        clearMedication,
        getMedicationsByDog,
        updateMedicationRemindersFlags,
      }}
    >
      {props.children}
    </MedicationsContext.Provider>
  );
};

export default MedicationsState;
