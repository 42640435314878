import React, { Fragment, useContext, useEffect } from 'react';
import AuthContext from '../../context/auth/authContext';
import AdminContext from '../../context/admin/adminContext';
import UserContext from '../../context/user/userContext';
import Register from '../auth/Register';
import FormBlogEntry from '../forms/FormBlogEntry';
import AdminBlog from '../pages/AdminBlog';
import AdminUserList from '../pages/AdminUserList';
import AdminApplicationList from '../pages/AdminAppicationList';
import Dashboard from '../modules/dashboard/Dashboard';
import ErrorLog from '../pages/ErrorLog';
import Logs from '../pages/Logs';
import Dogs from '../modules/dogs/Dogs';
import Tickets from '../modules/tickets/Tickets';
import AdminEmailTemplates from '../pages/AdminEmailTemplates';
import AdminZohoAppList from '../modules/zoho/AdminZohoAppListPage';
import AdoptionList from '../pages/AdoptionsList';
import ContactList from './ContactsList';
import AdminZohoApplication from '../modules/zoho/AdminZohoApplication';
import UserProfile from '../layout/UserProfile';
import AdminApplication from '../layout/AdminApplication';
import FormBlogEdit from '../forms/FormBlogEdit';
import AdminNewApplication from '../forms/AdminNewApplication';
import KnowledgeBase from '../modules/knowledgeBase/KnowledgeBase';
import MeetingNotes from '../modules/meetingNotes/MeetingNotes';
import MedicationList from '../modules/medicationList/MedicationList';

const Admin = () => {
  const authContext = useContext(AuthContext);
  const adminContext = useContext(AdminContext);
  const userContext = useContext(UserContext);
  const { current } = userContext;
  const { loadUser, user } = authContext;
  const {
    step,
    adminDashboard,
    blogEntryPage,
    blogPage,
    createUserPage,
    userList,
    editUserPage,
  } = adminContext;

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);

  const newBlog = () => {
    blogEntryPage();
  };

  const cancelBlogEntry = () => {
    blogPage();
  };

  const newUser = () => {
    createUserPage();
  };

  const cancelUserEntry = () => {
    userList();
  };

  const editUser = () => {
    editUserPage();
  };

  switch (step) {
    case 'tickets':
      return <Tickets user={user} />;
    case 'knowledgeBase':
      return <KnowledgeBase user={user} />;
    case 'meeting notes':
      return <MeetingNotes user={user} />;
    case 'adoption list':
      return <AdoptionList />;
    case 'errorsLog':
      return <ErrorLog />;
    case 'logs':
      return <Logs />;
    case 'dogs':
      return <Dogs user={user} />;
    case 'admin':
      return (
        <Fragment>
          <div className="container-fluid">
            <AdminApplicationList user={user} />;
          </div>
        </Fragment>
      );
    case 'userList':
      return (
        <div>
          <AdminUserList newUser={newUser} currentUser={user} />
        </div>
      );
    case 'blogList':
      return (
        <div>
          <AdminBlog adminDashboard={adminDashboard} newBlog={newBlog} />;
        </div>
      );
    case 'appList':
      return (
        <div>
          <AdminApplicationList user={user} />;
        </div>
      );
    case 'blogEntryPage':
      return (
        <div>
          <FormBlogEntry cancelBlogEntry={cancelBlogEntry} />
        </div>
      );
    case 'addUserPage':
      return (
        <div>
          <Register cancelUserEntry={cancelUserEntry} />
        </div>
      );
    case 'userProfile':
      return (
        <div>
          <UserProfile
            editUser={editUser}
            cancelUserEntry={cancelUserEntry}
            current={current}
          />
        </div>
      );
    case 'blogEditPage':
      return (
        <div>
          <FormBlogEdit cancelBlogEntry={cancelBlogEntry} />
        </div>
      );
    case 'viewApplication':
      return (
        <div>
          <AdminApplication user={user} />
        </div>
      );
    case 'zappListPage':
      return (
        <div>
          <AdminZohoAppList />
        </div>
      );
    case 'contactList':
      return (
        <div>
          <ContactList />
        </div>
      );
    case 'emailTemplatesPage':
      return (
        <div>
          <AdminEmailTemplates />
        </div>
      );
    case 'viewZohoApplication':
      return (
        <div>
          <AdminZohoApplication />
        </div>
      );
    case 'medicationList':
      return (
        <div>
          <MedicationList />
        </div>
      );
    case 'enterNewApplication':
      return (
        <div>
          <AdminNewApplication />
        </div>
      );
    case 'dashboard':
      return (
        <div className="m-2">
          {user ? <Dashboard user={user} /> : <div>Loading</div>}
        </div>
      );
    default:
      return <div>{user ? <Dashboard user={user} /> : <div>Loading</div>}</div>;
  }
};
export default Admin;
