import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import EmailTemplateContext from '../../context/emailTemplates/emailTemplatesContext';
import AuthContext from '../../context/auth/authContext';
import EmailTextEditor from '../../utils/EmailTextEditor';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';

const AdminEmailTemplates = () => {
  const emailTemplateContext = useContext(EmailTemplateContext);
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const {
    getTemplates,
    emailTemplates,
    createEmailTemplate,
    deleteEmailTemplate,
    editEmailTemplate,
    saveAttachments,
    getTemplateAttachments,
    emailTemplatesAttachments,
    deleteEmailTemplateAttachment,
    saveTemplateAttachments,
  } = emailTemplateContext;

  const [newEmailTemplate, setNewEmailTemplate] = useState({
    template_name: '',
    template_subject: '',
    template_email: '',
    Author: '',
  });
  const [newEmailTemplateBody, setNewEmailTemplateBody] = useState('');
  const [editedTemplateBody, setEditedTemplateBody] = useState('');
  const [editedEmailTemplate, setEditedEmailTemplate] = useState({
    template_name: '',
    template_subject: '',
    template_email: '',
    Author: '',
  });
  const [editedEmailTemplateId, setEditedEmailTemplateId] = useState();
  const [deleteTemplateId, setDeleteTemplateId] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [deletedSavedFiles, setDeletedSavedFiles] = useState();
  const [savedFiles, setSavedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [mode, setMode] = useState('List');
  const fileInputRef = useRef();

  useEffect(() => {
    getTemplates();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSavedFiles(emailTemplatesAttachments);
  }, [emailTemplatesAttachments]);

  const on_change = (e) => {
    setNewEmailTemplate({ ...newEmailTemplate, [e.target.id]: e.target.value });
  };

  const on_edit = (e) => {
    setEditedEmailTemplate({
      ...editedEmailTemplate,
      [e.target.id]: e.target.value,
    });
  };

  const createTemplate = (e) => {
    // ! SAVE FILES ON
    var fileNames = [];
    var x;
    for (x of selectedFiles) {
      const date = Date.now();
      const lastIndex = x.name.lastIndexOf('.') + 1;
      const fName = x.name.slice(0, lastIndex - 1);
      const ext = x.name.slice(lastIndex);
      const newFileName = `${fName}-${date}.${ext}`;

      const attachment = {
        file: x,
        fileName: newFileName,
      };
      saveAttachments(attachment);
      fileNames.push(newFileName);
    }

    const data = {
      template_name: newEmailTemplate.template_name,
      template_subject: newEmailTemplate.template_subject,
      template_email: newEmailTemplateBody,
      Author: user.First_Name + user.Last_Name,
      files: fileNames,
    };
    createEmailTemplate(data);

    setNewEmailTemplate({
      template_name: '',
      template_subject: '',
      template_email: '',
      Author: '',
    });
    setNewEmailTemplateBody('');
    fileInputRef.current.value = '';
    setSelectedFiles([]);
    setMode('List');
    window.scroll(0, 0);
  };

  const viewTemplate = (e) => {
    getTemplateAttachments(e.target.id);
    const oldEmailTemplateData = emailTemplates.filter(
      (template) => template._id === parseInt(e.target.id)
    );
    setEditedEmailTemplate({
      template_name: oldEmailTemplateData[0].template_name,
      template_subject: oldEmailTemplateData[0].template_subject,
      Author: oldEmailTemplateData[0].Author,
    });
    setNewEmailTemplateBody(oldEmailTemplateData[0].template_email);
    setEditedEmailTemplateId(parseInt(e.target.id));
  };

  const editTemplate = (e) => {
    getTemplateAttachments(e.target.id);
    const oldEmailTemplateData = emailTemplates.filter(
      (template) => template._id === parseInt(e.target.id)
    );
    setEditedEmailTemplate({
      template_name: oldEmailTemplateData[0].template_name,
      template_subject: oldEmailTemplateData[0].template_subject,
      Author: oldEmailTemplateData[0].Author,
    });
    setEditedTemplateBody(oldEmailTemplateData[0].template_email);
    setEditedEmailTemplateId(parseInt(e.target.id));
    setMode('Edit');
    window.scroll(0, 0);
  };

  const confirmDeleteId = (e) => {
    setDeleteTemplateId(e.target.id);
  };

  const deleteTemplate = (e) => {
    deleteEmailTemplate(deleteTemplateId);
    setTimeout(() => {
      getTemplates();
    }, 1500);
    setDeleteTemplateId(null);
  };

  const cancelDeletion = () => {
    setDeleteTemplateId(null);
  };

  const save_editedEmailTemplate = (e) => {
    // *  START ---> SAVE NEW FILE ATTACHMENTS
    var fileNames = [];
    var x;
    for (x of selectedFiles) {
      const date = Date.now();
      const fName = x.name.split('.')[0];
      const ext = x.name.split('.')[1];
      const newFileName = `${fName}-${date}.${ext}`;

      const attachment = {
        file: x,
        fileName: newFileName,
      };
      saveTemplateAttachments(attachment);
      fileNames.push(newFileName);
    }
    // !  END ----> SAVE NEW FILE ATTACHMENTS

    // * START ---> DELETE SAVED FILES THAT WERE REMOVED

    if (deletedSavedFiles !== undefined) {
      if (deletedSavedFiles.length === 1) {
        deleteEmailTemplateAttachment(deletedSavedFiles);
      } else if (deletedSavedFiles.length > 1) {
        var z;
        for (z of deletedSavedFiles) {
          deleteEmailTemplateAttachment(z);
        }
      }
    }
    // ! END ---> DELETED SAVED FILES THAT WERE REMVOED  *******************

    const data = {
      _id: editedEmailTemplateId,
      template_name: editedEmailTemplate.template_name,
      template_subject: editedEmailTemplate.template_subject,
      Author: user.First_Name + user.Last_Name,
      template_email: editedTemplateBody,
      files: fileNames,
    };
    editEmailTemplate(data);
    setTimeout(() => {
      getTemplates();
    }, 800);
    setEditedEmailTemplateId('');
    setEditedEmailTemplate('');
    setEditedTemplateBody('');
    fileInputRef.current.value = '';
    setMode('List');
    window.scroll(0, 0);
  };

  const cancelEdit = (e) => {
    setEditedEmailTemplateId('');
    setEditedEmailTemplate('');
    setEditedTemplateBody('');
    setNewEmailTemplateBody('');
    setDeletedSavedFiles([]);
    setSavedFiles([]);
    setSelectedFiles([]);
    fileInputRef.current.value = '';
    setMode('List');
    window.scroll(0, 0);
  };

  const cancelView = (e) => {
    setEditedEmailTemplateId('');
    setEditedEmailTemplate('');
    setEditedTemplateBody('');
    setNewEmailTemplateBody('');
    setDeletedSavedFiles([]);
    setSavedFiles([]);
    setSelectedFiles([]);
    setMode('List');
    window.scroll(0, 0);
  };

  const cancelCreate = (e) => {
    setNewEmailTemplate({
      template_name: '',
      template_subject: '',
      template_email: '',
      Author: '',
    });
    setNewEmailTemplateBody('');
    setSelectedFiles([]);
    fileInputRef.current.value = '';
    setMode('List');
    window.scroll(0, 0);
  };

  // ! ****************** DROP ZONE  CODE  *****************

  const downloadAttachment = (id) => {
    axios(`/api/emailTemplate/attachment/${id}`, {
      method: 'GET',
      responseType: 'blob',
    })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers.content_type,
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
      } else {
        files[i]['invalid'] = true;
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        setErrorMessage('File type not permitted');
      }
    }
  };

  const validateFile = (file) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon',
      'application/doc',
      'application/docx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/msword',
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  };

  const fileSize = (size) => {
    if (size === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const removeFile = (name) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== name);
    setSelectedFiles(updatedFiles);
    fileInputRef.current.value = '';
  };

  const removeSavedFile = (name, id) => {
    let deleteFileIdList = [];
    const updatedFiles = savedFiles.filter((file) => file.fileName !== name);
    setSavedFiles(updatedFiles);
    if (deletedSavedFiles === undefined) {
      deleteFileIdList.push(id);
      setDeletedSavedFiles(deleteFileIdList);
    } else {
      deleteFileIdList.push(id);
      var z;
      for (z of deletedSavedFiles) {
        deleteFileIdList.push(z);
      }
      setDeletedSavedFiles(deleteFileIdList);
    }
  };

  //  ********************* DROP ZONE CODE END ***************************

  //! ************* TEMPLATE LIST MODE VIEW ******************************

  if (mode === 'List') {
    return (
      <Fragment>
        <div className="container">
          <h2 className="text-center">EMAIL TEMPLATES</h2>
          <div className="text-right">
            <button
              className="btn btn-success"
              onClick={() => {
                setMode('Create');
                window.scroll(0, 0);
              }}
            >
              Create Template
            </button>
          </div>

          <table className="table table-striped mt-3">
            <thead>
              <tr>
                <th scope="col">NAME</th>
                <th scope="col" className="d-none d-md-table-cell">
                  SUBJECT
                </th>
                <th scope="col" className="d-none d-md-table-cell">
                  AUTHOR
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {emailTemplates != null ? (
                emailTemplates.map((template) => (
                  <tr key={template._id}>
                    <td>{template.template_name}</td>
                    <td className="d-none d-md-table-cell">
                      {template.template_subject}
                    </td>
                    <td className="d-none d-md-table-cell">
                      {template.Author}
                    </td>
                    <td className="text-right">
                      <i
                        className="fa fa-address-card-o mr-2 text-success pointer"
                        data-toggle="modal"
                        data-target="#staticBackdrop3"
                        aria-hidden="true"
                        id={template._id}
                        onClick={viewTemplate}
                      ></i>
                      <i
                        className="fa fa-pencil mr-2 pointer text-warning"
                        id={template._id}
                        onClick={editTemplate}
                      ></i>
                      <i
                        className="fa fa-trash pointer text-danger"
                        id={template._id}
                        title="Delete Application"
                        data-toggle="modal"
                        data-target="#templateDeleteConfirmationModal"
                        onClick={confirmDeleteId}
                        aria-hidden="true"
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <th>LOADING</th>
              )}
            </tbody>
          </table>
          {/*********************** DELETE TEMPLATE CONFIRMATION **********************/}
          <div
            className="modal fade"
            id="templateDeleteConfirmationModal"
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="templateDeleteConfirmationModal"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-danger font-weight-bold">
                    DELETE CONFIRMATION
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p className="font-weight-bold">
                    ARE YOU SURE YOU WANT TO DELETE THIS TEMPLATE?
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={cancelDeletion}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    CANCEL
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={deleteTemplate}
                    data-dismiss="modal"
                  >
                    CONFRIM
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* ************************* VIEW ONLY TEMPLATE MODAL ************** */}
          <div
            className="modal fade"
            id="staticBackdrop3"
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="staticBackdrop3Label"
            aria-hidden="true"
          >
            <div className="modal-dialog gt-modal" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdrop3Label">
                    VIEW EMAIL TEMPLATE
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={cancelView}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form className="m-2">
                    <div className="container">
                      <div className="form-group">
                        <div className="row">
                          <label className="active" htmlFor="template_name">
                            NAME:
                          </label>
                          <input
                            id="template_name"
                            type="text"
                            className="form-control"
                            defaultValue={editedEmailTemplate.template_name}
                            onChange={on_edit}
                            maxLength="100"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <label className="active" htmlFor="template_subject">
                            SUBJECT:
                          </label>
                          <input
                            id="email_subject"
                            type="text"
                            className="form-control"
                            defaultValue={editedEmailTemplate.template_subject}
                            onChange={on_edit}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="card">
                          <div className="card-body">
                            <div className="px-3 pt-3">
                              {' '}
                              {ReactHtmlParser(newEmailTemplateBody)}
                            </div>
                          </div>
                        </div>{' '}
                      </div>
                    </div>
                  </form>
                  <div className="mt-2">
                    <label className="active font-weight-bolder">
                      ATTACHMENTS:
                    </label>
                    <div className="file-display-container">
                      {savedFiles.map((data, i) => (
                        <div className="file-status-bar" key={i}>
                          <span
                            className={`file-name ${
                              data.invalid
                                ? 'file-error'
                                : 'mx-2 pointer text-danger'
                            }`}
                            key={data._id}
                            onClick={() => downloadAttachment(data._id)}
                          >
                            {data.fileName}
                          </span>
                          {data.invalid && (
                            <span className="file-error-message">
                              ({errorMessage})
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={cancelView}
                  >
                    BACK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else if (mode === 'Create') {
    return (
      // /* *********** CREATE EMAIL MODE ************* */
      <div className="container p-3">
        <h4 className="text-center font-weight-bold">NEW EMAIL TEMPLATE</h4>
        <form className="m-2">
          <div className="container">
            <div className="form-group">
              <div className="row">
                <label className="font-weight-bolder" htmlFor="template_name">
                  NAME:
                </label>
                <input
                  id="template_name"
                  type="text"
                  className="form-control"
                  value={newEmailTemplate.template_name}
                  onChange={on_change}
                  maxLength="100"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <label
                  className="font-weight-bolder"
                  htmlFor="template_subject"
                >
                  SUBJECT:
                </label>
                <input
                  id="template_subject"
                  type="text"
                  className="form-control"
                  value={newEmailTemplate.template_subject}
                  onChange={on_change}
                  maxLength="100"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <label
                  className="font-weight-bolder"
                  htmlFor="newEmailTemplateBody"
                >
                  MESSAGE:
                </label>
              </div>
              <div className="row">
                <EmailTextEditor
                  textData={newEmailTemplateBody}
                  setTextData={setNewEmailTemplateBody}
                />
              </div>
            </div>
          </div>
          <div>
            <label className="font-weight-bolder">ATTACHMENTS:</label>
            <div className="file-display-container">
              {selectedFiles.map((data, i) => (
                <div className="file-status-bar" key={i}>
                  <span
                    className={`file-name ${data.invalid ? 'file-error' : ''}`}
                  >
                    {data.name}
                  </span>
                  <span className="file-size">({fileSize(data.size)})</span>{' '}
                  {data.invalid && (
                    <span className="file-error-message">({errorMessage})</span>
                  )}
                  <span
                    className="file-remove ml-2 font-weight-bolder"
                    onClick={() => removeFile(data.name)}
                  >
                    REMOVE
                  </span>
                </div>
              ))}
            </div>
          </div>
        </form>
        <div
          className="drop-container text-center mt-2"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          id="uploadFile"
          onClick={fileInputClicked}
        >
          <div className="drop-message">
            <div className="upload-icon"></div>
            Drag & Drop file here or click to select file from computer
          </div>
          <div>
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple
              onChange={filesSelected}
            />
          </div>
        </div>
        <div className="file-progressBar" />
        <div></div>
        <div className="mt-2 row">
          <div className="col-9"></div>
          <div className="col-3">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={cancelCreate}
            >
              CANCEL
            </button>
            <button
              type="button"
              className="btn btn-background"
              onClick={createTemplate}
            >
              SAVE TEMPLATE
            </button>
          </div>
        </div>
      </div>
    );
  } else if (mode === 'Edit') {
    /* ************************* TEMPLATE EDIT MODE    ************** */

    return (
      <div className="container bg-white mt-2">
        <h4 className="text-center font-weight-bold">EDIT EMAIL TEMPLATE</h4>
        <form className="m-3">
          <div className="form-group">
            <div className="row">
              <label
                className="active font-weight-bold"
                htmlFor="template_name"
              >
                NAME:
              </label>
              <input
                id="template_name"
                type="text"
                className="form-control"
                defaultValue={editedEmailTemplate.template_name}
                onChange={on_edit}
                maxLength="100"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <label
                className="active font-weight-bold"
                htmlFor="template_subject"
              >
                SUBJECT:
              </label>
              <input
                id="template_subject"
                type="text"
                className="form-control"
                defaultValue={editedEmailTemplate.template_subject}
                onChange={on_edit}
                maxLength="100"
              />
            </div>
          </div>
          <div className="row email-editor">
            <div className="form-group ck-editor">
              <EmailTextEditor
                textData={editedTemplateBody}
                setTextData={setEditedTemplateBody}
              />
            </div>
          </div>
        </form>
        <div>
          <label className="active font-weight-bolder">ATTACHMENTS:</label>
          <div className="file-display-container">
            {savedFiles.map((data) => (
              <div className="file-status-bar" key={data._id}>
                <span
                  className={`file-name ${data.invalid ? 'file-error' : ''}`}
                >
                  {data.fileName}
                </span>
                {data.invalid && (
                  <span className="file-error-message">({errorMessage})</span>
                )}
                <span
                  className="file-remove ml-2 font-weight-bolder"
                  onClick={() => removeSavedFile(data.fileName, data._id)}
                >
                  REMOVE
                </span>
              </div>
            ))}
            {selectedFiles.map((data, i) => (
              <div className="file-status-bar" key={i}>
                <span
                  className={`file-name ${data.invalid ? 'file-error' : ''}`}
                >
                  {data.name}
                </span>
                {data.invalid && (
                  <span className="file-error-message">({errorMessage})</span>
                )}
                <span
                  className="file-remove ml-2 font-weight-bolder"
                  onClick={() => removeFile(data.name)}
                >
                  REMOVE
                </span>
              </div>
            ))}
          </div>
        </div>
        <div
          className="drop-container text-center mt-2"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          id="uploadFile"
          onClick={fileInputClicked}
        >
          <div className="drop-message">
            <div className="upload-icon"></div>
            Drag & Drop file here or click to select file from computer
          </div>
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            multiple
            onChange={filesSelected}
          />
        </div>
        <div></div>
        <div className="row mt-2">
          <div className="col-9"></div>
          <div className="col-3">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={cancelEdit}
            >
              CANCEL
            </button>
            <button
              type="button"
              onClick={save_editedEmailTemplate}
              className="btn btn-background"
            >
              SAVE TEMPLATE
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default AdminEmailTemplates;
