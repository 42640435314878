import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import ZohoContext from '../../../context/zoho/zohoContext';
import AdminZohoFile from './AdminZohoFile';
import './DropZone.css';

const AdminZohoFiles = (props) => {
  const zohoContext = useContext(ZohoContext);
  const {
    zapp,
    getZohoUsers,
    zusers,
    zfiles,
    getAppFiles,
    deleteAFile,
    uploadTheFile,
    fileToDB,
  } = zohoContext;
  const { user, users } = props;
  const [showNewFileButton, setShowNewFileButton] = useState(true);
  const [showNewFileform, setShowNewFileform] = useState(false);
  const fileInputRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [filesReceived, setFilesReceived] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState(null);

  useEffect(() => {
    getZohoUsers();
    getAppFiles(props.appID);
    // eslint-disable-next-line
  }, []);

  const uploadNewFile = () => {
    setShowNewFileform(true);
    setShowNewFileButton(false);
  };

  const submitUploadFile = async (e) => {
    e.preventDefault();

    const date = Date.now();
    const fileName = `${selectedFiles.name.split('.')[0]}${date}`;
    const lastIndex = selectedFiles.name.lastIndexOf('.') + 1;
    const fileExtension = selectedFiles.name.slice(lastIndex);
    const data = {
      file: selectedFiles,
      fileName: fileName,
      fileExtension: fileExtension,
    };

    await uploadTheFile(data);

    const dbData = {
      Old_Attachment_Id: Date.now(),
      Attachment_ID: fileName + '.' + fileExtension,
      File_Name: selectedFiles.name,
      Parent_ID: zapp.Application_ID,
      Size: selectedFiles.size,
      Attachment_Owner_ID: user._id,
    };

    await fileToDB(dbData);
    setFilesReceived(false);
    setSelectedFiles([]);
    setShowNewFileButton(true);
    setShowNewFileform(false);
  };

  const cancelUpload = () => {
    setSelectedFiles([]);
    setFilesReceived(false);
    setShowNewFileform(false);
    setShowNewFileButton(true);
  };

  const deleteThisFile = () => {
    deleteAFile(deleteFileId);
    setDeleteFileId(null);
  };

  const confirmDeleteId = (e) => {
    setDeleteFileId(e);
  };

  const cancelDeletion = () => {
    setDeleteFileId(null);
  };

  // ********************** DROP ZONE  CODE  ******************************

  const preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length > 1) {
      setErrorMessage('ONLY ONE FILE CAN BE UPLOADED AT A TIME');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    } else {
      if (validateFile(files[0])) {
        setSelectedFiles(files[0]);
        setFilesReceived(true);
      } else {
        setErrorMessage('File type not permitted');
      }
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length > 1) {
      setErrorMessage('ONLY ONE FILE CAN BE UPLOADED AT A TIME');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    } else {
      if (validateFile(fileInputRef.current.files[0])) {
        setSelectedFiles(fileInputRef.current.files[0]);
        setFilesReceived(true);
      } else {
        setErrorMessage('File type not permitted');
      }
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const validateFile = (file) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon',
      'application/doc',
      'application/docx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/msword',
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  };

  const fileSize = (size) => {
    if (size === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const fileType = (fileName) => {
    return (
      fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
      fileName
    );
  };

  const removeFile = (name) => {
    setSelectedFiles([]);
    setFilesReceived(false);
  };

  //  ********************* DROP ZONE CODE END ***************************

  return (
    <Fragment>
      {showNewFileform ? (
        <div className="container">
          <form>
            {filesReceived ? (
              <div className="file-display-container">
                <div className="row">
                  <span className="file-error-message">{errorMessage}</span>
                </div>
                <div className="file-type-logo"></div>
                <div className="file-type">{fileType(selectedFiles.name)}</div>
                <span className={`file-name`}>{selectedFiles.name}</span>
                <span className="file-size">
                  ({fileSize(selectedFiles.size)})
                </span>

                <div
                  className="file-remove"
                  onClick={() => removeFile(selectedFiles.name)}
                >
                  REMOVE
                </div>
              </div>
            ) : (
              <div
                className="drop-container text-center"
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                id="uploadFile"
                onClick={fileInputClicked}
              >
                <span className="file-error-message">{errorMessage}</span>

                <div className="drop-message">
                  <div className="upload-icon"></div>
                  Drag & Drop file here or click to select file from computer
                </div>
                <input
                  ref={fileInputRef}
                  className="file-input"
                  type="file"
                  multiple
                  onChange={filesSelected}
                />
              </div>
            )}
          </form>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={cancelUpload}
            >
              CANCEL
            </button>
            <button
              type="button"
              onClick={submitUploadFile}
              className="btn btn-background"
            >
              UPLOAD
            </button>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-lg-3">
          <h2 className="ml-2 mt-3">FILES</h2>
        </div>
        <div className="col-lg-6"></div>
        <div className="col-lg-3 text-right">
          {showNewFileButton ? (
            <button
              className="btn btn-success mt-3 mr-3"
              onClick={uploadNewFile}
            >
              NEW FILE
            </button>
          ) : null}
        </div>
      </div>
      <div className="container-fluid shadow mt-3 p-0">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th>FILE NAME</th>
              <th>FILE SIZE</th>
              <th>UPLOADED BY</th>
              <th>UPLOADED DATE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {zfiles !== null && zusers !== null ? (
              zfiles.map((file) => (
                <AdminZohoFile
                  key={file.Old_Attachment_Id}
                  zusers={zusers}
                  users={users}
                  file={file}
                  confirmDeleteId={confirmDeleteId}
                />
              ))
            ) : (
              <tr>
                <td className="text-center font-weight-bold mt-3">
                  NO FILES FOR THIS APPLICATION
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* DELETE CONFIRMATION */}
      <div
        className="modal fade"
        id="fileDeleteConfirmationModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="fileDeleteConfirmationModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger font-weight-bold">
                DELETE CONFIRMATION
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="font-weight-bold">
                ARE YOU SURE YOU WANT TO DELETE THIS FILE?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={cancelDeletion}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteThisFile}
                data-dismiss="modal"
              >
                CONFRIM
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminZohoFiles;
