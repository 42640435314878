import React, { Fragment, useState, useContext } from 'react';
import AlertContext from '../../context/alert/alertContext';
import axios from 'axios';

const ForgotPassword = props => {
  const alertContext = useContext(AlertContext);
  const [email, setEmail] = useState('');
  const { setAlert } = alertContext;

  const onChange = e => {
    setEmail(e.target.value);
  };

  const submitReset = e => {
    e.preventDefault();
    if (email === '') {
      setAlert('Please Enter A Username to Reset', 'danger');
    } else {
      axios
        .post('/api/forgotPassword', {
          email: email
        })
        .then(response => {
          if (response.data === 'recovery email sent') {
            setAlert('Reset Email has been sent', 'success');
            props.history.push('/admin');
          } else {
            setAlert('Not a Valid UserName', 'danger');
          }
        })
        .catch(error => {
          setAlert('Server Error', 'danger');
        });
    }
  };

  return (
    <div className="container">
      <Fragment>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="card m-3 col-md-6">
            <h2 className="text-center">RESET PASSWORD </h2>
            <form>
              <div className="form-group">
                <label htmlFor="Username">Enter UserName</label>
                <input
                  type="email"
                  className="form-control"
                  id="Username"
                  value={email}
                  onChange={onChange}
                />
              </div>
              <button
                type="submit"
                className="btn btn-warning m-2"
                onClick={submitReset}
              >
                RESET
              </button>
            </form>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default ForgotPassword;
