import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import ZohoContext from '../../../context/zoho/zohoContext';
import AdminContext from '../../../context/admin/adminContext';
import AdminZohoNote from './AdminZohoNote';
import TextEditor from '../../../utils/TextEditor';

const AdminZohoNotes = (props) => {
  const zohoContext = useContext(ZohoContext);
  const adminContext = useContext(AdminContext);
  const {
    znotes,
    getAppNotes,
    getZohoUsers,
    zusers,
    editNote,
    createNote,
    deleteNote,
  } = zohoContext;
  const { startEditing, doneEditing } = adminContext;
  const [newNoteEditor, setNewNoteEditor] = useState(false);
  const [editNoteEditor, setEditNoteEditor] = useState(false);
  const [newNote, setNewNote] = useState();
  const [editedNote, setEditednote] = useState();
  const [editedNoteId, setEditedNoteId] = useState();
  const [showNewNoteButton, setShowNewNoteButton] = useState(true);
  const [removeNoteId, setRemoveNoteId] = useState(null);

  const useScroll = () => {
    const htmlElRef = useRef(null);
    const executeScroll = () => window.scrollTo(0, htmlElRef.current.offsetTop);

    return [executeScroll, htmlElRef];
  };

  const [executeScroll, elementToScrollRef] = useScroll();

  useEffect(() => {
    getAppNotes(props.appID);
    getZohoUsers();
    // eslint-disable-next-line
  }, []);

  const add_note = () => {
    const date = Date.now();
    const data = {
      Note_ID: 'zcrm_' + date,
      Note_Content: newNote,
      Parent_ID: props.appID,
      Note_Owner_ID: `zcrm_1108341000000134010`,
    };
    createNote(data);
    setNewNote('');
    setNewNoteEditor(false);
    setShowNewNoteButton(true);
    doneEditing();
  };

  const insertEditNote = (e) => {
    startEditing();
    const oldNoteData = znotes.filter((note) => note.Note_ID === e);
    setEditednote(oldNoteData[0].Note_Content);
    setEditedNoteId(e);
    setEditNoteEditor(true);
    setShowNewNoteButton(false);
    setNewNoteEditor(false);
    executeScroll();
  };

  const update_note = (e) => {
    const data = {
      Note_ID: editedNoteId,
      Note_Content: editedNote,
      Note_Owner_ID: `zcrm_1108341000000134010`,
    };
    editNote(data);
    setEditedNoteId('');
    setEditednote('');
    setEditNoteEditor(false);
    setShowNewNoteButton(true);
    doneEditing();
  };

  const cancelEdit = (e) => {
    setEditedNoteId('');
    setEditednote('');
    setNewNote('');
    setNewNoteEditor(false);
    setEditNoteEditor(false);
    setShowNewNoteButton(true);
    doneEditing();
  };

  const createNewNote = () => {
    setNewNoteEditor(true);
    setShowNewNoteButton(false);
    setEditNoteEditor(false);
    startEditing();
  };

  const deleteANote = (e) => {
    deleteNote(e);
  };

  const removeNote = (e) => {
    setRemoveNoteId(e);
  };

  const cancelDeletion = () => {
    setRemoveNoteId(null);
  };

  if (znotes !== null) {
    if (znotes.length !== 0) {
      return (
        <Fragment>
          <div className="row" ref={elementToScrollRef}>
            <div className="col-lg-3">
              <h2 className="ml-2 mt-3">NOTES:</h2>
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-3 text-right">
              {showNewNoteButton ? (
                <button
                  className="btn btn-success mt-3 mr-3"
                  onClick={createNewNote}
                >
                  NEW NOTE
                </button>
              ) : null}
            </div>
          </div>
          {newNoteEditor ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10">
                  <form>
                    <div className="form-group">
                      <TextEditor textData={newNote} setTextData={setNewNote} />
                    </div>
                    <button
                      type="button"
                      className="btn btn-secondary mr-1"
                      data-dismiss="modal"
                      onClick={cancelEdit}
                    >
                      CANCEL
                    </button>
                    <button
                      type="button"
                      onClick={add_note}
                      className="btn btn-background"
                      // data-dismiss="modal"
                    >
                      ADD NOTE
                    </button>
                  </form>
                </div>
                <div className="col-lg-1"></div>
              </div>
            </div>
          ) : null}
          {editNoteEditor ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10">
                  <form>
                    <div className="form-group">
                      <TextEditor
                        textData={editedNote}
                        setTextData={setEditednote}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-secondary mr-1"
                      data-dismiss="modal"
                      onClick={cancelEdit}
                    >
                      CANCEL
                    </button>
                    <button
                      type="button"
                      onClick={update_note}
                      className="btn btn-background"
                      // data-dismiss="modal"
                    >
                      SAVE
                    </button>
                  </form>
                </div>
                <div className="col-lg-1"></div>
              </div>
            </div>
          ) : null}

          {znotes !== null && zusers !== null ? (
            znotes.map((note) => (
              <AdminZohoNote
                key={note.Note_ID}
                zusers={zusers}
                note={note}
                insertEditNote={insertEditNote}
                removeNote={removeNote}
              />
            ))
          ) : (
            <h2 className="text-center font-weight-bold mt-2">
              NO NOTES FOR THIS APPLICATION
            </h2>
          )}
          <div
            className="modal fade"
            id="noteDeleteConfirmationModal"
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="noteDeleteConfirmationModal"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-danger font-weight-bold">
                    DELETE CONFIRMATION
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p className="font-weight-bold">
                    ARE YOU SURE YOU WANT TO DELETE THIS NOTE?
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={cancelDeletion}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    CANCEL
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      deleteANote(removeNoteId);
                      setRemoveNoteId(null);
                    }}
                    data-dismiss="modal"
                  >
                    CONFRIM
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="row">
            <div className="col-lg-9"></div>
            <div className="col-lg-3 text-right">
              {showNewNoteButton ? (
                <button
                  className="btn btn-success mt-3 mr-3"
                  onClick={createNewNote}
                >
                  NEW NOTE
                </button>
              ) : null}
            </div>
          </div>
          {newNoteEditor ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-10">
                  <form>
                    <div className="form-group">
                      <TextEditor textData={newNote} setTextData={setNewNote} />
                    </div>
                    <button
                      type="button"
                      className="btn btn-secondary mr-1"
                      data-dismiss="modal"
                      onClick={cancelEdit}
                    >
                      CANCEL
                    </button>
                    <button
                      type="button"
                      onClick={add_note}
                      className="btn btn-background"
                      // data-dismiss="modal"
                    >
                      ADD NOTE
                    </button>
                  </form>
                </div>
                <div className="col-lg-1"></div>
              </div>
            </div>
          ) : null}
          <h2 className="ml-2 mt-3">NOTES:</h2>
          <div className="card">
            <div className="card-body">
              <h3 className="mt-4 text-center font-weight-bold text-danger">
                NO NOTES FOR THIS APPLICATION
              </h3>
            </div>
          </div>
        </Fragment>
      );
    }
  } else {
    return <h2 className="mt-3">.......LOADING NOTES</h2>;
  }
};

export default AdminZohoNotes;
