import React, { useContext, useState } from 'react';
import MedicationListContext from '../../../context/medication_list/medicationListContext';
import MedicationListCreate from './MedicationListCreate';
import MedicationListEdit from './MedicationListEdit';

const MedicationListFullList = () => {
  const { deleteMed, createMed, editMed, meds } = useContext(
    MedicationListContext
  );
  const [newMedication, setNewMedication] = useState(null);
  const [pageView, setPageView] = useState('create');

  const onchange = (e) => {
    setNewMedication({ ...newMedication, medication_name: e.target.value });
  };

  const addMedication = () => {
    createMed(newMedication);
    setNewMedication({ medication_name: '' });
    document.getElementById('medication_name').value = '';
  };

  const editMedication = (id) => {
    editMed(id, newMedication);
    setNewMedication({ medication_name: '' });
    document.getElementById('medication_name').value = '';
    setPageView('create');
  };

  const loadToEdit = (medication) => {
    setPageView('edit');
    setNewMedication({
      _id: medication._id,
      medication_name: medication.medication_name,
    });
  };

  return (
    <div className="medicationListTable text-center m-auto">
      {pageView === 'create' ? (
        <MedicationListCreate
          meds={meds}
          onchange={onchange}
          addMedication={addMedication}
        />
      ) : (
        <MedicationListEdit
          med={newMedication}
          editMedication={editMedication}
          onchange={onchange}
        />
      )}

      <div className="row">
        <div className="col-lg-4"></div>
        <div className="col-lg-4">
          <h2 className="mt-3">MEDICATIONS LIST</h2>
          <table className="table table-sm table-hover mt-3">
            <tbody>
              {meds !== null && meds !== undefined
                ? meds.map((med) => (
                    <tr className="border border-none">
                      <td key={med._id} className="font-weight-bold">
                        {med.medication_name}
                      </td>
                      <td className="text-right">
                        <button
                          className="btn pointer bg-warning font-weight-bold rounded "
                          onClick={() => loadToEdit(med)}
                        >
                          EDIT
                        </button>

                        <button
                          className="btn pointer bg-danger font-weight-bold rounded text-light ml-2"
                          onClick={() => deleteMed(med._id)}
                        >
                          REMOVE
                        </button>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-lg-4"></div>
    </div>
  );
};

export default MedicationListFullList;
