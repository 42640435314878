import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import AppContext from '../../../context/app/appContext';
import AdminContext from '../../../context/admin/adminContext';
import NotesContext from '../../../context/notes/notesContext';
import AuthContext from '../../../context/auth/authContext';
import TextEditor from '../../../utils/TextEditor';
import ReactHtmlParser from 'react-html-parser';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'America/New_York',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const AdminAppNotes = (props) => {
  const appContext = useContext(AppContext);
  const adminContext = useContext(AdminContext);
  const authContext = useContext(AuthContext);
  const notesContext = useContext(NotesContext);
  const { user } = authContext;
  const { startEditing, doneEditing } = adminContext;
  const {
    editNote,
    deleteNote,
    getNotes,
    notes,
    noteLoading,
    createNote,
  } = notesContext;
  const { app } = appContext;
  const [newNote, setNewNote] = useState();
  const [editedNote, setEditednote] = useState();
  const [editedNoteId, setEditedNoteId] = useState();
  const [showNewNoteButton, setShowNewNoteButton] = useState(true);
  const [newNoteEditor, setNewNoteEditor] = useState(false);
  const [editNoteEditor, setEditNoteEditor] = useState(false);
  const [sortedNotes, setSortedNotes] = useState([]);
  const [removeNoteId, setRemoveNoteId] = useState(null);
  const { sort } = props;

  const useScroll = () => {
    const htmlElRef = useRef(null);
    const executeScroll = () => window.scrollTo(0, htmlElRef.current.offsetTop);

    return [executeScroll, htmlElRef];
  };

  const [executeScroll, elementToScrollRef] = useScroll();

  useEffect(() => {
    if (app !== null) {
      getNotes(app._id);
    }
    // eslint-disable-next-line
  }, [app]);

  useEffect(() => {
    if (notes !== null) {
      if (sort === 'Ascending') {
        const notesSorted = notes.slice().sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        setSortedNotes(notesSorted);
      } else {
        const notesSorted = notes.slice().sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setSortedNotes(notesSorted);
      }
    }
    // eslint-disable-next-line
  }, [notes, props.sort]);

  const add_note = () => {
    const data = {
      Note: newNote,
      NoteApp_Id: app._id,
      Author: `${user.First_Name} ${user.Last_Name}`,
    };
    createNote(data);
    getNotes(app._id);
    setNewNote('');
    setNewNoteEditor(false);
    setShowNewNoteButton(true);
    doneEditing();
  };

  const insertEditNote = (e) => {
    startEditing();
    const oldNoteData = notes.filter(
      (note) => note._id === parseInt(e.target.id)
    );
    setEditednote(oldNoteData[0].Note);
    setEditedNoteId(parseInt(e.target.id));
    setEditNoteEditor(true);
    setShowNewNoteButton(false);
    setNewNoteEditor(false);
    executeScroll();
  };

  const update_note = (e) => {
    const data = {
      _id: editedNoteId,
      Note: editedNote,
      Author: `${user.First_Name} ${user.Last_Name}`,
    };
    editNote(data);
    setTimeout(() => {
      getNotes(app._id);
    }, 800);
    setEditedNoteId('');
    setEditednote('');
    setEditNoteEditor(false);
    setShowNewNoteButton(true);
    doneEditing();
  };

  const cancelEdit = (e) => {
    setEditedNoteId('');
    setEditednote('');
    setNewNote('');
    setNewNoteEditor(false);
    setEditNoteEditor(false);
    setShowNewNoteButton(true);
    doneEditing();
  };

  const createNewNote = () => {
    setNewNoteEditor(true);
    setShowNewNoteButton(false);
    setEditNoteEditor(false);
    startEditing();
  };

  const deleteANote = (e) => {
    deleteNote(e);
  };

  const removeNote = (e) => {
    setRemoveNoteId(e.target.id);
  };

  const cancelDeletion = () => {
    setRemoveNoteId(null);
  };

  return (
    <Fragment>
      <div ref={elementToScrollRef} className="container-fluid my-3">
        <div className="row">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            {showNewNoteButton ? (
              <button
                type="button"
                className="btn btn-warning text-dark font-weight-bold"
                onClick={createNewNote}
              >
                CREATE NOTE
              </button>
            ) : null}
          </div>
        </div>
      </div>
      {newNoteEditor ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <form>
                <div className="form-group">
                  <TextEditor textData={newNote} setTextData={setNewNote} />
                </div>
                <button
                  type="button"
                  className="btn btn-secondary mr-1"
                  data-dismiss="modal"
                  onClick={cancelEdit}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  onClick={add_note}
                  className="btn btn-background"
                  // data-dismiss="modal"
                >
                  ADD NOTE
                </button>
              </form>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      ) : null}
      {editNoteEditor ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <form>
                <div className="form-group">
                  <TextEditor
                    textData={editedNote}
                    setTextData={setEditednote}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-secondary mr-1"
                  data-dismiss="modal"
                  onClick={cancelEdit}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  onClick={update_note}
                  className="btn btn-background"
                  // data-dismiss="modal"
                >
                  SAVE
                </button>
              </form>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      ) : null}

      <div className="container-fluid">
        {sortedNotes !== null && !noteLoading ? (
          sortedNotes.map((note) => (
            <div className="row m-3" key={note._id}>
              <div className="col-lg-12">
                <div className="card  rounded px-3 bg-foster-theme">
                  <div className="row  mt-2">
                    <div className="card-body bg-light text-dark rounded">
                      <div className="note-text px-3 pt-3">
                        {ReactHtmlParser(note.Note)}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-9">
                      <p className="mb-0 pl-3">
                        <span className="font-weight-bold">BY:</span>{' '}
                        {note.Author}
                        <span className="font-weight-bold ml-4">
                          POSTED AT:{' '}
                        </span>
                        {formatDate(note.createdAt)}
                        <span className="font-weight-bold ml-4">
                          UPDATED AT:{' '}
                        </span>
                        {formatDate(note.updatedAt)}
                      </p>
                    </div>
                    <div className="col-lg-3 text-right">
                      <i
                        className="fa fa-pencil mr-4 pointer text-warning"
                        id={note._id}
                        onClick={insertEditNote}
                      ></i>
                      <i
                        className="fa fa-trash-o pointer text-danger"
                        onClick={removeNote}
                        id={note._id}
                        data-toggle="modal"
                        data-target="#noteDeleteConfirmationModal"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="noteDeleteConfirmationModal"
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="noteDeleteConfirmationModal"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title text-danger font-weight-bold">
                        DELETE CONFIRMATION
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p className="font-weight-bold">
                        ARE YOU SURE YOU WANT TO DELETE THIS NOTE?
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={cancelDeletion}
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        CANCEL
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          deleteANote(removeNoteId);
                          setRemoveNoteId(null);
                        }}
                        data-dismiss="modal"
                      >
                        CONFRIM
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h4>Loading</h4>
        )}
      </div>
    </Fragment>
  );
};

export default AdminAppNotes;
