import {
  GET_MEDICATIONS,
  CREATE_MEDICATION,
  GET_MEDICATION,
  EDIT_MEDICATION,
  DELETE_MEDICATION,
  CLEAR_MEDICATION,
} from '../types';

const medicationListReducer = (state, action) => {
  switch (action.type) {
    case GET_MEDICATIONS:
      return {
        ...state,
        medications: action.payload,
      };

    case EDIT_MEDICATION:
      return {
        ...state,
        medications: [
          action.payload,
          ...state.medications.filter(
            (med) => med._id !== parseInt(action.payload._id)
          ),
        ],
      };

    case DELETE_MEDICATION:
      return {
        ...state,
        medications: state.medications.filter(
          (med) => med._id !== action.payload
        ),
      };

    case GET_MEDICATION:
      return {
        ...state,
        medication: action.payload,
      };

    case CREATE_MEDICATION:
      return {
        ...state,
        medications: [action.payload, ...state.medications],
      };
    case CLEAR_MEDICATION:
      return {
        ...state,
        medication: null,
      };

    default:
      return state;
  }
};

export default medicationListReducer;
