import React, { useReducer, useContext } from 'react';
import MeetingNotesContext from './meetingNotesContext';
import meetingNotesReducer from './meetingNotesReducer';
import AlertContext from '../alert/alertContext';
import axios from 'axios';

import {
  DELETE_MEETING_NOTE,
  GET_MEETING_NOTES,
  CREATE_MEETING_NOTE,
  GET_MEETING_NOTE,
  CLEAR_MEETING_NOTES,
  UPDATE_MEETING_NOTE,
} from '../types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const MeetingNotesState = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialstate = {
    mnotes: null,
    mnote: null,
  };

  const [state, dispatch] = useReducer(meetingNotesReducer, initialstate);

  const createMnote = async (data) => {
    try {
      const res = await axios.post('/api/meetingnotes/', data, config);
      dispatch({
        type: CREATE_MEETING_NOTE,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getMnotes = async (e) => {
    try {
      const res = await axios.get(`/api/meetingnotes/`);
      dispatch({
        type: GET_MEETING_NOTES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getMnote = async (log) => {
    dispatch({
      type: GET_MEETING_NOTE,
      payload: log,
    });
  };

  const deleteMnote = async (id) => {
    try {
      const res = await axios.delete(`/api/meetingnotes/${id}`);
      setAlert(res.data, 'success');
      dispatch({
        type: DELETE_MEETING_NOTE,
        payload: id,
      });
    } catch (error) {}
  };

  const clearMnotes = () => {
    dispatch({
      type: CLEAR_MEETING_NOTES,
    });
  };

  const updateMnote = async (data) => {
    const res = await axios.put(`/api/meetingnotes/${data._id}`, data, config);
    dispatch({
      type: UPDATE_MEETING_NOTE,
      payload: res.data,
    });
  };
  return (
    <MeetingNotesContext.Provider
      value={{
        mnotes: state.mnotes,
        mnote: state.mnote,
        getMnotes,
        deleteMnote,
        getMnote,
        updateMnote,
        clearMnotes,
        createMnote,
      }}
    >
      {props.children}
    </MeetingNotesContext.Provider>
  );
};

export default MeetingNotesState;
