import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import AppContext from './appContext';
import appReducer from './appReducer';
import TaskContext from '../tasks/taskContext';
import AlertContext from '../alert/alertContext';
import Email from '../../components/templates/email';
import FosterEmail from '../../components/templates/fosterEmail';

import {
  SUBMIT_APP,
  PREV_PAGE,
  NEXT_PAGE,
  GO_HOME,
  GET_APPS,
  GET_APP,
  UPDATE_APP,
  APP_TAB,
  DELETE_APP,
  CHECK_CAPTCHA,
  MANUAL_SUBMIT_APP,
  GET_STATS,
  GET_OPEN_APPS,
  RESET_APP_STATE,
  CLEAR_APP_STATE,
} from '../types';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return new Date(string).toLocaleDateString([], options);
}

const AppState = (props) => {
  const initialstate = {
    submissions: 0,
    newApps: 0,
    processing: 0,
    approved: 0,
    screened: 0,
    adoptions: 0,
    app: null,
    apps: null,
    holding: 0,
    openApps: null,
    current: null,
    isVerified: false,
    submitComplete: false,
    appsLoading: true,
    step: 1,
    appPageID: 'profile',
    openAppsloading: true,
  };
  const taskContext = useContext(TaskContext);
  const alertContext = useContext(AlertContext);
  const { createInitialTask } = taskContext;
  const { setAlert } = alertContext;

  const [state, dispatch] = useReducer(appReducer, initialstate);

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // SUBMIT APPLICATION
  const submitApp = async (data) => {
    const appData = { data: [data] };
    if (data.Application_Type === 'Adoption') {
      const message = Email(data);
      axios.post('/api/createPDF', data, config);
      axios.post('/api/sendEmail', message, config);
      axios.post('/api/thankyouAdoption', data, config);
    } else {
      const message = FosterEmail(data);
      axios.post('/api/createFosterPDF', data, config);
      axios.post('/api/sendEmail', message, config);
      axios.post('/api/thankyouFoster', data, config);
    }
    try {
      const dbResults = await axios.post('/api/database', data, config);
      createInitialTask(dbResults.data._id);
      const res = await axios.post('/api/v1/zohoCRM/adoption', appData, config);
      dispatch({
        type: SUBMIT_APP,
        payload: res,
      });
    } catch (err) {
      console.log(err);
    }
  };
  // MANUAL SUBMIT APPLICATION
  const manualSubmitApp = async (data) => {
    try {
      const dbResults = await axios.post('/api/database', data, config);
      createInitialTask(dbResults.data._id);
      dispatch({
        type: MANUAL_SUBMIT_APP,
        payload: dbResults.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Get APPS List
  const getApps = async () => {
    try {
      const res = await axios.get('/api/database');
      dispatch({
        type: GET_APPS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getFilteredApps = async (filter) => {
    try {
      const res = await axios.get(`/api/database/filter/${filter}`);
      dispatch({
        type: GET_APPS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getOpenApps = async () => {
    try {
      const res = await axios.get(`/api/database/filter/open`);
      dispatch({
        type: GET_OPEN_APPS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Get APP

  const getApp = async (id) => {
    try {
      const res = await axios.get(`/api/database/${id}`);
      const formatedDate = formatDate(res.data.submit_date);
      res.data.submit_date = formatedDate;
      dispatch({
        type: GET_APP,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // UPDATE APP
  const updateApp = async (id, data) => {
    try {
      const res = await axios.put(`/api/database/${id}`, data, config);
      const formatedDate = formatDate(res.data.submit_date);
      res.data.submit_date = formatedDate;
      dispatch({
        type: UPDATE_APP,
        payload: res.data,
      });
      setAlert('Application Status Updated', 'success');
    } catch (err) {
      console.log(err);
    }
  };

  // DELETE APP

  const deleteApp = async (id) => {
    await axios.delete(`/api/database/${id}`);
    dispatch({
      type: DELETE_APP,
      payload: id,
    });
  };

  // Proceed to next step
  const nextStep = (step) => {
    dispatch({
      type: NEXT_PAGE,
      payload: step,
    });
  };

  // Go back to prev step
  const prevStep = () => {
    dispatch({
      type: PREV_PAGE,
    });
  };

  const goHome = () => {
    dispatch({
      type: GO_HOME,
    });
  };

  const addStep = () => {
    console.log('CLICKED');
  };

  const appTab = (tab) => {
    dispatch({
      type: APP_TAB,
      payload: tab,
    });
  };

  const getStats = async () => {
    try {
      const appNew = await axios.get(`/api/database/count/new application`);
      const proc = await axios.get(`/api/database/count/processing`);
      const approvedPending = await axios.get(
        `/api/database/count/Approved - pending adoption`
      );
      const screenedOnHold = await axios.get(
        `/api/database/count/Screened - On Hold`
      );
      const adoptions = await axios.get(`/api/adoptions/adoptions/`);
      const submissions = await axios.get(`/api/database/submissions/`);
      const holding = await axios.get(`/api/database/count/Approved - On Hold`);
      const data = {
        submissions: submissions.data.count,
        newApps: appNew.data.count,
        processing: proc.data.count,
        approved: approvedPending.data.count,
        screened: screenedOnHold.data.count,
        adoptions: adoptions.data.count,
        holding: holding.data.count,
      };
      dispatch({
        type: GET_STATS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const checkCaptcha = async (data) => {
    const resBody = {
      response: data,
    };
    try {
      const res = await axios.post('/api/captcha', resBody, config);
      dispatch({
        type: CHECK_CAPTCHA,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const resetAppState = () => {
    dispatch({
      type: RESET_APP_STATE,
    });
  };

  const multiAppUpdate = async (data) => {
    await axios
      .post('/api/database/multiApps/', data, config)
      .then((response) => {
        if (response.status === 200) {
          getFilteredApps('open');
        }
      })
      .catch((error) => {
        console.log('Server Error');
      });
  };

  const clearAppState = () => {
    dispatch({
      type: CLEAR_APP_STATE,
    });
  };

  return (
    <AppContext.Provider
      value={{
        submissions: state.submissions,
        holding: state.holding,
        processing: state.processing,
        newAppsList: state.newAppsList,
        screened: state.screened,
        approved: state.approved,
        adoptions: state.adoptions,
        app: state.app,
        apps: state.apps,
        current: state.current,
        submitComplete: state.submitComplete,
        step: state.step,
        appsLoading: state.appsLoading,
        appPageID: state.appPageID,
        isVerified: state.isVerified,
        openApps: state.openApps,
        openAppsloading: state.openAppsloading,
        submitApp,
        getFilteredApps,
        prevStep,
        nextStep,
        addStep,
        getApps,
        goHome,
        getApp,
        appTab,
        deleteApp,
        checkCaptcha,
        updateApp,
        manualSubmitApp,
        getStats,
        getOpenApps,
        resetAppState,
        multiAppUpdate,
        clearAppState,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
