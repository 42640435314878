import React from 'react';
import PropTypes from 'prop-types';

function formatDate(string) {
  var options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const MeetingNotesListItems = ({ mnote, editMnote, removeMnote, user }) => {
  const editTheNote = (e) => {
    editMnote(mnote);
  };

  return (
    <tr>
      <td className="text-center pointer" onClick={editTheNote}>
        {mnote.title}
      </td>

      <td className="text-center">{formatDate(mnote.updatedAt)}</td>
      {user.Role === 'Admin' || user.Role === 'Manager' ? (
        <td className="text-right">
          <i
            data-toggle="modal"
            data-target="#NotesDeleteConfirmationModal"
            className="fa fa-trash text-danger pointer mr-2"
            onClick={() => removeMnote(mnote._id)}
            aria-hidden="true"
          ></i>
        </td>
      ) : null}
    </tr>
  );
};

MeetingNotesListItems.propTypes = {
  mnote: PropTypes.object.isRequired,
};
export default MeetingNotesListItems;
