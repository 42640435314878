import { GET_MEDS, CREATE_MED, GET_MED, EDIT_MED, DELETE_MED } from '../types';

const medicationListReducer = (state, action) => {
  switch (action.type) {
    case GET_MEDS:
      return {
        ...state,
        meds: action.payload,
      };

    case EDIT_MED:
      return {
        ...state,
        meds: [
          action.payload,
          ...state.meds.filter(
            (med) => med._id !== parseInt(action.payload._id)
          ),
        ],
      };

    case DELETE_MED:
      return {
        ...state,
        meds: state.meds.filter((med) => med._id !== action.payload),
      };

    case GET_MED:
      return {
        ...state,
        med: action.payload,
      };

    case CREATE_MED:
      return {
        ...state,
        meds: [action.payload, ...state.meds],
      };

    default:
      return state;
  }
};

export default medicationListReducer;
