import React from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const EmailTextEditor = ({ textData, setTextData }) => {
  const editorConfig = {
    toolbar: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'link',
      '|',
      'bulletedList',
      'numberedList',
      'insertTable',
      '|',
      'alignment',
      'outdent',
      'indent',
      '|',
      'undo',
      'redo',
    ],
    link: {
      addTargetToExternalLinks: true,
    },
    mediaEmbed: {
      removeProviders: [
        'instagram',
        'twitter',
        'googleMaps',
        'flickr',
        'facebook',
        'youtube',
        'vimeo',
        'spotify',
        'dailymotion',
      ],
    },
  };

  const handleData = (event, editor) => {
    const data = editor.getData();
    setTextData(data);
  };

  return (
    <div className="CkEditor">
      <CKEditor
        editor={Editor}
        data={textData}
        onChange={handleData}
        config={editorConfig}
      />
    </div>
  );
};

export default EmailTextEditor;
