import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AdminContext from '../../../context/admin/adminContext';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const AdminDashMyAppList = ({ app }) => {
  const adminContext = useContext(AdminContext);
  const { viewApp } = adminContext;

  const {
    _id,
    First_Name,
    Last_Name,
    submit_date,
    Application_Type,
    Application_Status,
  } = app;
  const formatedDate = formatDate(submit_date);

  const viewTheApp = (e) => {
    viewApp(_id);
    window.scroll(0, 0);
  };

  return (
    <tr className="d-flex">
      <td onClick={viewTheApp} className="pointer col-lg-3">
        {First_Name} {Last_Name}
      </td>
      <td
        onClick={viewTheApp}
        className="text-center pointer d-none d-lg-block col-lg-3"
      >
        {Application_Type}
      </td>
      <td onClick={viewTheApp} className="text-center pointer col-lg-3">
        {Application_Status}
      </td>
      <td className="text-center col-lg-3">{formatedDate}</td>
    </tr>
  );
};

AdminDashMyAppList.propTypes = {
  app: PropTypes.object.isRequired,
};
export default AdminDashMyAppList;
