import React, { Fragment, useEffect, useState, useContext } from 'react';
import AdminAppList from '../layout/AdminAppList';
import Pagination from '../pagination/Pagination';
import UserContext from '../../context/user/userContext';
import AppContext from '../../context/app/appContext';
import AdminContext from '../../context/admin/adminContext';
import AlertContext from '../../context/alert/alertContext';
import ExportDataExcel from '../utils/ExportDataExcel';
import axios from 'axios';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const AdminApplicationList = (props) => {
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const adminContext = useContext(AdminContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const { newApplicationPage, filters, setFilters, clearFilters } =
    adminContext;
  const { users, getUsers, appsLoading } = userContext;
  const { apps, getApps, getFilteredApps, multiAppUpdate } = appContext;
  const [displayFilteredApps, setDisplayFilteredApps] = useState([]);
  const [displayApps, setDisplayApps] = useState([]);
  const [totalDisplayApps, setTotalDisplayApps] = useState([]);
  const [appTypeFilter, setAppTypeFilter] = useState([]);
  // const [appInterestFilter, setAppInterestFilter] = useState([]);
  const [loadingApps] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportData, setExportData] = useState([]);
  const { appsPerPage, appsCurrentStatus } = filters;
  const [showFilters, setShowFilters] = useState(false);
  const [searchBar, setSearchBar] = useState('');
  const [showMultiEdit, setShowMultiEdit] = useState(false);
  const [applicationsToEdit, setApplicationsToEdit] = useState([]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [multiSelectCheckBox, setMultiSelectCheckBox] = useState('');
  const [sendNotificationEmail, setSendNotificationEmail] = useState(false);
  const [newAppStatus, setNewAppStatus] = useState('');
  const [newAppOwner, setNewAppOwner] = useState('');
  const [searchBarError, setSearchBarError] = useState('');
  const applicationStatusOptions = [
    'New Application',
    'Processing',
    'Email App Requested',
    'Screened - On Hold',
    'Approved - Pending Adoption',
    'Approved - No Adopt',
    'Approved - Returned',
    'Approved - On Hold',
    'Adopted - F/U on S/N',
    'Screened - No Adopt',
    'Stop Processing',
    'Approved - Foster',
    'Adopted',
    'To Be Deleted',
  ];

  useEffect(() => {
    var csvData = [];
    totalDisplayApps.map((app) => {
      const record = {
        _id: app._id,
        First_Name: app.First_Name,
        Middle_Initial: app.Middle_Initial,
        Last_Name: app.Last_Name,
        CoApp_Full_Name: app.CoApp_Full_Name,
        Street: app.Street,
        City: app.City,
        State: app.State,
        Zip_Code: app.Zip_Code,
        Phone: app.Phone,
        Mobile: app.Phone,
        Email: app.Email,
        Application_Status: app.Application_Status,
        Application_Type: app.Application_Type,
        st_Choice: app.st_Choice,
        nd_Choice: app.nd_Choice,
        Dog_Adopted: app.Dog_Adopted,
        submit_date: app.submit_date,
        createdAt: app.createdAt,
        updatedAt: app.updatedAt,
        Owner_Id: app.Owner_Id,
      };
      csvData.push(record);

      return null;
    });

    setExportData(csvData);
    //eslint-disable-next-line
  }, [totalDisplayApps]);

  const filterTheApps = async () => {
    let filterResults = apps;
    if (filterResults !== undefined && filterResults !== null) {
      if (filters.Application_Type) {
        filterResults = filterResults.filter(
          (app) => app.Application_Type === filters.Application_Type
        );
      }
      if (filters.Application_Status) {
        filterResults = filterResults.filter((app) => {
          return app.Application_Status === filters.Application_Status;
        });
      }
      if (filters.Application_Owner) {
        filterResults = filterResults.filter((app) => {
          return app.Owner_Id === parseInt(filters.Application_Owner);
        });
      }
      if (filters.Prefer) {
        filterResults = filterResults.filter((app) => {
          return (
            app.st_Choice === filters.Prefer.toLowerCase() ||
            app.nd_Choice === filters.Prefer.toLowerCase() ||
            app.nd_Choice === filters.Prefer ||
            app.st_Choice === filters.Prefer
          );
        });
      }
      let sortedData = apps;
      if (
        filters.Sort === 'Ascending' &&
        filterResults !== null &&
        filterResults !== undefined
      ) {
        sortedData = filterResults.slice().sort(function (a, b) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
      } else if (filterResults !== null && filterResults !== undefined) {
        sortedData = filterResults.slice().sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      }
      setDisplayApps(sortedData);
    }
  };

  const showOpenApps = () => {
    getFilteredApps('open');
  };

  const showPendingApps = () => {
    getFilteredApps('pending');
  };

  const showClosedApps = () => {
    getFilteredApps('closed');
  };

  const showAllApps = () => {
    getApps();
  };

  const newApplicationEntry = () => {
    newApplicationPage();
  };

  const showTheFilters = () => {
    setShowFilters(true);
  };
  const hideTheFilters = () => {
    setShowFilters(false);
  };

  useEffect(
    () => {
      if (appsCurrentStatus === 'ALL') {
        showAllApps();
      } else if (appsCurrentStatus === 'OPEN') {
        showOpenApps();
      } else if (appsCurrentStatus === 'PENDING') {
        showPendingApps();
      } else {
        showClosedApps();
      }
      setSearchBar('');
    },
    // eslint-disable-next-line
    [appsCurrentStatus]
  );

  useEffect(() => {
    getUsers();

    if (filters.Remembered) {
      setShowFilters(true);
    }

    if (appsCurrentStatus === 'ALL') {
      showAllApps();
    } else if (appsCurrentStatus === 'OPEN') {
      showOpenApps();
    } else if (appsCurrentStatus === 'PENDING') {
      showPendingApps();
    } else {
      showClosedApps();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      filters !== undefined &&
      filters.Remembered !== true &&
      props.user.Role === 'User'
    ) {
      clearFilters(props.user.Role, props.user._id);
    } else if (filters !== undefined && filters.Remembered !== true) {
      clearFilters('Manager');
    }

    // eslint-disable-next-line
  }, [props.user]);

  useEffect(() => {
    setDisplayApps(apps);
    filterTheApps();
    // eslint-disable-next-line
  }, [filters, appsPerPage, apps]);

  useEffect(() => {
    if (displayApps !== null && displayApps !== undefined) {
      const indexOfLastApp = currentPage * appsPerPage;
      const indexofFirstApp = indexOfLastApp - appsPerPage;
      setTotalDisplayApps(displayApps);
      setDisplayFilteredApps(
        displayApps.slice(indexofFirstApp, indexOfLastApp)
      );
    }
    // eslint-disable-next-line
  }, [displayApps, appsPerPage, currentPage]);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [searchBar]);

  useEffect(() => {
    if (displayApps !== null && displayApps !== undefined) {
      const appTypeFilter = displayApps.reduce(
        (unique, item) => {
          return unique.includes(item.Application_Status)
            ? unique
            : [...unique, item.Application_Status];
        },

        // eslint-disable-next-line
        []
      );

      setAppTypeFilter(appTypeFilter);
    }
  }, [displayApps]);

  const onChange = (e) => {
    setFilters({ ...filters, [e.target.id]: e.target.value, Remembered: true });
    setCurrentPage(1);
  };

  const searchData = (e) => {
    const specialCharacters = /[*)(?/[\]]|\\/g;
    if (specialCharacters.test(e.target.value)) {
      setSearchBarError('- Cannot use these characters * / \\ ) ( [ ] ?');
      setTimeout(() => setSearchBarError(''), 3000);
    } else {
      setSearchBar(e.target.value);
      search();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const search = () => {
    if (apps !== null && !appsLoading && apps !== undefined) {
      const searchResults = apps.filter((app) => {
        const regex = new RegExp(`${searchBar}`, 'gi');

        if (app.Email !== null) {
          if (app.st_Choice !== null && app.nd_Choice !== null) {
            return (
              app.First_Name.match(regex) ||
              app.Last_Name.match(regex) ||
              app.Email.match(regex) ||
              app._id === parseInt(searchBar) ||
              app.st_Choice.match(regex) ||
              app.nd_Choice.match(regex)
            );
          } else if (app.st_Choice !== null) {
            return (
              app.First_Name.match(regex) ||
              app.Last_Name.match(regex) ||
              app.Email.match(regex) ||
              app._id === parseInt(searchBar) ||
              app.st_Choice.match(regex)
            );
          } else if (app.nd_Choice !== null) {
            return (
              app.First_Name.match(regex) ||
              app.Last_Name.match(regex) ||
              app.Email.match(regex) ||
              app._id === parseInt(searchBar) ||
              app.nd_Choice.match(regex)
            );
          }
        } else {
          return (
            app.First_Name.match(regex) ||
            app.Last_Name.match(regex) ||
            app._id === parseInt(searchBar) ||
            app.st_Choice.match(regex)
          );
        }
        return null;
      });
      setCurrentPage(1);
      setDisplayApps(searchResults);
    }
  };

  const resetFilters = () => {
    setSearchBar('');
    if (props.user.Role === 'Admin' || props.user.Role === 'Manager') {
      setFilters({
        Remembered: false,
        Application_Type: '',
        Application_Status: '',
        Application_Owner: '',
        Prefer: '',
        Sort: 'Ascending',
        appsPerPage: 10,
        appsCurrentStatus: 'OPEN',
      });
    } else {
      setFilters({
        Remembered: false,
        Application_Type: '',
        Application_Status: '',
        Application_Owner: props.user._id,
        Prefer: '',
        Sort: 'Ascending',
        appsPerPage: 10,
        appsCurrentStatus: 'OPEN',
      });
    }

    showOpenApps();
  };

  useEffect(() => {
    if (applicationsToEdit.length !== displayFilteredApps.length) {
      setMultiSelectCheckBox('');
      setShowMultiEdit(false);
    }
    if (applicationsToEdit.length > 0) {
      setShowMultiEdit(true);
    }
    // eslint-disable-next-line
  }, [applicationsToEdit]);

  const selectAppToEdit = (id) => {
    var appIdList = [];
    if (applicationsToEdit.includes(id)) {
      applicationsToEdit.map((app) => {
        if (app === id) {
          return null;
        }
        appIdList.push(app);
        return null;
      });
      setApplicationsToEdit(appIdList);
    } else {
      applicationsToEdit.map((app) => {
        appIdList.push(app);
        return null;
      });
      appIdList.push(id);
      setApplicationsToEdit(appIdList);
    }
  };

  const selectAllToggle = (e) => {
    if (showMultiEdit === false) {
      var appIdList = [];
      displayFilteredApps.map((app) => {
        appIdList.push(app._id);
        return null;
      });
      setMultiSelectCheckBox('checked');
      setShowMultiEdit(true);
      setApplicationsToEdit(appIdList);
    } else {
      setShowMultiEdit(false);
      setApplicationsToEdit([]);
      setMultiSelectCheckBox('');
      setNewAppStatus('');
      setNewAppOwner('');
    }
  };

  const emailNotificationToggle = () => {
    if (sendNotificationEmail === true) {
      setSendNotificationEmail(false);
    } else {
      setSendNotificationEmail(true);
    }
  };

  const selectStatus = (e) => {
    setNewAppStatus(e.target.value);
  };
  const selectOwner = (e) => {
    setNewAppOwner(e.target.value);
  };

  const updateAppStatus = () => {
    if (newAppOwner !== '' || newAppStatus !== '') {
      var current_status = 'Closed';
      if (
        newAppStatus === 'Screened - On Hold' ||
        newAppStatus === 'Approved - Pending Adoption' ||
        newAppStatus === 'Approved - On Hold' ||
        newAppStatus === 'Adopted - F/U on S/N'
      ) {
        current_status = 'pending';
      }
      if (newAppStatus === 'New Application' || newAppStatus === 'Processing') {
        current_status = 'Open';
      }

      const updateData = {
        ids: applicationsToEdit,
        owner: newAppOwner,
        status: newAppStatus,
        current_status: current_status,
        notify: sendNotificationEmail,
      };

      multiAppUpdate(updateData);
      if (sendNotificationEmail && parseInt(newAppOwner) !== 1045) {
        if (applicationsToEdit.length > 1) {
          console.log('Send Multi Email');
          const newOwner = users.filter(
            (user) => user._id === parseInt(newAppOwner)
          );
          const data = {
            recipient_to: newOwner[0].Username,
            email_subject: `Multiple PPR Adoption Applications Have Been Assigned to You`,
            email_body: `
        ${newOwner[0].First_Name}, <br>
        <br>
  
        Multiple applications have been assigned to you:<br>
  <br>
        Please login to the portal to view the applications that have been assigned to you<br>
  <br>
        Go to: https://portal.puppypawsRescue.org/admin
        `,
            email_sender: 'admin@puppypawsrescue.org',
          };

          axios.post('/api/email/assignmentNotify/', data, config);

          setAlert(
            'Application updates have been made and notification sent',
            'success'
          );
        } else if (applicationsToEdit.length === 1) {
          const newOwner = users.filter(
            (user) => user._id === parseInt(newAppOwner)
          );
          const data = {
            recipient_to: newOwner[0].Username,
            email_subject: `A PPR Adoption Application Has Been Assigned to You`,
            email_body: `
          ${newOwner[0].First_Name}, <br>
          <br>
    
          An application has been assigned to you:<br>
    <br>
          Please login to the portal to view the application that have been assigned to you<br>
    <br>
          Go to: https://portal.puppypawsRescue.org/admin
          `,
            email_sender: 'admin@puppypawsrescue.org',
          };

          axios.post('/api/email/assignmentNotify/', data, config);

          setAlert(
            'Application updates have been made and notification sent',
            'success'
          );
        }
      } else {
        setAlert('Application updates have been made', 'success');
      }
      setShowMultiEdit(false);
      setApplicationsToEdit([]);
      setMultiSelectCheckBox('');
      setSendNotificationEmail(false);
      setNewAppStatus('');
      setNewAppOwner('');
    } else {
      alert('MAKE A SELCTION TO CHANGE');
    }
  };

  return (
    <Fragment>
      <div className="container pt-3">
        {!showMultiEdit && showFilters ? (
          <form onSubmit={submitHandler}>
            <div className="row">
              <div className="col-lg-5">
                <div className="form-group">
                  <label htmlFor="filterByStatus" className="font-weight-bold">
                    SEARCH{' '}
                    <span className="text-danger font-weight-bold">
                      {searchBarError}
                    </span>
                  </label>
                  <input
                    type="search"
                    className="form-control"
                    value={searchBar}
                    onChange={searchData}
                    id="search"
                    autoComplete="off"
                  ></input>
                </div>
              </div>
              <div className="col-lg-7"></div>
              <div className="col-lg-2 p-3">
                <button
                  type="button"
                  onClick={resetFilters}
                  className="btn btn-danger my-3"
                >
                  RESET FILTERS
                </button>
              </div>
            </div>
            <div className="row col-lg-12">
              <div className="form-group">
                <label htmlFor="apps_Per_Page" className="font-weight-bold">
                  PER PAGE
                </label>
                <select
                  className="form-control"
                  onChange={onChange}
                  id="appsPerPage"
                  value={appsPerPage}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                  <option>100</option>
                </select>
              </div>
              <div className="col-lg-2">
                <div className="form-group">
                  <label
                    htmlFor="Application_Type"
                    className="font-weight-bold"
                  >
                    TYPE
                  </label>
                  <select
                    className="form-control"
                    onChange={onChange}
                    id="Application_Type"
                    value={filters.Application_Type}
                  >
                    <option value="">ALL</option>
                    <option>Adoption</option>
                    <option>Foster</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="form-group">
                  <label
                    htmlFor="Application_Status"
                    className="font-weight-bold"
                  >
                    STAGE
                  </label>
                  <select
                    className="form-control"
                    onChange={onChange}
                    id="Application_Status"
                    value={filters.Application_Status}
                  >
                    <option value="">ALL</option>

                    {appTypeFilter.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="form-group">
                  <label
                    htmlFor="Application_Owner"
                    className="font-weight-bold"
                  >
                    ASSIGNED TO
                  </label>
                  <select
                    className="form-control"
                    onChange={onChange}
                    id="Application_Owner"
                    value={filters.Application_Owner}
                  >
                    <option value="">ALL</option>
                    {users !== undefined && !appsLoading ? (
                      users.map((user) => (
                        <option key={user._id} value={user._id}>
                          {user.First_Name} {user.Last_Name}
                        </option>
                      ))
                    ) : (
                      <option>loading</option>
                    )}
                  </select>
                </div>
              </div>
              {/* <div className="col-lg-2">
                <div className="form-group">
                  <label
                    htmlFor="Application_Owner"
                    className="font-weight-bold"
                  >
                    INTEREST
                  </label>
                  <select
                    className="form-control"
                    onChange={onChange}
                    id="Prefer"
                    value={filters.Prefer}
                  >
                    <option value="">ALL</option>
                    {appInterestFilter.map((prefer) => (
                      <option key={prefer} value={prefer}>
                        {prefer}
                      </option>
                    ))}
                  </select>
                </div>
              </div> */}
              <div className="col-lg-2">
                <div className="form-group">
                  <label
                    htmlFor="Application_Owner"
                    className="font-weight-bold"
                  >
                    STATUS
                  </label>
                  <select
                    className="form-control"
                    onChange={onChange}
                    id="appsCurrentStatus"
                    value={appsCurrentStatus}
                  >
                    <option>ALL</option>
                    <option>OPEN</option>
                    <option>PENDING</option>
                    <option>CLOSED</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2">
                <div className="form-group">
                  <label
                    htmlFor="Application_Owner"
                    className="font-weight-bold"
                  >
                    SORT
                  </label>
                  <select
                    className="form-control"
                    onChange={onChange}
                    id="Sort"
                    value={filters.Sort}
                  >
                    <option>Ascending</option>

                    <option>Descending</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <button
                className="btn btn-success ml-3 mb-2"
                onClick={hideTheFilters}
              >
                HIDE FILTERS
              </button>
            </div>
          </form>
        ) : (
          <div>
            {!showMultiEdit ? (
              <div>
                <button
                  className="btn btn-danger mb-2"
                  onClick={showTheFilters}
                >
                  FILTER LIST
                </button>
                <ExportDataExcel
                  csvData={exportData}
                  fileName="Exported Data"
                  filters={filters}
                  searchBar={searchBar}
                />
              </div>
            ) : null}
          </div>
        )}
        {showMultiEdit ? (
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <table className="table table-striped border border-dark">
                <thead>
                  <tr className="bg-theme-orange text-white">
                    <th scope="col" className="text-center"></th>
                    <th scope="col" className="text-center"></th>
                    <th scope="col" className="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center font-weight-bold">
                      <button
                        onClick={updateAppStatus}
                        className="btn btn-warning"
                      >
                        UPDATE
                      </button>
                    </td>
                    <td className="text-center">
                      <div className="input-group">
                        <select
                          className="form-control"
                          onChange={selectStatus}
                          id="Aplication_Status"
                        >
                          <option value="New Application">SELECT STATUS</option>
                          {applicationStatusOptions.map((app) => (
                            <option key={app} value={app}>
                              {app}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                    <td className="text-center font-weight-bold m-auto">
                      APPLICATION STATUS
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center"></td>
                    <td className="text-center">
                      <div className="input-group">
                        <select
                          className="form-control"
                          onChange={selectOwner}
                          id="Application_Owner"
                        >
                          <option value="UnAssigned">SELECT PROCESSOR</option>
                          {users !== undefined ? (
                            users.map((user) => (
                              <option key={user._id} value={user._id}>
                                {user.First_Name} {user.Last_Name}
                              </option>
                            ))
                          ) : (
                            <option>loading</option>
                          )}
                        </select>
                      </div>
                    </td>
                    <td className="font-weight-bold m-auto text-center">
                      <input
                        type="checkbox"
                        onChange={emailNotificationToggle}
                        className="m-auto"
                      />
                      <label className="ml-1">Notify</label>
                    </td>
                  </tr>
                  {/* FUTURE ADDITION OF EMAIL SEND TO MULITPLE APPLICANTS */}
                  {/* <tr>
                      <td className="text-center">
                        <button className="btn btn-danger">EMAIL</button>
                      </td>
                      <td className="text-center">
                        <div className="input-group">
                          <select
                            className="custom-select"
                            id="inputGroupSelect04"
                            aria-label="Example select with button addon"
                          >
                            <option value="">SELECT EMAIL</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </td>
                      <td className="font-weight-bold m-auto text-center">
                        SEND MESSAGE
                      </td>
                    </tr> */}
                </tbody>
              </table>
            </div>
            <div className="col-md-2"></div>
          </div>
        ) : null}
        <table className="table table-striped table-hover">
          <thead>
            <tr className="bg-theme text-white">
              {props.user.Role === 'Admin' || props.user.Role === 'Manager' ? (
                <th scope="col">
                  <div className="input-group">
                    <input
                      type="checkbox"
                      checked={multiSelectCheckBox}
                      onChange={selectAllToggle}
                      className="m-auto"
                    />
                  </div>
                </th>
              ) : null}
              <th scope="col">NAME</th>
              <th scope="col" className="d-none d-md-table-cell">
                1st Choice
              </th>
              <th scope="col" className="d-none d-md-table-cell">
                2nd Choice
              </th>
              <th scope="col" className="d-none d-md-table-cell">
                TYPE
              </th>
              <th scope="col">STAGE</th>
              <th scope="col" className="d-none d-md-table-cell">
                SUBMISSION DATE
              </th>
              <th scope="col">ASSIGNED TO</th>
              <th scope="col">
                <span
                  onClick={newApplicationEntry}
                  className="text-success pointer"
                >
                  ADD{' '}
                  <i
                    className="fa fa-plus"
                    onClick={newApplicationEntry}
                    aria-hidden="true"
                  ></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {displayFilteredApps !== null && !loadingApps && users !== null ? (
              displayFilteredApps.map((app) => (
                <AdminAppList
                  app={app}
                  key={app._id}
                  value={app.Owner_Id}
                  users={users}
                  user={props.user}
                  applicationsToEdit={applicationsToEdit}
                  selectAppToEdit={selectAppToEdit}
                />
              ))
            ) : (
              <tr>
                <td>Loading</td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          appsPerPage={appsPerPage}
          totalApps={totalDisplayApps.length}
          paginate={paginate}
        />
      </div>
    </Fragment>
  );
};

export default AdminApplicationList;
