import {
  EDIT_ZOHO_APP,
  GET_ZOHO_APPS,
  GET_ZOHO_APP,
  CLEAR_ZOHO_APP,
  GET_ZOHO_APP_NOTES,
  GET_ZOHO_USERS,
  GET_ZOHO_APP_FILES,
  CREATE_ZOHO_APP_NOTE,
  UPDATE_ZOHO_APP_NOTE,
  DELETE_ZOHO_APP_NOTE,
  CREATE_ZOHO_APP_FILE,
  DELETE_ZOHO_APP_FILE,
  CREATE_ZOHO_APP_FOLLOWUP,
  UPDATE_ZOHO_APP_FOLLOWUP,
  DELETE_ZOHO_APP_FOLLOWUP,
  CLEAR_ZOHO_APP_FOLLOWUPS,
  GET_ZOHO_APP_FOLLOWUPS,
  GET_ZOHO_APP_FOLLOWUP,
  TOGGLE_COMPLETE_ZOHO_FOLLOWUP,
} from '../types';

const zohoReducer = (state, action) => {
  switch (action.type) {
    case GET_ZOHO_APPS:
      return {
        ...state,
        zapps: action.payload.rows,
        appCount: action.payload.count,
        zappsloading: false,
      };
    case GET_ZOHO_APP:
      return {
        ...state,
        zapp: action.payload,
        zappsloading: false,
      };
    case EDIT_ZOHO_APP:
      return {
        ...state,
        zapp: action.payload,
        zapps: action.payload,
        ...state,
      };
    case CLEAR_ZOHO_APP:
      return {
        ...state,
        zapp: null,
      };
    case GET_ZOHO_APP_NOTES:
      return {
        ...state,
        znotes: action.payload,
      };
    case GET_ZOHO_USERS:
      return {
        ...state,
        zusers: action.payload,
      };
    case GET_ZOHO_APP_FILES:
      return {
        ...state,
        zfiles: action.payload,
      };
    case CREATE_ZOHO_APP_NOTE:
      return {
        ...state,
        znotes: [action.payload, ...state.znotes],
      };
    case UPDATE_ZOHO_APP_NOTE:
      return {
        ...state,
        znotes: [
          action.payload,
          ...state.znotes.filter(
            (note) => note.Note_ID !== action.payload.Note_ID
          ),
        ],
      };
    case DELETE_ZOHO_APP_NOTE:
      return {
        ...state,
        znotes: state.znotes.filter((note) => note.Note_ID !== action.payload),
      };

    case DELETE_ZOHO_APP_FILE:
      return {
        ...state,
        zfiles: state.zfiles.filter(
          (file) => file.Old_Attachment_Id !== action.payload
        ),
      };

    case CREATE_ZOHO_APP_FILE:
      return {
        ...state,
        zfiles: [action.payload, ...state.zfiles],
      };
    case CREATE_ZOHO_APP_FOLLOWUP:
      return {
        ...state,
        zfus: [action.payload, ...state.zfus],
        fuLoading: false,
      };
    case GET_ZOHO_APP_FOLLOWUPS:
      return {
        ...state,
        zfus: action.payload,
        fuLoading: false,
      };

    case GET_ZOHO_APP_FOLLOWUP:
      return {
        ...state,
        zfu: action.payload,
        fuLoading: false,
      };
    case TOGGLE_COMPLETE_ZOHO_FOLLOWUP:
      return {
        ...state,
        zfus: [
          action.payload,
          ...state.zfus.filter((fu) => fu._id !== action.payload._id),
        ],
      };

    case UPDATE_ZOHO_APP_FOLLOWUP:
      return {
        ...state,
        zfus: [
          action.payload,
          ...state.zfus.filter((fu) => fu._id !== action.payload._id),
        ],
      };
    case DELETE_ZOHO_APP_FOLLOWUP:
      return {
        ...state,
        zfus: state.zfus.filter((fu) => fu._id !== parseInt(action.payload)),
      };
    case CLEAR_ZOHO_APP_FOLLOWUPS:
      return {
        zfus: [],
        zfu: null,
        fuLoading: true,
      };
    default:
      return state;
  }
};

export default zohoReducer;
