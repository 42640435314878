import React, { useContext, useEffect, useState } from 'react';
import ErrorsContext from '../../context/errors/errorsContext';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const date = new Date(string).toLocaleDateString('en-US', options);
  const time = new Date(string).toLocaleTimeString();
  const convertedDate = date + ' ' + time;

  return convertedDate;
}

const ErrorLog = () => {
  const errorsContext = useContext(ErrorsContext);
  const {
    getErrorLogs,
    clearErrorLogState,
    getErrorLog,
    errorLogs,
    errorLog,
    deleteErrorLog,
  } = errorsContext;
  const [displayErrorLog, setDisplayErrorLog] = useState(false);
  const [removeErrorId, setRemoveErrorId] = useState(null);

  useEffect(() => {
    getErrorLogs();
    return () => {
      clearErrorLogState();
    };
    // eslint-disable-next-line
  }, []);

  const onClick = (e) => {
    getErrorLog(e);
    setDisplayErrorLog(true);
  };

  const returnToErrorList = () => {
    setDisplayErrorLog(false);
  };

  const deleteAError = (e) => {
    deleteErrorLog(e);
  };

  const removeError = (e) => {
    setRemoveErrorId(e.target.id);
  };

  const cancelDeletion = () => {
    setRemoveErrorId(null);
  };

  if (displayErrorLog) {
    return (
      <div className="container mt-3">
        <div className="card rounded shadow">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <table className="table">
                  <tbody>
                    <tr>
                      <th>MODULE:</th>
                      <td>{errorLog.module}</td>
                    </tr>
                    <tr>
                      <th>FUNCTION:</th>
                      <td>{errorLog.function}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-6">
                <table className="table">
                  <tbody>
                    <tr>
                      <th>NOTE 1:</th>
                      <td>{errorLog.note1}</td>
                    </tr>
                    <tr>
                      <th>NOTE 2:</th>
                      <td>{errorLog.note2}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <h5 className="ml-2 font-weight-bold">ERROR MESSAGE:</h5>
            <div className="row mx-2 border border-dark">
              <p className="p-3">{errorLog.error_message}</p>
            </div>

            <button className="btn btn-danger mt-3" onClick={returnToErrorList}>
              BACK
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container border border-dark shadow mt-3">
        <table className="table table-hover">
          <thead>
            <tr className="bg-theme text-white">
              <th scope="col" className="text-center">
                DATE
              </th>
              <th scope="col" className="text-center">
                MODULE
              </th>
              <th scope="col" className="text-center">
                FUNCTION
              </th>

              <th scope="col" className="text-center"></th>
            </tr>
          </thead>
          <tbody>
            {errorLogs !== null
              ? errorLogs.map((log) => (
                  <tr key={log._id}>
                    <td
                      className="text-center pointer"
                      onClick={() => onClick(log)}
                    >
                      {formatDate(log.createdAt)}
                    </td>
                    <td
                      className="text-center pointer"
                      onClick={() => onClick(log)}
                    >
                      {log.module}
                    </td>
                    <td
                      className="text-center pointer"
                      onClick={() => onClick(log)}
                    >
                      {log.function}
                    </td>
                    <td className="text-right mr-3">
                      <i
                        className="fa fa-trash pointer text-danger"
                        onClick={removeError}
                        data-toggle="modal"
                        data-target="#errorDeleteConfirmationModal"
                        aria-hidden="true"
                        id={log._id}
                      ></i>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
        <div
          className="modal fade"
          id="errorDeleteConfirmationModal"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="errorDeleteConfirmationModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-danger font-weight-bold">
                  DELETE CONFIRMATION
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="font-weight-bold">
                  ARE YOU SURE YOU WANT TO DELETE THIS ERROR?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={cancelDeletion}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    deleteAError(removeErrorId);
                    setRemoveErrorId(null);
                  }}
                  data-dismiss="modal"
                >
                  CONFRIM
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ErrorLog;
