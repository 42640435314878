import {
  CREATE_FOLLOWUP,
  EDIT_FOLLOWUP,
  GET_FOLLOWUPS,
  GET_FOLLOWUP,
  CLEAR_FOLLOWUPS,
  TOGGLE_COMPLETE_FOLLOWUP,
  DELETE_FOLLOWUP,
} from '../types';

const followUpReducer = (state, action) => {
  switch (action.type) {
    case CREATE_FOLLOWUP:
      return {
        ...state,
        followUps: [action.payload, ...state.followUps],
        fuLoading: false,
      };
    case GET_FOLLOWUPS:
      return {
        ...state,
        followUps: action.payload,
        fuLoading: false,
      };

    case GET_FOLLOWUP:
      return {
        ...state,
        followUp: action.payload,
        fuLoading: false,
      };

    case TOGGLE_COMPLETE_FOLLOWUP:
      return {
        ...state,
        followUps: [
          action.payload,
          ...state.followUps.filter((fu) => fu._id !== action.payload._id),
        ],
        taskLoading: false,
      };
    case EDIT_FOLLOWUP:
      return {
        ...state,
        followUps: [
          action.payload,
          ...state.followUps.filter((fu) => fu._id !== action.payload._id),
        ],
      };
    case DELETE_FOLLOWUP:
      return {
        ...state,
        followUps: state.followUps.filter(
          (fu) => fu._id !== parseInt(action.payload)
        ),
      };
    case CLEAR_FOLLOWUPS:
      return {
        followUps: [],
        followUP: null,
        fuLoading: true,
      };
    default:
      return state;
  }
};

export default followUpReducer;
