function formatDateTimeSeconds(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'America/New_York',
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

module.exports = formatDateTimeSeconds;
