import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../../context/app/appContext';
import AdminContext from '../../context/admin/adminContext';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const AdminAppList = (props) => {
  const { app, users, user, selectAppToEdit, applicationsToEdit } = props;
  const appContext = useContext(AppContext);
  const adminContext = useContext(AdminContext);
  const { viewApp } = adminContext;
  const { deleteApp } = appContext;
  const {
    _id,
    First_Name,
    Last_Name,
    submit_date,
    Application_Status,
    Application_Type,
    st_Choice,
    nd_Choice,
    Owner_Id,
  } = app;
  const [processor, setProcessor] = useState({
    _id: '',
    First_Name: '',
    Last_Name: '',
  });

  const fetchOwnerName = async (id) => {
    const res = users.filter((user) => {
      return user._id === parseInt(id);
    });

    setProcessor({
      _id: res[0]._id,
      First_Name: res[0].First_Name,
      Last_Name: res[0].Last_Name,
    });
  };

  useEffect(() => {
    if (app !== null && users !== undefined) {
      fetchOwnerName(Owner_Id);
    }
    // eslint-disable-next-line
  }, [users, app]);

  const formatedDate = formatDate(submit_date);

  const deleteTheApp = (e) => {
    deleteApp(_id);
  };

  const viewTheApp = (e) => {
    viewApp(_id);
    window.scroll(0, 0);
  };

  return (
    <tr>
      {user.Role === 'Admin' || user.Role === 'Manager' ? (
        <td>
          <div className="input-group mt-2">
            <input
              type="checkbox"
              checked={applicationsToEdit.includes(app._id)}
              onChange={() => selectAppToEdit(app._id)}
              className="m-auto"
            />
          </div>
        </td>
      ) : null}

      <td onClick={viewTheApp} className="pointer text-center">
        {First_Name} {Last_Name}
      </td>
      <td
        onClick={viewTheApp}
        className="pointer text-center d-none d-md-table-cell "
      >
        {st_Choice}
      </td>
      <td
        onClick={viewTheApp}
        className="pointer text-center d-none d-md-table-cell"
      >
        {nd_Choice}
      </td>
      <td
        onClick={viewTheApp}
        className="pointer text-center d-none d-md-table-cell"
      >
        {Application_Type}
      </td>
      <td onClick={viewTheApp} className="pointer text-center ">
        {Application_Status}
      </td>
      <td className="text-center d-none d-md-table-cell">{formatedDate}</td>
      <td onClick={viewTheApp} className="pointer">
        {processor.First_Name} {processor.Last_Name}
      </td>
      <td className="text-right">
        {user != null && user.Role === 'Admin' ? (
          <i
            className="fa fa-trash pointer text-danger"
            title="Delete Application"
            onClick={deleteTheApp}
            aria-hidden="true"
          ></i>
        ) : (
          <div></div>
        )}
      </td>
    </tr>
  );
};

AdminAppList.propTypes = {
  app: PropTypes.object.isRequired,
};
export default AdminAppList;
