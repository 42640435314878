import React, { Fragment, useContext, useEffect, useState } from 'react';
import UserContext from '../../context/user/userContext';
import AdminContext from '../../context/admin/adminContext';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import PasswordValidator from '../../utils/PasswordValidator';
import TextEditor from '../../utils/TextEditor';

const UserProfile = (props) => {
  const userContext = useContext(UserContext);
  const adminContext = useContext(AdminContext);
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const { updateAuthUser } = authContext;
  const { getUsers, getUser, editUser, editCurrent } = userContext;
  const { userID, dashboard } = adminContext;
  const { setAlert } = alertContext;
  const { cancelUserEntry, current } = props;
  const [emailSignature, setEmailSignature] = useState('');
  const [user, setUser] = useState({
    First_Name: '',
    Last_Name: '',
    Username: '',
    Role: '',
    email_signature: '',
  });

  useEffect(() => {
    // if (current === null) {
    getUser(userID);
    getUsers();
    // }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (current !== null) {
      setUser({
        First_Name: current.First_Name,
        Last_Name: current.Last_Name,
        Username: current.Username,
        Role: current.Role,
        email_signature: current.email_signature,
      });
      if (current.email_signature === null) {
        setEmailSignature('');
      } else {
        setEmailSignature(current.email_signature);
      }
    } else {
      setUser({
        First_Name: '',
        Last_Name: '',
        Username: '',
        Role: '',
        email_signature: '',
      });
      setEmailSignature('');
    }
  }, [userContext, current]);

  useEffect(() => {
    if (emailSignature.length === 0) {
      setUser({ ...user, email_signature: null });
    } else {
      setUser({ ...user, email_signature: emailSignature });
    }
    // eslint-disable-next-line
  }, [emailSignature]);

  const [aNewPassword, setANewPassword] = useState({
    newPassword: '',
    newPassword2: '',
  });

  const { First_Name, Last_Name, Username, Role, email_signature } = user;
  const { newPassword, newPassword2 } = aNewPassword;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const passwordChange = (e) =>
    setANewPassword({ ...aNewPassword, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    if (userID === current._id) {
      const updatedCurrent = {
        _id: userID,
        First_Name: First_Name,
        Last_Name: Last_Name,
        Username: Username,
        Role: Role,
        email_signature: email_signature,
      };

      editCurrent(updatedCurrent);
      updateAuthUser(updatedCurrent);
    }

    if (newPassword !== newPassword2) {
      setAlert("PASSWORDS DON'T MATCH", 'danger');
    } else {
      if (newPassword === '') {
        const updatedUser = {
          userID,
          First_Name,
          Last_Name,
          Username,
          Role,
          email_signature,
        };
        editUser(updatedUser);

        setAlert(
          `User Named ${First_Name} ${Last_Name} updated successfully`,
          'success'
        );
        if (user.Role !== 'User') {
          cancelUserEntry();
        } else {
          dashboard();
        }
      } else {
        const updatedUser = {
          userID,
          First_Name,
          Last_Name,
          Username,
          Role,
          newPassword,
          email_signature,
        };

        if (PasswordValidator(newPassword)) {
          editUser(updatedUser);
          setAlert(
            `User Named ${First_Name} ${Last_Name} updated successfully`,
            'success'
          );
          if (user.Role !== 'User') {
            cancelUserEntry();
          } else {
            dashboard();
          }
        } else {
          setAlert('Invalid Password - See Password rules');
        }
      }
    }
  };

  const cancel = () => {
    if (user.Role !== 'User') {
      cancelUserEntry();
    } else {
      dashboard();
    }
  };

  return (
    <Fragment>
      {current !== null ? (
        <div className="container">
          <h1>EDIT USER</h1>
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="First_Name">First Name</label>
              <input
                className="form-control"
                type="text"
                name="First_Name"
                defaultValue={First_Name}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Last_Name">Last Name</label>
              <input
                className="form-control"
                type="text"
                name="Last_Name"
                defaultValue={Last_Name}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Username">Email Address</label>
              <input
                className="form-control"
                type="Username"
                name="Username"
                defaultValue={Username}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="Role">Role</label>
              <select name="Role" className="form-control" onChange={onChange}>
                <option value={Role}>{Role}</option>
                <option>Admin</option>
                <option>Manager</option>
                <option>User</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="email_signature">
                Email Signature - (Shift-Enter for single line return)
              </label>
              <TextEditor
                textData={current.email_signature}
                setTextData={setEmailSignature}
              />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                className="form-control"
                type="password"
                name="newPassword"
                defaultValue={newPassword}
                onChange={passwordChange}
                minLength="6"
              />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword2">Confirm New Password</label>
              <input
                className="form-control"
                type="password"
                name="newPassword2"
                defaultValue={newPassword2}
                onChange={passwordChange}
              />
            </div>
            <p>
              Password Rules: Atleast 8 Characters Uppercase Character(s)
              Lowercase Character(s) Number(s) Special Character
            </p>
            <div className="my-4">
              <input
                type="submit"
                value="UPDATE"
                className="btn btn-primary mr-3"
              />
              <button
                type="button"
                className="btn btn-danger mr-3"
                onClick={cancel}
              >
                CANCEL
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div>
          <h1>User Not Found</h1>
        </div>
      )}
    </Fragment>
  );
};

export default UserProfile;
