import React, { useContext, useEffect, useState, useRef } from 'react';
import AuthContext from '../../../context/auth/authContext';
import AlertContext from '../../../context/alert/alertContext';
import DogsContext from '../../../context/dogs/dogsContext';
import AdminDashDifList from './AdminDashDifList';
import TextEditor from '../../../utils/TextEditor';
import axios from 'axios';

const formDate = (string) => {
  var d = new Date(string);
  var mm = ('0' + (d.getMonth() + 1)).slice(-2);
  var dd = ('0' + (d.getDate() + 1)).slice(-2);
  var yy = d.getFullYear();

  var myDateString = yy + '-' + mm + '-' + dd; //(US)

  return myDateString;
};

const AdminDashDif = () => {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const dogsContext = useContext(DogsContext);
  const { user } = authContext;
  const { setAlert } = alertContext;
  const {
    getDogs,
    availDogs,
    dogFiles,
    dog,
    getDog,
    editDogDash,
    createDogDash,
    clearDog,
    getDogFiles,
    uploadDogFile,
    createDogFileDBEntry,
    deleteDogFile,
  } = dogsContext;
  const [showEdit, setShowEdit] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [processors, setProcessors] = useState();
  const [fosters, setFosters] = useState();
  const [editedDog, setEditedDog] = useState([]);
  const [newDog, setNewDog] = useState({
    dogName: '',
    foster: '',
    processor: '',
    location: '',
    intake: formDate(Date.now()),
    available_date: formDate(Date.now()),
    status: 'Available',
    notes: '',
  });
  const fileInputRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [savedFiles, setSavedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [contacts, setContacts] = useState(null);
  const [dogNote, setDogNote] = useState('');

  useEffect(() => {
    getDogs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    axios
      .get(`/api/contact/`, {
        cancelToken: source.token,
      })

      .then((response) => {
        if (mounted) {
          setContacts(response.data);
          mounted = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      source.cancel('Component got unmounted - My Application Follow UPs');
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (contacts !== null) {
      const fosterList = contacts.filter(
        (contact) =>
          contact.Contact_Type === 'Foster' ||
          contact.Contact_Type === 'Processor & Foster' ||
          (contact.Last_Name !== 'Zito' &&
            contact.Contact_Type === 'Administration')
      );
      const processorList = contacts.filter(
        (contact) =>
          contact.Contact_Type === 'Processor' ||
          contact.Contact_Type === 'Processor & Foster' ||
          contact.Contact_Type === 'Administration'
      );

      setFosters(fosterList);
      setProcessors(processorList);
    }
  }, [contacts]);

  useEffect(() => {
    if (dogFiles !== null) {
      setSavedFiles(dogFiles);
    }
  }, [dogFiles]);

  useEffect(() => {
    if (showEdit) {
      setEditedDog({
        dogName: dog.dogName,
        foster: dog.foster,
        processor: dog.processor,
        location: dog.location,
        intake: dog.intake,
        available_date: dog.available_date,
        status: dog.status,
        notes: dog.notes,
      });
      setDogNote(dog.notes);
    }
    // eslint-disable-next-line
  }, [showEdit]);

  const editDogInFoster = () => {
    setShowEdit(true);
  };

  const cancelEdit = () => {
    setEditedDog([]);
    setChecked(false);
    setShowEdit(false);
    clearDog();
  };

  const cancelCreate = () => {
    setShowCreate(false);
    setNewDog({
      dogName: '',
      foster: '',
      processor: '',
      location: '',
      intake: formDate(Date.now()),
      available_date: formDate(Date.now()),
      status: 'available',
      notes: '',
    });
  };

  const editTheDog = (e) => {
    if (e.target.id === 'processor') {
      const processorSelected = contacts.filter(
        (contact) => contact._id === parseInt(e.target.value)
      );
      const processorName =
        processorSelected[0].First_Name + ' ' + processorSelected[0].Last_Name;
      setEditedDog({ ...editedDog, processor: processorName });
    } else if (e.target.id === 'foster') {
      const fosterSelected = contacts.filter(
        (contact) => contact._id === parseInt(e.target.value)
      );
      const fosterLocation =
        fosterSelected[0].City + ', ' + fosterSelected[0].State;
      const fosterName =
        fosterSelected[0].First_Name + ' ' + fosterSelected[0].Last_Name;
      setEditedDog({
        ...editedDog,
        foster: fosterName,
        location: fosterLocation,
      });
    } else {
      setEditedDog({ ...editedDog, [e.target.id]: e.target.value });
    }
  };

  const onChange = (e) => {
    if (e.target.id === 'processor') {
      const processorSelected = contacts.filter(
        (contact) => contact._id === parseInt(e.target.value)
      );
      const processorName =
        processorSelected[0].First_Name + ' ' + processorSelected[0].Last_Name;
      setNewDog({ ...newDog, processor: processorName });
    } else if (e.target.id === 'foster') {
      const fosterSelected = contacts.filter(
        (contact) => contact._id === parseInt(e.target.value)
      );
      const fosterLocation =
        fosterSelected[0].City + ', ' + fosterSelected[0].State;
      const fosterName =
        fosterSelected[0].First_Name + ' ' + fosterSelected[0].Last_Name;
      setNewDog({
        ...newDog,
        foster: fosterName,
        location: fosterLocation,
      });
    } else {
      setNewDog({ ...newDog, [e.target.id]: e.target.value });
    }
  };

  const updateDog = () => {
    const updatedDogData = {
      dogName: editedDog.dogName,
      foster: editedDog.foster,
      processor: editedDog.processor,
      location: editedDog.location,
      intake: editedDog.intake,
      available_date: editedDog.available_date,
      status: editedDog.status,
      notes: dogNote,
    };

    editDogDash(dog._id, updatedDogData);
    setDogNote('');
    setEditedDog([]);
    setChecked(false);
    setShowEdit(false);
    setAlert('DOG HAS BEEN UPDATED', 'success');
  };

  const newDogEntry = () => {
    window.scroll(0, 0);
    setShowCreate(true);
  };

  const submitDog = () => {
    var dbFileEntryData = [];

    var x;
    for (x of selectedFiles) {
      const date = Date.now();
      const lastIndex = x.name.lastIndexOf('.') + 1;
      const fName = x.name.slice(0, lastIndex - 1);
      const ext = x.name.slice(lastIndex);
      const newFileName = `${fName}-${date}.${ext}`;

      const attachment = {
        file: x,
        fileName: newFileName,
      };

      uploadDogFile(attachment);

      const dbData = {
        File_Name: newFileName,
        Uploaded_By: user.First_Name + ' ' + user.Last_Name,
      };

      dbFileEntryData.push(dbData);
    }
    createDogDash(newDog, dbFileEntryData);
    setShowCreate(false);
    setNewDog({
      dogName: '',
      foster: '',
      processor: '',
      location: '',
      intake: formDate(Date.now()),
      available_date: formDate(Date.now()),
      status: 'available',
      notes: '',
    });
    setSelectedFiles([]);
  };

  const removeNewFile = (name) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== name);
    setSelectedFiles(updatedFiles);
    fileInputRef.current.value = '';
  };

  const removeFile = (id) => {
    deleteDogFile(id);
    fileInputRef.current.value = '';
  };

  const downloadFile = (id) => {
    axios(`/api/dogs/file/db/${id}`, {
      method: 'GET',
      responseType: 'blob',
    })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers.content_type,
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const newFileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleNewFiles(files);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const newFileSelected = () => {
    if (fileInputRef.current.files.length) {
      handleNewFiles(fileInputRef.current.files);
    }
  };

  const handleNewFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
      } else {
        files[i]['invalid'] = true;
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        setErrorMessage('File type not permitted');
      }
    }
  };

  const handleFiles = async (file) => {
    if (validateFile(file[0])) {
      const date = Date.now();
      const lastIndex = file[0].name.lastIndexOf('.') + 1;
      const fName = file[0].name.slice(0, lastIndex - 1);
      const ext = file[0].name.slice(lastIndex);
      const newFileName = `${fName}-${date}.${ext}`;

      const medicalRecord = {
        file: file[0],
        fileName: newFileName,
      };
      await uploadDogFile(medicalRecord);

      const dbData = {
        File_Name: newFileName,
        File_DogID: dog._id,
        Uploaded_By: user.First_Name + ' ' + user.Last_Name,
      };

      await createDogFileDBEntry(dbData);
    } else {
      setErrorMessage('File type not permitted');
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const validateFile = (file) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon',
      'application/doc',
      'application/docx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/msword',
      'video/mp4',
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  };

  if (showEdit === false && showCreate === false) {
    return (
      <div className="card shadow col-12 ">
        <h5 className="card-title text-center font-weight-bold mt-2">
          DOGS IN FOSTER
          <span>
            <i
              className="fa fa-plus text-success float-right mr-3 mt-2 pointer"
              aria-hidden="true"
              onClick={newDogEntry}
            ></i>
          </span>
        </h5>

        <div className="card-body">
          <table className="table  table-hover table-striped table-borderless table-dark">
            <thead>
              <tr>
                <th className="text-center" scope="col">
                  DOG
                </th>
                <th className="text-center" scope="col">
                  FOSTER
                </th>
                <th className="text-center" scope="col">
                  PROCESSOR
                </th>
                <th className="text-center d-none d-md-block" scope="col">
                  LOCATION
                </th>
              </tr>
            </thead>

            <tbody>
              {availDogs !== null && availDogs !== undefined ? (
                availDogs.map((dog) => (
                  <AdminDashDifList
                    dog={dog}
                    key={dog._id}
                    getDog={getDog}
                    getDogFiles={getDogFiles}
                    editDogInFoster={editDogInFoster}
                  />
                ))
              ) : (
                <tr>
                  <td>Loading</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else if (showEdit === true) {
    return (
      <div className="card shadow bg-secondary col-lg-12">
        <h5 className="card-title text-center mt-2 text-white font-weight-bolder">
          EDIT DOGS IN FOSTER
        </h5>
        <div className="card-body">
          <table className="table table-sm">
            <tbody>
              <tr>
                <th className="text-white font-weight-bolder">DOG NAME</th>
                <td>
                  <form>
                    <div className="form-group m-0">
                      <input
                        type="text"
                        id="dogName"
                        className="form-control p-1"
                        onChange={editTheDog}
                        defaultValue={dog.dogName}
                      />
                    </div>
                  </form>
                </td>
              </tr>
              <tr>
                <th className="text-white font-weight-bolder">FOSTER</th>
                <td>
                  <form>
                    <div className="form-group m-0">
                      <select
                        id="foster"
                        className="custom-select"
                        onChange={editTheDog}
                      >
                        <option>{dog.foster}</option>
                        {fosters !== null
                          ? fosters.map((foster) => (
                              <option key={foster._id} value={foster._id}>
                                {foster.First_Name} {foster.Last_Name}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                  </form>
                </td>
              </tr>
              <tr>
                <th className="text-white font-weight-bolder">PROCESSOR</th>
                <td>
                  <form>
                    <div className="form-group m-0">
                      <select
                        id="processor"
                        className="custom-select"
                        onChange={editTheDog}
                      >
                        <option>{dog.processor}</option>
                        {processors !== null
                          ? processors.map((processor) => (
                              <option key={processor._id} value={processor._id}>
                                {processor.First_Name} {processor.Last_Name}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                  </form>
                </td>
              </tr>
              <tr>
                <th className="text-white font-weight-bolder">INTAKE DATE</th>
                <td>
                  <form>
                    <div className="form-group m-0">
                      <input
                        type="date"
                        id="intake"
                        className="form-control"
                        onChange={editTheDog}
                        defaultValue={formDate(dog.intake)}
                      />
                    </div>
                  </form>
                </td>
              </tr>
              <tr>
                <th className="text-white font-weight-bolder">
                  AVAILABLE DATE
                </th>
                <td>
                  <form>
                    <div className="form-group m-0">
                      <input
                        id="available_date"
                        type="date"
                        className="form-control"
                        onChange={editTheDog}
                        defaultValue={formDate(dog.available_date)}
                      />
                    </div>
                  </form>
                </td>
              </tr>
              <tr>
                <th className="text-white  font-weight-bolder">STATUS</th>
                <td>
                  <form>
                    <div className="form-group">
                      <select
                        id="status"
                        className="custom-select"
                        onChange={editTheDog}
                        defaultValue={dog.status}
                        checked={checked}
                      >
                        <option>Available</option>
                        <option>Pending Transport</option>
                        <option>Medical Hold</option>
                        <option>Not Available</option>
                        <option>Adoption Pending</option>
                        <option>Adopted</option>
                      </select>
                    </div>
                  </form>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="container">
            <div className="text-white text-center font-weight-bolder border border-white">
              MEDICAL RECORDS
            </div>

            <div className="container my-3">
              {savedFiles.map((data, i) => (
                <div className="row" key={data._id}>
                  <div className="col-10">
                    <div
                      className="file-status-bar pointer text-light"
                      key={data._id}
                      onClick={() => downloadFile(data._id)}
                    >
                      {data.File_Name}

                      {data.invalid && (
                        <span className="file-error-message">
                          ({errorMessage})
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="col-2 text-left text-warning  font-weight-bold pointer"
                    onClick={() => removeFile(data._id)}
                  >
                    remove
                  </div>
                </div>
              ))}
              {selectedFiles.map((data, i) => (
                <div className="file-status-bar" key={i}>
                  <span
                    className={`file-name ${data.invalid ? 'file-error' : ''}`}
                  >
                    {data.name}
                  </span>

                  {data.invalid && (
                    <span className="file-error-message">({errorMessage})</span>
                  )}
                  <span
                    className="file-remove ml-2 font-weight-bolder"
                    onClick={() => removeFile(data.name)}
                  >
                    remove
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="container">
            <form>
              <div
                className="drop-container text-center mb-3"
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                id="uploadFile"
                onClick={fileInputClicked}
              >
                <span className="file-error-message">{errorMessage}</span>

                <div className="drop-message">
                  <div className="upload-icon"></div>
                  Drag & Drop medical records files here or click to select file
                  from computer
                </div>
                <input
                  ref={fileInputRef}
                  className="file-input"
                  type="file"
                  multiple
                  onChange={filesSelected}
                />
              </div>
            </form>
          </div>
          <div className="m-3">
            <form>
              <div className="form-group m-0">
                <label htmlFor="notes" className="text-white font-weight-bold">
                  NOTES:
                </label>
                {/* <textarea
                  type="textbox"
                  id="notes"
                  className="form-control p-1"
                  rows="5"
                  onChange={editTheDog}
                  defaultValue={dog.notes}
                /> */}
                <TextEditor textData={dogNote} setTextData={setDogNote} />
              </div>
            </form>
          </div>
        </div>
        <div className="row mb-2">
          <div className="d-none d-lg-block col-lg-7"></div>
          <div className="col-lg-5 text-right">
            <button className="btn btn-danger" onClick={cancelEdit}>
              BACK
            </button>
            <button className="btn btn-success ml-2" onClick={updateDog}>
              UPDATE
            </button>
          </div>
        </div>
      </div>
    );
  } else if (showCreate === true) {
    return (
      <div className="card shadow bg-secondary col-12">
        <h5 className="card-title text-center mt-2 text-white font-weight-bolder">
          CREATE DOGS IN FOSTER
        </h5>
        <div className="card-body">
          <table className="table table-sm">
            <tbody>
              <tr>
                <th className="text-white font-weight-bolder">DOG NAME</th>
                <td>
                  <form>
                    <div className="form-group m-0">
                      <input
                        type="text"
                        id="dogName"
                        className="form-control p-1"
                        onChange={onChange}
                      />
                    </div>
                  </form>
                </td>
              </tr>
              <tr>
                <th className="text-white font-weight-bolder">FOSTER</th>
                <td>
                  <form>
                    <div className="form-group m-0">
                      <select
                        id="foster"
                        className="custom-select"
                        onChange={onChange}
                      >
                        <option value="">Choose Foster</option>
                        {fosters !== null
                          ? fosters.map((foster) => (
                              <option key={foster._id} value={foster._id}>
                                {foster.First_Name} {foster.Last_Name}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                  </form>
                </td>
              </tr>
              <tr>
                <th className="text-white font-weight-bolder">PROCESSOR</th>
                <td>
                  <form>
                    <div className="form-group m-0">
                      <select
                        id="processor"
                        className="custom-select"
                        onChange={onChange}
                      >
                        <option value="">Choose Processor</option>
                        {processors !== null
                          ? processors.map((processor) => (
                              <option key={processor._id} value={processor._id}>
                                {processor.First_Name} {processor.Last_Name}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                  </form>
                </td>
              </tr>
              <tr>
                <th className="text-white font-weight-bolder">INTAKE DATE</th>
                <td>
                  <form>
                    <div className="form-group m-0">
                      <input
                        type="date"
                        id="intake"
                        className="form-control"
                        defaultValue={newDog.intake}
                        onChange={onChange}
                      />
                    </div>
                  </form>
                </td>
              </tr>
              <tr>
                <th className="text-white font-weight-bolder">
                  AVAILABLE DATE
                </th>
                <td>
                  <form>
                    <div className="form-group m-0">
                      <input
                        id="available_date"
                        type="date"
                        className="form-control"
                        defaultValue={newDog.available_date}
                        onChange={onChange}
                      />
                    </div>
                  </form>
                </td>
              </tr>
              <tr>
                <th className="text-white font-weight-bolder">STATUS</th>
                <td>
                  <form>
                    <div className="form-group">
                      <select
                        id="status"
                        className="custom-select"
                        onChange={onChange}
                        defaultValue="Available"
                        checked={checked}
                      >
                        <option>Available</option>
                        <option>Not Available</option>
                        <option>Adopted</option>
                      </select>
                    </div>
                  </form>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="text-white text-center font-weight-bolder border border-white">
            MEDICAL RECORDS
          </div>
          <div className="container my-3">
            {selectedFiles.map((data, i) => (
              <div className="row" key={i}>
                <div className="col-10">
                  <div className="file-status-bar pointer text-light" key={i}>
                    {data.name}

                    {data.invalid && (
                      <span className="file-error-message">
                        ({errorMessage})
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className=" col-2 text-left text-warning font-weight-bold pointer"
                  onClick={() => removeNewFile(data.name)}
                >
                  remove
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container">
          <form>
            <div
              className="drop-container text-center mb-3"
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={newFileDrop}
              id="uploadFile"
              onClick={fileInputClicked}
            >
              <span className="file-error-message">{errorMessage}</span>

              <div className="drop-message">
                <div className="upload-icon"></div>
                Drag & Drop medical records files here or click to select file
                from computer
              </div>
              <input
                ref={fileInputRef}
                className="file-input"
                type="file"
                multiple
                onChange={newFileSelected}
              />
            </div>
          </form>
        </div>
        <div className="m-3">
          <form>
            <div className="form-group m-0">
              <label htmlFor="notes" className="text-white font-weight-bold">
                NOTES:
              </label>
              <textarea
                type="textbox"
                id="notes"
                className="form-control p-1"
                rows="5"
                onChange={onChange}
              />
            </div>
          </form>
        </div>
        <div className="row mb-2">
          <div className="d-none d-lg-block col-lg-7"></div>
          <div className="col-lg-5 text-right">
            <button className="btn btn-danger" onClick={cancelCreate}>
              BACK
            </button>
            <button className="btn btn-success ml-2" onClick={submitDog}>
              CREATE
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default AdminDashDif;
