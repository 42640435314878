export const GET_PETS = 'GET_PETS';
export const VIEW_PET = 'VIEW_PET';
export const SUBMIT_APP = 'SUBMIT_APP';
export const SUBMIT_FAPP = 'SUBMIT_FAPP';
export const SET_CURRENT = 'SET_CURRENT';
export const NEXT_PAGE = 'NEXT_PAGE';
export const PREV_PAGE = 'PREV_PAGE';
export const GO_HOME = 'GO_HOME';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CREATE_BLOG = 'CREATE_BLOG';
export const EDIT_BLOG = 'EDIT_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const GET_BLOGS = 'GET_BLOGS';
export const VIEW_BLOG = 'VIEW_BLOG';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOAD_BLOG_PAGE = 'LOAD_BLOG_PAGE';
export const LOAD_DASHBOARD = 'LOAD_DASHBOARD';
export const LOAD_USER_PAGE = 'LOAD_USER_PAGE';
export const LOAD_APPLICATIONS_PAGE = 'LOAD_APPLICATIONS_PAGE';
export const LOAD_CREATE_BLOG_PAGE = 'LOAD_CREATE_BLOG_PAGE';
export const CREATE_USER = 'CREATE_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const LOAD_CREATE_USER_PAGE = 'LOAD_CREATE_USER_PAGE';
export const LOAD_EDIT_USER_PAGE = 'LOAD_EDIT_USER_PAGE';
export const LOAD_ZOHO_APPLICATIONS_PAGE = 'LOAD_ZOHO_APPLICATIONS_PAGE';
export const LOAD_ZOHO_APPLICATION_PAGE = 'LOAD_ZOHO_APPLICATION_PAGE';
export const LOAD_USER_PROFILE = 'LOAD_USER_PROFILE';
export const LOAD_USER_ID = 'LOAD_USER_ID';
export const LOAD_EDIT_BLOG_PAGE = 'LOAD_EDIT_BLOG_PAGE';
export const LOAD_BLOG_ID = 'LOAD_BLOG_ID';
export const GET_APPS = 'GET_APPS';
export const LOAD_APPLICATION_PAGE = 'LOAD_APPLICATION_PAGE';
export const LOAD_APP_ID = 'LOAD_APP_ID';
export const GET_APP = 'GET_APP';
export const APP_TAB = 'APP_TAB';
export const DELETE_APP = 'DELETE_APP';
export const CHECK_CAPTCHA = 'CHECK_CAPTCHA';
export const CREATE_TASK = 'CREATE_TASK';
export const EDIT_TASK = 'EDIT_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const GET_TASKS = 'GET_TASKS';
export const GET_TASK = 'GET_TASK';
export const CREATE_NOTE = 'CREATE_NOTE';
export const EDIT_NOTE = 'EDIT_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const GET_NOTES = 'GET_NOTES';
export const GET_NOTE = 'GET_NOTE';
export const SAVE_EMAIL = 'SAVE_EMAIL';
export const EDIT_EMAIL_SEND = 'EDIT_EMAIL_SEND';
export const EDIT_EMAIL = 'EDIT_EMAIL';
export const DELETE_EMAIL = 'DELETE_EMAIL';
export const GET_EMAILS = 'GET_EMAILS';
export const SEND_EMAIL = 'SEND_EMAIL';
export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES';
export const LOAD_EMAIL_TEMPLATES_PAGE = 'LOAD_EMAIL_TEMPLATES_PAGE';
export const GET_APP_FILES = 'GET_APP_FILES';
export const DELETE_FILE = 'DELETE_FILE';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const GET_ADOPTIONS = 'GET_ADOPTIONS';
export const UPDATE_ADOPTION = 'UPDATE_ADOPTION';
export const DELETE_ADOPTION = 'DELETE_ADOPTION';
export const CREATE_ADOPTION = 'CREATE_ADOPTION';
export const EDIT_ZOHO_APP = 'EDIT_ZOHO_APP';
export const GET_ZOHO_APPS = 'GET_ZOHO_APPS';
export const GET_ZOHO_APP = 'GET_ZOHO_APP';
export const CLEAR_ZOHO_APP = 'CLEAR_ZOHO_APP';
export const GET_ZOHO_APP_NOTES = 'GET_ZOHO_APP_NOTES';
export const GET_ZOHO_USERS = 'GET_ZOHO_USERS';
export const GET_ZOHO_APP_FILES = 'GET_ZOHO_APP_FILES';
export const LOAD_NEW_APPLICATION_PAGE = 'LOAD_NEW_APPLICATION_PAGE';
export const MANUAL_SUBMIT_APP = 'MANUAL_SUBMIT_APP';
export const UPDATE_APP = 'UPDATE_APP';
export const LOAD_DASHBOARD_PAGE = 'LOAD_DASHBOARD_PAGE';
export const GET_STATS = 'GET_STATS';
export const CLEAR_TASKS = 'CLEAR_TASKS';
export const TOGGLE_COMPLETE_TASK = 'TOGGLE_COMPLETE_TASK';
export const GET_OPEN_APPS = 'GET_OPEN_APPS';
export const LOAD_CONTACTS_PAGE = 'LOAD_CONTACTS_PAGE';
export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_CONTACT = 'GET_CONTACT';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const GET_EMAIL_ATTACHMENTS = 'GET_EMAIL_ATTACHMENTS';
export const SAVE_ATTACHMENTS = 'SAVE_ATTACHMENTS';
export const DELETE_EMAIL_ATTACHMENT = 'DELETE_EMAIL_ATTACHMENT';
export const DELETE_EMAIL_ATTACHMENTS = 'DELETE_EMAIL_ATTACHMENTS';
export const CREATE_FOLLOWUP = 'CREATE_FOLLOWUP';
export const EDIT_FOLLOWUP = 'EDIT_FOLLOWUP';
export const GET_FOLLOWUPS = 'GET_FOLLOWUPS';
export const CLEAR_FOLLOWUPS = 'CLEAR_FOLLOWUPS';
export const TOGGLE_COMPLETE_FOLLOWUP = 'TOGGLE_COMPLETE_FOLLOWUP';
export const DELETE_FOLLOWUP = 'DELETE_FOLLOWUP';
export const GET_FOLLOWUP = 'GET_FOLLOWUP';
export const RESET_APP_STATE = 'RESET_APP_STATE';
export const SET_FILTERS = 'SET_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const DELETE_ERROR_LOG = 'DELETE_ERROR_LOG';
export const GET_ERROR_LOGS = 'GET_ERROR_LOGS';
export const GET_ERROR_LOG = 'GET_ERROR_LOG';
export const LOAD_ERROR_LOG_PAGE = 'LOAD_ERROR_LOG_PAGE';
export const CLEAR_ERROR_LOGS_STATE = 'CLEAR_ERROR_LOGS_STATE';
export const CREATE_EMAIL_TEMPLATE = 'CREATE_EMAIL_TEMPLATE';
export const GET_EMAIL_TEMPLATE_ATTACHMENTS = 'GET_EMAIL_TEMPLATE_ATTACHMENTS';
export const GET_DOGS = 'GET_DOGS';
export const CREATE_DOG = 'CREATE_DOG';
export const GET_DOG = 'GET_DOG';
export const EDIT_DOG = 'EDIT_DOG';
export const DELETE_DOG = 'DELETE_DOG';
export const LOAD_DOGS_PAGE = 'LOAD_DOGS_PAGE';
export const CLEAR_EMAIL_TEMPLATE_ATTACHMENTS =
  'CLEAR_EMAIL_TEMPLATE_ATTACHMENTS';
export const LOAD_ADOPTION_LIST_PAGE = 'LOAD_ADOPTION_LIST_PAGE';
export const DELETE_LOG = 'DELETE_LOG';
export const GET_LOGS = 'GET_LOGS';
export const GET_LOG = 'GET_LOG';
export const CLEAR_LOGS_STATE = 'CLEAR_LOGS_STATE';
export const DELETE_KNOWLEDGEBASE = 'DELETE_KNOWLEDGEBASE';
export const GET_KNOWLEDGEBASES = 'GET_KNOWLEDGEBASES';
export const GET_KNOWLEDGEBASE = 'GET_KNOWLEDGEBASE';
export const CLEAR_KNOWLEDGEBASE = 'CLEAR_KNOWLEDGEBASE';
export const UPDATE_KNOWLEDGEBASE = 'UPDATE_KNOWLEDGEBASE';
export const LOAD_PAGE = 'LOAD_PAGE';
export const CREATE_KNOWLEDGE_BASE = 'CREATE_KNOWLEDGE_BASE';
export const DELETE_MEETING_NOTE = 'DELETE_MEETING_NOTE';
export const GET_MEETING_NOTES = 'GET_MEETING_NOTES';
export const CREATE_MEETING_NOTE = 'CREATE_MEETING_NOTE';
export const GET_MEETING_NOTE = 'GET_MEETING_NOTE';
export const CLEAR_MEETING_NOTES = 'CLEAR_MEETING_NOTES';
export const UPDATE_MEETING_NOTE = 'UPDATE_MEETING_NOTE';
export const TOGGLE_EDITING = 'TOGGLE_EDITING';
export const CREATE_ZOHO_APP_NOTE = 'CREATE_ZOHO_APP_NOTE';
export const UPDATE_ZOHO_APP_NOTE = 'UPDATE_ZOHO_APP_NOTE';
export const DELETE_ZOHO_APP_NOTE = 'DELETE_ZOHO_APP_NOTE';
export const CREATE_ZOHO_APP_FILE = 'CREATE_ZOHO_APP_FILE';
export const DELETE_ZOHO_APP_FILE = 'DELETE_ZOHO_APP_FILE';
export const CREATE_ZOHO_APP_FOLLOWUP = 'CREATE_ZOHO_APP_FOLLOWUP';
export const UPDATE_ZOHO_APP_FOLLOWUP = 'UPDATE_ZOHO_APP_FOLLOWUP';
export const DELETE_ZOHO_APP_FOLLOWUP = 'DELETE_ZOHO_APP_FOLLOWUP';
export const CLEAR_ZOHO_APP_FOLLOWUPS = 'CLEAR_ZOHO_APP_FOLLOWUPS';
export const GET_ZOHO_APP_FOLLOWUPS = 'GET_ZOHO_APP_FOLLOWUPS';
export const TOGGLE_COMPLETE_ZOHO_FOLLOWUP = 'TOGGLE_COMPLETE_ZOHO_FOLLOWUP';
export const GET_ZOHO_APP_FOLLOWUP = 'GET_ZOHO_APP_FOLLOWUP';
export const GET_DOG_FILES = 'GET_DOG_FILES';
export const DELETE_DOG_FILE = 'DELETE_DOG_FILE';
export const UPLOAD_DOG_FILE = 'UPLOAD_DOG_FILE';
export const DELETE_TICKET = 'DELETE_TICKET';
export const GET_TICKETS = 'GET_TICKETS';
export const GET_TICKET = 'GET_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const UPDATE_TICKET_NOTE = 'UPDATE_TICKET_NOTE';
export const GET_TICKET_NOTES = 'GET_TICKET_NOTES';
export const GET_TICKET_NOTE = 'GET_TICKET_NOTE';
export const DELETE_TICKET_NOTE = 'DELETE_TICKET_NOTE';
export const CREATE_TICKET = 'CREATE_TICKET';
export const CREATE_TICKET_NOTE = 'CREATE_TICKET_NOTE';
export const GET_TICKET_FILES = 'GET_TICKET_FILES';
export const UPLOAD_TICKET_FILE = 'UPLOAD_TICKET_FILE';
export const DELETE_TICKET_FILE = 'DELETE_TICKET_FILE';
export const GET_PET_PICTURE = 'GET_PET_PICTURE';
export const CLEAR_DOG_FROM_STATE = 'CLEAR_DOG_FROM_STATE';
export const GET_MEDS = 'GET_MEDS';
export const CREATE_MED = 'CREATE_MED';
export const GET_MED = 'GET_MED';
export const EDIT_MED = 'EDIT_MED';
export const DELETE_MED = 'DELETE_MED';
export const GET_MEDICATIONS = 'GET_MEDICATIONS';
export const CREATE_MEDICATION = 'CREATE_MEDICATION';
export const GET_MEDICATION = 'GET_MEDICATION';
export const EDIT_MEDICATION = 'EDIT_MEDICATION';
export const DELETE_MEDICATION = 'DELETE_MEDICATION';
export const CLEAR_MEDICATION = 'CLEAR_MEDICATION';
export const GET_MEDICATION_REMINDERS = 'GET_MEDICATION_REMINDERS';
export const CREATE_MEDICATION_REMINDER = 'CREATE_MEDICATION_REMINDER';
export const GET_MEDICATION_REMINDER = 'GET_MEDICATION_REMINDER';
export const EDIT_MEDICATION_REMINDER = 'EDIT_MEDICATION_REMINDER';
export const DELETE_MEDICATION_REMINDER = 'DELETE_MEDICATION_REMINDER';
export const CLEAR_MEDICATION_REMINDER = 'CLEAR_MEDICATION_REMINDER';
export const DELETE_MEDICATION_REMINDER_FROM_STATE =
  'DELETE_MEDICATION_REMINDER_FROM_STATE';
export const DELETE_MEDICATION_REMINDER_BY_DOG =
  'DELETE_MEDICATION_REMINDER_BY_DOG';
export const UPDATE_CURRENT = 'UPDATE_CURRENT';
export const CLEAR_APP_STATE = 'CLEAR_APP_STATE';
