import React, { useContext, useEffect, useState, useRef } from 'react';
import ContactsContext from '../../../context/contacts/contactsContext';
import AlertContext from '../../../context/alert/alertContext';
import DogsContext from '../../../context/dogs/dogsContext';
import MedicationsContext from '../../../context/medications/medicationsContext';
import MedicationRemindersContext from '../../../context/medication_reminders/medication_reminderContext';
import MedicationListContext from '../../../context/medication_list/medicationListContext';
import axios from 'axios';
import DogListItem from './DogsListItem';
import NoDogImage from '../../media/NoPictureAvailable.png';
import TextEditor from '../../../utils/TextEditor';
import ReactHtmlParser from 'react-html-parser';
import './dogs.css';

const formDate = (string) => {
  var d = new Date(string);
  var mm = ('0' + (d.getMonth() + 1)).slice(-2);
  var dd = ('0' + (d.getDate() + 1)).slice(-2);
  var yy = d.getFullYear();

  var myDateString = yy + '-' + mm + '-' + dd; //(US)

  return myDateString;
};
const formTheDate = (string) => {
  var d = new Date(string);
  var mm = ('0' + (d.getMonth() + 1)).slice(-2);
  var dd = ('0' + (d.getDate() + 1)).slice(-2);
  var yy = d.getFullYear();

  var myDateString = mm + '-' + dd + '-' + yy; //(US)

  return myDateString;
};

const Dogs = (props) => {
  const contactsContext = useContext(ContactsContext);
  const alertContext = useContext(AlertContext);
  const dogsContext = useContext(DogsContext);
  const medicationsContext = useContext(MedicationsContext);
  const medicationRemindersContext = useContext(MedicationRemindersContext);
  const medicationListContext = useContext(MedicationListContext);
  const { contacts, getContacts } = contactsContext;
  const { meds, getMeds } = medicationListContext;
  const {
    getMedRemindersByDog,
    getMedReminderByMedication,
    med_reminders,
    clearMedReminder,
    med_reminder,
    createMedReminder,
    editMedReminder,
    deleteMedReminder,
    deleteMedReminderByDog,
  } = medicationRemindersContext;
  const {
    getMedicationsByDog,
    medications,
    medication,
    createMedication,
    deleteMedication,
    editMedication,
    getMedication,
    clearMedication,
    updateMedicationRemindersFlags,
  } = medicationsContext;
  const { setAlert } = alertContext;
  const {
    dogs,
    getAllDogs,
    dogFiles,
    dog,
    dog_picture_url,
    deleteDog,
    getDog,
    editDog,
    createDog,
    getDogPicture,
    dogsLoading,
    getDogFiles,
    uploadDogFile,
    createDogFileDBEntry,
    clearDogFromState,
    deleteDogFile,
  } = dogsContext;
  const [
    showReminderAssignmentErrorMessage,
    setShowReminderAssignmentErrorMessage,
  ] = useState(false);
  const [showAddMedication, setShowAddMedication] = useState(false);
  const [showEditMedication, setShowEditMedication] = useState(false);
  const [showAssignReminderModal, setShowAssignReminderModal] = useState(false);
  const [showEditDogNotes, setShowEditDogNotes] = useState(false);
  const [dogNote, setDogNote] = useState('');
  const [showEdit, setShowEdit] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showView, setShowView] = useState(false);
  const [checked, setChecked] = useState(false);
  const [processors, setProcessors] = useState();
  const [fosters, setFosters] = useState();
  const [editedDog, setEditedDog] = useState([]);
  const [newDog, setNewDog] = useState({
    dogName: '',
    foster: '',
    processor: '',
    location: '',
    intake: formDate(Date.now()),
    available_date: formDate(Date.now()),
    status: 'Available',
    notes: '',
    petfinder_id: null,
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [savedFiles, setSavedFiles] = useState([]);
  const fileInputRef = useRef();
  const { user } = props;

  const [searchBar, setSearchBar] = useState('');
  const [displayedDogs, setDisplayedDogs] = useState(null);
  const [removeDogId, setRemoveDogId] = useState(null);
  const [filters, setFilters] = useState('All');
  const [currentDogTab, setCurrentDogTab] = useState('notes');
  const [newMedication, setNewMedication] = useState({
    medication: '',
    dog: null,
    administered_by: '',
    date_given: null,
    due_date: null,
    reminder: 'no',
    status: 'Pending',
  });
  const [editedMedication, setEditedMedication] = useState({});
  const [reminderDetails, setReminderDetails] = useState({});
  const [contactsInSortedOrder, setContactsInSortedOrder] = useState({});
  const [displayedMedications, setDisplayedMedications] = useState([]);
  const [medicationHasReminder, setMedicationHasReminder] = useState(false);
  const [showAddFilesDialog, setShowAddFilesDialog] = useState(false);

  useEffect(() => {
    getContacts();
    getAllDogs();
    getMeds();
    return () => {
      setCurrentDogTab('notes');
      setNewMedication({
        medication: '',
        dog: null,
        administered_by: '',
        date_given: null,
        due_date: null,
        reminder: 'no',
        status: 'Pending',
      });
      setShowEdit(false);
      setShowCreate(false);
      setShowView(false);
      setReminderDetails({});
      clearMedication();
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showView) {
      getDogPicture(dog.petfinder_id);
      getMedicationsByDog(dog._id);
      getMedRemindersByDog(dog._id);
    }
    if (dog !== null && dog !== undefined) {
      setNewMedication({ ...newMedication, dog: dog._id });
    }
    // eslint-disable-next-line
  }, [showView, dog]);

  useEffect(() => {
    if (dogs !== null) {
      filterTheDogs();
    }

    // eslint-disable-next-line
  }, [dogs, filters, searchBar]);

  useEffect(() => {
    if (editedDog.petfinder_id === '') {
      setEditedDog({ ...editedDog, petfinder_id: null });
    }
    // eslint-disable-next-line
  }, [editedDog]);

  useEffect(() => {
    if (dogFiles !== null) {
      setSavedFiles(dogFiles);
    }
  }, [dogFiles]);

  useEffect(() => {
    if (contacts !== null && contacts !== undefined) {
      const sortedList = contacts.sort((a, b) =>
        a.First_Name > b.First_Name ? 1 : -1
      );

      setContactsInSortedOrder(sortedList);
    }
  }, [contacts]);

  useEffect(() => {
    if (medications !== null && medications !== undefined) {
      const sortedMedications = medications.sort((a, b) => {
        return a.status < b.status ? 1 : -1;
      });
      setDisplayedMedications(sortedMedications);
    }
  }, [medications]);

  const filterTheDogs = () => {
    if (filters !== 'All' && !dogsLoading) {
      const filteredDogs = dogs.filter((dog) => {
        return dog.status === filters;
      });

      const searchResults = filteredDogs.filter((dog) => {
        const regex = new RegExp(`${searchBar}`, 'gi');
        return (
          dog.dogName.match(regex) ||
          dog.processor.match(regex) ||
          dog.foster.match(regex)
        );
      });

      setDisplayedDogs(searchResults);
    } else {
      const searchResults = dogs.filter((dog) => {
        const regex = new RegExp(`${searchBar}`, 'gi');
        return (
          dog.dogName.match(regex) ||
          dog.processor.match(regex) ||
          dog.foster.match(regex)
        );
      });
      setDisplayedDogs(searchResults);
    }
  };

  const search = (e) => {
    setSearchBar(e.target.value);
  };

  const removeDog = (e) => {
    setRemoveDogId(e);
  };

  const cancelDeletion = () => {
    setRemoveDogId(null);
  };

  useEffect(() => {
    if (contacts !== null) {
      const fosterList = contacts.filter(
        (contact) =>
          contact.Contact_Type === 'Foster' ||
          contact.Contact_Type === 'Processor & Foster' ||
          (contact.Last_Name !== 'Zito' &&
            contact.Contact_Type === 'Administration')
      );
      const processorList = contacts.filter(
        (contact) =>
          contact.Contact_Type === 'Processor' ||
          contact.Contact_Type === 'Processor & Foster' ||
          contact.Contact_Type === 'Administration'
      );

      setFosters(fosterList);
      setProcessors(processorList);
    }
  }, [contacts]);

  const editDogInFoster = () => {
    setShowEdit(true);
    setEditedDog({
      _id: dog._id,
      dogName: dog.dogName,
      foster: dog.foster,
      processor: dog.processor,
      notes: dog.notes,
      status: dog.status,
      location: dog.location,
      intake: dog.intake,
      available_date: dog.available_date,
      petfinder_id: dog.petfinder_id,
    });
  };
  const showDogInFoster = () => {
    setShowView(true);
  };

  const returnToDogList = () => {
    setShowView(false);
    clearDogFromState();
    setShowEditMedication(false);
    clearMedication();
    setCurrentDogTab('notes');
  };

  const cancelEdit = () => {
    setEditedDog([]);
    setChecked(false);
    setShowEdit(false);
    fileInputRef.current.value = '';
    setSelectedFiles([]);
    setDogNote('');
  };

  const cancelCreate = () => {
    setShowCreate(false);
    setNewDog({
      dogName: '',
      foster: '',
      processor: '',
      location: '',
      intake: formDate(Date.now()),
      available_date: formDate(Date.now()),
      status: 'available',
      notes: '',
    });
    setDogNote('');
  };

  const editTheDog = (e) => {
    if (e.target.id === 'processor') {
      const processorSelected = contacts.filter(
        (contact) => contact._id === parseInt(e.target.value)
      );
      const processorName =
        processorSelected[0].First_Name + ' ' + processorSelected[0].Last_Name;
      setEditedDog({ ...editedDog, processor: processorName });
    } else if (e.target.id === 'foster') {
      const fosterSelected = contacts.filter(
        (contact) => contact._id === parseInt(e.target.value)
      );
      const fosterLocation =
        fosterSelected[0].City + ', ' + fosterSelected[0].State;
      const fosterName =
        fosterSelected[0].First_Name + ' ' + fosterSelected[0].Last_Name;
      setEditedDog({
        ...editedDog,
        foster: fosterName,
        location: fosterLocation,
      });
    } else {
      setEditedDog({ ...editedDog, [e.target.id]: e.target.value });
    }
  };

  const onChangeReminder = (e) => {
    setReminderDetails({ ...reminderDetails, [e.target.id]: e.target.value });

    if (e.target.value !== '000') {
      setShowReminderAssignmentErrorMessage(false);
    }
  };
  const onChangeMedication = (e) => {
    if (e.target.id === 'reminder' && e.target.value === 'yes') {
      showTheAssignReminderModal();
    }
    if (e.target.id === 'reminder' && e.target.value === 'no') {
      setReminderDetails({});
    }
    setNewMedication({ ...newMedication, [e.target.id]: e.target.value });
  };

  const onChange = (e) => {
    if (e.target.id === 'processor') {
      const processorSelected = contacts.filter(
        (contact) => contact._id === parseInt(e.target.value)
      );
      const processorName =
        processorSelected[0].First_Name + ' ' + processorSelected[0].Last_Name;
      setNewDog({ ...newDog, processor: processorName });
    } else if (e.target.id === 'foster') {
      const fosterSelected = contacts.filter(
        (contact) => contact._id === parseInt(e.target.value)
      );
      const fosterLocation =
        fosterSelected[0].City + ', ' + fosterSelected[0].State;
      const fosterName =
        fosterSelected[0].First_Name + ' ' + fosterSelected[0].Last_Name;
      setNewDog({
        ...newDog,
        foster: fosterName,
        location: fosterLocation,
      });
    } else {
      setNewDog({ ...newDog, [e.target.id]: e.target.value });
    }
  };

  const selectFilters = (e) => {
    setFilters(e.target.value);
  };

  const updateDog = () => {
    editDog(dog._id, editedDog, 'all');
    if (editedDog.status === 'Adopted') {
      deleteMedReminderByDog(dog._id);
      updateMedicationRemindersFlags(dog._id);
    }
    setEditedDog([]);
    setChecked(false);
    setShowEdit(false);
    setAlert('DOG HAS BEEN UPDATED', 'success');
  };

  const updateDogNote = () => {
    const dogNotes = {
      notes: dogNote,
    };
    editDog(dog._id, dogNotes, 'all');
    setShowEditDogNotes(false);
    setDogNote('');
  };

  const cancelNoteEdit = () => {
    setShowEditDogNotes(false);
    setDogNote('');
  };
  const newDogEntry = () => {
    setShowCreate(true);
  };

  const submitDog = () => {
    var dbFileEntryData = [];

    var x;
    for (x of selectedFiles) {
      const date = Date.now();
      const lastIndex = x.name.lastIndexOf('.') + 1;
      const fName = x.name.slice(0, lastIndex - 1);
      const ext = x.name.slice(lastIndex);
      const newFileName = `${fName}-${date}.${ext}`;

      const attachment = {
        file: x,
        fileName: newFileName,
      };

      uploadDogFile(attachment);

      const dbData = {
        File_Name: newFileName,
        Uploaded_By: user.First_Name + ' ' + user.Last_Name,
      };

      dbFileEntryData.push(dbData);
    }

    const newDogSubmitData = {
      dogName: newDog.dogName,
      foster: newDog.foster,
      processor: newDog.processor,
      location: newDog.location,
      intake: newDog.intake,
      available_date: newDog.available_date,
      status: newDog.status,
      notes: dogNote,
      petfinder_id: newDog.petfinder_id,
    };

    createDog(newDogSubmitData, dbFileEntryData);
    setShowCreate(false);
    setNewDog({
      dogName: '',
      foster: '',
      processor: '',
      location: '',
      intake: formDate(Date.now()),
      available_date: formDate(Date.now()),
      status: 'available',
      notes: '',
      petfinder_id: null,
    });
    setDogNote('');
    setSelectedFiles([]);
  };

  const removeNewFile = (name) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== name);
    setSelectedFiles(updatedFiles);
    fileInputRef.current.value = '';
  };

  const removeFile = (id) => {
    deleteDogFile(id);
    fileInputRef.current.value = '';
  };
  const removeFileFromMain = (id) => {
    deleteDogFile(id);
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const mainFileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
    setShowAddFilesDialog(false);
  };
  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const newFileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleNewFiles(files);
    }
  };

  const mainFilesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
    setShowAddFilesDialog(false);
  };
  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const newFileSelected = () => {
    if (fileInputRef.current.files.length) {
      handleNewFiles(fileInputRef.current.files);
    }
  };

  const handleNewFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
      } else {
        files[i]['invalid'] = true;
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        setErrorMessage('File type not permitted');
      }
    }
  };

  const handleFiles = async (file) => {
    if (validateFile(file[0])) {
      const date = Date.now();
      const lastIndex = file[0].name.lastIndexOf('.') + 1;
      const fName = file[0].name.slice(0, lastIndex - 1);
      const ext = file[0].name.slice(lastIndex);
      const newFileName = `${fName}-${date}.${ext}`;

      const medicalRecord = {
        file: file[0],
        fileName: newFileName,
      };
      await uploadDogFile(medicalRecord);

      const dbData = {
        File_Name: newFileName,
        File_DogID: dog._id,
        Uploaded_By: user.First_Name + ' ' + user.Last_Name,
      };

      await createDogFileDBEntry(dbData);
    } else {
      setErrorMessage('File type not permitted');
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const validateFile = (file) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon',
      'application/doc',
      'application/docx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/msword',
      'video/mp4',
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  };

  const downloadFile = (id) => {
    axios(`/api/dogs/file/db/${id}`, {
      method: 'GET',
      responseType: 'blob',
    })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers.content_type,
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addAMedication = () => {
    if (reminderDetails.assigned_to === undefined) {
      createMedication(newMedication);
    } else {
      createMedication(newMedication, reminderDetails);
    }
    setShowAddMedication(false);
    setNewMedication({
      medication: '',
      dog: dog._id,
      administered_by: '',
      date_given: null,
      due_date: null,
      reminder: 'no',
      status: 'Pending',
    });
    setReminderDetails({});
  };

  const cancelAddMedication = () => {
    setShowAddMedication(false);
    setNewMedication({
      medication: '',
      dog: dog._id,
      administered_by: '',
      date_given: null,
      due_date: null,
      reminder: 'no',
      status: 'Pending',
    });
    setReminderDetails({});
  };

  const editTheMedication = (med) => {
    setShowEditMedication(true);
    getMedication(med._id);
    getMedReminderByMedication(med._id);
    setEditedMedication({
      ...editedMedication,
      _id: med._id,
      status: med.status,
      due_date: med.due_date,
      reminder: med.reminder,
      medication: med.medication,
      dog: med.dog,
    });
    if (med.reminder === 'yes') {
      const reminderToEdit = med_reminders.filter(
        (reminder) => reminder.medication === med._id
      );
      setReminderDetails({
        ...reminderDetails,
        _id: reminderToEdit[0]._id,
        reminder: reminderToEdit[0].reminder,
        due_date: reminderToEdit[0].due_date,
        assigned_to: reminderToEdit[0].assigned_to,
      });
      setMedicationHasReminder(true);
    }
  };

  const cancelEditMedication = () => {
    setShowEditMedication(false);
    clearMedReminder();
    clearMedication();
    setEditedMedication({});
    setReminderDetails({});
    setMedicationHasReminder(false);
  };

  const onChangeEditMedication = (e) => {
    if (e.target.id === 'reminder' && e.target.value === 'yes') {
      showTheAssignReminderModal();
    }
    if (e.target.id === 'reminder' && e.target.value === 'no') {
      setReminderDetails({});
      setEditedMedication({
        ...editedMedication,
        due_date: null,
        [e.target.id]: e.target.value,
      });
      document.getElementById('due_date').value = '';
    } else if (e.target.id === 'status' && e.target.value === 'Complete') {
      setEditedMedication({
        ...editedMedication,
        reminder: 'no',
        due_date: null,
        [e.target.id]: e.target.value,
      });
    } else if (e.target.id === 'status' && e.target.value === 'Pending') {
      setEditedMedication({
        ...editedMedication,
        date_given: null,
        administered_by: null,
        [e.target.id]: e.target.value,
      });
    } else {
      setEditedMedication({
        ...editedMedication,
        [e.target.id]: e.target.value,
      });
    }
  };

  const editTheDogNotes = () => {
    if (showEditDogNotes) {
      setShowEditDogNotes(false);
      setDogNote('');
    } else {
      setShowEditDogNotes(true);
      setDogNote(dog.notes);
    }
  };

  const editAMedication = () => {
    if (
      med_reminder === undefined &&
      reminderDetails.assigned_to !== undefined &&
      medicationHasReminder === false
    ) {
      const reminderData = {
        reminder: editedMedication.medication,
        dog: editedMedication.dog,
        medication: editedMedication._id,
        due_date: editedMedication.due_date,
        status: 'pending',
        complete: 'no',
        assigned_to: reminderDetails.assigned_to,
      };
      createMedReminder(reminderData);
    }

    if (
      med_reminder !== undefined &&
      editedMedication.status === 'Pending' &&
      editedMedication.reminder === 'yes' &&
      medicationHasReminder === true
    ) {
      const updatedReminder = {
        _id: reminderDetails._id,
        reminder: editedMedication.medication,
        due_date: editedMedication.due_date,
        assigned_to: reminderDetails.assigned_to,
      };
      editMedReminder(updatedReminder._id, updatedReminder);
    }

    if (
      editedMedication.status === 'Complete' &&
      med_reminder !== null &&
      med_reminder !== undefined
    ) {
      deleteMedReminder(med_reminder._id);
    }

    if (
      editedMedication.status === 'Pending' &&
      editedMedication.reminder === 'no' &&
      medicationHasReminder === true
    ) {
      deleteMedReminder(med_reminder._id);
      setMedicationHasReminder(false);
    }

    editMedication(editedMedication);
    setShowEditMedication(false);
    clearMedication();
    clearMedReminder();
    setEditedMedication({});
    setReminderDetails({});
    setMedicationHasReminder(false);
  };

  const filesDogTab = () => {
    if (currentDogTab === 'medication') {
      setShowEditMedication(false);
      setShowAddMedication(false);
      clearMedication();
      setEditedMedication({});
    }
    if (currentDogTab === 'notes') {
      setShowEditDogNotes(false);
      setDogNote('');
    }

    setCurrentDogTab('files');
  };

  const notesDogTab = () => {
    if (currentDogTab === 'medication') {
      setShowEditMedication(false);
      setShowAddMedication(false);
      clearMedication();
      setEditedMedication({});
    }

    setCurrentDogTab('notes');
  };

  const medicationDogTab = () => {
    if (currentDogTab === 'notes') {
      setShowEditDogNotes(false);
      setDogNote('');
    }
    setCurrentDogTab('medication');
  };

  const lookupContact = (id) => {
    if (id === '000') {
      return 'UnAssigned';
    } else {
      const results = contacts.filter(
        (contact) => contact._id === parseInt(id)
      );
      const assignedContact =
        results[0].First_Name + ' ' + results[0].Last_Name;
      return assignedContact;
    }
  };

  const showTheAssignReminderModal = () => {
    setShowAssignReminderModal(true);
  };

  const cancelAssignment = () => {
    setShowAssignReminderModal(false);
    document.getElementById('reminder').value = 'no';
    document.getElementById('due_date').value = '';
    setNewMedication({ ...newMedication, reminder: 'no' });
    setEditedMedication({
      ...editedMedication,
      reminder: 'no',
      due_date: null,
    });
    setShowReminderAssignmentErrorMessage(false);
    setReminderDetails({});
  };

  const assignReminder = () => {
    if (
      reminderDetails.assigned_to === '000' ||
      reminderDetails === undefined
    ) {
      setShowReminderAssignmentErrorMessage(true);
    } else if (
      reminderDetails.assigned_to === '000' &&
      medicationHasReminder === true
    ) {
      setShowAssignReminderModal(false);
      document.getElementById('reminder').value = 'no';
      setNewMedication({ ...newMedication, reminder: 'no' });
      setEditedMedication({ ...editedMedication, reminder: 'no' });
      setShowReminderAssignmentErrorMessage(false);
      setReminderDetails({});
    } else {
      setShowAssignReminderModal(false);
    }
  };

  const toggleFilesDialog = () => {
    if (showAddFilesDialog) {
      setShowAddFilesDialog(false);
    } else {
      setShowAddFilesDialog(true);
    }
  };

  if (showEdit === false && showCreate === false && showView === false) {
    return (
      <div className="container pt-3 ">
        <div className="row">
          <div className="col-lg-6">
            <form>
              <div className="form-group">
                <input
                  type="search"
                  className="form-control"
                  placeholder="    ENTER DOG, FOSTER, OR PROCESSOR NAME TO SEARCH"
                  onChange={search}
                ></input>
              </div>
            </form>
          </div>
          <div className="col-lg-4">
            <form>
              <div className="form-group">
                <select
                  id="filters"
                  className="custom-select"
                  defaultValue={filters}
                  onChange={selectFilters}
                >
                  <option>All</option>
                  <option>Available</option>
                  <option>Pending Transport</option>
                  <option>Medical Hold</option>
                  <option>Not Available</option>
                  <option>Adoption Pending</option>
                  <option>Adopted</option>
                </select>
              </div>
            </form>
          </div>
          <div className="col-lg-2">
            <button
              className="btn btn-success float-right mr-3 pointer"
              onClick={newDogEntry}
            >
              NEW DOG
            </button>
          </div>
        </div>
        <table className="table table-striped table-hover border border-dark">
          <thead>
            <tr className="bg-theme text-white">
              <th className="text-center" scope="col">
                DOG
              </th>
              <th className="text-center" scope="col">
                FOSTER
              </th>
              <th className="text-center" scope="col">
                PROCESSOR
              </th>
              <th className="text-center d-none d-md-table-cell" scope="col">
                LOCATION
              </th>
              <th className="text-center d-none d-md-table-cell" scope="col">
                INTAKE DATE
              </th>
              <th className="text-center d-none d-md-table-cell" scope="col">
                AVAILABLE DATE
              </th>
              <th className="text-center" scope="col">
                STATUS
              </th>
              {user.Role === 'Manager' || user.Role === 'Admin' ? (
                <th className="text-center" scope="col"></th>
              ) : null}
            </tr>
          </thead>

          <tbody>
            {displayedDogs !== null ? (
              displayedDogs.map((dog) => (
                <DogListItem
                  dog={dog}
                  key={dog._id}
                  removeDog={removeDog}
                  user={user}
                  getDog={getDog}
                  getDogFiles={getDogFiles}
                  showDogInFoster={showDogInFoster}
                />
              ))
            ) : (
              <tr>
                <td>Loading</td>
              </tr>
            )}
          </tbody>
        </table>
        <div
          className="modal fade"
          id="dogDeleteConfirmationModal"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="dogDeleteConfirmationModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-danger font-weight-bold">
                  DELETE CONFIRMATION
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="font-weight-bold">
                  ARE YOU SURE YOU WANT TO DELETE THIS DOG?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={cancelDeletion}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    deleteDog(removeDogId);
                    setRemoveDogId(null);
                  }}
                  data-dismiss="modal"
                >
                  CONFRIM
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (showEdit === true) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="container rounded mt-3 p-3 bg-secondary shadow-lg">
              <h5 className="card-title text-center mt-2 text-white font-weight-bolder">
                EDIT DOGS IN FOSTER
              </h5>
              <table className="table table-sm">
                <tbody>
                  <tr>
                    <th className="text-white font-weight-bolder">DOG NAME</th>
                    <td>
                      <form>
                        <div className="form-group m-0">
                          <input
                            type="text"
                            id="dogName"
                            className="form-control p-1"
                            onChange={editTheDog}
                            defaultValue={dog.dogName}
                          />
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th className="text-white font-weight-bolder">FOSTER</th>
                    <td>
                      <form>
                        <div className="form-group m-0">
                          <select
                            id="foster"
                            className="custom-select"
                            onChange={editTheDog}
                          >
                            <option>{dog.foster}</option>
                            {fosters !== null
                              ? fosters.map((foster) => (
                                  <option key={foster._id} value={foster._id}>
                                    {foster.First_Name} {foster.Last_Name}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th className="text-white  font-weight-bolder">
                      PROCESSOR
                    </th>
                    <td>
                      <form>
                        <div className="form-group m-0">
                          <select
                            id="processor"
                            className="custom-select"
                            onChange={editTheDog}
                          >
                            <option>{dog.processor}</option>
                            {processors !== null
                              ? processors.map((processor) => (
                                  <option
                                    key={processor._id}
                                    value={processor._id}
                                  >
                                    {processor.First_Name} {processor.Last_Name}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th className="text-white  font-weight-bolder">
                      INTAKE DATE
                    </th>
                    <td>
                      <form>
                        <div className="form-group m-0">
                          <input
                            type="date"
                            id="intake"
                            className="form-control"
                            onChange={editTheDog}
                            defaultValue={formDate(dog.intake)}
                          />
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th className="text-white  font-weight-bolder">
                      AVAILABLE DATE
                    </th>
                    <td>
                      <form>
                        <div className="form-group m-0">
                          <input
                            id="available_date"
                            type="date"
                            className="form-control"
                            onChange={editTheDog}
                            defaultValue={formDate(dog.available_date)}
                          />
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th className="text-white  font-weight-bolder">STATUS</th>
                    <td>
                      <form>
                        <div className="form-group">
                          <select
                            id="status"
                            className="custom-select"
                            onChange={editTheDog}
                            defaultValue={dog.status}
                            checked={checked}
                          >
                            <option>Available</option>
                            <option>Pending Transport</option>
                            <option>Medical Hold</option>
                            <option>Not Available</option>
                            <option>Adoption Pending</option>
                            <option>Adopted</option>
                          </select>
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th className="text-white  font-weight-bolder">
                      PETFINDER ID
                    </th>
                    <td>
                      <form>
                        <div className="form-group">
                          <input
                            id="petfinder_id"
                            type="text"
                            className="form-control p-1"
                            onChange={editTheDog}
                            defaultValue={dog.petfinder_id}
                            checked={checked}
                          ></input>
                        </div>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="container">
                <div className="text-white text-center font-weight-bolder border border-white">
                  MEDICAL RECORDS
                </div>
                <div className="container my-3">
                  {savedFiles.map((data, i) => (
                    <div className="row" key={data._id}>
                      <div className="col-10">
                        <div
                          className="file-status-bar pointer text-light"
                          key={data._id}
                          onClick={() => downloadFile(data._id)}
                        >
                          {data.File_Name}

                          {data.invalid && (
                            <span className="file-error-message">
                              ({errorMessage})
                            </span>
                          )}
                        </div>
                      </div>
                      <div
                        className=" col-2 text-left text-warning font-weight-bold pointer"
                        onClick={() => removeFile(data._id)}
                      >
                        remove
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="container">
                <form>
                  <div
                    className="drop-container text-center mb-3"
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                    id="uploadFile"
                    onClick={fileInputClicked}
                  >
                    <span className="file-error-message">{errorMessage}</span>

                    <div className="drop-message">
                      <div className="upload-icon"></div>
                      Drag & Drop medical records files here or click to select
                      file from computer
                    </div>
                    <input
                      ref={fileInputRef}
                      className="file-input"
                      type="file"
                      multiple
                      onChange={filesSelected}
                    />
                  </div>
                </form>
              </div>
              <div className="row mb-2">
                <div className="col-lg-9"></div>
                <div className="col-lg-3">
                  <button className="btn btn-danger" onClick={cancelEdit}>
                    BACK
                  </button>
                  <button className="btn btn-success ml-2" onClick={updateDog}>
                    UPDATE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    );
  } else if (showCreate === true) {
    return (
      <div className="container rounded mt-3 p-3 bg-secondary shadow round">
        <h5 className="card-title text-center mt-2 text-white font-weight-bolder">
          CREATE DOGS IN FOSTER
        </h5>
        <table className="table table-sm ">
          <tbody>
            <tr>
              <th className="text-white font-weight-bolder">DOG NAME</th>
              <td>
                <form>
                  <div className="form-group m-0">
                    <input
                      type="text"
                      id="dogName"
                      className="form-control p-1"
                      onChange={onChange}
                    />
                  </div>
                </form>
              </td>
            </tr>
            <tr>
              <th className="text-white font-weight-bolder">FOSTER</th>
              <td>
                <form>
                  <div className="form-group m-0">
                    <select
                      id="foster"
                      className="custom-select"
                      onChange={onChange}
                    >
                      <option value="">Choose Foster</option>
                      {fosters !== null
                        ? fosters.map((foster) => (
                            <option key={foster._id} value={foster._id}>
                              {foster.First_Name} {foster.Last_Name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                </form>
              </td>
            </tr>
            <tr>
              <th className="text-white font-weight-bolder">PROCESSOR</th>
              <td>
                <form>
                  <div className="form-group m-0">
                    <select
                      id="processor"
                      className="custom-select"
                      onChange={onChange}
                    >
                      <option value="">Choose Processor</option>
                      {processors !== null
                        ? processors.map((processor) => (
                            <option key={processor._id} value={processor._id}>
                              {processor.First_Name} {processor.Last_Name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                </form>
              </td>
            </tr>
            <tr>
              <th className="text-white font-weight-bolder">INTAKE DATE</th>
              <td>
                <form>
                  <div className="form-group m-0">
                    <input
                      type="date"
                      id="intake"
                      className="form-control"
                      defaultValue={newDog.intake}
                      onChange={onChange}
                    />
                  </div>
                </form>
              </td>
            </tr>
            <tr>
              <th className="text-white font-weight-bolder">AVAILABLE DATE</th>
              <td>
                <form>
                  <div className="form-group m-0">
                    <input
                      id="available_date"
                      type="date"
                      className="form-control"
                      defaultValue={newDog.available_date}
                      onChange={onChange}
                    />
                  </div>
                </form>
              </td>
            </tr>
            <tr>
              <th className="text-white font-weight-bolder">STATUS</th>
              <td>
                <form>
                  <div className="form-group">
                    <select
                      id="status"
                      className="custom-select"
                      onChange={onChange}
                      defaultValue="Available"
                      checked={checked}
                    >
                      <option>Available</option>
                      <option>Pending Transport</option>
                      <option>Medical Hold</option>
                      <option>Not Available</option>
                      <option>Adoption Pending</option>
                      <option>Adopted</option>
                    </select>
                  </div>
                </form>
              </td>
            </tr>
            <tr>
              <th className="text-white font-weight-bolder">PETFINDER ID</th>
              <td>
                <form>
                  <div className="form-group m-0">
                    <input
                      type="text"
                      id="petfinder_id"
                      className="form-control p-1"
                      onChange={onChange}
                    />
                  </div>
                </form>
              </td>
            </tr>
          </tbody>
        </table>
        <div></div>
        <div className="container">
          <div className="text-white text-center font-weight-bolder border border-white">
            MEDICAL RECORDS
          </div>
          <div className="container my-3">
            {selectedFiles.map((data, i) => (
              <div className="row" key={i}>
                <div className="col-10">
                  <div className="file-status-bar pointer text-light" key={i}>
                    {data.name}

                    {data.invalid && (
                      <span className="file-error-message">
                        ({errorMessage})
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className=" col-2 text-left text-warning font-weight-bold pointer"
                  onClick={() => removeNewFile(data.name)}
                >
                  remove
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="container">
          <form>
            <div
              className="drop-container text-center mb-3"
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={newFileDrop}
              id="uploadFile"
              onClick={fileInputClicked}
            >
              <span className="file-error-message">{errorMessage}</span>

              <div className="drop-message">
                <div className="upload-icon"></div>
                Drag & Drop medical records files here or click to select file
                from computer
              </div>
              <input
                ref={fileInputRef}
                className="file-input"
                type="file"
                multiple
                onChange={newFileSelected}
              />
            </div>
          </form>
        </div>

        <div className="m-3">
          <form>
            <div className="form-group m-0">
              <label htmlFor="notes" className="text-white font-weight-bold">
                NOTES:
              </label>

              <TextEditor textData={dogNote} setTextData={setDogNote} />
            </div>
          </form>
        </div>

        <div className="row mb-2">
          <div className="col-8"></div>
          <div className="col-3 text-right">
            <button className="btn btn-danger" onClick={cancelCreate}>
              BACK
            </button>
            <button className="btn btn-success ml-2" onClick={submitDog}>
              CREATE
            </button>
          </div>
        </div>
      </div>
    );
  } else if (showView === true) {
    return (
      <div className="dog_view_container">
        {showAssignReminderModal && <div className="dog_background_blur"></div>}

        <div className="dog_view_navigation mt-2 pl-4">
          <button
            className="btn btn-danger ml-3 font-weight-bold color"
            onClick={returnToDogList}
          >
            RETURN
          </button>
          <button
            className="btn btn-warning ml-2 font-weight-bold"
            onClick={editDogInFoster}
          >
            EDIT
          </button>
        </div>
        <div className="dog_top_section row mt-2">
          <div className="dog_picture  col-sm-3 ml-2 text-center">
            {dog_picture_url === null ? (
              <img src={NoDogImage} alt="not loaded"></img>
            ) : (
              <img src={dog_picture_url} alt="petfinder loaded" />
            )}
          </div>
          <div className="dog_information col-sm-4">
            <table className="table table-sm table-borderless">
              <tbody>
                <tr>
                  <th>DOG NAME:</th>
                  <td className="text-left">{dog.dogName}</td>
                </tr>
                <tr>
                  <th>PROCESSOR:</th>
                  <td className="text-left">{dog.processor}</td>
                </tr>
                <tr>
                  <th>FOSTER:</th>
                  <td className="text-left">{dog.foster}</td>
                </tr>
                <tr>
                  <th>LOCATION:</th>
                  <td className="text-left">{dog.location}</td>
                </tr>
                <tr>
                  <th>INTAKE DATE:</th>
                  <td className="text-left">{formTheDate(dog.intake)}</td>
                </tr>
                <tr>
                  <th>AVAILABLE DATE:</th>
                  <td className="text-left">
                    {formTheDate(dog.available_date)}
                  </td>
                </tr>
                <tr>
                  <th>PETFINDER ID:</th>
                  <td className="text-left">{dog.petfinder_id}</td>
                </tr>
                <tr>
                  <th>CURRENT STATUS:</th>
                  {dog.status === 'Available' ? (
                    <td className="bg-success text-center font-weight-bold text-white">
                      {dog.status}
                    </td>
                  ) : (
                    <td className="bg-warning text-center font-weight-bold text-dark">
                      {dog.status}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="dog_reminders col-sm-4">
            <div className="row">
              <h4 className="font-weight-bold">REMINDERS</h4>
            </div>

            {med_reminders !== null && med_reminders !== undefined ? (
              <div className="row dog_reminder_box border-dark">
                <table className="table table-sm table-bordered">
                  <thead>
                    <tr>
                      <th>DESCRIPTION</th>
                      <th>ASSIGNED TO</th>
                      <th>DUE DATE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {med_reminders.map((reminder) => (
                      <tr key={reminder._id}>
                        <td>{reminder.reminder}</td>
                        <td>
                          {reminder.assigned_to !== '000'
                            ? lookupContact(reminder.assigned_to)
                            : null}
                        </td>
                        <td>{formTheDate(reminder.due_date)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>NO REMINDERS</p>
            )}
          </div>
        </div>
        <div className="dog_lower_section p-4">
          {currentDogTab === 'notes' && (
            <div className="notes_section">
              <div className="row dog_lower_section_tabs ml-2 mt-2">
                <div className="dog_notes_tab bg-blue rounded-top p-2 text-white font-weight-bold shadow">
                  NOTES
                </div>
                <div
                  className="dog_files_tab bg-white rounded-top p-2 text-dark font-weight-bold pointer"
                  onClick={filesDogTab}
                >
                  FILES
                </div>
                <div
                  className="dog_medications_tab bg-white rounded-top p-2 text-dark font-weight-bold shadow pointer"
                  onClick={medicationDogTab}
                >
                  MEDICATIONS
                </div>
              </div>
              <div className="dog_notes_outside_border rounded bg-blue p-3 mx-2 mt-0">
                <div className="row px-2">
                  <div className="dog_notes bg-white text-dark rounded  col-12">
                    {showEditDogNotes === false ? (
                      <div className="mt-2">{ReactHtmlParser(dog.notes)}</div>
                    ) : (
                      <TextEditor textData={dogNote} setTextData={setDogNote} />
                    )}
                  </div>
                </div>
                {showEditDogNotes === false ? (
                  <div className="row mt-3">
                    <div className="col-11"></div>
                    <button
                      className="btn btn-warning"
                      onClick={editTheDogNotes}
                    >
                      EDIT
                    </button>
                  </div>
                ) : (
                  <div className="row mt-3">
                    <div className="col-10"></div>
                    <div className="col-2">
                      <button
                        className="btn btn-danger ml-2"
                        onClick={cancelNoteEdit}
                      >
                        CANCEL
                      </button>
                      <button
                        className="btn btn-success ml-2"
                        onClick={updateDogNote}
                      >
                        UPDATE
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {currentDogTab === 'files' && (
            <div className="notes_section">
              <div className="row dog_lower_section_tabs ml-2 mt-2">
                <div
                  className="dog_notes_tab bg-white rounded-top p-2 text-dark font-weight-bold shadow pointer"
                  onClick={notesDogTab}
                >
                  NOTES
                </div>
                <div className="dog_files_tab bg-blue rounded-top p-2 text-white font-weight-bold">
                  FILES
                </div>
                <div
                  className="dog_medications_tab bg-white rounded-top p-2 text-dark font-weight-bold shadow pointer"
                  onClick={medicationDogTab}
                >
                  MEDICATIONS
                </div>
              </div>
              <div className="dog_notes_outside_border bg-blue p-4 mx-2 mt-0">
                {!showAddFilesDialog ? (
                  <div className="files_container my-3">
                    <div className="row mt-3 ml-2">
                      <button
                        className="btn btn-success"
                        onClick={toggleFilesDialog}
                      >
                        ADD FILE
                      </button>
                    </div>
                    <div className="mt-2">
                      {savedFiles.map((data, i) => (
                        <div className="row" key={i}>
                          <div className="col-10">
                            <div
                              className="file-status-bar pointer text-light"
                              key={i}
                              onClick={() => downloadFile(data._id)}
                            >
                              {data.File_Name}

                              {data.invalid && (
                                <span className="file-error-message">
                                  ({errorMessage})
                                </span>
                              )}
                            </div>
                          </div>
                          <div
                            className=" col-2 text-left text-warning font-weight-bold pointer"
                            onClick={() => removeFileFromMain(data._id)}
                          >
                            remove
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <form>
                      <div
                        className="drop-container text-center mb-3"
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={mainFileDrop}
                        id="uploadFile"
                        onClick={fileInputClicked}
                      >
                        <span className="file-error-message">
                          {errorMessage}
                        </span>

                        <div className="drop-message">
                          <div className="upload-icon"></div>
                          Drag & Drop medical records files here or click to
                          select file from computer
                        </div>
                        <input
                          ref={fileInputRef}
                          className="file-input"
                          type="file"
                          multiple
                          onChange={mainFilesSelected}
                        />
                      </div>
                    </form>
                    <div className="row mt-3">
                      <div className="col-10"></div>
                      <div className="col-1">
                        <button
                          className="btn btn-danger"
                          onClick={toggleFilesDialog}
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {currentDogTab === 'medication' && (
            <div className="notes_section">
              <div className="row dog_lower_section_tabs ml-2 mt-2">
                <div
                  className="dog_notes_tab bg-white rounded-top p-2 text-dark font-weight-bold shadow pointer"
                  onClick={notesDogTab}
                >
                  NOTES
                </div>
                <div
                  className="dog_files_tab bg-white rounded-top p-2 text-dark font-weight-bold pointer"
                  onClick={filesDogTab}
                >
                  FILES
                </div>
                <div className="dog_medications_tab bg-blue rounded-top p-2 text-white font-weight-bold shadow">
                  MEDICATIONS
                </div>
              </div>

              <div className="dog_notes_outside_border dog_notes_parent bg-blue p-4 mx-2 mt-0">
                {showAssignReminderModal && (
                  <div className="assign_reminder_module">
                    <h3>ASSIGN REMINDER:</h3>
                    <select
                      name="assigned_to"
                      className="custom-select"
                      id="assigned_to"
                      onChange={onChangeReminder}
                      defaultValue={reminderDetails.assigned_to}
                    >
                      <option value="000">UnAssigned</option>
                      {contactsInSortedOrder.map((contact) => (
                        <option key={contact._id} value={contact._id}>
                          {contact.First_Name} {contact.Last_Name}
                        </option>
                      ))}
                    </select>
                    {showReminderAssignmentErrorMessage ? (
                      <p className="text-danger font-weight-bold">
                        PLEASE CHOOSE A CONTACT FROM THE LIST OR CHOOSE CANCEL
                      </p>
                    ) : null}

                    <div className="text-right mt-4">
                      <button
                        className="btn btn-danger mr-1"
                        onClick={cancelAssignment}
                      >
                        CANCEL
                      </button>
                      <button
                        className="btn btn-success"
                        onClick={assignReminder}
                      >
                        ASSIGN
                      </button>
                    </div>
                  </div>
                )}
                <div className="dog_notes bg-white text-dark p-2 ">
                  <table className="table table-sm table-responsive-sm ">
                    <thead>
                      <tr>
                        <th className="text-center">MEDICATION</th>
                        <th className="text-center">DUE DATE</th>
                        <th className="text-center">REMINDER</th>
                        <th className="text-center">DATE GIVEN</th>
                        <th className="text-center">ADMINISTERED BY</th>
                        <th className="text-center">STATUS</th>
                        <th className="text-right">
                          {!showEditMedication && !showAddMedication ? (
                            <button
                              className="btn btn-success"
                              onClick={() => setShowAddMedication(true)}
                            >
                              ADD
                            </button>
                          ) : null}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedMedications !== null &&
                      displayedMedications !== undefined
                        ? displayedMedications.map((med) => (
                            <tr key={med._id}>
                              <td>{med.medication}</td>
                              <td className="text-center">
                                {med.due_date !== null
                                  ? formTheDate(med.due_date)
                                  : null}
                              </td>
                              <td className="text-center">{med.reminder}</td>

                              <td className="text-center">
                                {med.date_given !== null
                                  ? formTheDate(med.date_given)
                                  : null}
                              </td>

                              <td className="text-center">
                                {med.administered_by}
                              </td>

                              <td className="text-center">{med.status}</td>
                              <td className="text-right">
                                <button
                                  className="pointer btn btn-warning mr-2"
                                  onClick={() => editTheMedication(med)}
                                >
                                  EDIT
                                </button>
                                <button
                                  className="pointer btn btn-danger"
                                  onClick={() => deleteMedication(med._id)}
                                >
                                  REMOVE
                                </button>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                  <table className="table table-sm table-secondary border-0 table-responsive-sm ">
                    {showAddMedication ? (
                      <thead className="border-0">
                        <tr className="border-0">
                          <th className="border-0">MEDICATION</th>
                          <th className="border-0">STATUS</th>
                          {newMedication.status === 'Pending' && (
                            <th className="border-0">DUE DATE</th>
                          )}
                          {newMedication.status === 'Complete' && (
                            <th className="border-0">DATE GIVEN</th>
                          )}
                          {newMedication.status === 'Complete' && (
                            <th className="border-0">ADMINISTERED BY</th>
                          )}
                          {newMedication.status === 'Pending' && (
                            <th className="border-0 text-center">REMINDER</th>
                          )}
                          <th className="border-0"></th>
                        </tr>
                      </thead>
                    ) : null}

                    <tbody className="border-0">
                      {showAddMedication ? (
                        <tr className="border-0">
                          <td className="border-0">
                            <input
                              type="text"
                              className="col-12 form-control"
                              id="medication"
                              list="medsList"
                              autoComplete="off"
                              onChange={onChangeMedication}
                            />
                            <datalist id="medsList">
                              {meds !== null || meds !== undefined
                                ? meds.map((med) => (
                                    <option key={med._id}>
                                      {med.medication_name}
                                    </option>
                                  ))
                                : null}
                            </datalist>
                          </td>
                          <td className="border-0">
                            <select
                              className="form-control"
                              id="status"
                              onChange={onChangeMedication}
                            >
                              <option>Pending</option>
                              <option>Complete</option>
                            </select>
                          </td>
                          {newMedication.status === 'Pending' && (
                            <td className="border-0">
                              <input
                                className="form-control"
                                type="date"
                                id="due_date"
                                onChange={onChangeMedication}
                              />
                            </td>
                          )}
                          {newMedication.status === 'Complete' && (
                            <td className="border-0">
                              <input
                                className="form-control"
                                type="date"
                                id="date_given"
                                onChange={onChangeMedication}
                              />
                            </td>
                          )}
                          {newMedication.status === 'Complete' && (
                            <td className="border-0">
                              <input
                                type="text"
                                className="col-12 form-control"
                                id="administered_by"
                                onChange={onChangeMedication}
                              />
                            </td>
                          )}
                          {newMedication.status === 'Pending' && (
                            <td className="border-0 text-center">
                              <select
                                className="form-control"
                                type="select"
                                id="reminder"
                                onChange={onChangeMedication}
                              >
                                <option>no</option>
                                <option>yes</option>
                              </select>
                              {reminderDetails.assigned_to !== undefined ? (
                                <div>
                                  <h6 className="mt-1 font-weight-bold">
                                    ASSIGNED TO:
                                  </h6>
                                  <h6
                                    className="mt-0 pointer"
                                    onClick={() => showTheAssignReminderModal()}
                                  >
                                    {lookupContact(reminderDetails.assigned_to)}
                                  </h6>
                                </div>
                              ) : null}
                            </td>
                          )}

                          <td className="text-right border-0">
                            <button
                              onClick={cancelAddMedication}
                              className="btn btn-danger mr-2 mb-2"
                            >
                              CANCEL
                            </button>
                            <button
                              className="btn btn-success mr-2 mb-2"
                              onClick={addAMedication}
                            >
                              SAVE
                            </button>
                          </td>
                        </tr>
                      ) : (
                        !showEditMedication && (
                          <tr className="mt-2 bg-white">
                            <td className="border-0"></td>
                            <td className="border-0"></td>
                            <td className="border-0"></td>
                            <td className="border-0"></td>
                            <td className="text-right border-0"></td>
                          </tr>
                        )
                      )}
                      {showEditMedication && medication !== null ? (
                        <tr className="border-0 font-weight-bold">
                          <td className="border-0 font-weight-bold">
                            MEDICATION
                          </td>
                          <td className="border-0 font-weight-bold">STATUS</td>
                          {editedMedication.status === 'Pending' ? (
                            <td className="border-0 font-weight-bold">
                              DUE DATE
                            </td>
                          ) : null}

                          {editedMedication.status !== 'Pending' && (
                            <td className="border-0 font-weight-bold">
                              DATE GIVEN
                            </td>
                          )}
                          {editedMedication.status !== 'Pending' && (
                            <td className="border-0 font-weight-bold">
                              ADMINISTERED BY
                            </td>
                          )}
                          {editedMedication.status !== 'Complete' && (
                            <td className="border-0 font-weight-bold text-center">
                              REMINDER
                            </td>
                          )}
                          <td className="border-0"></td>
                        </tr>
                      ) : null}

                      {showEditMedication && medication !== null ? (
                        <tr className="border-0">
                          <td className="border-0">
                            <input
                              type="text"
                              className="col-12 form-control"
                              id="medication"
                              list="medsList"
                              autoComplete="off"
                              defaultValue={medication.medication}
                              onChange={onChangeEditMedication}
                            />
                            <datalist id="medsList">
                              {meds !== null || meds !== undefined
                                ? meds.map((med) => (
                                    <option key={med._id}>
                                      {med.medication_name}
                                    </option>
                                  ))
                                : null}
                            </datalist>
                          </td>
                          <td className=" border-0">
                            <select
                              className="form-control"
                              id="status"
                              defaultValue={medication.status}
                              onChange={onChangeEditMedication}
                            >
                              <option>Pending</option>
                              <option>Complete</option>
                            </select>
                          </td>
                          {editedMedication.status === 'Pending' && (
                            <td className="border-0">
                              <input
                                className=" form-control"
                                type="date"
                                id="due_date"
                                defaultValue={formDate(medication.due_date)}
                                onChange={onChangeEditMedication}
                              />
                            </td>
                          )}

                          {editedMedication.status === 'Complete' && (
                            <td className="border-0">
                              <input
                                className=" form-control"
                                type="date"
                                id="date_given"
                                defaultValue={formDate(medication.date_given)}
                                onChange={onChangeEditMedication}
                              />
                            </td>
                          )}

                          {editedMedication.status === 'Complete' && (
                            <td className="border-0">
                              <input
                                type="text"
                                className="col-12 form-control"
                                id="administered_by"
                                defaultValue={medication.administered_by}
                                onChange={onChangeEditMedication}
                              />
                            </td>
                          )}
                          {editedMedication.status === 'Pending' && (
                            <td className="border-0 text-center">
                              <select
                                className="form-control"
                                type="select"
                                id="reminder"
                                defaultValue={medication.reminder}
                                onChange={onChangeEditMedication}
                              >
                                <option>no</option>
                                <option>yes</option>
                              </select>
                              {reminderDetails.assigned_to !== undefined ? (
                                <div>
                                  <h6 className="mt-1 font-weight-bold">
                                    ASSIGNED TO:
                                  </h6>
                                  <h6
                                    className="mt-0 pointer"
                                    onClick={() => showTheAssignReminderModal()}
                                  >
                                    {lookupContact(reminderDetails.assigned_to)}
                                    <i
                                      className="fa fa-pencil ml-2 pointer"
                                      aria-hidden="true"
                                    ></i>
                                  </h6>
                                </div>
                              ) : null}
                            </td>
                          )}
                          <td className="text-right border-0">
                            <button
                              className="btn btn-danger"
                              onClick={cancelEditMedication}
                            >
                              CANCEL
                            </button>
                            <button
                              className="btn btn-success"
                              onClick={editAMedication}
                            >
                              SAVE
                            </button>
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default Dogs;
