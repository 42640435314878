import React, { Fragment, useContext, useState, useEffect } from 'react';
import AuthContext from '../../context/auth/authContext';
import AdminContext from '../../context/admin/adminContext';
import PPRLogo from '../../components/media/PPR_logo.png';
import '../../css/navbar.css';
import axios from 'axios';

const Navbar = () => {
  const authContext = useContext(AuthContext);
  const adminContext = useContext(AdminContext);
  const { isAuthenticated, logoutUser, user } = authContext;
  const {
    blogPage,
    kbPage,
    notesPage,
    dogsPage,
    userList,
    applicationList,
    adminDashboard,
    emailTemplatesList,
    zapplicationList,
    contactList,
    dashboard,
    adoptionListPage,
    errorLogPage,
    loadLogPage,
    currentlyEditing,
    doneEditing,
    ticketsList,
    loadMedicationList,
    userProfilePage,
  } = adminContext;

  const [adminRole, setAdminRole] = useState({
    show: false,
  });
  const [navPage, setNavPage] = useState(null);

  useEffect(() => {
    if (
      (user != null && user.Role === 'Admin') ||
      (user != null && user.Role === 'Manager')
    ) {
      setAdminRole({ show: true });
    } else if (user === null || user.Role === 'User') {
      setAdminRole({ show: false });
    }
  }, [user]);

  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  const loadDashboard = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('dashboard');
    } else {
      dashboard();
    }
  };
  const loadApplications = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('applications');
    } else {
      applicationList();
    }
  };
  const loadDogs = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('dogs');
    } else {
      dogsPage();
    }
  };
  const loadAdoptions = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('adoptions');
    } else {
      adoptionListPage();
    }
  };
  const loadContacts = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('contacts');
    } else {
      contactList();
    }
  };
  const loadZoho = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('zoho');
    } else {
      zapplicationList();
    }
  };
  const loadResources = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('resources');
    } else {
      kbPage();
    }
  };
  const loadNotes = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('notes');
    } else {
      notesPage();
    }
  };
  const loadMedsList = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('medicationList');
    } else {
      loadMedicationList();
    }
  };
  const loadBlogs = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('blogs');
    } else {
      blogPage();
    }
  };
  const loadEmailTemplates = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('emailTemplates');
    } else {
      emailTemplatesList();
    }
  };
  const loadErrorLogs = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('errors');
    } else {
      errorLogPage();
    }
  };
  const loadLogs = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('logs');
    } else {
      loadLogPage();
    }
  };
  const loadUsers = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('users');
    } else {
      userList();
    }
  };
  const loadTickets = () => {
    if (currentlyEditing) {
      openModal();
      setNavPage('tickets');
    } else {
      ticketsList();
    }
  };

  const onLogout = (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          'content-type': 'application/json',
        },
      };

      const logData = {
        module: 'AUTH',
        action: 'LogOut User',
        user: user.Username,
        notes: `user logged out`,
      };
      axios.post(`/api/logs`, logData, config);
    } catch (error) {
      console.log(error);
    }

    setNavPage(null);
    doneEditing();
    logoutUser();
    adminDashboard();
  };

  const continueWithoutSaving = () => {
    doneEditing();
    if (navPage === 'dashboard') {
      dashboard();
      setNavPage(null);
    }
    if (navPage === 'applications') {
      applicationList();
      setNavPage(null);
    }
    if (navPage === 'dogs') {
      dogsPage();
      setNavPage(null);
    }
    if (navPage === 'adoptions') {
      adoptionListPage();
      setNavPage(null);
    }
    if (navPage === 'contacts') {
      contactList();
      setNavPage(null);
    }
    if (navPage === 'zoho') {
      zapplicationList();
      setNavPage(null);
    }
    if (navPage === 'resources') {
      kbPage();
      setNavPage(null);
    }
    if (navPage === 'notes') {
      notesPage();
      setNavPage(null);
    }
    if (navPage === 'blog') {
      blogPage();
      setNavPage(null);
    }
    if (navPage === 'emailTemplates') {
      emailTemplatesList();
      setNavPage(null);
    }
    if (navPage === 'errors') {
      errorLogPage();
      setNavPage(null);
    }
    if (navPage === 'logs') {
      loadLogPage();
      setNavPage(null);
    }
    if (navPage === 'tickets') {
      errorLogPage();
      setNavPage(null);
    }
    if (navPage === 'users') {
      userList();
      setNavPage(null);
    }
    if (navPage === 'logout') {
      logoutUser();
      try {
        const config = {
          headers: {
            'content-type': 'application/json',
          },
        };

        const logData = {
          module: 'AUTH',
          action: 'LogOut User',
          user: user.Username,
          notes: `user logged out`,
        };
        axios.post(`/api/logs`, logData, config);
      } catch (error) {
        console.log(error);
      }
      setNavPage(null);
    }
  };

  // ! ********  MODAL CODE **********

  function openModal() {
    document.getElementById('backDrop').style.display = 'block';
    document.getElementById('editingModal').style.display = 'block';
    document.getElementById('editingModal').className += 'show';
  }
  function closeModal() {
    document.getElementById('backDrop').style.display = 'none';
    document.getElementById('editingModal').style.display = 'none';
    document.getElementById('editingModal').className += document
      .getElementById('editingModal')
      .className.replace('show', '');
  }
  // Get the modal
  var modal = document.getElementById('editingModal');

  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function (event) {
    if (event.target === modal) {
      closeModal();
    }
  };

  const authLinks = (
    <div className="container-fluid bg-theme">
      <div
        className="modal fade"
        id="editingModal"
        data-backdrop="static"
        tabIndex="-1"
        aria-labelledby="editingModalLabel"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger font-weight-bold">
                UNSAVED DATA
              </h5>
              <button
                type="button"
                className="close"
                onClick={closeModal}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="font-weight-bold">
                YOU HAVE AN UNSAVED NOTE OR EMAIL... DO YOU WANT TO CONTINUE
                WIHTOUT SAVING?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-secondary"
                // data-dismiss="modal"
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  continueWithoutSaving();
                  closeModal();
                }}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-lg-10">
          <img
            className="header_logo"
            src={PPRLogo}
            alt="puppy paws rescue logo"
          />
          {/* <h2 className="text-light mt-2">
            <i className=" fas fa-paw"></i> PPS APP PORTAL
          </h2> */}
        </div>
        <div className="row col-lg-2">
          <h6
            className="nav-link mt-2 text-light text-right d-none d-lg-block pointer"
            onClick={() => userProfilePage(user._id)}
          >
            <i className="fas fa-user mr-2"></i>
            {user && user.First_Name} {user && user.Last_Name}
          </h6>
        </div>
      </div>
      <div className="row">
        <div className="navbar navbar-dark navbar-expand-lg bg-theme">
          <button
            className="navbar-toggler pointer"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-2">
              <li className="nav-item nav-link d-lg-none text-white">
                {user && user.First_Name} {user && user.Last_Name}
              </li>
              <li className="nav-item">
                <a
                  onClick={loadDashboard}
                  className="nav-link  ml-2 pointer"
                  href="/admin"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  HOME
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  ml-2 pointer"
                  href="/admin"
                  onClick={loadApplications}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  APPLICATIONS
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  ml-2 pointer"
                  onClick={loadDogs}
                  href="/admin"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  DOGS
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  ml-2 pointer"
                  onClick={loadAdoptions}
                  href="/admin"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  ADOPTIONS
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link  ml-2 pointer"
                  onClick={loadContacts}
                  href="/admin"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  CONTACTS
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/admin"
                  className="nav-link  ml-2 pointer"
                  onClick={loadZoho}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  ZOHO
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/admin"
                  className="nav-link  ml-2 pointer"
                  onClick={loadResources}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  RESOURCES
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/admin"
                  className="nav-link  ml-2 pointer"
                  onClick={loadNotes}
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  NOTES
                </a>
              </li>
              {adminRole.show ? (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    href="/admin"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    ADMIN
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <a
                      onClick={loadBlogs}
                      className={
                        adminRole.show ? 'dropdown-item pointer' : 'd-none'
                      }
                      href="/admin"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      BLOG ENTRIES
                    </a>
                    <a
                      className={
                        adminRole.show ? 'dropdown-item pointer' : 'd-none'
                      }
                      onClick={loadEmailTemplates}
                      href="/admin"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      EMAIL TEMPLATES
                    </a>
                    <a
                      className={
                        adminRole.show ? 'dropdown-item pointer' : 'd-none'
                      }
                      onClick={loadMedsList}
                      href="/admin"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      MEDICATION LIST
                    </a>
                    <a
                      className={
                        user !== null && user.Role === 'Admin'
                          ? 'dropdown-item pointer'
                          : 'd-none'
                      }
                      onClick={loadErrorLogs}
                      href="/admin"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      ERRORS
                    </a>

                    <a
                      className={
                        user !== null && user.Role === 'Admin'
                          ? 'dropdown-item pointer'
                          : 'd-none'
                      }
                      onClick={loadLogs}
                      href="/admin"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      LOGS
                    </a>
                    <a
                      className={
                        user !== null &&
                        (user.Role === 'Admin' || user.Role === 'Manager')
                          ? 'dropdown-item pointer'
                          : 'd-none'
                      }
                      onClick={loadTickets}
                      href="/admin"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      TICKETS
                    </a>
                    <a
                      className={
                        adminRole.show ? 'dropdown-item pointer' : 'd-none'
                      }
                      onClick={loadUsers}
                      href="/admin"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      USERS
                    </a>
                  </div>
                </li>
              ) : null}

              <li className="nav-item">
                <a
                  className="nav-link ml-2 pointer"
                  onClick={onLogout}
                  href="/admin"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  LOGOUT
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  const guestLinks = (
    <div className="navbar navbar-dark navbar-expand-lg bg-theme">
      <div className="container-fluid m-1">
        <div className="col-lg-4">
          <img
            className="header_logo"
            src={PPRLogo}
            alt="puppy paws rescue logo"
          />
          {/* <h2 className="text-light">
            <i className=" fas fa-paw"></i> PPR APP PORTAL
          </h2> */}
        </div>
      </div>
    </div>
  );

  const ieLinks = (
    <div className="navbar navbar-expand-lg navbar-dark bg-dark"></div>
  );

  return (
    <Fragment>
      <nav>{isAuthenticated ? authLinks : isIE ? ieLinks : guestLinks}</nav>
    </Fragment>
  );
};

export default Navbar;
