import React, { Fragment, useEffect, useState, useContext } from 'react';
import AdminZohoAppList from './AdminZohoAppList';
import Pagination from '../../pagination/Pagination';
import UserContext from '../../../context/user/userContext';
import ZohoContext from '../../../context/zoho/zohoContext';

const AdminApplicationList = (props) => {
  const userContext = useContext(UserContext);
  const zohoContext = useContext(ZohoContext);
  const { users, getUsers } = userContext;
  const {
    zapps,
    getZohoApps,
    getFilteredZohoApps,
    clearApp,
    loadZohoApp,
    appCount,
  } = zohoContext;
  const [displayFilteredApps, setDisplayFilteredApps] = useState([]);
  const [displayApps, setDisplayApps] = useState([]);
  const [totalDisplayApps, setTotalDisplayApps] = useState([]);
  const [loadingApps] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [appsPerPage, setAppsPerPage] = useState(100);
  const [filters, setFilters] = useState({
    Application_Status: 'All',
  });
  const [searchBar, setSearchBar] = useState('');
  const [searchBarError, setSearchBarError] = useState('');
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    clearApp();
    getZohoApps();
    getUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDisplayApps(zapps);
    // eslint-disable-next-line
  }, [zapps]);

  useEffect(() => {
    if (displayApps !== null && displayApps !== undefined) {
      const indexOfLastApp = currentPage * appsPerPage;
      const indexofFirstApp = indexOfLastApp - appsPerPage;
      setTotalDisplayApps(displayApps);
      setDisplayFilteredApps(
        displayApps.slice(indexofFirstApp, indexOfLastApp)
      );
    }
    // eslint-disable-next-line
  }, [displayApps, appsPerPage, currentPage]);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [searchBar]);

  const appsPerPageUpdate = (e) => {
    setAppsPerPage(e.target.value);
    setCurrentPage(1);
  };

  const onChange = (e) => {
    if (e.target.value !== 'ALL') {
      const filter = {
        field: 'Application_Status',
        value: e.target.value,
      };

      getFilteredZohoApps(filter);
    } else {
      getZohoApps();
    }
    setCurrentPage(1);
    setFilters({ Application_Status: e.target.value });
  };

  const searchData = (e) => {
    const specialCharacters = /[*)(?/[\]]|\\/g;
    if (specialCharacters.test(e.target.value)) {
      setSearchBarError('- Cannot use these characters * / \\ ) ( [ ] ?');
      setTimeout(() => setSearchBarError(''), 3000);
    } else {
      setSearchBar(e.target.value);
      search();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const search = (e) => {
    if (zapps !== null && zapps !== undefined) {
      const searchResults = zapps.filter((app) => {
        const regex = new RegExp(`${searchBar}`, 'gi');

        if (!app.Dog_Adopted) {
          return (
            app.First_Name.match(regex) ||
            app.Last_Name.match(regex) ||
            app.Application_ID.match(regex) ||
            app.Email.match(regex)
          );
        } else {
          return (
            app.First_Name.match(regex) ||
            app.Last_Name.match(regex) ||
            app.Application_ID.match(regex) ||
            app.Email.match(regex) ||
            app.Dog_Adopted.match(regex) ||
            app.Micro_Chip_Number.match(regex) ||
            app.Micro_Chip_Brand.match(regex)
          );
        }
      });
      setCurrentPage(1);
      setDisplayApps(searchResults);
    }
  };

  const resetFilters = () => {
    setSearchBar('');
    setAppsPerPage(100);
    setFilters({
      Application_Status: 'All',
    });

    getZohoApps();
  };

  return (
    <Fragment>
      <div className="container">
        <h2 className="text-center">ZOHO APPLICATION LIST</h2>
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="filterByStatus" className="font-weight-bold">
                  SEARCH{' '}
                  <span className="text-danger font-weight-bold">
                    {searchBarError}
                  </span>
                </label>
                <input
                  type="search"
                  className="form-control"
                  value={searchBar}
                  onChange={searchData}
                  id="search"
                ></input>
              </div>
            </div>
          </div>
          <div className="row col-lg-12">
            <div className="form-group">
              <label htmlFor="apps_Per_Page" className="font-weight-bold">
                PER PAGE
              </label>
              <select
                className="form-control"
                onChange={appsPerPageUpdate}
                id="apps_Per_Page"
                value={appsPerPage}
              >
                <option>10</option>
                <option>20</option>
                <option>50</option>
                <option>100</option>
              </select>
            </div>
            <div className="col-lg-2">
              <div className="form-group">
                <label
                  htmlFor="Application_Status"
                  className="font-weight-bold"
                >
                  STATUS
                </label>
                <select
                  className="form-control"
                  onChange={onChange}
                  id="Application_Status"
                  value={filters.Application_Status}
                >
                  <option value="ALL">ALL</option>
                  <option>Adopted</option>
                  <option>Adopted - F/U on S/N</option>
                  <option>Adopted - Returned</option>
                  <option>Approved - Foster</option>
                  <option>Approved - No Adopt</option>
                  <option>Approved-ON HOLD</option>
                  <option>Approved-pending adoption</option>
                  <option>NOT Approved</option>
                  <option>Not Qualified</option>
                  <option>Processing</option>
                  <option>Stop Processing</option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 p-3">
              <button
                type="button"
                onClick={resetFilters}
                className="btn btn-danger my-3"
              >
                RESET FILTERS
              </button>
            </div>
            <div className="col-lg-6 p-3">
              <h4 className="text-right">TOTAL COUNT: {appCount}</h4>
            </div>
          </div>
        </form>
        <table className="table table-striped border shadow">
          <thead>
            <tr className="bg-theme text-white">
              <th scope="col">NAME</th>
              <th scope="col">TYPE</th>
              <th scope="col">STAGE</th>
              <th scope="col">SUBMISSION DATE</th>
              <th scope="col">ADOPTION DATE</th>
              <th scope="col">DOG ADOPTED</th>
            </tr>
          </thead>
          <tbody>
            {displayFilteredApps !== null && !loadingApps && users !== null ? (
              displayFilteredApps.map((app) => (
                <AdminZohoAppList
                  app={app}
                  key={app.Application_ID}
                  loadZohoApp={loadZohoApp}
                  // value={app.Application_ID}
                />
              ))
            ) : (
              <tr>
                <td>Loading</td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          appsPerPage={appsPerPage}
          totalApps={totalDisplayApps.length}
          paginate={paginate}
        />
      </div>
    </Fragment>
  );
};

export default AdminApplicationList;
