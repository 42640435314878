import React, { useContext, useEffect, useState } from 'react';
import LogsContext from '../../context/logs/logContext';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const date = new Date(string).toLocaleDateString('en-US', options);
  const time = new Date(string).toLocaleTimeString();
  const convertedDate = date + ' ' + time;

  return convertedDate;
}

const Logs = () => {
  const logsContext = useContext(LogsContext);
  const { getLogs, clearLogState, getLog, logs, log, deleteLog } = logsContext;
  const [displayLog, setDisplayLog] = useState(false);
  const [removeId, setRemoveId] = useState(null);

  useEffect(() => {
    getLogs();
    return () => {
      clearLogState();
    };
    // eslint-disable-next-line
  }, []);

  const onClick = (e) => {
    getLog(e);
    setDisplayLog(true);
  };

  const returnToList = () => {
    setDisplayLog(false);
  };

  const deleteAError = (e) => {
    deleteLog(e);
  };

  const removeLog = (e) => {
    setRemoveId(e.target.id);
  };

  const cancelDeletion = () => {
    setRemoveId(null);
  };

  if (displayLog) {
    return (
      <div className="container mt-3">
        <div className="card rounded shadow">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <table className="table">
                  <tbody>
                    <tr>
                      <th>MODULE:</th>
                      <td>{log.module}</td>
                    </tr>
                    <tr>
                      <th>ACTION:</th>
                      <td>{log.action}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-6">
                <table className="table">
                  <tbody>
                    <tr>
                      <th>DATE:</th>
                      <td>{formatDate(log.createdAt)}</td>
                    </tr>
                    <tr>
                      <th>USER:</th>
                      <td>{log.user}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <h5 className="ml-2 font-weight-bold">NOTES:</h5>
            <div className="row mx-2 border border-dark">
              <p className="p-3">{log.notes}</p>
            </div>

            <button className="btn btn-danger mt-3" onClick={returnToList}>
              BACK
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container border border-dark shadow mt-3">
        <table className="table table-hover">
          <thead>
            <tr className="bg-theme text-white">
              <th scope="col" className="text-center">
                DATE
              </th>
              <th scope="col" className="text-center">
                USER
              </th>
              <th scope="col" className="text-center">
                MODULE
              </th>
              <th scope="col" className="text-center">
                ACTION
              </th>
              <th scope="col" className="text-center">
                NOTES
              </th>

              <th scope="col" className="text-center"></th>
            </tr>
          </thead>
          <tbody>
            {logs !== null && logs !== undefined
              ? logs.map((log) => (
                  <tr key={log._id}>
                    <td
                      className="text-center pointer"
                      onClick={() => onClick(log)}
                    >
                      {formatDate(log.createdAt)}
                    </td>
                    <td
                      className="text-center pointer"
                      onClick={() => onClick(log)}
                    >
                      {log.user}
                    </td>
                    <td
                      className="text-center pointer"
                      onClick={() => onClick(log)}
                    >
                      {log.module}
                    </td>
                    <td
                      className="text-center pointer"
                      onClick={() => onClick(log)}
                    >
                      {log.action}
                    </td>
                    <td
                      className="text-center pointer"
                      onClick={() => onClick(log)}
                    >
                      {log.notes}
                    </td>
                    <td className="text-right mr-3">
                      <i
                        className="fa fa-trash pointer text-danger"
                        onClick={removeLog}
                        data-toggle="modal"
                        data-target="#DeleteConfirmationModal"
                        aria-hidden="true"
                        id={log._id}
                      ></i>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
        <div
          className="modal fade"
          id="DeleteConfirmationModal"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="DeleteConfirmationModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-danger font-weight-bold">
                  DELETE CONFIRMATION
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="font-weight-bold">
                  ARE YOU SURE YOU WANT TO DELETE THIS LOG?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={cancelDeletion}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    deleteAError(removeId);
                    setRemoveId(null);
                  }}
                  data-dismiss="modal"
                >
                  CONFRIM
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Logs;
