import React, { useReducer } from 'react';
import NotesContext from './notesContext';
import notesReducer from './notesReducer';
import axios from 'axios';

import { CREATE_NOTE, EDIT_NOTE, GET_NOTES, DELETE_NOTE } from '../types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const NotesState = (props) => {
  const initialstate = {
    notes: [],
    noteLoading: true,
  };

  const [state, dispatch] = useReducer(notesReducer, initialstate);

  const createNote = async (noteData) => {
    await axios.post('/api/notes', noteData, config);
    const res = await axios.get(`/api/notes/app/${noteData.NoteApp_Id}`);
    dispatch({
      type: CREATE_NOTE,
      payload: res.data,
    });
  };
  const editNote = async (e) => {
    const data = {
      Note: e.Note,
      Author: e.Author,
    };
    try {
      await axios.put(`/api/notes/${e._id}`, data, config);
      dispatch({
        type: EDIT_NOTE,
        payload: e,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteNote = async (id) => {
    await axios.delete(`/api/notes/${id}`);
    dispatch({
      type: DELETE_NOTE,
      payload: id,
    });
  };

  const getNotes = async (e) => {
    try {
      const res = await axios.get(`/api/notes/app/${e}`);
      dispatch({
        type: GET_NOTES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getNote = (e) => {
    console.log(e);
  };

  return (
    <NotesContext.Provider
      value={{
        notes: state.notes,
        noteLoading: state.noteLoading,
        createNote,
        editNote,
        deleteNote,
        getNotes,
        getNote,
      }}
    >
      {props.children}
    </NotesContext.Provider>
  );
};

export default NotesState;
