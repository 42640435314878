import React from 'react';

const MedicationListCreate = ({
  changePageView,
  meds,
  onchange,
  addMedication,
}) => {
  return (
    <div className="newMedicationContainer">
      <label className="font-weight-bold m-3" htmlFor="medication_name">
        Medication Name:
      </label>
      <input
        type="text"
        list="medsList"
        id="medication_name"
        onChange={onchange}
        autoComplete="off"
      />
      {/* <datalist id="medsList">
        {meds !== null
          ? meds.map((med) => (
              <option key={med._id}>{med.medication_name}</option>
            ))
          : null}
      </datalist> */}
      <button className="btn btn-success ml-3" onClick={() => addMedication()}>
        ADD
      </button>
    </div>
  );
};

export default MedicationListCreate;
