import {
  DELETE_ERROR_LOG,
  GET_ERROR_LOGS,
  GET_ERROR_LOG,
  CLEAR_ERROR_LOGS_STATE,
} from '../types';

const errorReducer = (state, action) => {
  switch (action.type) {
    case GET_ERROR_LOGS:
      return {
        ...state,
        errorLogs: action.payload,
      };
    case GET_ERROR_LOG:
      return {
        ...state,
        errorLog: action.payload,
      };
    case DELETE_ERROR_LOG:
      return {
        ...state,
        errorLogs: state.errorLogs.filter(
          (log) => log._id !== parseInt(action.payload)
        ),
      };
    case CLEAR_ERROR_LOGS_STATE:
      return {
        ...state,
        errorLogs: null,
        errorLog: null,
      };
    default:
      return state;
  }
};

export default errorReducer;
