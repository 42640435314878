import React, { Fragment, useContext } from 'react';
import AuthContext from '../../context/auth/authContext';

const Navbar = () => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;
  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  const authLinks = (
    <div className="footer">
      <h4 className="text-center text-white tagline">
        <i className="tagline">I Can't Do Everything But I Can Do Something</i>
      </h4>
      <p className="text-light text-center align-middle mr-2 mb-0">
        PuppyPaws Rescue
      </p>
    </div>
  );

  const guestLinks = (
    <div className=" bg-theme">
      <div className="container">
        <div className="row">
          <div className="col-xs-1 col-md-4 text-light">
            <h5 className="  mt-3 mb-0 pb-0">
              <i className="fa fa-paw pr-1"></i>PuppyPaws Rescue
            </h5>
            <p className="pt-0 mb-0 mt-0 ml-4 text-light">PO Box 45 </p>
            <p className="pt-0 mb-0 mt-0 ml-4 text-light">
              Huntingtown, MD 20639
            </p>
            <p className="pt-0 mb-0 mt-0 ml-4 text-light">
              information@puppypawsrescue.org
            </p>
            <p className="pt-0 pb-4 mb-0 mt-0 ml-4 text-light">
              (202) 630-5874
            </p>
          </div>
        </div>
      </div>
      <p className="text-light text-center align-middle mr-2">
        {' '}
        PuppyPaws Rescue &copy; 2021
      </p>
    </div>
  );

  const ieLinks = (
    <div className=" bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-xs-1 col-md-4 text-light">
            <h5 className="  mt-3 mb-0 pb-0">
              <i className="fa fa-paw pr-1"></i>PuppyPaws Rescue
            </h5>
            <p className="pt-0 mb-0 mt-0 ml-4 text-light">PO Box 45 </p>
            <p className="pt-0 mb-0 mt-0 ml-4 text-light">
              Huntingtown, MD 20639
            </p>
            <p className="pt-0 mb-0 mt-0 ml-4 text-light">
              information@puppypawsrescue.org
            </p>
            <p className="pt-0 pb-4 mb-0 mt-0 ml-4 text-light">
              (202) 630-5874
            </p>
          </div>
          {/* ***************** COLUMN 2 **********************  */}

          <div className="col-xs-1 col-md-4">
            <div className="row">
              <h5 className="text-light pt-3">FOLLOW US: </h5>
            </div>
            <div className="row">
              <a href="https://www.facebook.com/pg/PuppyPawsRescue">
                <i className="fab fa-facebook-square fa-w-16 fa-3x text-light ml-5"></i>
              </a>
              <a href="https://www.instagram.com/puppypawsrescue/">
                <i className="fab fa-instagram fa-w-16 fa-3x text-light pl-2"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="text-light text-center align-middle mr-2">
        {' '}
        PuppyPaws Rescue &copy; 2021
      </p>
    </div>
  );

  return (
    <Fragment>
      {isAuthenticated ? authLinks : isIE ? ieLinks : guestLinks}
    </Fragment>
  );
};

export default Navbar;
