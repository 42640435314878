import React, { useReducer, useContext } from 'react';
import LogContext from './logContext';
import logReducer from './logReducer';
import AlertContext from '../alert/alertContext';
import axios from 'axios';

import { DELETE_LOG, GET_LOGS, GET_LOG, CLEAR_LOGS_STATE } from '../types';

const LogState = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialstate = {
    logs: null,
    log: null,
  };

  const [state, dispatch] = useReducer(logReducer, initialstate);

  const getLogs = async (e) => {
    try {
      const res = await axios.get(`/api/logs/`);
      dispatch({
        type: GET_LOGS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getLog = async (log) => {
    dispatch({
      type: GET_LOG,
      payload: log,
    });
  };

  const deleteLog = async (id) => {
    try {
      const res = await axios.delete(`/api/logs/${id}`);
      setAlert(res.data, 'success');
      dispatch({
        type: DELETE_LOG,
        payload: id,
      });
    } catch (error) {}
  };

  const clearLogState = () => {
    dispatch({
      type: CLEAR_LOGS_STATE,
    });
  };
  return (
    <LogContext.Provider
      value={{
        logs: state.logs,
        log: state.log,
        getLogs,
        deleteLog,
        getLog,
        clearLogState,
      }}
    >
      {props.children}
    </LogContext.Provider>
  );
};

export default LogState;
