import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AdminContext from '../../../context/admin/adminContext';
import '../../../css/Dashboard.css';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return new Date(string).toLocaleDateString([], options);
}

const AdminDashAppList = ({ app }) => {
  const adminContext = useContext(AdminContext);
  const { viewApp } = adminContext;

  const {
    _id,
    First_Name,
    Last_Name,
    st_Choice,
    submit_date,
    Application_Type,
  } = app;
  const formatedDate = formatDate(submit_date);

  const viewTheApp = (e) => {
    viewApp(_id);
    window.scroll(0, 0);
  };

  return (
    <tr className="d-flex">
      <td onClick={viewTheApp} className="pointer col-lg-3">
        {First_Name} {Last_Name}
      </td>
      <td
        onClick={viewTheApp}
        className=" d-none d-md-block text-center pointer col-lg-2"
      >
        {Application_Type}
      </td>
      <td
        onClick={viewTheApp}
        className="text-center limitDisplayText pointer col-lg-4"
      >
        {st_Choice}
      </td>
      <td className="col-lg-3 text-center">{formatedDate}</td>
    </tr>
  );
};

AdminDashAppList.propTypes = {
  app: PropTypes.object.isRequired,
};
export default AdminDashAppList;
