import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../../../context/app/appContext';
import AlertContext from '../../../context/alert/alertContext';
import ContactsContext from '../../../context/contacts/contactsContext';
import MMDDYY from '../../../utils/mmddyy';

import { CreateAdoptionContract } from '../../templates/adoptionContract';
import { CreateESignAdoptionContract } from '../../templates/ESignAdoptionContract';

const AdminAppContractGenerator = () => {
  const appContext = useContext(AppContext);
  const contactsContext = useContext(ContactsContext);
  const { setAlert } = useContext(AlertContext);
  const { app, updateApp } = appContext;
  const { contacts, getContacts } = contactsContext;
  const [signorValid, setSignorValid] = useState(true);
  const [hwValid, setHwValid] = useState(true);
  const [alteredValid, setAlteredValid] = useState(true);
  const [fostersList, setFostersList] = useState(null);
  const [contractData, setContractData] = useState({
    dog: '',
    Name: '',
    Fee: '$300.00',
    Address: '',
    County: '',
    Phone: '',
    Email: '',
    ChipBrand: '_______________',
    Chip: '__________________',
    Sex: '_____________________',
    BirthDay: '_____________________',
    Breed: '_____________________',
    Description: '_____________________',
    Medical_Info: '  See Records  ',
    Altered: '',
    DateSent: MMDDYY(Date.now()),
    AlteredDueDate: '',
    AlteredRefund: '$75.00',
    treatedHw: '',
    pprSignature: '',
  });

  const [eSignData, setESignData] = useState({
    request_name: '',
    notes: '',
    private_notes: '',
    recipient_name: '',
    recipient_email: '',
    foster_name: '',
    foster_email: '',
    cc1_name: 'PPR Admin',
    cc1_email: 'agreements@puppypawsrescue.org',
    scenerio: 1,
    fileName: '',
  });
  var delivery = 'esign';

  const on_change = (e) => {
    if (e.target.id === 'Altered' && e.target.value === 'yes') {
      setContractData({
        ...contractData,
        [e.target.id]: e.target.value,
        AlteredDueDate: '',
      });
    } else {
      setContractData({ ...contractData, [e.target.id]: e.target.value });
    }
  };

  const handleESignChange = (e) => {
    setESignData({ ...eSignData, [e.target.id]: e.target.value });
  };

  const handleFosterSelection = (e) => {
    const selectedFoster = fostersList.filter(
      (foster) => foster._id === parseInt(e.target.value)
    );
    if (e.target.value === '') {
      setESignData({
        ...eSignData,
        foster_name: '',
        foster_email: '',
      });
    } else {
      setESignData({
        ...eSignData,
        foster_name:
          selectedFoster[0].First_Name + ' ' + selectedFoster[0].Last_Name,
        foster_email: selectedFoster[0].Email_Address,
      });
    }
  };

  useEffect(() => {
    getContacts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (contacts === null) return;

    const fosters = contacts.filter(
      (contact) =>
        contact.Contact_Type === 'Foster' ||
        contact.Contact_Type === 'Processor & Foster'
    );
    const sortedFosters = fosters.sort((a, b) => {
      return a.First_Name > b.First_Name ? 1 : -1;
    });

    setFostersList(sortedFosters);
    // eslint-disable-next-line
  }, [contacts]);

  useEffect(() => {
    if (app !== null) {
      setContractData({
        ...contractData,
        Name: app.First_Name + ' ' + app.Last_Name + ' ' + app.CoApp_Full_Name,
        Address:
          app.Street + ' ' + app.City + ', ' + app.State + ' ' + app.Zip_Code,
        County: app.County,
        Phone: app.Phone.length > 0 ? app.Phone : app.Mobile,
        Email: app.Email,
        AppID: app._id,
      });

      setESignData({
        ...eSignData,
        recipient_name: app.First_Name + ' ' + app.Last_Name,
        recipient_email: app.Email,
      });
    }
    // eslint-disable-next-line
  }, []);

  const validation = () => {
    if (contractData.pprSignature === '') {
      setSignorValid(false);
    } else {
      setSignorValid(true);
    }
    if (contractData.treatedHw === '') {
      setHwValid(false);
    } else {
      setHwValid(true);
    }
    if (contractData.Altered === '') {
      setAlteredValid(false);
    } else {
      setAlteredValid(true);
    }
    if (
      contractData.Altered === '' ||
      contractData.treatedHw === '' ||
      contractData.pprSignature === ''
    )
      return false;
    if (contractData.Altered === 'no' && contractData.AlteredDueDate === '') {
      window.alert('Enter S/N Due Date');
    }
    return true;
  };

  function sendViaESign() {
    delivery = 'esign';
    const isValidated = validation();

    if (isValidated === false) {
      return;
    } else {
      openModal();
      console.log('ESignature is ready to be started');
    }
  }

  async function orderESign() {
    const updatedNotes = eSignData.notes.replace(/(\r\n|\n|\r)/gm, ' ');

    var scenerio = 1;
    if (contractData.Altered === 'yes' && contractData.treatedHw === 'yes')
      scenerio = 2;
    if (contractData.Altered === 'no' && contractData.treatedHw === 'yes')
      scenerio = 3;
    if (contractData.Altered === 'no' && contractData.treatedHw === 'no')
      scenerio = 4;
    const signatureData = {
      request_name: `Adoption Agreement AppID ${app._id} ${app.First_Name} ${app.Last_Name} `,
      notes: updatedNotes,
      private_notes: 'Please review and sign this agreement',
      recipient_name: eSignData.recipient_name,
      recipient_email: eSignData.recipient_email,
      foster_name: eSignData.foster_name,
      foster_email: eSignData.foster_email,
      cc1_name: eSignData.cc1_name,
      cc1_email: eSignData.cc1_email,
      scenerio: scenerio,
      fileName: 'PPR Adoption Agreement-' + app.Last_Name + '.docx',
    };

    const test = async () => {
      var b;
      await CreateESignAdoptionContract(app, contractData, signatureData).then(
        (response) => {
          b = response;
        }
      );
      return b;
    };
    closeModal();
    await test().then((results) => {
      if (results === 'Error') {
        setAlert('SERVER ERROR SENDING AGREEMENT', 'danger');
      } else {
        setAlert('AGREEMENT HAS BEEN SENT SUCCESSFULLY', 'success');
      }
    });
  }

  function createContract() {
    delivery = 'download';
    const isValidated = validation();
    if (isValidated === false) {
      return;
    } else {
      CreateAdoptionContract(app, contractData, delivery);

      const data = {
        snDueDate: contractData.AlteredDueDate,
      };

      updateApp(app._id, data);
    }
  }

  function openModal(id) {
    document.getElementById('backdrop').style.display = 'block';
    document.getElementById('eSignModal').style.display = 'block';
    document.getElementById('eSignModal').classList.add('show');
  }
  function closeModal(id) {
    document.getElementById('backdrop').style.display = 'none';
    document.getElementById('eSignModal').style.display = 'none';
    document.getElementById('eSignModal').classList.remove('show');
  }
  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function (event) {
    if (event.target.id === 'eSignModal') {
      closeModal('eSignModal');
    }
  };

  return (
    <>
      <div className="container">
        <div className="contract_builder_form_title">
          <h3 className="text-center text-danger mt-3">CONTRACT GENERATOR</h3>
        </div>
        <form>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-3 m-0 p-0">
                    <label htmlFor="Name" className="m-0 font-weight-bold mt-2">
                      Adopter's Name(s)
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      defaultValue={
                        app.First_Name +
                        ' ' +
                        app.Last_Name +
                        ' ' +
                        app.CoApp_Full_Name
                      }
                      onChange={on_change}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 m-0 p-0">
                    <label
                      htmlFor="Street"
                      className="m-0 font-weight-bold mt-2"
                    >
                      Street:
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <input
                      type="text"
                      className="form-control"
                      id="Address"
                      defaultValue={
                        app.Street +
                        ' ' +
                        app.City +
                        ', ' +
                        app.State +
                        ' ' +
                        app.Zip_Code
                      }
                      onChange={on_change}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 m-0 p-0">
                    <label
                      htmlFor="County"
                      className="m-0 font-weight-bold mt-2"
                    >
                      County:
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <input
                      type="text"
                      className="form-control"
                      id="County"
                      defaultValue={app.County}
                      onChange={on_change}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 m-0 p-0">
                    <label
                      htmlFor="Phone"
                      className="m-0 font-weight-bold mt-2"
                    >
                      Phone:
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <input
                      type="text"
                      className="form-control"
                      id="Phone"
                      defaultValue={
                        app.Phone.length > 0 ? app.Phone : app.Mobile
                      }
                      onChange={on_change}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 m-0 p-0">
                    <label
                      htmlFor="Email"
                      className="m-0 font-weight-bold mt-2"
                    >
                      Email:
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <input
                      type="text"
                      className="form-control"
                      id="Email"
                      defaultValue={app.Email}
                      onChange={on_change}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 m-0 p-0">
                    <label htmlFor="dog" className="m-0 font-weight-bold mt-2">
                      Dog:
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <input
                      type="text"
                      className="form-control"
                      id="dog"
                      onChange={on_change}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 m-0 p-0">
                    <label
                      htmlFor="Breed"
                      className="m-0 font-weight-bold mt-2"
                    >
                      Breed:
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <input
                      type="text"
                      className="form-control"
                      id="Breed"
                      onChange={on_change}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 m-0 p-0">
                    <label
                      htmlFor="Description"
                      className="m-0 font-weight-bold mt-2"
                    >
                      Color:
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <input
                      type="text"
                      className="form-control"
                      id="Description"
                      onChange={on_change}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-5">
                <div className="row">
                  <div className="col-lg-3 m-0 p-0">
                    <label htmlFor="Sex" className="m-0 font-weight-bold mt-2">
                      Sex of Dog:
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <input
                      type="text"
                      className="form-control"
                      id="Sex"
                      onChange={on_change}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 m-0 p-0">
                    <label
                      htmlFor="BirthDay"
                      className="m-0 font-weight-bold mt-2"
                    >
                      Birthday/Age:
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <input
                      type="text"
                      className="form-control"
                      id="BirthDay"
                      onChange={on_change}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 m-0 p-0">
                    <label
                      htmlFor="pprSignature"
                      className={
                        !signorValid
                          ? 'color-danger text-danger m-0 font-weight-bold mt-2'
                          : ' m-0 font-weight-bold mt-2'
                      }
                    >
                      PPR Signature:
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <input
                      type="text"
                      className={
                        !signorValid
                          ? 'color-danger text-danger border-danger font-weight-bold form-control'
                          : 'form-control'
                      }
                      id="pprSignature"
                      onChange={on_change}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 m-0 p-0"></div>
                  <div className="col-lg-9 m-0 p-0">
                    <div className={signorValid ? 'd-none' : 'text-danger'}>
                      REQUIRED
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 m-0 p-0">
                    <label
                      htmlFor="treatedHw"
                      className={
                        !hwValid
                          ? 'color-danger text-danger m-0 font-weight-bold mt-2'
                          : ' m-0 font-weight-bold mt-2'
                      }
                    >
                      Treated HW:
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <select
                      className={
                        !hwValid
                          ? 'custom-select border-danger color-danger text-danger'
                          : 'custom-select'
                      }
                      id="treatedHw"
                      onChange={on_change}
                    >
                      <option value="">Choose One</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                    <div className={hwValid ? 'd-none' : 'text-danger'}>
                      REQUIRED
                    </div>
                  </div>
                  <div className="col-lg-3 m-0 p-0">
                    <label
                      htmlFor="Altered"
                      className={
                        !alteredValid
                          ? 'color-danger text-danger m-0 font-weight-bold mt-2'
                          : ' m-0 font-weight-bold mt-2'
                      }
                    >
                      Altered:
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <select
                      className={
                        !alteredValid
                          ? 'custom-select border-danger color-danger text-danger'
                          : 'custom-select'
                      }
                      id="Altered"
                      onChange={on_change}
                      required
                    >
                      <option value="">Choose One</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                    <div className={alteredValid ? 'd-none' : 'text-danger'}>
                      REQUIRED
                    </div>
                  </div>
                </div>
                {contractData.Altered === 'no' && (
                  <>
                    <div className="row">
                      <div className="col-lg-3 m-0 p-0">
                        <label
                          htmlFor="AlteredRefund"
                          className="m-0 font-weight-bold mt-2"
                        >
                          Refund Amt:
                        </label>
                      </div>
                      <div className="col-lg-9 m-0 p-0">
                        <input
                          type="text"
                          className="form-control"
                          id="AlteredRefund"
                          defaultValue={contractData.AlteredRefund}
                          onChange={on_change}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 m-0 p-0">
                        <label
                          htmlFor="AlteredDueDate"
                          className="m-0 font-weight-bold mt-2"
                        >
                          S/N Due Date:
                        </label>
                      </div>
                      <div className="col-lg-9 m-0 p-0">
                        <input
                          type="date"
                          className="form-control"
                          id="AlteredDueDate"
                          onChange={on_change}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="row">
                  <div className="col-lg-3 m-0 p-0">
                    <label htmlFor="Fee" className="m-0 font-weight-bold mt-2">
                      Fee:
                    </label>
                  </div>
                  <div className="col-lg-9 m-0 p-0">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue="$300.00"
                      id="Fee"
                      onChange={on_change}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="row">
          <div className="col-lg-7"></div>
          <div className="col-lg-5">
            <button className="btn btn-warning mr-1" onClick={sendViaESign}>
              SEND VIA E-SIGN
            </button>
            <button className="btn btn-secondary" onClick={createContract}>
              CREATE CONTRACT
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal fade show"
        id="eSignModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropESignLabel"
        style={{ display: 'none' }}
      >
        <div className="modal-dialog gt-modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropESignLabel">
                SEND AGREEMENT ELECTRONICALLY
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label
                    className="active font-weight-bolder"
                    htmlFor="recipient_name"
                  >
                    ADOPTER'S Name:
                  </label>
                  <div className="col-md-10">
                    <input
                      id="recipient_name"
                      type="text"
                      className="form-control"
                      defaultValue={eSignData.recipient_name}
                      onChange={handleESignChange}
                      maxLength="50"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label
                    className="active font-weight-bolder"
                    htmlFor="recipient_email"
                  >
                    ADOPTER'S EMAIL ADDRESS:
                  </label>
                  <div className="col-md-10">
                    <input
                      id="recipient_email"
                      type="text"
                      className="form-control"
                      defaultValue={eSignData.recipient_email}
                      onChange={handleESignChange}
                      maxLength="50"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label
                    className="active font-weight-bolder"
                    htmlFor="foster_email"
                  >
                    FOSTER: (Agreement View & Notification Only)
                  </label>
                  <div className="col-md-10">
                    <select
                      id="foster_id"
                      type="text"
                      className="form-control"
                      onChange={handleFosterSelection}
                      maxLength="50"
                    >
                      <option value="">Not Selected</option>
                      {fostersList !== null
                        ? fostersList.map((foster) => (
                            <option value={foster._id} key={foster._id}>
                              {foster.First_Name} {foster.Last_Name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="active font-weight-bolder" htmlFor="notes">
                    NOTE:
                  </label>
                  <div className="col-md-10">
                    <textarea
                      id="notes"
                      type="textfield"
                      className="form-control"
                      value={eSignData.notes}
                      onChange={handleESignChange}
                      rows={4}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={closeModal}
              >
                CANCEL
              </button>
              <button
                type="button"
                onClick={orderESign}
                className="btn btn-background"
                data-dismiss="modal"
              >
                SEND
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade show"
        id="backdrop"
        style={{ display: 'none' }}
      ></div>
    </>
  );
};
export default AdminAppContractGenerator;
