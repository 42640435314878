import React, { Fragment, useContext, useEffect } from 'react';
import BlogContext from '../../context/blog/blogContext';
import AdminBlogList from '../layout/AdminBlogList';

const AdminBlog = props => {
  const blogContext = useContext(BlogContext);
  const { newBlog } = props;
  const { blogs, getBlogs, loading } = blogContext;

  useEffect(() => {
    getBlogs();
    // eslint-disable-next-line
  }, []);

  const addBlog = () => {
    newBlog();
  };

  return (
    <Fragment>
      <div className="container">
        <h2 className="text-center">BLOG LIST</h2>
        <div className="text-right">
          <button className="btn btn-dark" onClick={addBlog}>
            Create Blog
          </button>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Creation Date</th>
              <th scope="col">Title</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {blogs !== null && !loading ? (
              blogs.map(blog => <AdminBlogList blog={blog} key={blog._id} />)
            ) : (
              <tr>
                <td>Loading</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default AdminBlog;
