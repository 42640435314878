import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import BlogContext from '../../context/blog/blogContext';
import AdminContext from '../../context/admin/adminContext';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return new Date(string).toLocaleDateString([], options);
}

const AdminBlogList = ({ blog }) => {
  const blogContext = useContext(BlogContext);
  const adminContext = useContext(AdminContext);
  const { blogPage, blogEditPage } = adminContext;
  const { deleteBlog, loadBlog } = blogContext;
  const { _id, title, post_date } = blog;

  const formatedDate = formatDate(post_date);

  const deleteTheBlog = (e) => {
    deleteBlog(_id);
    setTimeout(() => {
      blogPage();
    }, 500);
  };

  const editTheBlog = (e) => {
    blogEditPage(_id);
    loadBlog(blog);
  };

  return (
    <tr>
      <td>{formatedDate}</td>
      <td>{title}</td>
      <td className="text-right">
        <i
          className="fa fa-pencil mr-1 text-warning pointer"
          title="Edit Blog"
          onClick={editTheBlog}
          aria-hidden="true"
        ></i>
        <i
          className="fa fa-trash text-danger pointer"
          title="Delete Blog"
          onClick={deleteTheBlog}
          aria-hidden="true"
        ></i>
      </td>
    </tr>
  );
};

AdminBlogList.propTypes = {
  blog: PropTypes.object.isRequired,
};
export default AdminBlogList;
