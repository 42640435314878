import React, { Fragment, useEffect, useContext, useState } from 'react';
import AppContext from '../../../context/app/appContext';
import NotesContext from '../../../context/notes/notesContext';
import UserContext from '../../../context/user/userContext';
import EmailContext from '../../../context/email/emailContext';
import AlertContext from '../../../context/alert/alertContext';

const AdminAppProfile = () => {
  const appContext = useContext(AppContext);
  const notesContext = useContext(NotesContext);
  const userContext = useContext(UserContext);
  const emailContext = useContext(EmailContext);
  const alertContext = useContext(AlertContext);
  const { loading, app, updateApp } = appContext;
  const { notes } = notesContext;
  const { sendNotifyEmail } = emailContext;
  const { setAlert } = alertContext;
  const { users, getUser, getUsers, current } = userContext;
  const [appUpdates, setAppUpdates] = useState({});
  const [editMode, setEditMode] = useState({
    Current_Status: false,
    CoApp_Full_Name: 'readOnly',
    Primary_Caregiver: 'readOnly',
    Home: 'readOnly',
    Mobile: 'readOnly',
    Work: 'readOnly',
    Email: 'readOnly',
    Street: 'readOnly',
    City: 'readOnly',
    State: 'readOnly',
    Zip_Code: 'readOnly',
    County: 'readOnly',
    Past_Addresses: 'readOnly',
    Vet_Name: 'readOnly',
    Vet_Number: 'readOnly',
    Landlord_Name: 'readOnly',
    Landlord_Phone: 'readOnly',
    Reference_1: 'readOnly',
    Reference_1_Phone: 'readOnly',
    Reference_2: 'readOnly',
    Reference_2_Phone: 'readOnly',
    st_Choice: 'readOnly',
    nd_Choice: 'readOnly',
    Future_Dog2: 'readOnly',
  });
  const [displayEdit, setDisplayEdit] = useState({
    CoApp_Full_Name: false,
    Primary_Caregiver: false,
    Home: false,
    Mobile: false,
    Work: false,
    Email: false,
    Street: false,
    City: false,
    State: false,
    Zip_Code: false,
    County: false,
    Past_Addresses: false,
    Vet_Name: false,
    Vet_Number: false,
    Landlord_Name: false,
    Landlord_Phone: false,
    Reference_1: false,
    Reference_1_Phone: false,
    Reference_2: false,
    Reference_2_Phone: false,
    st_Choice: false,
    nd_Choice: false,
    Future_Dog2: false,
  });
  const [editModeIcons, setEditModeIcons] = useState({
    CoApp_Full_Name: false,
    Primary_Caregiver: false,
    Home: false,
    Mobile: false,
    Work: false,
    Email: false,
    Street: false,
    City: false,
    State: false,
    Zip_Code: false,
    County: false,
    Past_Addresses: false,
    Vet_Name: false,
    Vet_Number: false,
    Landlord_Name: false,
    Landlord_Phone: false,
    Reference_1: false,
    Reference_1_Phone: false,
    Reference_2: false,
    Reference_2_Phone: false,
    st_Choice: false,
    nd_Choice: false,
    Future_Dog2: false,
  });
  const [preEditData, setPreEditData] = useState({});
  const [processor, setProcessor] = useState({
    First_Name: '',
    Last_Name: '',
    UserName: '',
  });
  const [appOwner, setAppOwner] = useState({
    First_Name: '',
    Last_Name: '',
    Username: '',
    _id: '',
  });
  const [appStatus, setAppStatus] = useState('');
  const applicationStatusOptions = [
    'New Application',
    'Processing',
    'Email App Requested',
    'Screened - On Hold',
    'Approved - Pending Adoption',
    'Approved - No Adopt',
    'Approved - On Hold',
    'Adopted - F/U on S/N',
    'Screened - No Adopt',
    'Stop Processing',
    'Approved - Foster',
    'Adopted',
    'Adopted - Returned',
    'To Be Deleted',
  ];

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (app !== null && !loading) {
      getUser(app.Owner_Id);
    }
    // eslint-disable-next-line
  }, [app]);

  useEffect(() => {
    if (current !== null) {
      setProcessor({
        First_Name: current.First_Name,
        Last_Name: current.Last_Name,
        Username: current.Username,
      });
    }
  }, [current]);

  useEffect(() => {
    if (app !== null && users !== undefined) {
      const ownerList = users.filter((user) => {
        return user._id !== app.Owner_Id;
      });
      setAppOwner(ownerList);
      const statusList = applicationStatusOptions.filter((status) => {
        return status !== app.Application_Status;
      });
      setAppStatus(statusList);
      setAppUpdates(app);
    }
    // eslint-disable-next-line
  }, [users, app]);

  const updateAppOwner = (e) => {
    const ownerUpdate = {
      Owner_Id: e.target.value,
    };
    updateApp(app._id, ownerUpdate);

    const processorUpdate = users.filter((user) => {
      return user._id === parseInt(e.target.value);
    });
    setProcessor({
      First_Name: processorUpdate[0].First_Name,
      Last_Name: processorUpdate[0].Last_Name,
      Username: processorUpdate[0].Username,
    });
  };

  const updateAppStatus = (e) => {
    const openStatus = ['New Application', 'Processing'];
    const pendingStatus = [
      'Approved - Pending Adoption',
      'Screened - On Hold',
      'Adopted - F/U on S/N',
      'Approved - On Hold',
    ];
    if (
      e.target.value === 'Adopted' ||
      e.target.value === 'Adopted - F/U on S/N'
    ) {
      alert(
        'PLEASE ADD ADOPTION RECORD TO APPLICATION - Click Adoption Link from Menu'
      );
    }

    if (openStatus.indexOf(e.target.value) !== -1) {
      const statusUpdate = {
        Application_Status: e.target.value,
        Application_Current_Status: 'open',
      };
      updateApp(app._id, statusUpdate);
    } else if (pendingStatus.indexOf(e.target.value) !== -1) {
      const statusUpdate = {
        Application_Status: e.target.value,
        Application_Current_Status: 'pending',
      };
      updateApp(app._id, statusUpdate);
    } else if (e.target.value === 'Stop Processing') {
      if (notes.length > 0) {
        const statusUpdate = {
          Application_Status: e.target.value,
          Application_Current_Status: 'closed',
        };
        updateApp(app._id, statusUpdate);
      } else {
        setAlert(
          'PLEASE ADD NOTE EXPLAINING THE REASON FOR STOP PROCESSING',
          'danger'
        );
        document.getElementById('Application_Status').value =
          app.Application_Status;
      }
    } else {
      const statusUpdate = {
        Application_Status: e.target.value,
        Application_Current_Status: 'closed',
      };
      updateApp(app._id, statusUpdate);
    }
  };

  const notifyUser = () => {
    if (processor.Username === 'gt@abttelecom.com') {
      window.scroll(0, 0);
      setAlert('Must Choose a Processor. Cannot be UnAssigned', 'danger');
    } else {
      const data = {
        recipient_to: processor.Username,
        email_subject: `A PPR Adoption Application has been Assigned to you`,
        email_body: `
      ${processor.First_Name}, <br>
      <br>

      An Adoption Application has been assigned to you:<br>
<br>
      Application ID: ${app._id}<br>
      Applicant Name: ${app.First_Name} ${app.Last_Name}<br>
<br>
      Go to: https://portal.puppypawsRescue.org/admin
      `,
        email_appId: app._id,
        email_sender: 'admin@puppypawsrescue.org',
      };
      sendNotifyEmail(data);
      setAlert('Notification Sent', 'success');
    }
  };

  // **************************** EDIT MODULE **********************************
  const resetEditMode = () => {
    setEditMode({
      Current_Status: false,
      CoApp_Full_Name: 'readOnly',
      Primary_Caregiver: 'readOnly',
      Home: 'readOnly',
      Mobile: 'readOnly',
      Work: 'readOnly',
      Email: 'readOnly',
      Street: 'readOnly',
      City: 'readOnly',
      State: 'readOnly',
      Zip_Code: 'readOnly',
      County: 'readOnly',
      Past_Addresses: 'readOnly',
      Vet_Name: 'readOnly',
      Vet_Number: 'readOnly',
      Landlord_Name: 'readOnly',
      Landlord_Phone: 'readOnly',
      Reference_1: 'readOnly',
      Reference_1_Phone: 'readOnly',
      Reference_2: 'readOnly',
      Reference_2_Phone: 'readOnly',
      st_Choice: 'readOnly',
      nd_Choice: 'readOnly',
      Future_Dog2: 'readOnly',
    });
  };

  const editApp = (e) => {
    setAppUpdates({ ...appUpdates, [e.target.id]: e.target.value });
  };

  const toggleEditMode = (e) => {
    if (!editMode.Current_Status) {
      setPreEditData({ ...preEditData, [e]: appUpdates[e] });
      setEditModeIcons({ ...editModeIcons, [e]: true });
      setDisplayEdit({ ...displayEdit, [e]: false });
      setEditMode({ ...editMode, [e]: '', Current_Status: true });
    }
  };

  const resetInputOnCancel = (e) => {
    document.getElementById(e).value = preEditData[e];
  };

  const cancelEditMode = (e) => {
    resetInputOnCancel(e);
    setAppUpdates({ ...appUpdates, [e]: preEditData[e] });
    resetEditMode();
    setEditModeIcons(false);
    setDisplayEdit(false);
  };

  const submitEdits = (e) => {
    updateApp(app._id, appUpdates);
    resetEditMode();
    setEditModeIcons(false);
    setDisplayEdit(false);
  };

  const enterEdits = (e) => {
    if (e.key === 'Enter') {
      updateApp(app._id, appUpdates);
      resetEditMode();
      setEditModeIcons(false);
      setDisplayEdit(false);
    }
  };

  // ****************************** End of Edit Module *********************************************

  return (
    <Fragment>
      {app !== null && !loading && appUpdates !== null ? (
        <div className="container-fluid bg-container p-2 mb-5">
          <div className="row">
            <div className="col-lg-6">
              <div className="card border border-grey">
                <table className="table">
                  <tbody>
                    <tr className="bg-theme text-light">
                      <th>APPLICANT'S INFORMATION</th>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">PRIMARY APPLICANT</th>
                      <td>
                        <div
                          data-toggle="modal"
                          data-target="#staticBackdrop"
                          className="pl-1 pointer"
                        >
                          {app.First_Name} {app.Middle_Initial} {app.Last_Name}{' '}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">CO-APPLICANT</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.CoApp_Full_Name}
                          id="CoApp_Full_Name"
                          defaultValue={app.CoApp_Full_Name}
                          onClick={() => toggleEditMode('CoApp_Full_Name')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.CoApp_Full_Name ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('CoApp_Full_Name')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="CoApp_Full_Name"
                                value={preEditData.CoApp_Full_Name}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() =>
                                  cancelEditMode('CoApp_Full_Name')
                                }
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">PRIMARY CAREGIVER</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Primary_Caregiver}
                          id="Primary_Caregiver"
                          defaultValue={app.Primary_Caregiver}
                          onClick={() => toggleEditMode('Primary_Caregiver')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>
                        {editModeIcons.Primary_Caregiver ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('CoApp_Full_Name')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Primary_Caregiver"
                                value={preEditData.Primary_Caregiver}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() =>
                                  cancelEditMode('Primary_Caregiver')
                                }
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <table className="table table-hover mb-0 ">
                  <tbody>
                    <tr className="bg-theme text-light">
                      <th scope="row">APPLICATION ID</th>
                      <td>{app._id}</td>
                    </tr>
                    <tr>
                      <th scope="row">SUBMISSION DATE</th>
                      <td>{app.submit_date}</td>
                    </tr>
                    <tr>
                      <th scope="row">APPLICATION STATUS</th>
                      <td>
                        <select
                          className="form-control"
                          onChange={updateAppStatus}
                          id="Application_Status"
                        >
                          <option value={app.Application_Status}>
                            {app.Application_Status}
                          </option>
                          {appStatus !== '' ? (
                            appStatus.map((app) => (
                              <option key={app} value={app}>
                                {app}
                              </option>
                            ))
                          ) : (
                            <option>...Loading</option>
                          )}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row pb-0">
                        PROCESSOR
                        <button
                          className="btn btn-success btn-sm ml-3"
                          onClick={notifyUser}
                        >
                          NOTIFY
                        </button>
                      </th>
                      <td>
                        <select
                          className="form-control"
                          onChange={updateAppOwner}
                          id="Application_Owner"
                        >
                          <option value={app.Owner_Id}>
                            {processor.First_Name} {processor.Last_Name}
                          </option>
                          {appOwner._id !== '' ? (
                            appOwner.map((user) => (
                              <option key={user._id} value={user._id}>
                                {user.First_Name} {user.Last_Name}
                              </option>
                            ))
                          ) : (
                            <option>loading</option>
                          )}
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card border border-grey mt-3">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  CONTACT INFORMATION
                </h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">HOME PHONE</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Phone}
                          id="Phone"
                          defaultValue={app.Phone}
                          onClick={() => toggleEditMode('Phone')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Phone ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Phone')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Phone"
                                value={preEditData.Phone}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Phone')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">MOBILE PHONE</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Mobile}
                          id="Mobile"
                          defaultValue={app.Mobile}
                          onClick={() => toggleEditMode('Mobile')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Mobile ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Mobile')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Mobile"
                                value={preEditData.Mobile}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Mobile')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">WORK PHONE</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Work}
                          id="Work"
                          defaultValue={app.Work}
                          onClick={() => toggleEditMode('Work')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Work ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Work')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Work"
                                value={preEditData.Work}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Work')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">EMAIL ADDRESS</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Email}
                          id="Email"
                          defaultValue={app.Email}
                          onClick={() => toggleEditMode('Email')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Email ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Email')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Email"
                                value={preEditData.Email}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Email')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card border border-grey mt-3">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  INTEREST
                </h4>
                <table className="table table-hover ">
                  <tbody>
                    <tr>
                      <th scope="row">FIRST CHOICE</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.st_Choice}
                          id="st_Choice"
                          defaultValue={app.st_Choice}
                          onClick={() => toggleEditMode('st_Choice')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.st_Choice ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('st_Choice')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="st_Choice"
                                value={preEditData.st_Choice}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('st_Choice')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                      <th scope="row">SECOND CHOICE</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.nd_Choice}
                          id="nd_Choice"
                          defaultValue={app.nd_Choice}
                          onClick={() => toggleEditMode('nd_Choice')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.nd_Choice ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('nd_Choice')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="nd_Choice"
                                value={preEditData.nd_Choice}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('nd_Choice')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover ">
                  <tbody>
                    <tr>
                      <th scope="row">FUTURE</th>
                      <td>
                        <textarea
                          className="col-lg-12 p-1 pointer"
                          type="text"
                          readOnly={editMode.Future_Dog2}
                          id="Future_Dog2"
                          defaultValue={app.Future_Dog2}
                          onClick={() => toggleEditMode('Future_Dog2')}
                          onChange={editApp}
                          rows="3"
                        ></textarea>

                        {editModeIcons.Future_Dog2 ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Future_Dog2')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Future_Dog2"
                                value={preEditData.Future_Dog2}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Future_Dog2')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="card border border-grey mt-2">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  ADDRESS
                </h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">STREET</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Street}
                          id="Street"
                          defaultValue={app.Street}
                          onClick={() => toggleEditMode('Street')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Street ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Street')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Street"
                                value={preEditData.Street}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Street')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">CITY</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.City}
                          id="City"
                          defaultValue={app.City}
                          onClick={() => toggleEditMode('City')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.City ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('City')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="City"
                                value={preEditData.City}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('City')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">STATE, ZIP CODE</th>
                      <td
                        data-toggle="modal"
                        data-target="#staticBackdrop2"
                        className="pl-1"
                      >
                        {app.State}, {app.Zip_Code}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">COUNTY</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.County}
                          id="County"
                          defaultValue={app.County}
                          onClick={() => toggleEditMode('County')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.County ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('County')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="County"
                                value={preEditData.County}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('County')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">PAST ADDRESSES</th>
                      <td>
                        <textarea
                          className="noborder"
                          type="text"
                          readOnly={editMode.Past_Addresses}
                          id="Past_Addresses"
                          defaultValue={app.Past_Addresses}
                          onClick={() => toggleEditMode('Past_Addresses')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                          rows="3"
                        ></textarea>

                        {editModeIcons.Past_Addresses ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Past_Addresses')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Past_Addresses"
                                value={preEditData.Past_Addresses}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Past_Addresses')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card border border-grey mt-2">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  REFERENCE CONTACTS
                </h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">LANDLORD NAME</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Landlord_Name}
                          id="Landlord_Name"
                          defaultValue={app.Landlord_Name}
                          onClick={() => toggleEditMode('Landlord_Name')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Landlord_Name ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Landlord_Name')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Landlord_Name"
                                value={preEditData.Landlord_Name}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Landlord_Name')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                      <th scope="row">PHONE</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Landlord_Phone}
                          id="Landlord_Phone"
                          defaultValue={app.Landlord_Phone}
                          onClick={() => toggleEditMode('Landlord_Phone')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Landlord_Phone ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Landlord_Phone')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Landlord_Phone"
                                value={preEditData.Landlord_Phone}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Landlord_Phone')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">REFERENCE NAME</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Reference_1}
                          id="Reference_1"
                          defaultValue={app.Reference_1}
                          onClick={() => toggleEditMode('Reference_1')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Reference_1 ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Reference_1')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Reference_1"
                                value={preEditData.Reference_1}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Reference_1')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                      <th scope="row">PHONE</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Reference_1_Phone}
                          id="Reference_1_Phone"
                          defaultValue={app.Reference_1_Phone}
                          onClick={() => toggleEditMode('Reference_1_Phone')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Reference_1_Phone ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Reference_1_Phone')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Reference_1_Phone"
                                value={preEditData.Reference_1_Phone}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() =>
                                  cancelEditMode('Reference_1_Phone')
                                }
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">REFERENCE NAME</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Reference_2}
                          id="Reference_2"
                          defaultValue={app.Reference_2}
                          onClick={() => toggleEditMode('Reference_2')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Reference_2 ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Reference_2')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Reference_2"
                                value={preEditData.Reference_2}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Reference_2')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                      <th scope="row">PHONE</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Reference_2_Phone}
                          id="Reference_2_Phone"
                          defaultValue={app.Reference_2_Phone}
                          onClick={() => toggleEditMode('Reference_2_Phone')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Reference_2_Phone ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Reference_2_Phone')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Reference_2_Phone"
                                value={preEditData.Reference_2_Phone}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() =>
                                  cancelEditMode('Reference_2_Phone')
                                }
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">PRIMARY VET</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Vet_Name}
                          id="Vet_Name"
                          defaultValue={app.Vet_Name}
                          onClick={() => toggleEditMode('Vet_Name')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Vet_Name ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Vet_Name')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Vet_Name"
                                value={preEditData.Vet_Name}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Vet_Name')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                      <th scope="row">PHONE</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Vet_Number}
                          id="Vet_Number"
                          defaultValue={app.Vet_Number}
                          onClick={() => toggleEditMode('Vet_Number')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Vet_Number ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Vet_Number')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Vet_Number"
                                value={preEditData.Vet_Number}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Vet_Number')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">OTHER VET INFORMATION</th>
                      <td>
                        <input
                          className="noborder"
                          type="text"
                          readOnly={editMode.Other_Vet}
                          id="Other_Vet"
                          defaultValue={app.Other_Vet}
                          onClick={() => toggleEditMode('Other_Vet')}
                          onChange={editApp}
                          onKeyPress={enterEdits}
                        ></input>

                        {editModeIcons.Other_Vet ? (
                          <span className="text-success text-right ml-3">
                            <i
                              className="fa fa-check"
                              aria-hidden="true"
                              onClick={() => submitEdits('Other_Vet')}
                            ></i>
                            <span className="text-danger ml-2">
                              <i
                                id="Other_Vet"
                                value={preEditData.Other_Vet}
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => cancelEditMode('Other_Vet')}
                              ></i>
                            </span>
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* *********** EDIT APPLICANT NAME MODAL ************* */}

          <div
            className="modal fade"
            id="staticBackdrop"
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    APPLICANT NAME UPDATE
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container">
                    <form>
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="First_Name">FIRST NAME</label>
                        </div>
                        <div className="row">
                          <input
                            className="form-control"
                            type="text"
                            id="First_Name"
                            defaultValue={app.First_Name}
                            onChange={editApp}
                          ></input>
                        </div>
                        <div className="row">
                          <label htmlFor="Middle_Initial">MIDDLE NAME</label>
                        </div>
                        <div className="row">
                          <input
                            className="form-control"
                            type="text"
                            id="Middle_Initial"
                            defaultValue={app.Middle_Initial}
                            onChange={editApp}
                          ></input>
                        </div>
                        <div className="row">
                          <label htmlFor="Last_Name">LAST NAME</label>
                        </div>
                        <div className="row">
                          <input
                            className="form-control"
                            type="text"
                            id="Last_Name"
                            defaultValue={app.Last_Name}
                            onChange={editApp}
                          ></input>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      CANCEL
                    </button>
                    <button
                      type="button"
                      onClick={submitEdits}
                      className="btn btn-background"
                      data-dismiss="modal"
                    >
                      UPDATE NAME
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* *********** EDIT STATE / ZIP CODE MODAL ************* */}

          <div
            className="modal fade"
            id="staticBackdrop2"
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="staticBackdropLabel2"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel2">
                    STATE/ZIP CODE UPDATE
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container">
                    <form>
                      <div className="form-group">
                        <div className="row">
                          <label htmlFor="State">STATE</label>
                        </div>
                        <div className="row">
                          <input
                            className="form-control"
                            type="text"
                            id="State"
                            defaultValue={app.State}
                            onChange={editApp}
                          ></input>
                        </div>
                        <div className="row">
                          <label htmlFor="Zip_Code">ZIP CODE</label>
                        </div>
                        <div className="row">
                          <input
                            className="form-control"
                            type="text"
                            id="Zip_Code"
                            defaultValue={app.Zip_Code}
                            onChange={editApp}
                          ></input>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      CANCEL
                    </button>
                    <button
                      type="button"
                      onClick={submitEdits}
                      className="btn btn-background"
                      data-dismiss="modal"
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h4>Loading</h4>
      )}
    </Fragment>
  );
};

export default AdminAppProfile;
