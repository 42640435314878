import React, { useContext, useEffect } from 'react';
import MedicationListContext from '../../../context/medication_list/medicationListContext';
import MedicationFullList from './MedicationListFullList';

const MedicationList = () => {
  const { getMeds, meds, med } = useContext(MedicationListContext);

  useEffect(() => {
    getMeds();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <MedicationFullList meds={meds} med={med} />
    </div>
  );
};

export default MedicationList;
