import React, { Fragment, useState, useContext, useEffect } from 'react';
import AlertContext from '../../context/alert/alertContext';
import BlogContext from '../../context/blog/blogContext';
import BlogTextEditor from '../../utils/BlogTextEditor';

const FormBlogEntry = (props) => {
  const alertContext = useContext(AlertContext);
  const blogContext = useContext(BlogContext);
  const { setAlert } = alertContext;
  const { createBlog, current } = blogContext;
  const { cancelBlogEntry } = props;
  const [blogBody, setBlogBody] = useState('');

  const [blog, setBlog] = useState({
    title: '',
    post: '',
    author: '',
  });

  useEffect(() => {
    if (current !== null) {
      setBlog(current);
    } else {
      setBlog({
        title: '',
        post: '',
        author: '',
      });
    }
  }, [blogContext, setBlog, current]);

  useEffect(() => {
    setBlog({ ...blog, post: blogBody });
    // eslint-disable-next-line
  }, [blogBody]);

  const { title, post, author } = blog;

  const on_change = (e) => {
    setBlog({ ...blog, [e.target.id]: e.target.value });
  };

  const CreateBlog = (e) => {
    e.preventDefault();
    if (title === '' || post === '' || author === '') {
      setAlert('MISSING DATA - ALL FIELDS ARE REQUIRED', 'danger');
      window.scrollTo(0, 0);
    } else {
      createBlog(blog);
      setAlert('Blog Added Successfully', 'success');
      setTimeout(() => {
        cancelBlogEntry();
      }, 500);
    }
  };
  const cancelEdit = () => {
    cancelBlogEntry();
  };

  return (
    <Fragment>
      <div className="container">
        <h2 className="text-center">BLOG ENTRY</h2>
        <form>
          <div className="form-group">
            <label htmlFor="title">
              <h5>TITLE</h5>
            </label>
            <input
              id="title"
              type="text"
              className="form-control"
              value={title}
              onChange={on_change}
            />
          </div>
          <div className="form-group">
            <label htmlFor="author">
              <h5>AUTHOR</h5>
            </label>
            <input
              id="author"
              type="text"
              className="form-control"
              value={author}
              onChange={on_change}
            />
          </div>
          <div className="form-group">
            <label className="active" htmlFor="post">
              <h5>BLOG POST</h5>
            </label>
            <BlogTextEditor textData={blogBody} setTextData={setBlogBody} />
          </div>

          {/* ****************BUTTONS ARE BELOW HERE*********** */}
          <div className="container">
            <div className="row">
              <button
                type="button"
                className="btn btn-warning col-lg-2 mt-3 mb-3"
                onClick={cancelEdit}
              >
                <i className="fa fa-arrow-left p" aria-hidden="true" />
                CANCEL
              </button>
              <div className="col-lg-8"></div>
              <button
                type="button"
                className="btn btn-primary col-lg-2 mt-3 mb-3"
                onClick={CreateBlog}
              >
                SAVE BLOG
                <i className="fa fa-arrow-right p" aria-hidden="true" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default FormBlogEntry;
