import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import AdminContext from '../../context/admin/adminContext';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return new Date(string).toLocaleDateString([], options);
}

const AdminUsersList = (props) => {
  const adminContext = useContext(AdminContext);
  const { userList, userProfilePage } = adminContext;
  const { _id, First_Name, Last_Name, Username, Role, createdAt } = props.user;
  const { currentUser, removeUser } = props;

  const formatedDate = formatDate(createdAt);

  const deleteTheUser = (e) => {
    removeUser(_id);
    setTimeout(() => {
      userList();
    }, 500);
  };

  const editTheUser = (e) => {
    userProfilePage(_id);
  };

  return (
    <tr>
      <td>
        {First_Name} {Last_Name}
      </td>
      <td className="d-none d-md-table-cell">{Username}</td>
      <td>{Role}</td>
      <td className="d-none d-md-table-cell">{formatedDate}</td>
      <td className="text-right">
        {currentUser.Role === 'Admin' ? (
          <Fragment>
            <i
              className="fa fa-pencil mr-4 text-warning pointer"
              title="Edit User"
              onClick={editTheUser}
              aria-hidden="true"
            ></i>

            <i
              className="fa fa-trash text-danger pointer"
              title="Delete User"
              onClick={deleteTheUser}
              data-toggle="modal"
              data-target="#userDeleteConfirmationModal"
              aria-hidden="true"
            ></i>
          </Fragment>
        ) : (
          <i
            className="fa fa-pencil mr-1 text-dark"
            title="Edit User"
            onClick={editTheUser}
            aria-hidden="true"
          ></i>
        )}
      </td>
    </tr>
  );
};

AdminUsersList.propTypes = {
  user: PropTypes.object.isRequired,
};
export default AdminUsersList;
