import {
  SUBMIT_APP,
  SUBMIT_FAPP,
  UPDATE_APP,
  SET_CURRENT,
  PREV_PAGE,
  NEXT_PAGE,
  GO_HOME,
  GET_APPS,
  GET_APP,
  APP_TAB,
  DELETE_APP,
  CHECK_CAPTCHA,
  MANUAL_SUBMIT_APP,
  GET_STATS,
  GET_OPEN_APPS,
  RESET_APP_STATE,
  CLEAR_APP_STATE,
} from '../types';

const appReducer = (state, action) => {
  switch (action.type) {
    case SUBMIT_APP:
      return {
        ...state,
        app: action.payload,
        appsLoading: false,
        submitComplete: true,
      };
    case UPDATE_APP:
      return {
        ...state,
        app: action.payload,
      };
    case SUBMIT_FAPP:
      return {
        ...state,
        app: action.payload,
        appsLoading: false,
      };
    case GET_STATS:
      return {
        ...state,
        newApps: action.payload.newApps,
        processing: action.payload.processing,
        approved: action.payload.approved,
        screened: action.payload.screened,
        adoptions: action.payload.adoptions,
        submissions: action.payload.submissions,
        holding: action.payload.holding,
      };
    case MANUAL_SUBMIT_APP:
      return {
        ...state,
        apps: [action.payload, ...state.apps],
      };
    case GET_APPS:
      return {
        ...state,
        apps: action.payload,
        app: null,
        appsLoading: false,
      };

    case GET_OPEN_APPS:
      return {
        ...state,
        apps: action.payload,
        openApps: action.payload,
        openAppsloading: false,
      };
    case DELETE_APP:
      return {
        ...state,
        apps: state.apps.filter((app) => app._id !== action.payload),
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case NEXT_PAGE:
      return {
        ...state,
        step: state.step + 1,
      };
    case PREV_PAGE:
      return {
        ...state,
        step: state.step - 1,
      };
    case GO_HOME:
      return {
        ...state,
        step: 99,
      };
    case GET_APP:
      return {
        ...state,
        app: action.payload,
        appPageID: 'profile',
        appsLoading: false,
      };
    case APP_TAB:
      return {
        ...state,
        appPageID: action.payload,
      };
    case RESET_APP_STATE:
      return {
        app: null,
        apps: null,
      };
    case CLEAR_APP_STATE:
      return {
        app: null,
      };
    case CHECK_CAPTCHA:
      return {
        ...state,
        isVerified: action.payload,
        // isVerified: true
      };

    default:
      return state;
  }
};

export default appReducer;
