import {
  DELETE_MEETING_NOTE,
  GET_MEETING_NOTES,
  CREATE_MEETING_NOTE,
  GET_MEETING_NOTE,
  CLEAR_MEETING_NOTES,
  UPDATE_MEETING_NOTE,
} from '../types';

const meetingReducer = (state, action) => {
  switch (action.type) {
    case GET_MEETING_NOTES:
      return {
        ...state,
        mnotes: action.payload,
      };
    case GET_MEETING_NOTE:
      return {
        ...state,
        mnote: action.payload,
      };
    case DELETE_MEETING_NOTE:
      return {
        ...state,
        mnotes: state.mnotes.filter(
          (mnote) => mnote._id !== parseInt(action.payload)
        ),
      };
    case UPDATE_MEETING_NOTE:
      return {
        ...state,
        mnotes: [
          action.payload,
          ...state.mnotes.filter((mnote) => mnote._id !== action.payload._id),
        ],
      };
    case CREATE_MEETING_NOTE:
      return {
        ...state,
        mnotes: [action.payload, ...state.mnotes],
      };
    case CLEAR_MEETING_NOTES:
      return {
        ...state,
        mnotes: null,
        mnote: null,
      };
    default:
      return state;
  }
};

export default meetingReducer;
