import { DELETE_LOG, GET_LOGS, GET_LOG, CLEAR_LOGS_STATE } from '../types';

const logReducer = (state, action) => {
  switch (action.type) {
    case GET_LOGS:
      return {
        ...state,
        logs: action.payload,
      };
    case GET_LOG:
      return {
        ...state,
        log: action.payload,
      };
    case DELETE_LOG:
      return {
        ...state,
        logs: state.logs.filter((log) => log._id !== parseInt(action.payload)),
      };
    case CLEAR_LOGS_STATE:
      return {
        ...state,
        logs: null,
        log: null,
      };
    default:
      return state;
  }
};

export default logReducer;
