import {
  CREATE_TASK,
  EDIT_TASK,
  DELETE_TASK,
  GET_TASKS,
  GET_TASK,
  CLEAR_TASKS,
  TOGGLE_COMPLETE_TASK,
} from '../types';

const taskReducer = (state, action) => {
  switch (action.type) {
    case CREATE_TASK:
      return {
        ...state,
        tasks: action.payload,
        taskLoading: false,
      };
    case GET_TASKS:
      return {
        ...state,
        tasks: action.payload,
        taskLoading: false,
      };

    case GET_TASK:
      return {
        ...state,
        tasks: action.payload,
        loading: false,
      };
    case TOGGLE_COMPLETE_TASK:
      return {
        ...state,
        tasks: state.tasks.map((task) =>
          task._id === parseInt(action.payload._id) ? action.payload : task
        ),
        taskLoading: false,
      };
    case EDIT_TASK:
      return {
        ...state,
        tasks: [
          action.payload,
          ...state.tasks.filter((task) => task._id !== action.payload._id),
        ],
      };

    case DELETE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter(
          (task) => task._id !== parseInt(action.payload)
        ),
      };
    case CLEAR_TASKS:
      return {
        tasks: [],
        taskLoading: true,
      };
    default:
      return state;
  }
};

export default taskReducer;
