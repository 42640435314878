import React, { useState, useContext } from 'react';
import TicketContext from '../../../context/tickets/ticketsContext';
import TextEditor from '../../../utils/TextEditor';

const CreateTicket = (props) => {
  const ticketContext = useContext(TicketContext);
  const { createTicket } = ticketContext;
  const { backToList, user } = props;
  const [ticketSubject, setTicketSubject] = useState([]);
  const [ticketDetails, setTicketDetails] = useState('');
  const [emptySubject, setEmptySubject] = useState(false);
  const [emptyDetails, setEmptyDetails] = useState(false);

  const submitTicket = () => {
    if (ticketDetails === '') {
      setEmptyDetails(true);
    } else if (
      ticketSubject.Subject === '' ||
      ticketSubject.Subject === undefined
    ) {
      setEmptySubject(true);
    } else {
      const ticketData = {
        Ticket_Type: ticketSubject.Ticket_Type,
        Subject: ticketSubject.Subject,
        Status: 'NEW',
        Request_Details: ticketDetails,
        Submitter: user.First_Name + ' ' + user.Last_Name,
        Completion_Date: null,
      };

      createTicket(ticketData);
      backToList();
    }
  };

  const cancelCreate = () => {
    setTicketSubject(null);
    setTicketDetails(null);
    backToList();
  };

  const on_change = (e) => {
    setTicketSubject({ ...ticketSubject, [e.target.id]: e.target.value });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <div className="card create_ticket_card rounded shadow-lg">
            <div className="card-body text-dark">
              <h4 className="card-title text-center">Create Ticket</h4>
              <table className="table table-secondary mt-2 pt-3">
                <tbody>
                  <tr>
                    <th className="text-center">TYPE:</th>
                    <td>
                      <form>
                        <div className="form-group">
                          <select
                            id="Ticket_Type"
                            className="custom-select"
                            onChange={on_change}
                          >
                            <option value="">CHOOSE</option>
                            <option>ENHANCEMENT</option>
                            <option>FIX</option>
                            <option>NEW FEATURE</option>
                          </select>
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th className="text-center">SUBJECT:</th>
                    <td>
                      <form>
                        {emptySubject ? (
                          <h5 className="text-danger font-weight-bold">
                            MUST ENTER TICKET SUBJECT
                          </h5>
                        ) : null}
                        <div className="form-group">
                          <input
                            type="text"
                            id="Subject"
                            className="col-lg-12"
                            onChange={on_change}
                          />
                        </div>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <th className="text-center">DETAILS:</th>
                    <td>
                      <form>
                        {emptyDetails ? (
                          <h5 className="text-danger font-weight-bold">
                            MUST ENTER TICKET DETAILS
                          </h5>
                        ) : null}
                        <div className="form-group">
                          <TextEditor
                            textData={ticketDetails}
                            setTextData={setTicketDetails}
                          />
                        </div>
                      </form>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                <div className="col-lg-9"></div>
                <div className="col-lg-3">
                  <button
                    className="btn btn-danger mr-2"
                    onClick={cancelCreate}
                  >
                    CANCEL
                  </button>
                  <button className="btn btn-success" onClick={submitTicket}>
                    SUBMIT
                  </button>
                </div>
              </div>
              <div className="row pl-3">
                <p className="text-center text-dark">
                  Files can be uploaded once ticket is created.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-1"></div>
      </div>
    </div>
  );
};

export default CreateTicket;
