import React, { useReducer, useContext } from 'react';
import DogsContext from './dogsContext';
import axios from 'axios';
import dogsReducer from './dogsReducer';
import AlertContext from '../alert/alertContext';

import {
  GET_DOGS,
  CREATE_DOG,
  GET_DOG,
  EDIT_DOG,
  DELETE_DOG,
  GET_DOG_FILES,
  DELETE_DOG_FILE,
  UPLOAD_DOG_FILE,
  GET_PET_PICTURE,
  CLEAR_DOG_FROM_STATE,
} from '../types';

const DogsState = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialstate = {
    dogs: null,
    dog: null,
    availDogs: null,
    dog_picture_url: null,
    dogFiles: null,
    dogsLoading: true,
  };

  const [state, dispatch] = useReducer(dogsReducer, initialstate);

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Get Dogs
  const getDogs = async () => {
    try {
      const res = await axios.get(`/api/dogs/`);
      dispatch({
        type: GET_DOGS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAllDogs = async () => {
    try {
      const res = await axios.get(`/api/dogs/all/`);
      dispatch({
        type: GET_DOGS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Create Dog
  const createDog = async (data, fileData) => {
    try {
      const res = await axios.post('/api/dogs', data, config);
      if (res.status === 200) {
        createNewDogFileDBEntry(fileData, res.data._id);
        dispatch({
          type: CREATE_DOG,
          payload: res.data,
        });

        setAlert('Dog Created', 'success');
      } else if (res.status === 210) {
        setAlert(`Duplicate Dog`, 'danger');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createDogDash = async (data, fileData) => {
    try {
      const res = await axios.post('/api/dogs', data, config);
      if (res.status === 200) {
        createNewDogFileDBEntry(fileData, res.data._id);
        if (res.data.status === 'Available') {
          dispatch({
            type: CREATE_DOG,
            payload: res.data,
          });
        }

        setAlert('Dog Created', 'success');
      } else if (res.status === 210) {
        setAlert(`Duplicate Dog`, 'danger');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createNewDogFileDBEntry = async (data, id) => {
    var x;

    for (x of data) {
      const dbEntry = {
        File_Name: x.File_Name,
        Uploaded_By: x.Uploaded_By,
        File_DogID: id,
      };
      await axios.post('/api/dogs/file/db', dbEntry, config);
    }
  };

  const getDog = async (data) => {
    dispatch({
      type: GET_DOG,
      payload: data,
    });
  };

  const editDog = async (id, data, page) => {
    try {
      const res = await axios.put(`/api/dogs/${id}`, data, config);
      dispatch({
        type: EDIT_DOG,
        payload: res.data,
      });
      getAllDogs();
    } catch (err) {
      console.log(err);
    }
  };

  const editDogDash = async (id, data, page) => {
    try {
      const res = await axios.put(`/api/dogs/${id}`, data, config);
      dispatch({
        type: EDIT_DOG,
        payload: res.data,
      });
      getDogs();
    } catch (err) {
      console.log(err);
    }
  };

  const clearDog = () => {
    dispatch({
      type: EDIT_DOG,
    });
  };

  const deleteDog = (e) => {
    try {
      axios.delete(`/api/dogs/${e}`);
      dispatch({
        type: DELETE_DOG,
        payload: e,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const uploadDogFile = async (data) => {
    const formData = new FormData();
    formData.append('file', data.file, data.fileName);
    try {
      await axios.post('/api/dogs/file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const createDogFileDBEntry = async (data) => {
    const res = await axios.post('/api/dogs/file/db', data, config);
    dispatch({
      type: UPLOAD_DOG_FILE,
      payload: res.data,
    });
  };

  const getDogFiles = async (id) => {
    try {
      const res = await axios.get(`/api/dogs/file/${id}`);
      dispatch({
        type: GET_DOG_FILES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteDogFile = (id) => {
    try {
      axios.delete(`/api/dogs/file/${id}`);
      dispatch({
        type: DELETE_DOG_FILE,
        payload: id,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getDogPicture = async (id) => {
    try {
      const res = await axios.get(`/api/dogs/picture/${id}`);
      if (res.status === 200) {
        dispatch({
          type: GET_PET_PICTURE,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_PET_PICTURE,
          payload: null,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const clearDogFromState = () => {
    dispatch({
      type: CLEAR_DOG_FROM_STATE,
    });
  };

  return (
    <DogsContext.Provider
      value={{
        dogs: state.dogs,
        dog: state.dog,
        availDogs: state.availDogs,
        dogsLoading: state.dogsLoading,
        dogFiles: state.dogFiles,
        dog_picture_url: state.dog_picture_url,
        createDog,
        createDogDash,
        getDogPicture,
        editDog,
        editDogDash,
        deleteDog,
        getDog,
        getDogs,
        clearDog,
        getAllDogs,
        uploadDogFile,
        createDogFileDBEntry,
        clearDogFromState,
        getDogFiles,
        deleteDogFile,
      }}
    >
      {props.children}
    </DogsContext.Provider>
  );
};

export default DogsState;
