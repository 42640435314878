import React, { Fragment, useState, useContext, useEffect } from 'react';
import AlertContext from '../../context/alert/alertContext';
import BlogContext from '../../context/blog/blogContext';
import AdminContext from '../../context/admin/adminContext';
import BlogTextEditor from '../../utils/BlogTextEditor';

const FormBlogEdit = (props) => {
  const alertContext = useContext(AlertContext);
  const blogContext = useContext(BlogContext);
  const adminContext = useContext(AdminContext);
  const { setAlert } = alertContext;
  const { blogID } = adminContext;
  const { editBlog, deleteBlog, current, clearCurrent } = blogContext;
  const { cancelBlogEntry } = props;

  const [blog, setBlog] = useState({
    title: '',
    post: '',
    author: '',
  });

  const [blogBody, setBlogBody] = useState();

  useEffect(() => {
    if (current !== null) {
      setBlog({
        title: current.title,
        post: '',
        author: current.author,
      });
      setBlogBody(current.post);
    }
    // eslint-disable-next-line
  }, []);

  const { title, author } = blog;

  const on_change = (e) => {
    setBlog({ ...blog, [e.target.id]: e.target.value });
  };

  const updateBlog = (e) => {
    e.preventDefault();
    const editedBlog = {
      _id: current._id,
      title: blog.title,
      author: blog.author,
      post: blogBody,
    };
    if (title === '' || author === '') {
      setAlert('MISSING DATA - ALL FIELDS ARE REQUIRED', 'danger');
      window.scrollTo(0, 0);
    } else {
      editBlog(editedBlog);
      cancelBlogEntry();
      clearCurrent();
    }
  };
  const DeleteBlog = () => {
    deleteBlog(blogID);
    cancelBlogEntry();
  };

  const cancelEdit = () => {
    cancelBlogEntry();
    clearCurrent();
  };

  return (
    <Fragment>
      {blog !== null ? (
        <div className="container">
          <h2 className="text-center">BLOG ENTRY</h2>
          <form>
            <div className="form-group">
              <label htmlFor="title">
                <h5>TITLE</h5>
              </label>
              <input
                id="title"
                type="text"
                className="form-control"
                value={title}
                onChange={on_change}
              />
            </div>
            <div className="form-group">
              <label htmlFor="author">
                <h5>AUTHOR</h5>
              </label>
              <input
                id="author"
                type="text"
                className="form-control"
                value={author}
                onChange={on_change}
              />
            </div>
            <div className="form-group">
              <label className="active" htmlFor="post">
                <h5>BLOG POST</h5>
              </label>
              <div>
                <BlogTextEditor textData={blogBody} setTextData={setBlogBody} />
              </div>
            </div>
          </form>
        </div>
      ) : (
        <h4>Loading</h4>
      )}

      {/* ****************BUTTONS ARE BELOW HERE*********** */}
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <button
              type="button"
              className="btn btn-danger col-lg-6 mt-3 mb-3"
              onClick={DeleteBlog}
            >
              <i className="fa fa-arrow-left p" aria-hidden="true" />
              DELETE
            </button>
          </div>
          <div className="col-lg-4">
            <button
              type="button"
              className="btn btn-warning col-lg-6 mt-3 mb-3"
              onClick={cancelEdit}
            >
              Cancel
              <i className="fa fa-arrow-right p" aria-hidden="true" />
            </button>
          </div>

          <div className="col-lg-4">
            <button
              type="button"
              className="btn btn-primary col-lg-6 mt-3 mb-3"
              onClick={updateBlog}
            >
              UPDATE BLOG
              <i className="fa fa-arrow-right p" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormBlogEdit;
