import React, { useReducer, useContext } from 'react';
import FileContext from './fileContext';
import fileReducer from './fileReducer';
import AlertContext from '../alert/alertContext';
import axios from 'axios';

import { GET_APP_FILES, DELETE_FILE, UPLOAD_FILE } from '../types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const FileState = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialstate = {
    files: null,
  };

  const [state, dispatch] = useReducer(fileReducer, initialstate);

  const getAppFiles = async (e) => {
    try {
      const res = await axios.get(`/api/upload/app/${e}`);
      dispatch({
        type: GET_APP_FILES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const uploadTheFile = async (data) => {
    const formData = new FormData();
    formData.append(
      'file',
      data.file,
      data.fileName + '.' + data.fileExtension
    );
    try {
      await axios.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fileToDB = async (data) => {
    const res = await axios.post('/api/upload/db', data, config);
    dispatch({
      type: UPLOAD_FILE,
      payload: res.data,
    });
  };

  const deleteFile = async (id) => {
    try {
      const res = await axios.delete(`/api/upload/${id}`);
      setAlert(res.data, 'success');
      dispatch({
        type: DELETE_FILE,
        payload: id,
      });
    } catch (error) {}
  };
  return (
    <FileContext.Provider
      value={{
        files: state.files,
        getAppFiles,
        deleteFile,
        uploadTheFile,
        fileToDB,
      }}
    >
      {props.children}
    </FileContext.Provider>
  );
};

export default FileState;
