import {
  DELETE_KNOWLEDGEBASE,
  GET_KNOWLEDGEBASES,
  CREATE_KNOWLEDGE_BASE,
  GET_KNOWLEDGEBASE,
  CLEAR_KNOWLEDGEBASE,
  UPDATE_KNOWLEDGEBASE,
} from '../types';

const knowledgeBaseReducer = (state, action) => {
  switch (action.type) {
    case GET_KNOWLEDGEBASES:
      return {
        ...state,
        kbs: action.payload,
      };
    case GET_KNOWLEDGEBASE:
      return {
        ...state,
        kb: action.payload,
      };
    case DELETE_KNOWLEDGEBASE:
      return {
        ...state,
        kbs: state.kbs.filter((kb) => kb._id !== parseInt(action.payload)),
      };
    case UPDATE_KNOWLEDGEBASE:
      return {
        ...state,
        kbs: [
          action.payload,
          ...state.kbs.filter((kb) => kb._id !== action.payload._id),
        ],
      };
    case CREATE_KNOWLEDGE_BASE:
      return {
        ...state,
        kbs: [action.payload, ...state.kbs],
      };
    case CLEAR_KNOWLEDGEBASE:
      return {
        ...state,
        kbs: null,
        kb: null,
      };
    default:
      return state;
  }
};

export default knowledgeBaseReducer;
