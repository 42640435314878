import React, { useState, useContext } from 'react';
import TicketContext from '../../../context/tickets/ticketsContext';
import TextEditor from '../../../utils/TextEditor';

const CreateTicketNote = (props) => {
  const ticketContext = useContext(TicketContext);
  const { createTicketNote } = ticketContext;
  const { backToView, user, ticketID } = props;
  const [noteBody, setNoteBody] = useState('');

  const submitNote = () => {
    const noteData = {
      Note: noteBody,
      Author: user.First_Name + ' ' + user.Last_Name,
      Ticket_Id: ticketID,
    };
    createTicketNote(noteData);
    setNoteBody('');
    backToView();
    window.scroll(0, 0);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <div className="card create_ticket_card rounded shadow-lg">
            <div className="card-body text-dark">
              <form>
                <div className="form-group">
                  <TextEditor textData={noteBody} setTextData={setNoteBody} />
                </div>
              </form>

              <div className="row">
                <button
                  className="btn btn-danger col-xs-6 col-md-2"
                  onClick={backToView}
                >
                  CANCEL
                </button>
                <div className="d-xs-none col-md-8"></div>
                <button
                  className="btn btn-success col-xs-6 col-md-2"
                  onClick={submitNote}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTicketNote;
