import React, { useState, useContext, useEffect } from 'react';
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';
import axios from 'axios';

const Login = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const {
    loginUser,
    error,
    clearErrors,
    isAuthenticated,
    loadUser,
  } = authContext;

  const { setAlert } = alertContext;
  const [user, setUser] = useState({
    Username: '',
    Password: '',
  });

  useEffect(() => {
    if (localStorage.auth_token) {
      loadUser();
    }
    if (isAuthenticated) {
      props.history.push('/admin');
    }
    if (error === 'Invalid Credentials') {
      setAlert(error, 'danger');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props]);

  const { Username, Password } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (Username === '' || Password === '') {
      setAlert('Please fill in all fields', 'danger');
    } else {
      loginUser({
        Username,
        Password,
      });
      try {
        const config = {
          headers: {
            'content-type': 'application/json',
          },
        };

        const logData = {
          module: 'AUTH',
          action: 'Login User',
          user: Username,
          notes: `device type ${navigator.appVersion}`,
        };
        axios.post(`/api/logs`, logData, config);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3"></div>
        <div className="card m-3 col-md-6">
          <h1>LOGIN</h1>
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="Username">Email Address</label>
              <input
                className="form-control"
                type="Username"
                name="Username"
                value={Username}
                onChange={onChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="Password">Password</label>
              <input
                className="form-control"
                type="Password"
                name="Password"
                value={Password}
                onChange={onChange}
                required
              />
            </div>
            <div className="row">
              <div className="my-4 col-md-6">
                <input
                  type="submit"
                  value="LOGIN"
                  className="btn btn-primary mr-3"
                />
              </div>
              <div className="col-md-5 text-right p-4">
                <a href="/forgotPassword">Forgot Password</a>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
};

export default Login;
