import React from 'react';
import PropTypes from 'prop-types';

function formatDate(string) {
  var options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const AdminDashDifList = ({
  dog,
  showDogInFoster,
  getDog,
  removeDog,
  getDogFiles,
  user,
}) => {
  const editDif = (e) => {
    showDogInFoster();
    getDog(dog);
    getDogFiles(dog._id);
  };

  return (
    <tr>
      <td className="text-center pointer" onClick={editDif}>
        {dog.dogName}
      </td>
      <td className="text-center">{dog.foster}</td>
      <td className="text-center">{dog.processor}</td>
      <td className="text-center d-none d-md-table-cell">{dog.location}</td>
      <td className="text-center d-none d-md-table-cell">
        {formatDate(dog.intake)}
      </td>
      <td className="text-center d-none d-md-table-cell">
        {formatDate(dog.available_date)}
      </td>
      <td className="text-center">{dog.status}</td>
      {user.Role === 'Manager' || user.Role === 'Admin' ? (
        <td>
          <i
            data-toggle="modal"
            data-target="#dogDeleteConfirmationModal"
            className="fa fa-trash text-danger pointer"
            onClick={() => removeDog(dog._id)}
            aria-hidden="true"
          ></i>
        </td>
      ) : null}
    </tr>
  );
};

AdminDashDifList.propTypes = {
  dog: PropTypes.object.isRequired,
};
export default AdminDashDifList;
