import React, { useReducer, useContext } from 'react';
import KnowledgeBaseContext from './knowledgeBaseContext';
import knowledgeBaseReducer from './knowledgeBaseReducer';
import AlertContext from '../alert/alertContext';
import axios from 'axios';

import {
  DELETE_KNOWLEDGEBASE,
  GET_KNOWLEDGEBASES,
  GET_KNOWLEDGEBASE,
  CLEAR_KNOWLEDGEBASE,
  UPDATE_KNOWLEDGEBASE,
  CREATE_KNOWLEDGE_BASE,
} from '../types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const KnowledgeBaseState = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialstate = {
    kbs: null,
    kb: null,
  };

  const [state, dispatch] = useReducer(knowledgeBaseReducer, initialstate);

  const createKB = async (data) => {
    try {
      const res = await axios.post('/api/knowledgeBase/', data, config);
      dispatch({
        type: CREATE_KNOWLEDGE_BASE,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getKBs = async (e) => {
    try {
      const res = await axios.get(`/api/knowledgeBase/`);
      dispatch({
        type: GET_KNOWLEDGEBASES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getKB = async (log) => {
    dispatch({
      type: GET_KNOWLEDGEBASE,
      payload: log,
    });
  };

  const deleteKB = async (id) => {
    try {
      const res = await axios.delete(`/api/knowledgeBase/${id}`);
      setAlert(res.data, 'success');
      dispatch({
        type: DELETE_KNOWLEDGEBASE,
        payload: id,
      });
    } catch (error) {}
  };

  const clearKBs = () => {
    dispatch({
      type: CLEAR_KNOWLEDGEBASE,
    });
  };
  const updateKB = async (data) => {
    const res = await axios.put(`/api/knowledgeBase/${data._id}`, data, config);
    dispatch({
      type: UPDATE_KNOWLEDGEBASE,
      payload: res.data,
    });
  };
  return (
    <KnowledgeBaseContext.Provider
      value={{
        kbs: state.kbs,
        kb: state.kb,
        getKBs,
        deleteKB,
        getKB,
        updateKB,
        clearKBs,
        createKB,
      }}
    >
      {props.children}
    </KnowledgeBaseContext.Provider>
  );
};

export default KnowledgeBaseState;
