import React, { useReducer } from 'react';
import EmailTemplatesContext from './emailTemplatesContext';
import emailTemplatesReducer from './emailTemplatesReducer';
import axios from 'axios';

import {
  GET_EMAIL_TEMPLATES,
  CREATE_EMAIL_TEMPLATE,
  CLEAR_EMAIL_TEMPLATE_ATTACHMENTS,
  GET_EMAIL_TEMPLATE_ATTACHMENTS,
} from '../types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const EmailTemplatesState = (props) => {
  const initialstate = {
    emailTemplates: [],
    emailTemplatesAttachments: [],
    templateLoading: true,
  };

  const [state, dispatch] = useReducer(emailTemplatesReducer, initialstate);

  const getTemplates = async () => {
    const res = await axios.get('/api/emailTemplate');
    dispatch({
      type: GET_EMAIL_TEMPLATES,
      payload: res.data,
    });
  };

  const createEmailTemplate = async (data) => {
    try {
      await axios.post('/api/emailTemplate', data, config).then((response) => {
        dispatch({
          type: CREATE_EMAIL_TEMPLATE,
          payload: response.data,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getTemplateAttachments = async (id) => {
    const res = await axios.get(`/api/emailTemplate/attachments/${id}`);
    dispatch({
      type: GET_EMAIL_TEMPLATE_ATTACHMENTS,
      payload: res.data,
    });
  };

  const saveAttachments = async (data) => {
    const formData = new FormData();
    formData.append('file', data.file, data.fileName);
    try {
      await axios.post('/api/emailTemplate/saveAttachment', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteEmailTemplate = (id) => {
    axios.delete(`/api/emailTemplate/${id}`);
  };

  const editEmailTemplate = async (e) => {
    const data = {
      template_name: e.template_name,
      template_subject: e.template_subject,
      Author: e.Author,
      template_email: e.template_email,
      files: e.files,
    };

    try {
      await axios.put(`/api/emailTemplate/${e._id}`, data, config);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteEmailTemplateAttachment = async (e) => {
    await axios
      .delete(`/api/emailTemplate/emailAttachment/delete/${e}`)
      .catch((error) => {
        console.log(error);
      });
  };

  const saveTemplateAttachments = async (data) => {
    const formData = new FormData();
    formData.append('file', data.file, data.fileName);
    try {
      await axios.post('/api/emailTemplate/saveAttachment', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const copyFileFromTemplateToEmailAttachment = async (fileToCopy) => {
    await axios.post(
      '/api/emailTemplate/copyTemplateAttachment',
      fileToCopy,
      config
    );
  };

  const clearEmailTemplateAttachments = () => {
    dispatch({
      type: CLEAR_EMAIL_TEMPLATE_ATTACHMENTS,
    });
  };

  return (
    <EmailTemplatesContext.Provider
      value={{
        emailTemplates: state.emailTemplates,
        templateLoading: state.templateLoading,
        emailTemplatesAttachments: state.emailTemplatesAttachments,
        getTemplates,
        createEmailTemplate,
        deleteEmailTemplate,
        editEmailTemplate,
        saveAttachments,
        clearEmailTemplateAttachments,
        getTemplateAttachments,
        deleteEmailTemplateAttachment,
        saveTemplateAttachments,
        copyFileFromTemplateToEmailAttachment,
      }}
    >
      {props.children}
    </EmailTemplatesContext.Provider>
  );
};

export default EmailTemplatesState;
