import {
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  CLEAR_CURRENT,
  GET_USER,
  GET_USERS,
  UPDATE_CURRENT,
} from '../types';

const userReducer = (state, action) => {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        users: action.payload,
        loading: false,
        submitComplete: true,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case GET_USER:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case EDIT_USER:
      return {
        ...state,
        users: [
          action.payload,
          ...state.users.filter((user) => user._id !== action.payload._id),
        ],
        user: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case UPDATE_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
};

export default userReducer;
