import {
  CREATE_NOTE,
  EDIT_NOTE,
  DELETE_NOTE,
  GET_NOTES,
  GET_NOTE,
} from '../types';

const notesReducer = (state, action) => {
  switch (action.type) {
    case CREATE_NOTE:
      return {
        ...state,
        notes: action.payload,
        noteLoading: false,
      };
    case GET_NOTES:
      return {
        ...state,
        notes: action.payload,
        noteLoading: false,
      };
    case GET_NOTE:
      return {
        ...state,
        notes: action.payload,
        noteloading: false,
      };
    case EDIT_NOTE:
      return {
        ...state,
        noteLoading: false,
      };
    case DELETE_NOTE:
      return {
        ...state,
        notes: state.notes.filter(
          (note) => note._id !== parseInt(action.payload)
        ),
      };
    default:
      return state;
  }
};

export default notesReducer;
