const Email = (data) => {
  const htmlMessage = `
<br>
A New Adoption Application has been Submitted 
<br>
<br>

INTERESTED IN: <br>
1st Choice: ${data.st_Choice}<br>
2nd Choice: ${data.nd_Choice}<br>
Future: ${data.Future_Dog2}<br>
<br>
<br>
ADOPTER INFORMATION: <br>
Name: ${data.First_Name} ${data.Last_Name}<br>
Phone: ${data.Phone}<br>
Email: ${data.Email}<br>


`;
  return {
    subject: 'New Adoption Application',
    html: htmlMessage,
    text: 'See Attached Adoption Application',
    attachments: [
      {
        filename: data.Last_Name + ' ' + data.st_Choice + '.pdf',
        path: './pdf_files/' + data.Last_Name + ' ' + data.st_Choice + '.pdf',
      },
    ],
  };
};

export default Email;
