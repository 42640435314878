import React, { useContext } from 'react';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx/xlsx.mjs';
import AuthContext from '../../context/auth/authContext';
import UserContext from '../../context/user/userContext';

const ExportDataExcel = ({ csvData, fileName, filters, searchBar }) => {
  const { user } = useContext(AuthContext);
  const { users } = useContext(UserContext);
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const Heading = [
    {
      _id: 'Record ID',
      First_Name: 'First_Name',
      Middle_Initial: 'Middle_Initial',
      Last_Name: 'Last_Name',
      CoApp_Full_Name: 'CoApp_Full_Name',
      Street: 'Street',
      City: 'City',
      State: 'State',
      Zip_Code: 'Zip',
      Phone: 'Phone',
      Mobile: 'Mobile',
      Email: 'Email',
      Application_Status: 'Status',
      Application_Type: 'type',
      st_Choice: '1st Choice',
      nd_Choice: '2nd Choice',
      Dog_Adopted: 'Dog Adopted',
      submit_date: 'Submit Date',
      createdAt: 'Created Date',
      updatedAt: 'Updated Date',
      Owner_Id: 'Owner_Id',
    },
  ];

  const lookupOwnwer = (id) => {
    if (users !== undefined) {
      const ownerName = users.filter((user) => user._id === parseInt(id));
      if (ownerName.length > 0) {
        const name = ownerName[0].First_Name + ' ' + ownerName[0].Last_Name;
        return name;
      }
    }
  };

  const filterHeading = [
    {
      ApplicationOwner: 'Owner',
      ApplicationStatus: 'Application Stage',
      ApplicationType: 'Application Type',
      CurrentStatus: 'Current Status',
      SearchBar: 'Search Bar',
    },
  ];

  const wscols = [
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 20 },
    { wch: 25 },
    { wch: 30 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
  ];
  var filtersSet = [];
  filtersSet.push({
    ApplicationOwner: lookupOwnwer(filters.Application_Owner),
    ApplicationStatus: filters.Application_Status,
    ApplicationType: filters.Application_Type,
    CurrentStatus: filters.appsCurrentStatus,
    Search: searchBar,
  });

  const exportToCSV = (csvData, fileName, wscols) => {
    const ws = XLSX.utils.json_to_sheet(Heading, {
      skipHeader: true,
      origin: 0, //ok
    });
    ws['!cols'] = wscols;
    XLSX.utils.sheet_add_json(ws, csvData, {
      skipHeader: true,
      origin: -1, //ok
    });

    const xp = XLSX.utils.json_to_sheet(filterHeading, {
      skipHeader: true,
      origin: 0, //ok
    });

    xp['!cols'] = wscols;
    XLSX.utils.sheet_add_json(xp, filtersSet, {
      skipHeader: true,
      origin: -1, //ok
    });

    const wb = {
      Sheets: { data: ws, filters: xp },
      SheetNames: ['data', 'filters'],
    };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      {user.Role === 'Admin' || user.Role === 'Manager' ? (
        <button
          className="btn btn-warning mb-2"
          onClick={(e) => exportToCSV(csvData, fileName, wscols)}
          style={{ float: 'right' }}
        >
          EXPORT
        </button>
      ) : null}
    </>
  );
};

export default ExportDataExcel;
