import React, { Fragment, useContext, useEffect, useState } from 'react';
import AppContext from '../../../context/app/appContext';
import FollowUpsContext from '../../../context/follow-ups/followUpsContext';
import AuthContext from '../../../context/auth/authContext';
import UserContext from '../../../context/user/userContext';
import MOMENT from 'moment';

function formTheDate(date) {
  const theDate = new Date(date);
  const a = theDate.toISOString();
  const yyyy = a.slice(0, 4);
  const mm = a.slice(5, 7);
  const dd = a.slice(8, 10);

  const formatedDate = `${mm}-${dd}-${yyyy}`;

  return formatedDate;
}

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'America/New_York',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const formFormatedDate = (string) => {
  var d = new Date(string);
  var mm = ('0' + (d.getMonth() + 1)).slice(-2);
  var dd = ('0' + (d.getDate() + 1)).slice(-2);
  var yy = d.getFullYear();

  var myDateString = yy + '-' + mm + '-' + dd; //(US)

  return myDateString;
};

function convertDate(date) {
  const year = date.split('-', 3)[0];
  const month = date.split('-', 3)[1];
  const day = date.split('-', 3)[2];
  const time = 'T12:00:00.000Z';
  const dueDate = year + '-' + month + '-' + day + time;
  return dueDate;
}

function formDate(date) {
  const formatedDate = date.split('T', 1)[0];
  return formatedDate;
}

const AdminAppFollowUps = (props) => {
  const appContext = useContext(AppContext);
  const authContext = useContext(AuthContext);
  const followUpsContext = useContext(FollowUpsContext);
  const userContext = useContext(UserContext);
  const { app } = appContext;
  const {
    editFollowUp,
    getFollowUps,
    followUps,
    createFollowUp,
    fuLoading,
    completeFollowUp,
    deleteFollowUp,
    notifyAssignment,
    clearFollowUps,
  } = followUpsContext;
  const { user } = authContext;
  const { users, loading, getUsers } = userContext;
  const [check, setCheck] = useState([]);
  const [newFollowUp, setNewFollowUp] = useState({
    Follow_Up_Description: '',
    Follow_Up_Status: 'Pending',
    Follow_Up_Owner: 9999,
    Follow_Up_Due_Date: formFormatedDate(Date.now()),
    Completed_By: '',
  });
  const [editedFollowUp, setEditedFollowUp] = useState({
    Edited_Follow_Up_Description: '',
    Edited_Follow_Up_Owner: 9999,
  });

  const [editedFollowUpId, setEditedFollowUpId] = useState();
  const [notify, setNotify] = useState(false);
  const [sortedFollowUps, setSortedFollowUps] = useState([]);
  const [removeFollowUpId, setRemoveFollowUpId] = useState(null);
  const { sort } = props;

  useEffect(() => {
    if (followUps !== null) {
      if (sort === 'Ascending') {
        const followUpsSorted = followUps.slice().sort((a, b) => {
          return (
            new Date(a.Follow_Up_Due_Date) - new Date(b.Follow_Up_Due_Date)
          );
        });
        setSortedFollowUps(followUpsSorted);
      } else {
        const followUpsSorted = followUps.slice().sort((a, b) => {
          return (
            new Date(b.Follow_Up_Due_Date) - new Date(a.Follow_Up_Due_Date)
          );
        });
        setSortedFollowUps(followUpsSorted);
      }
    }
    // eslint-disable-next-line
  }, [followUps, props.sort]);

  useEffect(() => {
    if (app !== null) {
      getFollowUps(app._id);
      getUsers();
    }

    return function cleanUp() {
      clearFollowUps();
    };
    // eslint-disable-next-line
  }, [app, check]);

  const on_change = (e) => {
    setNewFollowUp({ ...newFollowUp, [e.target.id]: e.target.value });
  };

  const on_update = (e) => {
    setEditedFollowUp({ ...editedFollowUp, [e.target.id]: e.target.value });
  };

  const notifyFollowUpAssignment = (e) => {
    if (!notify) {
      setNotify(true);
    } else {
      setNotify(false);
    }
  };

  const checked = (e) => {
    if (!check) {
      setCheck(true);
    } else {
      setCheck(false);
    }

    const status = followUps.filter((fu) => {
      if (typeof fu._id === 'string') {
        return fu._id === e.target.id;
      } else {
        return fu._id === parseInt(e.target.id);
      }
    });

    if (status[0].Follow_Up_Status === 'Pending') {
      const followUpUpdate = {
        _id: parseInt(e.target.id),
        Follow_Up_Description: status[0].Follow_Up_Description,
        Follow_Up_Owner: status[0].Follow_Up_Owner,
        Follow_Up_App_Id: status[0].Follow_Up_App_Id,
        Completed_By: `${user.First_Name} ${user.Last_Name}`,
        Follow_Up_Due_Date: status[0].Follow_Up_Due_Date,
        Completed_Date: MOMENT().format('YYYY-MM-DD  HH:mm:ss.000'),
        updatedAt: MOMENT().format('YYYY-MM-DD  HH:mm:ss.000'),
        Follow_Up_Status: 'Complete',
        Complete_Checkbox: 1,
      };
      completeFollowUp(followUpUpdate);
    } else {
      const followUpUpdate = {
        _id: parseInt(e.target.id),
        Follow_Up_Description: status[0].Follow_Up_Description,
        Follow_Up_Owner: status[0].Follow_Up_Owner,
        Follow_Up_App_Id: status[0].Follow_Up_App_Id,
        Follow_Up_Due_Date: status[0].Follow_Up_Due_Date,
        Completed_By: ``,
        updatedAt: MOMENT().format('YYYY-MM-DD  HH:mm:ss.000'),
        Completed_Date: null,
        Follow_Up_Status: 'Pending',
        Complete_Checkbox: 0,
      };
      completeFollowUp(followUpUpdate);
    }
  };

  const cancelEdit = () => {
    setNewFollowUp({
      Follow_Up_Description: '',
      Follow_Up_Status: 'Pending',
      Follow_Up_Owner: 9999,
      Follow_Up_Due_Date: formFormatedDate(Date.now()),
    });
    setEditedFollowUp({
      Edited_Follow_Up_Description: '',
      Edited_Follow_Up_Owner: 9999,
    });
    document.getElementById('Edited_Follow_Up_Due_Date').value = '';
    setNotify(false);
    setEditedFollowUpId(null);
  };

  const onChange = (e) => {
    setNewFollowUp({
      ...newFollowUp,
      Follow_Up_Owner: e.target.value,
    });
  };

  const add_FollowUp = (e) => {
    const data = {
      Follow_Up_Description: newFollowUp.Follow_Up_Description,
      Follow_Up_Status: 'Pending',
      Follow_Up_Owner: newFollowUp.Follow_Up_Owner,
      Follow_Up_Due_Date: convertDate(newFollowUp.Follow_Up_Due_Date),
      Follow_Up_App_Id: app._id,

      Complete_Checkbox: 0,
    };
    createFollowUp(data);

    if (notify && parseInt(newFollowUp.Follow_Up_Owner) !== 9999) {
      const owner = users.filter((user) => {
        return user._id === parseInt(newFollowUp.Follow_Up_Owner);
      });
      const email = {
        To: owner[0].Username,
        From: user.Username,
        Subject: 'PPR Application follow up assigned to you',
        Body: `
        <br>
        <b>The following follow up has been assigned to you:</b> <br>
        <b>Application ID:</b> ${app._id}<br>
        <b>Applicant Name:</b> ${app.First_Name} ${app.Last_Name}<br>
        <b>Follow Up:</b> ${newFollowUp.Follow_Up_Description}<br>
        <br>
        Go To https://portal.puppypawsrescue.org <br>

         `,
      };
      notifyAssignment(email);
      setNotify(false);
    } else if (parseInt(newFollowUp.Follow_Up_Owner) === 9999) {
      alert(
        'Must choose a user to notify. Cannot notify UnAssigned. Please choose a user to notify'
      );
    }
    setNotify(false);
    setNewFollowUp({
      Follow_Up_Description: '',
      Follow_Up_Status: 'Pending',
      Follow_Up_Owner: 9999,
      Follow_Up_Due_Date: formFormatedDate(Date.now()),
      Completed_By: '',
    });
  };

  const insertEditFollowUp = (e) => {
    const oldFollowUpData = followUps.filter(
      (fu) => fu._id === parseInt(e.target.id)
    );

    setEditedFollowUp({
      // ...editedFollowUp,
      Edited_Follow_Up_Description: oldFollowUpData[0].Follow_Up_Description,
      Edited_Follow_Up_Owner: oldFollowUpData[0].Follow_Up_Owner,
      _id: oldFollowUpData[0]._id,
      Follow_Up_App_Id: oldFollowUpData[0].Follow_Up_App_Id,
      Edited_Follow_Up_Due_Date: formDate(
        oldFollowUpData[0].Follow_Up_Due_Date
      ),
      Completed_By: oldFollowUpData[0].Completed_By,
      Completed_Date: oldFollowUpData[0].Completed_Date,
      Follow_Up_Status: oldFollowUpData[0].Follow_Up_Status,
      Complete_Checkbox: oldFollowUpData[0].Complete_Checkbox,
    });
    setEditedFollowUpId(parseInt(e.target.id));
    document.getElementById('Edited_Follow_Up_Due_Date').value = formDate(
      oldFollowUpData[0].Follow_Up_Due_Date
    );
  };

  const update_followUp = (e) => {
    const data = {
      _id: editedFollowUpId,
      Follow_Up_Description: editedFollowUp.Edited_Follow_Up_Description,
      Follow_Up_Owner: editedFollowUp.Edited_Follow_Up_Owner,
      Follow_Up_App_Id: editedFollowUp.Follow_Up_App_Id,
      Completed_By: editedFollowUp.Completed_By,
      Completed_Date: editedFollowUp.Completed_Date,
      Follow_Up_Due_Date: convertDate(editedFollowUp.Edited_Follow_Up_Due_Date),
      Follow_Up_Status: editedFollowUp.Follow_Up_Status,
      Complete_Checkbox: editedFollowUp.Complete_Checkbox,
    };
    editFollowUp(data);
    if (notify && parseInt(editedFollowUp.Edited_Follow_Up_Owner) !== 9999) {
      const owner = users.filter((user) => {
        return user._id === parseInt(editedFollowUp.Edited_Follow_Up_Owner);
      });
      const email = {
        To: owner[0].Username,
        From: user.Username,
        Subject: 'PPR Application follow up assigned to you',
        Body: `
        <br>
        <b>The following follow up has been assigned to you:</b> <br>
        <b>Application ID:</b> ${app._id}<br>
        <b>Applicant Name:</b> ${app.First_Name} ${app.Last_Name}<br>
        <b>Follow Up:</b> ${editedFollowUp.Edited_Follow_Up_Description}<br>
        <br>
        Go To https://portal.puppypawsrescue.org <br>

         `,
      };
      notifyAssignment(email);
      setNotify(false);
    } else if (parseInt(editedFollowUp.Edited_Follow_Up_Owner) === 9999) {
      alert(
        'Must choose a user to notify. Cannot notify UnAssigned. Please choose a user to notify'
      );
    }

    setNotify(false);

    setEditedFollowUpId('');
    setEditedFollowUp({
      Edited_Follow_Up_Description: '',
      Edited_Follow_Up_Owner: 9999,
    });
  };

  const fetchOwnerName = (id) => {
    if (id === null || parseInt(id) === 9999) {
      return 'UnAssigned';
    } else if (users !== undefined) {
      const owner = users.filter((user) => {
        return user._id === parseInt(id);
      });
      return owner[0].First_Name + ' ' + owner[0].Last_Name;
    }
  };

  formatDate(followUps.Completed_Date);

  const deleteAFollowUp = (e) => {
    deleteFollowUp(e);
  };

  const removeFollowUp = (e) => {
    setRemoveFollowUpId(e.target.id);
  };

  const cancelDeletion = () => {
    setRemoveFollowUpId(null);
  };

  return (
    <Fragment>
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            <button
              type="button"
              className="btn btn-warning text-dark font-weight-bold"
              data-toggle="modal"
              data-target="#staticBackdropFollowUp"
            >
              CREATE FOLLOW UP
            </button>
          </div>
        </div>
        <div className="container-fluid border border-dark mt-3 p-0">
          <table className="table table-hover text-center">
            <thead className="bg-foster-theme">
              <tr className="d-flex">
                <th scope="col" className="col-1 text-center">
                  DONE
                </th>
                <th scope="col" className="text-center col">
                  FOLLOW UP
                </th>
                <th scople="col" className="text-center col">
                  DUE DATE
                </th>
                <th scople="col" className="text-center col">
                  ASSIGNED TO
                </th>
                <th scope="col" className="text-center col d-none d-md-block">
                  COMPLETED BY
                </th>
                <th scope="col" className="text-center col d-none d-md-block">
                  LAST MODIFIED
                </th>
                <th scope="col" className="text-center col d-none d-md-block">
                  COMPLETION DATE
                </th>
                <th scope="col" className="text-center col-1"></th>
              </tr>
            </thead>
            <tbody>
              {sortedFollowUps !== null && !fuLoading ? (
                sortedFollowUps.map((fu) => (
                  <tr key={fu._id} id={fu._id} className="d-flex">
                    <td className="col-1 text-center">
                      <div className="form-check text-center">
                        <input
                          className="form-check-input text-center pointer"
                          type="checkbox"
                          id={fu._id}
                          checked={fu.Complete_Checkbox}
                          onChange={checked}
                        />
                      </div>
                    </td>
                    <td
                      className="text-center pointer font-weight-bold col"
                      data-toggle="modal"
                      data-target="#staticBackdropFollowUp2"
                      id={fu._id}
                      onClick={insertEditFollowUp}
                    >
                      {fu.Follow_Up_Description}
                    </td>
                    <td
                      className="text-center pointer col"
                      data-toggle="modal"
                      data-target="#staticBackdropFollowUp2"
                      id={fu._id}
                    >
                      {formTheDate(fu.Follow_Up_Due_Date)}
                    </td>
                    <td
                      className="text-center pointer  col"
                      data-toggle="modal"
                      data-target="#staticBackdropFollowUp2"
                      id={fu._id}
                      onClick={insertEditFollowUp}
                    >
                      {fetchOwnerName(fu.Follow_Up_Owner)}
                    </td>
                    <td className="text-center  col  d-none d-md-block">
                      {fu.Completed_Date !== null
                        ? fu.Completed_By
                        : fu.Follow_Up_Status}
                    </td>
                    <td className="text-center  col  d-none d-md-block">
                      {fu.updatedAt !== null
                        ? formatDate(fu.updatedAt)
                        : fu.Follow_Up_Status}
                    </td>
                    <td className="text-center  col  d-none d-md-block">
                      {fu.Completed_Date !== null
                        ? formatDate(fu.Completed_Date)
                        : fu.Follow_Up_Status}
                    </td>
                    <td className=" col-1">
                      <i
                        className="fa fa-trash-o pointer text-danger"
                        onClick={removeFollowUp}
                        data-toggle="modal"
                        data-target="#followUpDeleteConfirmationModal"
                        id={fu._id}
                        aria-hidden="true"
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="spinner-grow text-primary" role="status">
                  <td>Loading...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* *********** CREATE FOLLOW UP MODAL ************* */}
        <div
          className="modal fade"
          id="staticBackdropFollowUp"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdropFollowUpLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropFollowUpLabel">
                  NEW FOLLOW UP
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={cancelEdit}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label
                      className="active font-weight-bolder"
                      htmlFor="Follow_Up_Description"
                    >
                      FOLLOW UP:
                    </label>
                  </div>
                  <div className="col-md-10">
                    <input
                      id="Follow_Up_Description"
                      type="text"
                      className="form-control"
                      value={newFollowUp.Follow_Up_Description}
                      onChange={on_change}
                      maxLength="50"
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label
                      className="active font-weight-bolder"
                      htmlFor="Follow_Up_Description"
                    >
                      DUE DATE:
                    </label>
                  </div>
                  <div className="col-md-10">
                    <input
                      id="Follow_Up_Due_Date"
                      type="date"
                      className="form-control"
                      defaultValue={newFollowUp.Follow_Up_Due_Date}
                      onChange={on_change}
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label
                      htmlFor="Follow_Up_Owner"
                      className="font-weight-bold"
                    >
                      ASSIGNED TO:
                    </label>
                    <div className="col-md-10">
                      <select
                        className="form-control"
                        onChange={onChange}
                        id="Follow_Up_Owner"
                        value={newFollowUp.Follow_Up_Owner}
                      >
                        <option value={parseInt('9999')}>UnAssigned</option>
                        {users !== undefined && !loading ? (
                          users.map((user) => (
                            <option key={user._id} value={user._id}>
                              {user.First_Name} {user.Last_Name}
                            </option>
                          ))
                        ) : (
                          <option>loading</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <div className="row">
                      <div className="col-4">
                        <label
                          className="active font-weight-bolder"
                          htmlFor="Notify_Owner"
                        >
                          NOTIFY OWNER
                        </label>
                      </div>
                      <div className="col-1">
                        <div className="form-check text-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={notify}
                            id="Notify_Follow_Up_Owner"
                            onChange={notifyFollowUpAssignment}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={cancelEdit}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  onClick={add_FollowUp}
                  className="btn btn-background"
                  data-dismiss="modal"
                >
                  ADD FOLLOW UP
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* *********** EDIT FOLLOW UP MODAL ************* */}
        <div
          className="modal fade"
          id="staticBackdropFollowUp2"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdropFollowUp2Label"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropFollowUp2Label">
                  EDIT FOLLOW UP
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={cancelEdit}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label
                      className="active font-weight-bolder"
                      htmlFor="Follow_Up_Description"
                    >
                      FOLLOW UP:
                    </label>
                  </div>
                  <div className="col-md-10">
                    <input
                      id="Edited_Follow_Up_Description"
                      type="text"
                      className="form-control"
                      value={editedFollowUp.Edited_Follow_Up_Description}
                      onChange={on_update}
                      maxLength="50"
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label
                      className="active font-weight-bolder"
                      htmlFor="Follow_Up_Description"
                    >
                      DUE DATE:
                    </label>
                  </div>
                  <div className="col-md-10">
                    <input
                      id="Edited_Follow_Up_Due_Date"
                      type="date"
                      className="form-control"
                      defaultValue={editedFollowUp.Edited_Follow_Up_Due_Date}
                      onChange={on_update}
                    />
                  </div>
                  <div className="col-md-10">
                    {' '}
                    <div className="form-group">
                      <label
                        htmlFor="Follow_Up_Owner"
                        className="font-weight-bold"
                      >
                        ASSIGNED TO
                      </label>
                      <select
                        className="form-control"
                        onChange={on_update}
                        id="Edited_Follow_Up_Owner"
                        value={editedFollowUp.Edited_Follow_Up_Owner}
                      >
                        <option value={parseInt('9999')}>UnAssigned</option>
                        {users !== undefined && !loading ? (
                          users.map((user) => (
                            <option key={user._id} value={user._id}>
                              {user.First_Name} {user.Last_Name}
                            </option>
                          ))
                        ) : (
                          <option>loading</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <div className="row">
                      <div className="col-4">
                        <label
                          className="active font-weight-bolder"
                          htmlFor="Notify_Owner"
                        >
                          NOTIFY OWNER
                        </label>
                      </div>
                      <div className="col-1">
                        <div className="form-check text-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={notify}
                            id="Notify_Follow_Up_Owner - edit"
                            onChange={notifyFollowUpAssignment}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={cancelEdit}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  onClick={update_followUp}
                  className="btn btn-background"
                  data-dismiss="modal"
                >
                  UPDATE FOLLOW UP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="followUpDeleteConfirmationModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="followUpDeleteConfirmationModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger font-weight-bold">
                DELETE CONFIRMATION
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="font-weight-bold">
                ARE YOU SURE YOU WANT TO DELETE THIS FOLLOW UP?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={cancelDeletion}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  deleteAFollowUp(removeFollowUpId);
                  setRemoveFollowUpId(null);
                }}
                data-dismiss="modal"
              >
                CONFRIM
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminAppFollowUps;
