import React, { useReducer } from 'react';
import Medication_reminderContext from './medication_reminderContext';
import medication_reminderReducer from './medication_reminderReducer';
import axios from 'axios';

import {
  GET_MEDICATION_REMINDERS,
  CREATE_MEDICATION_REMINDER,
  GET_MEDICATION_REMINDER,
  EDIT_MEDICATION_REMINDER,
  DELETE_MEDICATION_REMINDER,
  CLEAR_MEDICATION_REMINDER,
  DELETE_MEDICATION_REMINDER_FROM_STATE,
  DELETE_MEDICATION_REMINDER_BY_DOG,
} from '../types';

const Medication_reminderState = (props) => {
  const initialstate = {
    med_reminders: null,
    med_reminder: null,
  };

  const [state, dispatch] = useReducer(
    medication_reminderReducer,
    initialstate
  );

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Get Meds
  const getMedReminders = async () => {
    try {
      const res = await axios.get(`/api/medicationReminders`);
      dispatch({
        type: GET_MEDICATION_REMINDERS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Create Med
  const createMedReminder = async (data) => {
    try {
      const res = await axios.post('/api/medicationReminders', data, config);
      dispatch({
        type: CREATE_MEDICATION_REMINDER,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getMedReminder = async (id) => {
    try {
      const res = await axios.get(`/api/medicationReminders/${id}`);
      dispatch({
        type: GET_MEDICATION_REMINDER,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const editMedReminder = async (id, data) => {
    try {
      const res = await axios.put(
        `/api/medicationReminders/${id}`,
        data,
        config
      );

      dispatch({
        type: EDIT_MEDICATION_REMINDER,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteMedReminder = (id) => {
    try {
      axios.delete(`/api/medicationReminders/${id}`);
      dispatch({
        type: DELETE_MEDICATION_REMINDER,
        payload: id,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const removeReminderFromState = (id) => {
    dispatch({
      type: DELETE_MEDICATION_REMINDER_FROM_STATE,
      payload: id,
    });
  };

  const deleteMedReminderByDog = (id) => {
    try {
      axios.delete(`/api/medicationReminders/dog/${id}`);
      dispatch({
        type: DELETE_MEDICATION_REMINDER_BY_DOG,
        payload: id,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getMedRemindersByDog = async (id) => {
    try {
      const res = await axios.get(`/api/medicationReminders/dog/${id}`);
      dispatch({
        type: GET_MEDICATION_REMINDERS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getMedReminderByMedication = async (id) => {
    try {
      const res = await axios.get(`/api/medicationReminders/med/${id}`);
      dispatch({
        type: GET_MEDICATION_REMINDER,
        payload: res.data[0],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const clearMedReminder = (id) => {
    try {
      dispatch({
        type: CLEAR_MEDICATION_REMINDER,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Medication_reminderContext.Provider
      value={{
        med_reminders: state.med_reminders,
        med_reminder: state.med_reminder,
        createMedReminder,
        editMedReminder,
        deleteMedReminder,
        getMedReminder,
        getMedReminders,
        clearMedReminder,
        getMedRemindersByDog,
        getMedReminderByMedication,
        removeReminderFromState,
        deleteMedReminderByDog,
      }}
    >
      {props.children}
    </Medication_reminderContext.Provider>
  );
};

export default Medication_reminderState;
