import {
  GET_CONTACTS,
  CREATE_CONTACT,
  GET_CONTACT,
  EDIT_CONTACT,
  DELETE_CONTACT,
} from '../types';

const contactReducer = (state, action) => {
  switch (action.type) {
    case GET_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
        contactsLoading: false,
      };

    case EDIT_CONTACT:
      let newState = state.contacts.filter(
        (contact) => contact._id !== action.payload._id
      );
      let updatedState = newState.concat(action.payload);
      return {
        ...state,
        contacts: updatedState,
      };

    case DELETE_CONTACT:
      return {
        contacts: state.contacts.filter(
          (contact) => contact._id !== action.payload
        ),
      };

    case GET_CONTACT:
      return {
        //   emailLoading: false,
      };
    case CREATE_CONTACT:
      return {
        ...state,
        contacts: [action.payload, ...state.contacts],
        contactsLoading: false,
      };
    default:
      return state;
  }
};

export default contactReducer;
