import React, { useContext, useEffect, useState, useRef } from 'react';
import TicketContext from '../../../context/tickets/ticketsContext';
import './DropZone.css';

const CreateTicketFile = (props) => {
  const ticketContext = useContext(TicketContext);
  const { saveFile, saveDBFile } = ticketContext;
  const fileInputRef = useRef();
  const modalImageRef = useRef();
  const modalRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const { backToView, ticketID, user } = props;

  useEffect(() => {
    let filteredArr = selectedFiles.reduce((acc, current) => {
      const x = acc.find((item) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setValidFiles([...filteredArr]);
  }, [selectedFiles]);

  // ! ****************** DROP ZONE  CODE  *****************

  const preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
      } else {
        files[i]['invalid'] = true;
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        setErrorMessage('File type not permitted');
      }
    }
  };

  const validateFile = (file) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon',
      'application/doc',
      'application/docx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/msword',
      'video/mp4',
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  };

  const removeFile = (name) => {
    const index = validFiles.findIndex((e) => e.name === name);
    const index2 = selectedFiles.findIndex((e) => e.name === name);
    const index3 = unsupportedFiles.findIndex((e) => e.name === name);
    validFiles.splice(index, 1);
    selectedFiles.splice(index2, 1);
    setValidFiles([...validFiles]);
    setSelectedFiles([...selectedFiles]);
    if (index3 !== -1) {
      unsupportedFiles.splice(index3, 1);
      setUnsupportedFiles([...unsupportedFiles]);
    }
  };

  const openImageModal = (file) => {
    const reader = new FileReader();
    modalRef.current.style.display = 'block';
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
    };
  };

  const upload = () => {
    var x;
    for (x of selectedFiles) {
      const date = Date.now();
      const lastIndex = x.name.lastIndexOf('.') + 1;
      const fName = x.name.slice(0, lastIndex - 1);
      const ext = x.name.slice(lastIndex);
      const newFileName = `${fName}-${date}.${ext}`;

      const saveFileData = {
        file: x,
        fileName: newFileName,
      };
      const fileDBData = {
        File_Name: newFileName,
        Uploaded_By: user.First_Name + ' ' + user.Last_Name,
        Ticket_id: parseInt(ticketID),
      };
      saveFile(saveFileData);
      saveDBFile(fileDBData);
    }

    setSelectedFiles([]);
    // fileInputRef.current.value = '';
    backToView();
  };

  return (
    <div>
      <h2>CREATE FILES</h2>
      {/* ********************* FILE DROP  RETURN *********************** */}
      {unsupportedFiles.length === 0 && validFiles.length ? (
        <div className="file-display-container">
          <span className="file-error-message">{errorMessage}</span>
          {validFiles.map((data, i) => (
            <div className="file-status-bar" key={i}>
              <div className="row">
                <div className="col-lg-9">
                  <div
                    onClick={
                      !data.invalid
                        ? () => openImageModal(data)
                        : () => removeFile(data.name)
                    }
                  >
                    <span
                      className={`file-name ${
                        data.invalid ? 'file-error' : ''
                      }`}
                    >
                      {data.name}
                    </span>
                    {data.invalid && (
                      <span className="file-error-message">
                        ({errorMessage})
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-3">
                  <div
                    className="file-remove"
                    onClick={() => removeFile(data.name)}
                  >
                    REMOVE
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          className="drop-container text-center"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          id="uploadFile"
          onClick={fileInputClicked}
        >
          <div className="drop-message">
            <div className="upload-icon"></div>
            Drag & Drop file here or click to select file from computer
          </div>
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            multiple
            onChange={filesSelected}
          />
        </div>
      )}
      {/* ****************** FILE DROP END ********************** */}
      <div className="row">
        <button
          className="btn btn-secondary mt-3 col-xs-6 col-md-2 "
          onClick={backToView}
        >
          BACK
        </button>
        <div className="col-lg-8"></div>
        <button
          className="btn btn-success mt-3  col-xs-6 col-md-2"
          onClick={upload}
        >
          UPLOAD
        </button>
      </div>
    </div>
  );
};

export default CreateTicketFile;
