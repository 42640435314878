import React, { Fragment, useContext } from 'react';
import AppContext from '../../../context/app/appContext';

const AdminAppApplication = () => {
  const appContext = useContext(AppContext);
  const { loading, app } = appContext;
  return (
    <Fragment>
      {app !== null && !loading ? (
        <div className="container-fluid bg-container p-2 mb-3">
          <h4 className="text-center align-middle mt-3">
            ADOPTION APPLICATION (APP # {app._id}) - {app.First_Name}{' '}
            {app.Last_Name}{' '}
          </h4>
          <div className="card border border-grey">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  INTEREST
                </h4>
                <table className="table table-hover m-0 ">
                  <tbody>
                    <tr>
                      <th scope="row">FIRST CHOICE</th>
                      <td className="text-danger font-weight-bold">
                        {app.st_Choice}
                      </td>
                      <th scope="row">SECOND CHOICE</th>
                      <td className="text-danger font-weight-bold">
                        {app.nd_Choice}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover ">
                  <tbody>
                    <tr>
                      <th scope="row">FUTURE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Future_Dog2}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="card border border-grey mt-3">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  APPLICANT'S INFORMATION
                </h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">PRIMARY APPLICANT</th>
                      <td className="text-danger font-weight-bold">
                        {app.First_Name} {app.Middle_Initial} {app.Last_Name}{' '}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">CO-APPLICANT</th>
                      <td className="text-danger font-weight-bold">
                        {app.CoApp_Full_Name}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">PRIMARY CAREGIVER</th>
                      <td className="text-danger font-weight-bold">
                        {app.Primary_Caregiver}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">SUBMISSION DATE</th>
                      <td className="text-danger font-weight-bold">
                        {app.submit_date}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card border border-grey mt-3">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  CONTACT INFORMATION
                </h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">HOME PHONE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Phone}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">MOBILE PHONE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Mobile}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">WORK PHONE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Work}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">EMAIL ADDRESS</th>
                      <td className="text-danger font-weight-bold">
                        {app.Email}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="card border border-grey mt-2">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  ADDRESS
                </h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">STREET</th>
                      <td className="text-danger font-weight-bold">
                        {app.Street}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">CITY</th>
                      <td className="text-danger font-weight-bold">
                        {app.City}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">STATE</th>
                      <td className="text-danger font-weight-bold">
                        {app.State}
                      </td>
                      <th scope="row">ZIP</th>
                      <td className="text-danger font-weight-bold">
                        {app.Zip_Code}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">COUNTY</th>
                      <td className="text-danger font-weight-bold">
                        {app.County}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">PAST ADDRESSES</th>
                      <td className="text-danger font-weight-bold">
                        {app.Past_Addresses}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card border border-grey mt-2">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  HOUSEHOLD INFORMATION
                </h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">OLDER THAN 18?</th>
                      <td className="text-danger font-weight-bold">
                        {app.Of_Age}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">NUMBER OF PEOPLE IN HOME</th>
                      <td className="text-danger font-weight-bold">
                        {app.Number_Household}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">AGES OF CHILDREN</th>
                      <td className="text-danger font-weight-bold">
                        {app.Age_of_Children}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">NAMES OF OTHER ADULTS</th>
                      <td className="text-danger font-weight-bold">
                        {app.Other_Adult_Names}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">ALL ADULTS AWARE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Adults_Aware}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card border border-grey mt-2">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  EMPLOYMENT INFORMATION
                </h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">APPLICANT'S EMPLOYER</th>
                      <td className="text-danger font-weight-bold">
                        {app.Employer1}
                      </td>
                      <th scope="row">HOURS</th>
                      <td className="text-danger font-weight-bold">
                        {app.Hours1}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">CO-APPLICANT'S EMPLOYER</th>
                      <td className="text-danger font-weight-bold">
                        {app.Employer2}
                      </td>
                      <th scope="row">HOURS</th>
                      <td className="text-danger font-weight-bold">
                        {app.Hours2}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card border border-grey mt-2">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  REFERENCES
                </h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">REFERENCE NAME</th>
                      <td className="text-danger font-weight-bold">
                        {app.Reference_1}
                      </td>
                      <th scope="row">PHONE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Reference_1_Phone}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">REFERENCE NAME</th>
                      <td className="text-danger font-weight-bold">
                        {app.Reference_2}
                      </td>
                      <th scope="row">PHONE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Reference_2_Phone}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card border border-grey mt-2">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  PET HISTORY
                </h4>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">BREED</th>
                      <th scope="col">NAMES</th>
                      <th scope="col">AGE</th>
                      <th scope="col">SEX</th>
                      <th scope="col">VACS</th>
                      <th scope="col">FIXED</th>
                      <th scope="col">LENGTH</th>
                      <th scope="col">KEPT</th>
                      <th scope="col">WHERE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-danger font-weight-bold">
                        {app.Pet1_Breed}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet1_Name}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet1_Age}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet1_Sex}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet1_Vac}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet1_Fixed}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet1_How_long}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet1_Kept}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet1_WhereNow}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-danger font-weight-bold">
                        {app.Pet2_Breed}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet2_Name}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet2_Age}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet2_Sex}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet2_Vac}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet2_Fixed}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet2_How_long}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet2_Kept}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet2_WhereNow}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-danger font-weight-bold">
                        {app.Pet3_Breed}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet3_Name}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet3_Age}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet3_Sex}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet3_Vac}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet3_Fixed}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet3_How_long}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet3_Kept}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet3_WhereNow}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-danger font-weight-bold">
                        {app.Pet4_Breed}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet4_Name}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet4_Age}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet4_Sex}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet4_Vac}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet4_Fixed}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet4_How_long}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet4_Kept}
                      </td>
                      <td className="text-danger font-weight-bold">
                        {app.Pet4_WhereNow}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h4 className="ml-2">WERE ANY OF YOUR ANIMALS:</h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">KILLED BY VEHICLE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Killed_By_Vehicle}
                      </td>
                      <th scope="row">GIVEN AWAY</th>
                      <td className="text-danger font-weight-bold">
                        {app.Given_Away}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">LOST/STOLEN</th>
                      <td className="text-danger font-weight-bold">
                        {app.Lost_Stolen}
                      </td>
                      <th scope="row">TURNED IN</th>
                      <td className="text-danger font-weight-bold">
                        {app.Turned_In}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">NONE APPLY</th>
                      <td className="text-danger font-weight-bold">
                        {app.Nothing_Applies}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">EXPLAINED OUTCOME</th>
                      <td className="text-danger font-weight-bold">
                        {app.Outcome_Explain}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">WHAT FED PAST DOGS</th>
                      <td className="text-danger font-weight-bold">
                        {app.Pet_Food}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card border border-grey mt-2">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  VETERINARIAN INFORMATION
                </h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">VETERINARIAN NAME</th>
                      <td className="text-danger font-weight-bold">
                        {app.Vet_Name}
                      </td>
                      <th scope="row">VETERINARIAN PHONE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Vet_Number}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">PERMISSION TO CONTACT VET</th>
                      <td className="text-danger font-weight-bold">
                        {app.Vet_Perm}
                      </td>
                      <th scope="row">POLICY UNDERSTOOD</th>
                      <td className="text-danger font-weight-bold">
                        {app.Policy_Acknowledged}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">OTHER VET INFORMATION</th>
                      <td className="text-danger font-weight-bold">
                        {app.Other_Vet}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">VACCINATION EXPLAIN</th>
                      <td className="text-danger font-weight-bold">
                        {app.Vac_Explain}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="card border border-grey mt-2">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  HOME INFORMATION
                </h4>
                <h4 className="ml-2">HOME TYPE:</h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">HOUSE</th>
                      <td className="text-danger font-weight-bold">
                        {app.House}
                      </td>
                      <th scope="row">CONDO</th>
                      <td className="text-danger font-weight-bold">
                        {app.Condo}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">TOWNHOUSE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Townhouse}
                      </td>
                      <th scope="row">MOBILE HOME</th>
                      <td className="text-danger font-weight-bold">
                        {app.Mobile_Home}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">APARTMENT</th>
                      <td className="text-danger font-weight-bold">
                        {app.Apartment}
                      </td>
                      <th scope="row">RENT/OWN</th>
                      <td className="text-danger font-weight-bold">
                        {app.Own_Rent}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">LENGTH AT THIS ADDRESS</th>
                      <td className="text-danger font-weight-bold">
                        {app.Lived_Length_Home}
                      </td>
                      <th scope="row">PETS ALLOWED</th>
                      <td className="text-danger font-weight-bold">
                        {app.Pets_Permitted}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">LANDLORD NAME</th>
                      <td className="text-danger font-weight-bold">
                        {app.Landlord_Name}
                      </td>
                      <th scope="row">PHONE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Landlord_Phone}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">PLANNING TO MOVE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Moving}
                      </td>
                      <th scope="row">WHEN MOVING?</th>
                      <td className="text-danger font-weight-bold">
                        {app.When_Moving}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">WHAT WILL DO WITH PET IF MOVE</th>
                      <td className="text-danger font-weight-bold">
                        {app.If_Move}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">PET WILL BE KEPT</th>
                      <td className="text-danger font-weight-bold">
                        {app.Kept_Where}
                      </td>
                      <th scope="row">WHERE WHEN HOME</th>
                      <td className="text-danger font-weight-bold">
                        {app.Location_When_Home}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">WHERE WHEN NOT HOME</th>
                      <td className="text-danger font-weight-bold">
                        {app.Location_When_Not_Home}
                      </td>
                      <th scope="row">WHERE WHEN SLEEPING</th>
                      <td className="text-danger font-weight-bold">
                        {app.Location_When_Sleeping}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">WHERE WHEN TRAVELING</th>
                      <td className="text-danger font-weight-bold">
                        {app.Location_When_Traveling}
                      </td>
                      <th scope="row">HOW LONG ALONE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Alone_Time}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">YARD TYPE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Fence}
                      </td>
                      <th></th>
                      <td className="text-danger font-weight-bold"></td>
                      <th></th>
                      <td className="text-danger font-weight-bold"></td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">YARD DESCRIPTION</th>
                      <td className="text-left text-danger font-weight-bold">
                        {app.Fence_Size}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card border border-grey mt-2">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  PREPARATIONS
                </h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">PREPARATIONS MADE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Preperations}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">GROOMING</th>
                      <td className="text-danger font-weight-bold">
                        {app.Grooming}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">ANY ALLERGIES</th>
                      <td className="text-danger font-weight-bold">
                        {app.Allergies}
                      </td>
                      <th scope="row">IF ALLERGIES - KEEP?</th>
                      <td className="text-danger font-weight-bold">
                        {app.If_Allergies}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">OBEDIENCE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Training}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">NORMAL BEHAVIORAL PROBLEMS</th>
                      <td className="text-danger font-weight-bold">
                        {app.Normal_Behavior}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">HOW LONG TO ACCLIMATE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Acclimate}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">INTRODUCE TO OTHER PETS</th>
                      <td className="text-danger font-weight-bold">
                        {app.Introductions}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">HOW EXERCISE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Exercise}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">HOW HOUSEBREAK</th>
                      <td className="text-danger font-weight-bold">
                        {app.Housebreak}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">HOW CORRECT AN ACCIDENT</th>
                      <td className="text-danger font-weight-bold">
                        {app.Corrections}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">HOW ENCOURAGE GOOD BEHAVIOR</th>
                      <td className="text-danger font-weight-bold">
                        {app.Encourage}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">LIFETIME COMMITMENT</th>
                      <td className="text-danger font-weight-bold">
                        {app.Lifetime_Commitment}
                      </td>
                      <th scope="row"> FLEA/TICK</th>
                      <td className="text-danger font-weight-bold">
                        {app.Will_Give_Preventatives}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">MEDICAL CARE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Medical_Care}
                      </td>
                      <th scope="row"> EST COST PER YEAR</th>
                      <td className="text-danger font-weight-bold">
                        {app.Costs}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        WILL SPEND NECESSARY MONEY TO CARE FOR PET
                      </th>
                      <td className="text-danger font-weight-bold">
                        {app.Expense}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h4>
                  WHICH OF THE FOLLOWING WOULD FORCE YOU TO GIVE UP YOUR PET?
                </h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">DIVORCE/SEPARATION</th>
                      <td className="text-danger font-weight-bold">
                        {app.Divorce}
                      </td>
                      <th scope="row"> MOVE OUT OF STATE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Move_Out_Of_State}
                      </td>
                      <th scope="row"> MOVE - NO PETS</th>
                      <td className="text-danger font-weight-bold">
                        {app.Move_No_Pets}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">BARKS ALOT</th>
                      <td className="text-danger font-weight-bold">
                        {app.Barks}
                      </td>
                      <th scope="row">BIG VET BILLS</th>
                      <td className="text-danger font-weight-bold">
                        {app.Bills}
                      </td>
                      <th scope="row">NEW SPOUSE HAS ALLERGIES</th>
                      <td className="text-danger font-weight-bold">
                        {app.Partner_Allergies}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">HAVING BABY</th>
                      <td className="text-danger font-weight-bold">
                        {app.Baby}
                      </td>
                      <th scope="row">UN-TRAINABLE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Un_Trainable}
                      </td>
                      <th scope="row">DOESN'T GET ALONG OTHER PETS</th>
                      <td className="text-danger font-weight-bold">
                        {app.Pet_Incompatible}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">LOSE OF BLADDER CONTROL</th>
                      <td className="text-danger font-weight-bold">
                        {app.Bladder_Issues}
                      </td>
                      <th scope="row">NEIGHBORS COMPLAIN</th>
                      <td className="text-danger font-weight-bold">
                        {app.Neighbors}
                      </td>
                      <th scope="row">PET DEVELOPS CHRONIC</th>
                      <td className="text-danger font-weight-bold">
                        {app.Chronic_Illness}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">PET NOT AS EXPECTED</th>
                      <td className="text-danger font-weight-bold">
                        {app.Pet_Not_As_Expected}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">OTHER REASONS</th>
                      <td className="text-danger font-weight-bold">
                        {app.Other_Reasons}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">NOTHING APPLIES</th>
                      <td className="text-danger font-weight-bold">
                        {app.Nothing_Applies}
                      </td>
                      <th>IF DIE</th>
                      <td className="text-danger font-weight-bold">
                        {app.If_Die}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">CONVICTIONS</th>
                      <td className="text-danger font-weight-bold">
                        {app.Convictions}
                      </td>
                      <th>AWARE OF FEE</th>
                      <td className="text-danger font-weight-bold">
                        {app.Adoption_Aware_Fee}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card border border-grey mt-2">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="text-center bg-theme text-light mb-0 rounded">
                  ADDITIONAL INFORMATION
                </h4>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">INFORMATION</th>
                      <td className="text-danger font-weight-bold">
                        {app.Description}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h4>Loading</h4>
      )}
    </Fragment>
  );
};

export default AdminAppApplication;
