import React, { Fragment, useContext, useEffect, useState } from 'react';
import TicketContext from '../../../context/tickets/ticketsContext';
import ReactHtmlParser from 'react-html-parser';
import TicketNotes from './TicketNotes';
import TicketFiles from './TicketFiles';
import CreateTicketNote from './CreateTicketNote';
import CreateTicketFile from './CreateTicketFile';
import EditTicketNote from './EditTicketNote';

const ViewTicket = (props) => {
  const ticketContext = useContext(TicketContext);
  const {
    ticket,
    getTicketNotes,
    getTicketNote,
    getTicketFiles,
    tnotes,
    tfiles,
    deleteTicketNote,
    removeTicketFile,
    downloadTicketFile,
  } = ticketContext;
  const [showTicketNotes, setShowTicketNotes] = useState(true);
  const [showTicketFiles, setShowTicketFiles] = useState(true);
  const [showNewTicketNote, setShowNewTicketNote] = useState(false);
  const [showNewTicketFile, setShowNewTicketFile] = useState(false);
  const [showEditTicketNote, setShowEditTicketNote] = useState(false);
  const [showNoNotesMessage, setShowNoNotesMessage] = useState(false);
  const [showNoFilesMessage, setShowNoFilesMessage] = useState(false);
  const [removeTicketNoteId, setRemoveTicketNoteId] = useState(null);
  const { backToList, editTicket, user } = props;

  useEffect(() => {
    if (ticket !== null) {
      getTicketNotes(ticket._id);
      getTicketFiles(ticket._id);
    }
    // eslint-disable-next-line
  }, [ticket]);

  useEffect(() => {
    checkForTicketNotes();
    // eslint-disable-next-line
  }, [tnotes]);

  useEffect(() => {
    checkForTicketFiles();
    // eslint-disable-next-line
  }, [tfiles]);

  const checkForTicketNotes = () => {
    if (tnotes !== null) {
      if (tnotes.length === 0) {
        setShowNewTicketNote(false);
        setShowNoNotesMessage(true);
      } else {
        setShowNoNotesMessage(false);
      }
    } else {
      setShowNoNotesMessage(false);
    }
  };

  const checkForTicketFiles = () => {
    if (tfiles !== null) {
      if (tfiles.length === 0) {
        // setShowNewTicketFile(false);
        setShowNoFilesMessage(true);
      } else {
        setShowNoFilesMessage(false);
      }
    } else {
      setShowNoFilesMessage(false);
    }
  };

  const formDate = (string) => {
    if (string === null) {
      return 'Pending';
    } else {
      var d = new Date(string);
      var mm = ('0' + (d.getMonth() + 1)).slice(-2);
      var dd = ('0' + d.getDate()).slice(-2);
      var yy = d.getFullYear();

      var myDateString = mm + '-' + dd + '-' + yy; //(US)

      return myDateString;
    }
  };

  const backToView = () => {
    checkForTicketFiles();
    checkForTicketNotes();
    setShowTicketNotes(true);
    setShowNewTicketNote(false);
    setShowEditTicketNote(false);
    setShowTicketFiles(true);
    setShowNewTicketFile(false);
  };

  const createNewTicketNote = () => {
    setShowTicketNotes(false);
    setShowNewTicketNote(true);
    setShowNoNotesMessage(false);
  };

  const createNewTicketFile = () => {
    setShowNewTicketFile(true);
    setShowTicketFiles(false);
    setShowNoFilesMessage(false);
  };

  const editTicketNote = (id) => {
    getTicketNote(id);
    setShowTicketNotes(false);
    setShowNewTicketNote(false);
    setShowNoNotesMessage(false);
    setShowEditTicketNote(true);
  };

  const deleteATicketNote = (e) => {
    deleteTicketNote(removeTicketNoteId);
  };

  const removeTicketNote = (e) => {
    setRemoveTicketNoteId(e);
  };

  const cancelDeletion = () => {
    setRemoveTicketNoteId(null);
  };

  if (ticket !== null) {
    return (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="card create_ticket_card rounded shadow-lg">
                <div className="card-body text-dark">
                  <div className="row">
                    <div className="col-lg-11 p-0"></div>
                    <div
                      className="col-lg-1 text-right text-danger p-0 pr-3 pb-2 font-weight-bold pointer "
                      title="BACK TO TICKET LIST"
                      onClick={backToList}
                    >
                      <h5 className="font-weight-bold">X</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-lg-2 p-0 pl-3">
                      <h4 className="text-left font-weight-bold">SUBJECT:</h4>
                    </div>
                    <div className="col-xs-12 col-lg-10 ml-3 p-0">
                      <h4 className="text-left text-dark font-weight-bold">
                        {ticket.Subject}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-lg-6">
                      <table className="table table-secondary rounded mt-2 pt-3">
                        <tbody>
                          <tr>
                            <th className="text-left">TICKET #:</th>
                            <td>{ticket._id}</td>
                          </tr>
                          <tr>
                            <th className="text-left">TYPE:</th>
                            <td>{ticket.Ticket_Type}</td>
                          </tr>
                          <tr>
                            <th className="text-left">SUBMITTER:</th>
                            <td>{ticket.Submitter}</td>
                          </tr>
                          <tr>
                            <th className="text-left">FEATURE RELEASE:</th>
                            <td>{ticket.Feature_Release}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-xs-12 col-lg-6">
                      <table className="table table-secondary rounded mt-2 pt-3">
                        <tbody>
                          <tr>
                            <th className="text-left">TICKET STATUS:</th>
                            <td>{ticket.Status}</td>
                          </tr>
                          <tr>
                            <th className="text-left">SUBMITTED DATE:</th>
                            <td>{formDate(ticket.createdAt)}</td>
                          </tr>
                          <tr>
                            <th className="text-left">MODIFIED DATE:</th>
                            <td>{formDate(ticket.updatedAt)}</td>
                          </tr>
                          <tr>
                            <th className="text-left">COMPLETED DATE:</th>
                            <td>{formDate(ticket.Completion_Date)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <table className="table table-secondary rounded mt-2 pt-3">
                    <tbody>
                      <tr>
                        <td className="p-4">
                          {ReactHtmlParser(ticket.Request_Details)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-lg-9"></div>
                    <div className="col-lg-3">
                      <button
                        className="btn btn-danger mr-2"
                        onClick={backToList}
                      >
                        BACK
                      </button>
                      <button
                        className="btn btn-warning text-dark"
                        onClick={editTicket}
                      >
                        EDIT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
        <div className="container mt-4">
          {showTicketNotes ? (
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-9">
                <h4>NOTES</h4>
              </div>
              <div className="col-lg-2">
                <button
                  className="btn btn-success"
                  onClick={createNewTicketNote}
                >
                  ADD
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            {showTicketNotes ? (
              <div className="col-lg-10">
                {tnotes !== null
                  ? tnotes.map((tnote) => (
                      <TicketNotes
                        tnote={tnote}
                        key={tnote._id}
                        removeTicketNote={removeTicketNote}
                        editTicketNote={editTicketNote}
                      />
                    ))
                  : null}
              </div>
            ) : null}
            {showNewTicketNote ? (
              <CreateTicketNote
                backToView={backToView}
                ticketID={ticket._id}
                user={user}
              />
            ) : null}
            {showEditTicketNote ? (
              <EditTicketNote
                backToView={backToView}
                ticketID={ticket._id}
                user={user}
              />
            ) : null}
            {showNoNotesMessage ? (
              <div className="container mt-3">
                <div className="row">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-10">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="text-center">NO TICKET NOTES</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="container mt-4">
          {showTicketFiles ? (
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-9">
                <h4>FILES</h4>
              </div>
              <div className="col-lg-2">
                <button
                  className="btn btn-success"
                  onClick={createNewTicketFile}
                >
                  ADD
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <div className="container mt-3">
          <div className="row">
            <div className="col-lg-1"></div>
            {showTicketFiles ? (
              <div className="col-lg-10">
                <table className="table table-secondary">
                  <thead>
                    <tr>
                      <th scope="col">FILE NAME</th>
                      <th scope="col">UPLOADED</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tfiles !== null
                      ? tfiles.map((tfile) => (
                          <tr key={tfile._id}>
                            <TicketFiles
                              tfile={tfile}
                              id={tfile._id}
                              key={tfile._id}
                              removeTicketFile={removeTicketFile}
                              downloadTicketFile={downloadTicketFile}
                            />
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            ) : null}
            {showNewTicketFile ? (
              <div className="col-lg-10">
                <CreateTicketFile
                  backToView={backToView}
                  ticketID={ticket._id}
                  user={user}
                />
              </div>
            ) : null}

            {showNoFilesMessage ? (
              <div className="container mt-3">
                <div className="row">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-10">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="text-center">NO TICKET FILES</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div
          className="modal fade"
          id="ticketNoteDeleteConfirmationModal"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ticketNoteDeleteConfirmationModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-danger font-weight-bold">
                  DELETE CONFIRMATION
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="font-weight-bold">
                  ARE YOU SURE YOU WANT TO DELETE THIS TICKET NOTE?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={cancelDeletion}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    deleteATicketNote(removeTicketNoteId);
                    setRemoveTicketNoteId(null);
                  }}
                  data-dismiss="modal"
                >
                  CONFRIM
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default ViewTicket;
