import React, { useState, useContext, useEffect } from 'react';
import TicketContext from '../../../context/tickets/ticketsContext';
import TextEditor from '../../../utils/TextEditor';

const EditTicketNote = (props) => {
  const ticketContext = useContext(TicketContext);
  const { editTicketNote, tnote } = ticketContext;
  const { backToView } = props;
  const [noteBody, setNoteBody] = useState('');

  useEffect(() => {
    if (tnote !== null) {
      setNoteBody(tnote.Note);
    }
    // eslint-disable-next-line
  }, []);

  const submitNote = () => {
    const noteData = {
      _id: tnote._id,
      Note: noteBody,
    };
    editTicketNote(noteData);
    setNoteBody('');
    backToView();
    window.scroll(0, 0);
  };

  if (tnote !== null) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="card create_ticket_card rounded shadow-lg">
              <div className="card-body text-dark">
                <form>
                  <div className="form-group">
                    <TextEditor
                      textData={tnote.Note}
                      setTextData={setNoteBody}
                    />
                  </div>
                </form>
                <div className="row">
                  <div className="col-lg-2">
                    <button className="btn btn-danger" onClick={backToView}>
                      CANCEL
                    </button>
                  </div>
                  <div className="col-lg-8"></div>
                  <div className="col-lg 2">
                    <button className="btn btn-success" onClick={submitNote}>
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default EditTicketNote;
