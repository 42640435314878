import React, { Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';

const TicketNotes = (props) => {
  const { tnote, removeTicketNote, editTicketNote } = props;

  const formDate = (string) => {
    if (string === null) {
      return 'Pending';
    } else {
      var d = new Date(string);
      var mm = ('0' + (d.getMonth() + 1)).slice(-2);
      var dd = ('0' + d.getDate()).slice(-2);
      var yy = d.getFullYear();

      var myDateString = mm + '-' + dd + '-' + yy; //(US)

      return myDateString;
    }
  };

  return (
    <Fragment>
      {tnote !== null && tnote !== undefined ? (
        <div className="card create_ticket_card rounded">
          <div className="card-header">
            <div className="row">
              <div className="col-1">
                <i
                  className="fa fa-trash text-danger pointer mr-2"
                  title="Delete Note"
                  data-toggle="modal"
                  data-target="#ticketNoteDeleteConfirmationModal"
                  onClick={() => removeTicketNote(tnote._id)}
                  aria-hidden="true"
                ></i>
              </div>

              <div className="col-10"></div>
              <div className="col-1 text-right ">
                <i
                  className="fa fa-pencil text-warning text-right pointer"
                  title="Edit Note"
                  onClick={() => editTicketNote(tnote._id)}
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
          <div className="card-body text-dark">
            <div className="note_body bg-white rounded">
              {ReactHtmlParser(tnote.Note)}
            </div>
          </div>

          <div className="row mx-2">
            <div className="col-4 text-left font-weight-bold">
              BY: {tnote.Author}
            </div>
            <div className="col-4 text-center font-weight-bold">
              CREATED: {formDate(tnote.createdAt)}
            </div>
            <div className="col-4 text-right font-weight-bold">
              UPDATED: {formDate(tnote.updatedAt)}
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default TicketNotes;
