import React from 'react';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'America/New_York',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const AdminDashFollowUpsList = (props) => {
  const { fu, viewFollowUp, viewZohoFollowUp } = props;

  const clickToViewApp = () => {
    if (fu.app_id.toString().includes('zcrm')) {
      viewZohoFollowUp(fu.app_id);
    } else {
      viewFollowUp(fu.app_id);
    }
  };

  return (
    <tr className="d-flex">
      <td onClick={clickToViewApp} className="text-center pointer col-lg-3">
        {fu.full_Name}
      </td>
      <td onClick={clickToViewApp} className="text-center pointer col-lg-6">
        {fu.follow_up}
      </td>
      <td onClick={clickToViewApp} className="text-center pointer col-lg-3">
        {formatDate(fu.due_date)}
      </td>
    </tr>
  );
};

export default AdminDashFollowUpsList;
