import React, { useReducer, useContext } from 'react';
import UserContext from './userContext';
import axios from 'axios';
import userReducer from './userReducer';
import AlertContext from '../alert/alertContext';

import {
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  CLEAR_CURRENT,
  GET_USER,
  GET_USERS,
  UPDATE_CURRENT,
} from '../types';

const UserState = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialstate = {
    user: null,
    current: null,
    clearCurrent: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(userReducer, initialstate);

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Get Users
  const getUsers = async (id) => {
    try {
      const res = await axios.get(`/api/user/`);
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Create USER
  const createUser = async (data) => {
    axios.post('/api/user', data, config);
    setAlert('User Created', 'success');
    try {
      dispatch({
        type: CREATE_USER,
        payload: data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getUser = async (e) => {
    try {
      const res = await axios.get(`/api/user/${e}`);

      const updatedData = {
        _id: res.data._id,
        First_Name: res.data.First_Name,
        Last_Name: res.data.Last_Name,
        Username: res.data.Username,
        Role: res.data.Role,
        email_signature: res.data.email_signature,
      };
      dispatch({
        type: GET_USER,
        payload: updatedData,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const editCurrent = (e) => {
    dispatch({
      type: UPDATE_CURRENT,
      payload: e,
    });
  };

  const editUser = async (e) => {
    if (e.newPassword === '') {
      const updateData = {
        First_Name: e.First_Name,
        Last_Name: e.Last_Name,
        Username: e.Username,
        Role: e.Role,
        email_signature: e.email_signature,
      };
      try {
        const res = await axios.put(
          `/api/user/${e.userID}`,
          updateData,
          config
        );
        dispatch({
          type: EDIT_USER,
          payload: res.data,
        });
        setAlert('User Updated', 'success');
      } catch (err) {
        console.log(err);
      }
    } else {
      const updateData = {
        First_Name: e.First_Name,
        Last_Name: e.Last_Name,
        Username: e.Username,
        Role: e.Role,
        Password: e.newPassword,
        email_signature: e.email_signature,
      };

      try {
        const res = await axios.put(
          `/api/user/${e.userID}`,
          updateData,
          config
        );
        dispatch({
          type: EDIT_USER,
          payload: res.data,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const deleteUser = (e) => {
    try {
      axios.delete(`/api/user/${e}`, e);
      dispatch({
        type: DELETE_USER,
        payload: e,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const clearCurrent = (e) => {
    dispatch({
      type: CLEAR_CURRENT,
    });
  };

  return (
    <UserContext.Provider
      value={{
        users: state.users,
        current: state.current,
        loading: state.loading,
        clearCurrent,
        createUser,
        editUser,
        deleteUser,
        getUser,
        getUsers,
        editCurrent,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
