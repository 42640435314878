import React, { Fragment, useContext, useState } from 'react';
import AppContext from '../../context/app/appContext';
import AdminContext from '../../context/admin/adminContext';
import AuthContext from '../../context/auth/authContext';

const AppNavbar = () => {
  const appContext = useContext(AppContext);
  const authContext = useContext(AuthContext);
  const adminContext = useContext(AdminContext);
  const { appPageID, appTab, app } = appContext;
  const { user } = authContext;
  const { currentlyEditing, doneEditing } = adminContext;
  const [navPage, setNavPage] = useState(null);

  const loadTab = (e) => {
    if (!currentlyEditing) {
      appTab(e.target.value);
    } else {
      openModal();
      setNavPage(e.target.value);
    }
  };

  const continueWithoutSavingSubMenu = () => {
    doneEditing();
    appTab(navPage);
    setNavPage(null);
  };

  // ! ******** SAFE GUARD  MODAL CODE **********

  function openModal() {
    document.getElementById('backDrop').style.display = 'block';
    document.getElementById('editingSubModal').style.display = 'block';
    document.getElementById('editingSubModal').className += 'show';
  }
  function closeModal() {
    document.getElementById('backDrop').style.display = 'none';
    document.getElementById('editingSubModal').style.display = 'none';
    document.getElementById(
      'editingSubModal'
    ).className += document
      .getElementById('editingSubModal')
      .className.replace('show', '');
  }
  // Get the modal
  var modal = document.getElementById('editingSubModal');

  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function (event) {
    if (event.target === modal) {
      closeModal();
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div
          className="modal fade"
          id="editingSubModal"
          data-backdrop="static"
          tabIndex="-1"
          aria-labelledby="editingSubModalLabel"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-danger font-weight-bold">
                  UNSAVED DATA
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={closeModal}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="font-weight-bold">
                  YOU HAVE AN UNSAVED NOTE OR EMAIL... DO YOU WANT TO CONTINUE
                  WIHTOUT SAVING?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={closeModal}
                  className="btn btn-secondary"
                  // data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    continueWithoutSavingSubMenu();
                    closeModal();
                  }}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                {appPageID === 'profile' ? (
                  <button className="btn nav-link active">PROFILE</button>
                ) : (
                  <button
                    className="btn nav-link"
                    onClick={loadTab}
                    value="profile"
                  >
                    PROFILE
                  </button>
                )}
              </li>
              <li className="nav-item">
                {appPageID === 'application' ? (
                  <button className="btn nav-link active">APPLICATION</button>
                ) : (
                  <button
                    className="btn nav-link"
                    onClick={loadTab}
                    value="application"
                  >
                    APPLICATION
                  </button>
                )}
              </li>
              <li className="nav-item">
                {appPageID === 'activities' ? (
                  <button className="btn nav-link active">ACTIVITIES</button>
                ) : (
                  <button
                    className="btn nav-link"
                    onClick={loadTab}
                    value="activities"
                  >
                    ACTIVITIES
                  </button>
                )}
              </li>

              <li className="nav-item">
                {appPageID === 'email' ? (
                  <button className="btn nav-link active">EMAILS</button>
                ) : (
                  <button
                    className="btn nav-link"
                    onClick={loadTab}
                    value="email"
                  >
                    EMAILS
                  </button>
                )}
              </li>
              {app !== null && app.Application_Type !== 'Foster' ? (
                <li className="nav-item">
                  {appPageID === 'adoptions' ? (
                    <button className="btn nav-link active">ADOPTIONS</button>
                  ) : (
                    <button
                      className="btn nav-link"
                      onClick={loadTab}
                      value="adoptions"
                    >
                      ADOPTIONS
                    </button>
                  )}
                </li>
              ) : null}

              <li className="nav-item">
                {appPageID === 'files' ? (
                  <button className="btn nav-link active">FILES</button>
                ) : (
                  <button
                    className="btn nav-link"
                    onClick={loadTab}
                    value="files"
                  >
                    FILES
                  </button>
                )}
              </li>
              {user.Role === 'Admin' || user.Role === 'Manager' ? (
                <li className="nav-item">
                  {appPageID === 'contract' ? (
                    <button className="btn nav-link active">CONTRACT</button>
                  ) : (
                    <button
                      className="btn nav-link"
                      onClick={loadTab}
                      value="contract"
                    >
                      CONTRACT
                    </button>
                  )}
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppNavbar;
