import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AdminDashMyAppList from '../dashboard/AdminDashMyAppList';

const AdminDashMyApps = (props) => {
  const { user } = props;
  const [userApps, setUserApps] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    axios
      .get(`/api/database/apps/user/${user._id}`, {
        cancelToken: source.token,
      })
      .then((response) => {
        if (mounted) {
          setUserApps(response.data);
          mounted = false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      source.cancel('Component got unmounted - My Application Follow UPs');
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className="card shadow col-12 ">
      <h5 className="card-title text-center font-weight-bold mt-2">
        MY ASSIGNED APPLICATIONS
      </h5>
      <div className="card-body">
        <table className="table table-hover table-striped table-borderless table-dark">
          <thead>
            <tr className="d-flex">
              <th className="text-center col-lg-3" scope="col">
                NAME
              </th>

              <th
                className="text-center d-none d-lg-block col-lg-3"
                scope="col"
              >
                TYPE
              </th>
              <th className="text-center col-lg-3" scope="col">
                STATUS
              </th>
              <th className="text-center col-lg-3" scope="col">
                DATE
              </th>
            </tr>
          </thead>

          <tbody>
            {userApps !== null && userApps !== undefined
              ? userApps.map((app) => (
                  <AdminDashMyAppList app={app} key={app._id} />
                ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminDashMyApps;
