import {
  GET_EMAIL_TEMPLATES,
  CREATE_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_ATTACHMENTS,
  CLEAR_EMAIL_TEMPLATE_ATTACHMENTS,
} from '../types';

const emailTemplateReducer = (state, action) => {
  switch (action.type) {
    case GET_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: action.payload,
        templateLoading: false,
      };

    case CREATE_EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplates: [action.payload, ...state.emailTemplates],
      };
    case GET_EMAIL_TEMPLATE_ATTACHMENTS:
      return {
        ...state,
        emailTemplatesAttachments: action.payload,
      };
    case CLEAR_EMAIL_TEMPLATE_ATTACHMENTS:
      return {
        ...state,
        emailTemplatesAttachments: [],
      };
    default:
      return state;
  }
};

export default emailTemplateReducer;
