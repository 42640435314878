import {
  GET_DOGS,
  CREATE_DOG,
  GET_DOG,
  EDIT_DOG,
  DELETE_DOG,
  GET_DOG_FILES,
  DELETE_DOG_FILE,
  UPLOAD_DOG_FILE,
  GET_PET_PICTURE,
  CLEAR_DOG_FROM_STATE,
} from '../types';

const dogReducer = (state, action) => {
  switch (action.type) {
    case GET_DOGS:
      return {
        ...state,
        dogs: action.payload,
        availDogs: action.payload,
        dogsLoading: false,
      };

    case EDIT_DOG:
      return {
        ...state,
        dog: action.payload,
      };

    case DELETE_DOG:
      return {
        ...state,
        dogs: state.dogs.filter((dog) => dog._id !== action.payload),
      };

    case GET_DOG:
      return {
        ...state,
        dog: action.payload,
      };
    case GET_PET_PICTURE:
      return {
        ...state,
        dog_picture_url: action.payload,
      };
    case CREATE_DOG:
      return {
        ...state,
        dogs: [action.payload, ...state.dogs],
        availDogs: [action.payload, ...state.dogs],
        dogsLoading: false,
      };

    case GET_DOG_FILES:
      return {
        ...state,
        dogFiles: action.payload,
      };
    case UPLOAD_DOG_FILE:
      return {
        ...state,
        dogFiles: [action.payload, ...state.dogFiles],
      };
    case DELETE_DOG_FILE:
      return {
        ...state,
        dogFiles: state.dogFiles.filter(
          (file) => file._id !== parseInt(action.payload)
        ),
      };
    case CLEAR_DOG_FROM_STATE:
      return {
        ...state,
        dog: null,
        dog_picture_url: null,
      };
    default:
      return state;
  }
};

export default dogReducer;
