import React, { Fragment, useContext, useState, useEffect } from 'react';
import AdminContext from '../../context/admin/adminContext';
import AlertContext from '../../context/alert/alertContext';
import AppContext from '../../context/app/appContext';

const AdminNewApplication = () => {
  const adminContext = useContext(AdminContext);
  const { applicationList } = adminContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const appContext = useContext(AppContext);
  const { manualSubmitApp } = appContext;

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  function formatDate(string) {
    var options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'America/New_York',
    };
    return new Date(string).toLocaleDateString('en-US', options);
  }

  const today = formatDate(Date.now());

  const [newApplication, setNewApplication] = useState({
    Application_Type: 'Adoption',
    Owner_Id: '1045',
    Application_Status: 'New Application',
    st_Choice: '',
    nd_Choice: '',
    Future_Dog2: '',
    submit_date: today,
    First_Name: '',
    Middle_Inital: '',
    Last_Name: '',
    CoApp_Full_Name: '',
    Landlord_Name: '',
    Landlord_Phone: '',
    Vet_Name: '',
    Vet_Number: '',
    Reference_1: '',
    Reference_2: '',
    Reference_1_Phone: '',
    Reference_2_Phone: '',
    Interest_Adult: '',
    Interest_Puppy: '',
    Interest_Mom: '',
    Interest_Litter: '',
    Interest_Set2Pups: '',
    Interest_NoPref: '',
    Interest_Male: '',
    Interest_Female: '',
    Interest_Either: '',
    Interest_Large: '',
    Interest_Medium: '',
    Interest_Small: '',
    Interest_SzNoPref: '',
    Interest_AddNeeds: '',
    Foster_Length: '',
  });

  const onChange = (e) => {
    setNewApplication({ ...newApplication, [e.target.id]: e.target.value });
  };

  let isChecked = (e) => {
    if (newApplication[e.target.id] === '') {
      setNewApplication({ ...newApplication, [e.target.id]: 'checked' });
    } else {
      setNewApplication({ ...newApplication, [e.target.id]: '' });
    }
  };

  const submit_application = (e) => {
    e.preventDefault();
    manualSubmitApp(newApplication);
    setNewApplication({
      Application_Type: 'Adoption',
      Owner_Id: '1045',
      Application_Status: 'New Application',
      st_Choice: '',
      nd_Choice: '',
      Future_Dog2: '',
      submit_date: today,
      First_Name: '',
      Middle_Inital: '',
      Last_Name: '',
      CoApp_Full_Name: '',
      Landlord_Name: '',
      Landlord_Phone: '',
      Vet_Name: '',
      Vet_Number: '',
      Reference_1: '',
      Reference_2: '',
      Reference_1_Phone: '',
      Reference_2_Phone: '',
      Interest_Adult: '',
      Interest_Puppy: '',
      Interest_Mom: '',
      Interest_Litter: '',
      Interest_Set2Pups: '',
      Interest_NoPref: '',
      Interest_Male: '',
      Interest_Female: '',
      Interest_Either: '',
      Interest_Large: '',
      Interest_Medium: '',
      Interest_Small: '',
      Interest_SzNoPref: '',
      Interest_AddNeeds: '',
      Foster_Length: '',
    });
    setAlert('APPLICATION ENTERED SUCCESSFULLY', 'danger');
    applicationList();
  };

  const cancel = () => {
    applicationList();
  };

  return (
    <Fragment>
      <div className="container bg-container">
        <h4 className="text-center align-middle mt-3">
          MANUAL ADOPTION APPLICATION ENTRY FORM
        </h4>
        <form>
          {/* ******************* APPLICANT INTEREST ****************** */}
          <div className="card ">
            <div className="card-body">
              <div className="card-title bg-theme">
                <div className="row bg-theme">
                  <div className="col-12 p-0">
                    <h4 className="text-center bg-theme text-light rounded m-0">
                      INTEREST
                    </h4>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      htmlFor="Application_Type"
                      className=" font-weight-bold"
                    >
                      Application_Type:
                    </label>
                    <select
                      type="text"
                      className="form-control"
                      id="Application_Type"
                      onChange={onChange}
                    >
                      <option>Adoption</option>
                      <option>Foster</option>
                    </select>
                  </div>
                </div>
              </div>
              {newApplication.Application_Type === 'Adoption' ? (
                <Fragment>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="st_Choice" className="font-weight-bold">
                          FIRST CHOICE:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="st_Choice"
                          onChange={onChange}
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="nd_Choice" className=" font-weight-bold">
                        {' '}
                        SECOND CHOICE{' '}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="nd_Choice"
                        onChange={onChange}
                      ></input>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label
                        htmlFor="Future_Dog2"
                        className=" font-weight-bold"
                      >
                        FUTURE
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="Future_Dog2"
                        onChange={onChange}
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="row pl-3">
                    <h5 className="my-1 font-weight-bold">
                      I AM INTERESTED IN FOSTERING:
                    </h5>
                    <span>
                      <p className="pl-2 mt-1">(check all that apply)</p>
                    </span>
                  </div>
                  <div className="row mx-2">
                    <div className="form-check col-lg-2 col-xl-1">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="Interest_Adult"
                        checked={newApplication.Interest_Adult}
                        onChange={isChecked}
                      />
                      <label
                        className="form-check-label p-0 ml-1 font-weight-bold"
                        htmlFor="Interest_Adult"
                      >
                        Adult
                      </label>
                    </div>
                    <div className="form-check  col-lg-2 col-xl-1">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="Interest_Puppy"
                        checked={newApplication.Interest_Puppy}
                        onChange={isChecked}
                      />
                      <label
                        className="form-check-label p-0 ml-1 font-weight-bold"
                        htmlFor="Interest_Puppy"
                      >
                        Puppy
                      </label>
                    </div>
                    <div className="form-check  col-lg-3 col-xl-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="Interest_Mom"
                        checked={newApplication.Interest_Mom}
                        onChange={isChecked}
                      />
                      <label
                        className="form-check-label p-0 ml-1 font-weight-bold"
                        htmlFor="Interest_Mom"
                      >
                        Mom with babies
                      </label>
                    </div>
                    <div className="form-check  col-lg-2 col-xl-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="Interest_Litter"
                        checked={newApplication.Interest_Litter}
                        onChange={isChecked}
                      />
                      <label
                        className="form-check-label p-0 ml-1 font-weight-bold"
                        htmlFor="Interest_Litter"
                      >
                        Litter of puppies
                      </label>
                    </div>
                    <div className="form-check  col-lg-2 col-xl-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="Interest_Set2Pups"
                        checked={newApplication.Interest_Set2Pups}
                        onChange={isChecked}
                      />
                      <label
                        className="form-check-label p-0 ml-1 font-weight-bold"
                        htmlFor="Interest_Set2Pups"
                      >
                        Set of two pups
                      </label>
                    </div>
                    <div className="form-check col-lg-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="Interest_NoPref"
                        checked={newApplication.Interest_NoPref}
                        onChange={isChecked}
                      />
                      <label
                        className="form-check-label p-0 ml-1 font-weight-bold"
                        htmlFor="Interest_NoPref"
                      >
                        No Preference
                      </label>
                    </div>
                  </div>
                  <div className="row mt-1 pl-3">
                    <h5 className="font-weight-bold">Gender preference</h5>
                  </div>
                  <div className="row">
                    <div className="form-check  col-lg-2 col-xl-2">
                      <input
                        type="checkbox"
                        className="Interest_Male"
                        id="Interest_Male"
                        checked={newApplication.Interest_Male}
                        onChange={isChecked}
                      />
                      <label
                        className="form-check-label p-0 ml-1 font-weight-bold"
                        htmlFor="Interest_Male"
                      >
                        Male
                      </label>
                    </div>
                    <div className="form-check  col-lg-2 col-xl-2">
                      <input
                        type="checkbox"
                        className="Interest_Female"
                        id="Interest_Female"
                        checked={newApplication.Interest_Female}
                        onChange={isChecked}
                      />
                      <label
                        className="form-check-label p-0 ml-1 font-weight-bold"
                        htmlFor="Interest_Female"
                      >
                        Female
                      </label>
                    </div>
                    <div className="form-check  col-lg-2 col-xl-2">
                      <input
                        type="checkbox"
                        className="Interest_Either"
                        id="Interest_Either"
                        checked={newApplication.Interest_Either}
                        onChange={isChecked}
                      />
                      <label
                        className="form-check-label p-0 ml-1 font-weight-bold"
                        htmlFor="Interest_Either"
                      >
                        Either
                      </label>
                    </div>
                  </div>
                  <div className="row pl-3 mt-1">
                    <h5 className="font-weight-bold">Size preference</h5>
                  </div>
                  <div className="row">
                    <div className="form-check  col-lg-2 col-xl-2">
                      <input
                        type="checkbox"
                        className="Interest_Large"
                        id="Interest_Large"
                        checked={newApplication.Interest_Large}
                        onChange={isChecked}
                      />
                      <label
                        className="form-check-label p-0 ml-1 font-weight-bold"
                        htmlFor="Interest_Large"
                      >
                        Large
                      </label>
                    </div>
                    <div className="form-check  col-lg-2 col-xl-2">
                      <input
                        type="checkbox"
                        className="Interest_Medium"
                        id="Interest_Medium"
                        checked={newApplication.Interest_Medium}
                        onChange={isChecked}
                      />
                      <label
                        className="form-check-label p-0 ml-1 font-weight-bold"
                        htmlFor="Interest_Medium"
                      >
                        Medium
                      </label>
                    </div>
                    <div className="form-check  col-lg-2 col-xl-2">
                      <input
                        type="checkbox"
                        className="Interest_Small"
                        id="Interest_Small"
                        checked={newApplication.Interest_Small}
                        onChange={isChecked}
                      />
                      <label
                        className="form-check-label p-0 ml-1 font-weight-bold"
                        htmlFor="Interest_Small"
                      >
                        Small
                      </label>
                    </div>
                    <div className="form-check  col-lg-2 col-xl-2">
                      <input
                        type="checkbox"
                        className="Interest_SzNoPref"
                        id="Interest_SzNoPref"
                        checked={newApplication.Interest_SzNoPref}
                        onChange={isChecked}
                      />
                      <label
                        className="form-check-label p-0 ml-1 font-weight-bold"
                        htmlFor="Interest_SzNoPref"
                      >
                        No Preference
                      </label>
                    </div>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mt-1">
                    <div className="row pl-3 mt-2">
                      <label className="active" htmlFor="Interest_AddNeeds">
                        <h5 className="font-weight-bold">
                          Please describe any additional preferences or needs:
                        </h5>
                      </label>
                    </div>
                    <div className="row">
                      <textarea
                        id="Interest_AddNeeds"
                        type="text"
                        className="form-control"
                        rows="4"
                        value={newApplication.Interest_AddNeeds}
                        onChange={onChange}
                        maxLength="1500"
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mt-1">
                      <label
                        className="active  font-weight-bold"
                        htmlFor="Foster_Length"
                      >
                        <h5 className="font-weight-bold">
                          How long can you foster?
                        </h5>
                      </label>
                      <input
                        id="Foster_Length"
                        type="text"
                        className="form-control"
                        value={newApplication.Foster_Length}
                        onChange={onChange}
                        maxLength="100"
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          {/* ******************* APPLICANT INFORMATION ****************** */}
          <div className="card mt-3">
            <div className="card-body">
              <div className="card-title bg-theme">
                <div className="row bg-theme">
                  <div className="col-12 p-0">
                    <h4 className="text-center bg-theme text-light rounded m-0">
                      APPLICANT
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="First_Name" className=" font-weight-bold">
                      FIRST NAME:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="First_Name"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="Middle_Initial" className=" font-weight-bold">
                    {' '}
                    MIDDLE NAME:{' '}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Middle_Initial"
                    onChange={onChange}
                  ></input>
                </div>
                <div className="col-md-4">
                  <label htmlFor="Last_Name" className=" font-weight-bold">
                    {' '}
                    LAST NAME{' '}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Last_Name"
                    onChange={onChange}
                  ></input>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label
                      htmlFor="CoApp_Full_Name"
                      className=" font-weight-bold"
                    >
                      C0-APPLICANT FULL NAME:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="CoApp_Full_Name"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ******************* CONTACT INFORMATION ****************** */}
          <div className="card mt-3">
            <div className="card-body">
              <div className="card-title bg-theme">
                <div className="row bg-theme">
                  <div className="col-12 p-0">
                    <h4 className="text-center bg-theme text-light rounded m-0">
                      CONTACT INFORMATION
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="Phone" className=" font-weight-bold">
                      HOME PHONE:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Phone"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
                <div className="col-md-4">
                  <label htmlFor="Mobile" className=" font-weight-bold">
                    {' '}
                    MOBILE PHONE:{' '}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Mobile"
                    onChange={onChange}
                  ></input>
                </div>
                <div className="col-md-4">
                  <label htmlFor="Work" className=" font-weight-bold">
                    {' '}
                    WORK PHONE{' '}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="Work"
                    onChange={onChange}
                  ></input>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="Email" className=" font-weight-bold">
                      EMAIL ADDRESS:
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="Email"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ******************* ADDRESS INFORMATION ****************** */}
          <div className="card mt-3">
            <div className="card-body">
              <div className="card-title bg-theme">
                <div className="row bg-theme">
                  <div className="col-12 p-0">
                    <h4 className="text-center bg-theme text-light rounded m-0">
                      ADDRESS INFORMATION
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="Street" className=" font-weight-bold">
                      STREET:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Street"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="City" className=" font-weight-bold">
                      City:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="City"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label htmlFor="State" className=" font-weight-bold">
                      STATE:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="State"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="Zip_Code" className=" font-weight-bold">
                      Zip_Code:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Zip_Code"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="County" className=" font-weight-bold">
                      COUNTY:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="County"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ******************* REFERENCES INFORMATION ****************** */}
          <div className="card mt-3">
            <div className="card-body">
              <div className="card-title bg-theme">
                <div className="row bg-theme">
                  <div className="col-12 p-0">
                    <h4 className="text-center bg-theme text-light rounded m-0">
                      REFERENCES
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label
                      htmlFor="Landlord_Name"
                      className=" font-weight-bold"
                    >
                      LANDLORD NAME:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Landlord_Name"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label
                      htmlFor="Landlord_Phone"
                      className=" font-weight-bold"
                    >
                      PHONE:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Landlord_Phone"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="Vet_Name" className=" font-weight-bold">
                      VET NAME:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Vet_Name"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="Vet_Number" className=" font-weight-bold">
                      PHONE:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Vet_Number"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="Reference_1" className=" font-weight-bold">
                      REFERENCE NAME:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Reference_1"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label
                      htmlFor="Reference_1_Phone"
                      className=" font-weight-bold"
                    >
                      PHONE:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Reference_1_Phone"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="Reference_2" className=" font-weight-bold">
                      REFERENCE NAME:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Reference_2"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label
                      htmlFor="Reference_2_Phone"
                      className=" font-weight-bold"
                    >
                      PHONE:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Reference_2_Phone"
                      onChange={onChange}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4 my-4">
              <button
                type="button"
                onClick={cancel}
                className="btn btn-danger mb-2"
              >
                CANCEL
              </button>
              <button
                type="submit"
                onClick={submit_application}
                className="btn btn-dark mb-2 ml-2"
              >
                SUBMIT
              </button>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default AdminNewApplication;
