import React from 'react';
import axios from 'axios';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const AdminZohoFile = (props) => {
  const { Old_Attachment_Id, File_Name, Size, createdAt } = props.file;
  const { confirmDeleteId, zusers, users } = props;

  const fetchOwnerName = (id) => {
    if (id !== null) {
      if (id.includes('zcrm')) {
        const res = zusers.filter((user) => {
          return user.User_ID === id;
        });
        const owner = res[0].First_Name + ' ' + res[0].Last_Name;

        return owner;
      } else if (!id.includes('zcrm') && users !== undefined) {
        const res = users.filter((user) => {
          return user._id === parseInt(id);
        });
        const owner = res[0].First_Name + ' ' + res[0].Last_Name;

        return owner;
      }
    }
  };

  const downloadThisFile = async (e) => {
    axios(`/api/zohoFiles/${e.target.id}`, {
      method: 'GET',
      responseType: 'blob',
    })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers.content_type,
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <tr>
      <td>{File_Name}</td>
      <td>{Size}</td>
      <td>{fetchOwnerName(props.file.Attachment_Owner_ID)}</td>
      <td>{formatDate(createdAt)}</td>
      <td className="text-right">
        <i
          className="fa fa-download mr-3 pointer"
          aria-hidden="true"
          id={Old_Attachment_Id}
          onClick={downloadThisFile}
        ></i>

        <i
          className="fa fa-trash-o pointer text-danger mr-3"
          onClick={() => confirmDeleteId(Old_Attachment_Id)}
          id={Old_Attachment_Id}
          data-toggle="modal"
          data-target="#fileDeleteConfirmationModal"
          aria-hidden="true"
        ></i>
      </td>
    </tr>
  );
};

export default AdminZohoFile;
