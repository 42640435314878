const formatDateDashed = (string) => {
  const theDate = new Date(string);
  const a = theDate.toISOString();
  const yy = a.slice(0, 4);
  const mm = a.slice(5, 7);
  const dd = a.slice(8, 10);

  var myDateString = yy + '-' + mm + '-' + dd; //(US)

  return myDateString;
};

module.exports = formatDateDashed;
