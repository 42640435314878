import React, { useEffect, useState, useContext } from 'react';
import AdoptionContext from '../../context/adoption/adoptionContext';
import AppContext from '../../context/app/appContext';
import AdminContext from '../../context/admin/adminContext';
import AdoptListItems from '../layout/AdopListItems';

const formatDateDashed = (string) => {
  var d = new Date(string);
  var mm = ('0' + (d.getMonth() + 1)).slice(-2);
  var dd = ('0' + d.getDate()).slice(-2);
  var yy = d.getFullYear();

  var myDateString = yy + '-' + mm + '-' + dd; //(US)

  return myDateString;
};

const AdoptionsList = () => {
  const adoptionContext = useContext(AdoptionContext);
  const appContext = useContext(AppContext);
  const adminContext = useContext(AdminContext);
  const { adoptions, getAllAdoptions, updateAdoption } = adoptionContext;
  const { apps, getApps, getApp } = appContext;
  const { viewApp } = adminContext;

  const [searchData, setSearchData] = useState('');
  const [filteredAdoptions, setFilteredAdoptions] = useState([]);
  const [pageToDisplay, setPageToDisplay] = useState('list');
  const [editedAdoption, setEditedAdoption] = useState([]);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    getApps();
    getAllAdoptions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [adoptions]);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [searchData]);

  const search = () => {
    if (adoptions !== null) {
      const searchResults = adoptions.filter((adoption) => {
        const regex = new RegExp(`${searchData}`, 'gi');
        return (
          adoption.Dog_Adopted.match(regex) ||
          adoption.MicroChip.match(regex) ||
          adoption.MicroChip_Brand.match(regex)
        );
      });
      setFilteredAdoptions(searchResults);
    } else {
      setFilteredAdoptions(adoptions);
    }
  };

  const searchEntry = (e) => {
    setSearchData(e.target.value);
    search();
  };

  const editAdoption = (adoption) => {
    setPageToDisplay('edit');
    setEditedAdoption(adoption);
  };

  const on_edit = (e) => {
    setEditedAdoption({ ...editedAdoption, [e.target.id]: e.target.value });
  };

  const cancelEdit = () => {
    setPageToDisplay('list');
    setEditedAdoption([]);
    setReadOnly(true);
    window.scroll(0, 0);
  };

  const save_editedAdoption = () => {
    updateAdoption(editedAdoption);
    setPageToDisplay('list');
    setEditedAdoption([]);
    setReadOnly(true);
    window.scroll(0, 0);
  };

  const toggleEdit = () => {
    setReadOnly(false);
  };

  const goToApp = () => {
    getApp(editedAdoption.Adoption_AppId);
    viewApp(editedAdoption.Adoption_AppId);
    window.scroll(0, 0);
  };

  if (pageToDisplay === 'list') {
    return (
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-lg-2">
            <div className="container">
              <form>
                <div className="form-group">
                  <div className="row">
                    <label htmlFor="search" className="font-weight-bold">
                      SEARCH
                    </label>
                  </div>
                  <div className="row">
                    <input
                      type="search"
                      value={searchData}
                      className="form-control"
                      placeholder="Dog Name / Micro Chip"
                      id="search"
                      autoComplete="off"
                      onChange={searchEntry}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-10">
            <table className="table table-striped table-hover border border-dark">
              <thead>
                <tr className="bg-theme text-white">
                  <th className="text-center" scope="col">
                    DOG
                  </th>
                  <th className="text-center" scope="col">
                    ADOPTERS
                  </th>
                  <th className="text-center" scope="col">
                    APP ID
                  </th>
                  <th className="text-center" scope="col">
                    ADOPTION DATE
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredAdoptions !== null && apps !== null
                  ? filteredAdoptions.map((adoption) => (
                      <AdoptListItems
                        key={adoption._id}
                        adoption={adoption}
                        apps={apps}
                        editAdoption={editAdoption}
                      />
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container mt-3 p-3 bg-white shadow">
        <form>
          <div className="container">
            <h2 className="text-center">EDIT ADOPTION</h2>
            <div className="form-group">
              <div className="row">
                <label
                  className="active font-weight-bolder"
                  htmlFor="AdopterName"
                >
                  ADOPTER'S NAME:
                </label>
              </div>
              <div className="row">
                <input
                  id="AdopterName"
                  type="text"
                  className="form-control"
                  value={editedAdoption.AdopterName}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <label
                  className="active font-weight-bolder"
                  htmlFor="Dog_Adopted"
                >
                  DOG ADOPTED:
                </label>
              </div>
              <div className="row">
                <input
                  id="Dog_Adopted"
                  type="text"
                  className="form-control"
                  value={editedAdoption.Dog_Adopted}
                  onChange={on_edit}
                  readOnly={readOnly}
                  maxLength="100"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <label
                  className="active font-weight-bolder"
                  htmlFor="MicroChip"
                >
                  MICROCHIP #
                </label>
              </div>
              <div className="row">
                <input
                  id="MicroChip"
                  type="text"
                  className="form-control"
                  value={editedAdoption.MicroChip}
                  onChange={on_edit}
                  readOnly={readOnly}
                  maxLength="100"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <label
                  className="active font-weight-bolder"
                  htmlFor="MicroChip_Brand"
                >
                  MICROCHIP BRAND:
                </label>
              </div>
              <div className="row">
                <input
                  id="MicroChip_Brand"
                  type="text"
                  className="form-control"
                  value={editedAdoption.MicroChip_Brand}
                  onChange={on_edit}
                  readOnly={readOnly}
                  maxLength="100"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <label className="active font-weight-bolder" htmlFor="Fixed">
                  FIXED:
                </label>
                <select
                  id="Fixed"
                  className="form-control"
                  value={editedAdoption.Fixed}
                  onChange={on_edit}
                  readOnly={readOnly}
                >
                  <option>Select</option>
                  <option>YES</option>
                  <option>NO</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <label
                  className="active font-weight-bolder"
                  htmlFor="Adoption_Date"
                >
                  ADOPTION DATE:
                </label>
                <input
                  id="Adoption_Date"
                  type="date"
                  className="form-control"
                  value={formatDateDashed(editedAdoption.Adoption_Date)}
                  onChange={on_edit}
                  readOnly={readOnly}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="button container">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={cancelEdit}
          >
            CANCEL
          </button>

          {readOnly ? (
            <button
              type="button"
              onClick={toggleEdit}
              className="btn btn-warning ml-3"
            >
              EDIT
            </button>
          ) : (
            <button
              type="button"
              onClick={save_editedAdoption}
              className="btn btn-background ml-3"
            >
              SAVE EMAIL
            </button>
          )}
          {readOnly ? (
            <button
              type="button"
              className="btn btn-success ml-3"
              onClick={goToApp}
            >
              VIEW APP
            </button>
          ) : null}
        </div>
      </div>
    );
  }
};

export default AdoptionsList;
