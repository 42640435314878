import React, { useContext, useEffect, useState } from 'react';
import TicketsContext from '../../../context/tickets/ticketsContext';
import TicketListItem from './TicketListItem';
import CreateTicket from './CreateTicket';
import EditTicket from './EditTicket';
import ViewTicket from './ViewTicket';
import './tickets.css';

const Tickets = (props) => {
  const ticketContext = useContext(TicketsContext);
  const {
    tickets,
    getTickets,
    deleteTicket,
    getTicket,
    ticketsLoading,
  } = ticketContext;
  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [removeTicketId, setRemoveTicketId] = useState(null);
  const [filteredTickets, setFilteredTickets] = useState(null);
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [searchBar, setSearchBar] = useState('');
  const [sortOrderType, setSortOrderType] = useState('ASC');
  const [displayedTickets, setDisplayedTickets] = useState(null);
  const { user } = props;

  useEffect(() => {
    getTickets();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tickets !== null) {
      filterTheTickets();
    }
    // eslint-disable-next-line
  }, [tickets, statusFilter, searchBar]);

  useEffect(() => {
    setDisplayedTickets(filteredTickets);
  }, [filteredTickets]);

  const filterTheTickets = () => {
    if (statusFilter !== 'ALL' && !ticketsLoading) {
      const filteredTicketsList = tickets.filter((ticket) => {
        return ticket.Status === statusFilter;
      });

      const searchResults = filteredTicketsList.filter((ticket) => {
        const regex = new RegExp(`${searchBar}`, 'gi');
        if (ticket.Feature_Release !== null) {
          return (
            ticket.Subject.match(regex) ||
            ticket.Request_Details.match(regex) ||
            ticket.Feature_Release.match(regex)
          );
        } else {
          return (
            ticket.Subject.match(regex) || ticket.Request_Details.match(regex)
          );
        }
      });

      setFilteredTickets(searchResults);
    } else if (tickets !== null) {
      const searchResults = tickets.filter((ticket) => {
        const regex = new RegExp(`${searchBar}`, 'gi');
        if (ticket.Feature_Release !== null) {
          return (
            ticket.Subject.match(regex) ||
            ticket.Request_Details.match(regex) ||
            ticket.Feature_Release.match(regex)
          );
        } else {
          return (
            ticket.Subject.match(regex) || ticket.Request_Details.match(regex)
          );
        }
      });

      setFilteredTickets(searchResults);
    }
  };

  const resetFilter = () => {
    setSearchBar('');
    setStatusFilter('ALL');
    document.getElementById('SearchBar').value = '';
  };

  const sortList = (e) => {
    if (sortOrderType === 'asc') {
      setSortOrderType('dsc');
    } else {
      setSortOrderType('asc');
    }
    setDisplayedTickets(displayedTickets.sort(sortedList(e.target.id)));
  };

  const sortedList = (property) => {
    return (a, b) => {
      if (sortOrderType === 'dsc') {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  };

  const search = (e) => {
    setSearchBar(e.target.value);
  };

  const selectFilters = (e) => {
    setStatusFilter(e.target.value);
  };

  const clickNewTicket = () => {
    setShowCreate(true);
  };

  const viewTicket = (id) => {
    window.scroll(0, 0);

    getTicket(id);
    setShowView(true);
  };

  const editTicket = () => {
    window.scroll(0, 0);
    setShowView(false);
    setShowEdit(true);
  };

  const editTheTicket = (id) => {
    window.scroll(0, 0);
    getTicket(id);
    setShowView(false);
    setShowEdit(true);
  };

  const backToList = () => {
    setShowCreate(false);
    setShowEdit(false);
    setShowView(false);
    window.scroll(0, 0);
  };

  const deleteATicket = (e) => {
    deleteTicket(removeTicketId);
  };

  const removeTicket = (e) => {
    setRemoveTicketId(e.target.id);
  };

  const cancelDeletion = () => {
    setRemoveTicketId(null);
  };

  if (showCreate && !showEdit && !showView) {
    return <CreateTicket backToList={backToList} user={user} />;
  }
  if (!showCreate && showEdit && !showView) {
    return <EditTicket backToList={backToList} user={user} />;
  }
  if (!showCreate && !showEdit && showView) {
    return (
      <ViewTicket backToList={backToList} editTicket={editTicket} user={user} />
    );
  }
  if (!showCreate && !showEdit && !showView) {
    return (
      <div className="ticket_main_container">
        <div className="ticket_top_side_left_container">
          <div className="row">
            <div className="col-lg-6">
              <input
                className="col-12 mt-2"
                type="search"
                id="SearchBar"
                onChange={search}
                placeholder="Search Tickets"
              />
            </div>
            <div className="col-lg-4">
              <form>
                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-2">
                      <label htmlFor="Status" className="font-weight-bold mt-2">
                        STATUS:
                      </label>
                    </div>
                    <div className="col-lg-10 pl-4">
                      <select
                        name="Status"
                        id="Status"
                        onChange={selectFilters}
                        value={statusFilter}
                        className="custom-select"
                      >
                        <option>ALL</option>
                        <option>NEW</option>
                        <option>OPEN</option>
                        <option>PENDING RELEASE</option>
                        <option>CLOSED</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-2">
              <button className="btn btn-danger" onClick={resetFilter}>
                {' '}
                RESET
              </button>
            </div>
          </div>
        </div>
        <div className="ticket_side_container">
          <button className="btn btn-success" onClick={clickNewTicket}>
            NEW TICKET
          </button>
        </div>
        <div className="ticket_list_container">
          <table className="table table-striped table-secondary table-hover rounded shadow">
            <thead>
              <tr>
                <th
                  className="text-center d-none d-md-table-cell pointer"
                  scope="col"
                  id="_id"
                >
                  #
                </th>
                <th
                  className="text-center d-none d-md-table-cell pointer"
                  scope="col"
                  id="Ticket_Type"
                  onClick={sortList}
                >
                  TICKET TYPE
                </th>
                <th
                  className="text-center pointer"
                  scope="col"
                  id="Subject"
                  onClick={sortList}
                >
                  SUBJECT
                </th>
                <th
                  className="text-center  d-none d-md-table-cell pointer"
                  scope="col"
                  id="Submitter"
                  onClick={sortList}
                >
                  SUBMITTER
                </th>
                <th
                  className="text-center  d-none d-md-table-cell"
                  scope="col"
                  id="Feature_Release"
                >
                  RELEASE
                </th>
                <th
                  className="text-center pointer"
                  scope="col"
                  id="Status"
                  onClick={sortList}
                >
                  STATUS
                </th>
                <th
                  className="text-center d-none d-md-table-cell pointer"
                  scope="col"
                  id="createdAt"
                  onClick={sortList}
                >
                  {' '}
                  SUBMISSION
                </th>
                <th
                  className="text-center  d-none d-md-table-cell"
                  scope="col"
                  id="Completion_Date"
                >
                  {' '}
                  COMPLETION
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {displayedTickets !== null
                ? displayedTickets.map((ticket) => (
                    <TicketListItem
                      ticket={ticket}
                      key={ticket._id}
                      viewTicket={viewTicket}
                      editTicket={editTheTicket}
                      removeTicket={removeTicket}
                    />
                  ))
                : null}
            </tbody>
          </table>
        </div>
        <div
          className="modal fade"
          id="ticketDeleteConfirmationModal"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ticketDeleteConfirmationModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-danger font-weight-bold">
                  DELETE CONFIRMATION
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="font-weight-bold">
                  ARE YOU SURE YOU WANT TO DELETE THIS TICKET?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={cancelDeletion}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    deleteATicket(removeTicketId);
                    setRemoveTicketId(null);
                  }}
                  data-dismiss="modal"
                >
                  CONFRIM
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Tickets;
