import React, { Fragment } from 'react';
import mmddyy from '../../../utils/mmddyy';

const TicketFiles = (props) => {
  const { tfile, removeTicketFile, downloadTicketFile } = props;

  const deleteFile = () => {
    removeTicketFile(tfile._id);
  };

  return (
    <Fragment>
      <td
        onClick={() => downloadTicketFile(tfile._id)}
        className="pointer font-weight-bold"
      >
        {tfile.File_Name}
      </td>
      <td>{mmddyy(tfile.createdAt)}</td>
      <td className="text-danger font-weight-bold pointer" onClick={deleteFile}>
        DELETE
      </td>
    </Fragment>
  );
};

export default TicketFiles;
