import React, { useEffect, useState, useContext } from 'react';
import KnowledgeBaseContext from '../../../context/knowledgeBase/knowledgeBaseContext';
import KnowledgeBaseListItems from './KnowledgeBaseListItems';
import TextEditor from '../../../utils/TextEditor';
import ReactHtmlParser from 'react-html-parser';

const KnowledgeBase = (props) => {
  const knowledgeBaseContext = useContext(KnowledgeBaseContext);
  const { kbs, getKBs, updateKB, createKB, deleteKB } = knowledgeBaseContext;
  const [searchData, setSearchData] = useState('');
  const [filteredKBs, setFilteredKBs] = useState([]);
  const [pageToDisplay, setPageToDisplay] = useState('list');
  const [editedKB, setEditedKB] = useState([]);
  const [editedKBbody, setEditedKBbody] = useState('');
  const [newKBbody, setNewKBbody] = useState('');
  const [newKB, setNewKB] = useState([]);
  const [readOnly, setReadOnly] = useState(true);
  const [removeKbID, setRemoveKbID] = useState(null);
  const { user } = props;

  useEffect(() => {
    getKBs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [kbs]);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [searchData]);

  const createAKB = () => {
    setPageToDisplay('create');
  };

  const on_change = (e) => {
    setNewKB({ ...newKB, [e.target.id]: e.target.value });
  };

  const cancelCreate = () => {
    setPageToDisplay('list');
    setNewKB([]);
    setNewKBbody('');
    setReadOnly(true);
    window.scroll(0, 0);
  };

  const save_newKB = () => {
    const entry = {
      author: user.First_Name + ' ' + user.Last_Name,
      title: newKB.title,
      body: newKBbody,
    };
    createKB(entry);
    setPageToDisplay('list');
    setNewKB([]);
    setNewKBbody([]);
    setReadOnly(true);
    window.scroll(0, 0);
  };

  const editKB = (kb) => {
    setPageToDisplay('edit');
    setEditedKB(kb);
    setEditedKBbody(kb.body);
  };

  const on_edit = (e) => {
    setEditedKB({ ...editedKB, [e.target.id]: e.target.value });
  };

  const cancelEdit = () => {
    setPageToDisplay('list');
    setEditedKB([]);
    setEditedKBbody([]);
    setReadOnly(true);
    window.scroll(0, 0);
  };

  const save_editedKB = () => {
    const updatedKB = {
      _id: editedKB._id,
      title: editedKB.title,
      author: editedKB.author,
      body: editedKBbody,
    };

    updateKB(updatedKB);
    setPageToDisplay('list');
    setEditedKB([]);
    setEditedKBbody('');
    setReadOnly(true);
    window.scroll(0, 0);
  };

  const toggleEdit = () => {
    setReadOnly(false);
  };

  const removeKb = (id) => {
    setRemoveKbID(id);
  };

  const cancelDeletion = () => {
    setRemoveKbID(null);
  };

  const search = () => {
    if (kbs !== null && kbs !== undefined) {
      const searchResults = kbs.filter((kb) => {
        const regex = new RegExp(`${searchData}`, 'gi');
        return kb.title.match(regex) || kb.body.match(regex);
      });
      setFilteredKBs(searchResults);
    } else {
      setFilteredKBs(kbs);
    }
  };

  const searchEntry = (e) => {
    setSearchData(e.target.value);
    search();
  };

  if (pageToDisplay === 'list') {
    return (
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-lg-2">
            <div className="container">
              <button className="btn btn-success" onClick={createAKB}>
                CREATE
              </button>
              <form>
                <div className="form-group mt-3">
                  <div className="row">
                    <label htmlFor="search" className="font-weight-bold">
                      SEARCH
                    </label>
                  </div>
                  <div className="row">
                    <input
                      type="search"
                      value={searchData}
                      className="form-control"
                      placeholder="SEARCH KNOWLEDGE BASE"
                      id="search"
                      autoComplete="off"
                      onChange={searchEntry}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-10">
            <table className="table table-striped table-hover border border-dark">
              <thead>
                <tr className="bg-theme text-white">
                  <th className="text-center" scope="col">
                    TITLE
                  </th>
                  <th className="text-center" scope="col">
                    AUTHOR
                  </th>

                  <th className="text-center" scope="col">
                    DATE
                  </th>
                  {user.Role === 'Admin' || user.Role === 'Manager' ? (
                    <th></th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {filteredKBs !== null
                  ? filteredKBs.map((kb) => (
                      <KnowledgeBaseListItems
                        user={user}
                        key={kb._id}
                        kb={kb}
                        editKB={editKB}
                        removeKb={removeKb}
                      />
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="modal fade"
          id="KBDeleteConfirmationModal"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="KBDeleteConfirmationModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-danger font-weight-bold">
                  DELETE CONFIRMATION
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="font-weight-bold">
                  ARE YOU SURE YOU WANT TO DELETE THIS RECORD?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={cancelDeletion}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    deleteKB(removeKbID);
                    setRemoveKbID(null);
                  }}
                  data-dismiss="modal"
                >
                  CONFRIM
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (pageToDisplay === 'edit') {
    return (
      <div className="container mt-3 p-3 bg-white shadow">
        <div></div>
        <form>
          <div className="container">
            <div className="form-group">
              <div className="row">
                <label className="active font-weight-bolder" htmlFor="title">
                  TITLE:
                </label>
              </div>
              <div className="row">
                <input
                  id="title"
                  type="text"
                  onChange={on_edit}
                  className="form-control"
                  defaultValue={editedKB.title}
                  readOnly={readOnly}
                />
              </div>
              <div className="row mt-2">
                {readOnly ? (
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        {ReactHtmlParser(editedKBbody)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <TextEditor
                    textData={editedKBbody}
                    setTextData={setEditedKBbody}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="button container">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={cancelEdit}
          >
            CANCEL
          </button>
          {readOnly ? (
            <button
              type="button"
              onClick={toggleEdit}
              className="btn btn-warning ml-3"
            >
              EDIT
            </button>
          ) : (
            <button
              type="button"
              onClick={save_editedKB}
              className="btn btn-background ml-3"
            >
              SAVE
            </button>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="container mt-3 p-3 bg-white shadow">
        <div></div>
        <form>
          <div className="container">
            <h2 className="text-center">CREATE KNOWLEDGE BASE</h2>
            <div className="form-group">
              <div className="row">
                <label className="active font-weight-bolder" htmlFor="title">
                  TITLE:
                </label>
              </div>
              <div className="row">
                <input
                  id="title"
                  type="text"
                  className="form-control"
                  onChange={on_change}
                />
              </div>
              <div className="row mt-2">
                {/* <CKEditor
                  config={theEditorConfig}
                  data={newKBbody}
                  editor={ClassicEditor}
                  onChange={handle_change}
                  id="post"
                /> */}

                <TextEditor textData={newKBbody} setTextData={setNewKBbody} />
              </div>
            </div>
          </div>
        </form>
        <div className="button container">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={cancelCreate}
          >
            CANCEL
          </button>
          <button
            type="button"
            onClick={save_newKB}
            className="btn btn-success ml-3"
          >
            SAVE
          </button>
        </div>
      </div>
    );
  }
};

export default KnowledgeBase;
