import React, { Fragment, useEffect, useContext, useState } from 'react';
import ContactsContext from '../../context/contacts/contactsContext';
import ContactsListItems from '../layout/contactsListItems';

const ContactsList = () => {
  const contactsContext = useContext(ContactsContext);
  const {
    contacts,
    contactsLoading,
    getContacts,
    createContact,
    deleteContact,
    editContact,
  } = contactsContext;

  // const [step, setStep] = useState('viewContact');
  const [step, setStep] = useState('contactList');

  const resetData = {
    First_Name: '',
    Last_Name: '',
    Organization: '',
    Contact_Type: '',
    Email_Address: '',
    Phone: '',
    Phone2: '',
    Notes: '',
    Address_1: '',
    Address_2: '',
    City: '',
    State: '',
    Zip_Code: '',
  };

  const [newContact, setNewContact] = useState(resetData);
  const [editedContact, setEditedContact] = useState(resetData);
  const [contact2Edit, setContact2Edit] = useState(resetData);
  const [contact2View, setContact2View] = useState(resetData);
  const [displayedContacts, setDisplayedContacts] = useState(resetData);
  const [sortOrderType, setSortOrderType] = useState('ASC');
  const [searchBar, setSearchBar] = useState('');
  const [removeContactId, setRemoveContactId] = useState(null);

  const {
    First_Name,
    Last_Name,
    Organization,
    Contact_Type,
    Email_Address,
    Phone,
    Phone2,
    Notes,
    Address_1,
    Address_2,
    City,
    State,
    Zip_Code,
  } = newContact;

  useEffect(() => {
    getContacts();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!contactsLoading) {
      setDisplayedContacts(contacts);
    }
    // eslint-disable-next-line
  }, [contacts]);

  useEffect(() => {
    searchContacts();
    // eslint-disable-next-line
  }, [searchBar]);

  const updateContact = () => {
    setStep('contactList');
    editContact(editedContact);
    setEditedContact(resetData);
    setContact2Edit(resetData);
  };

  const onChange = (e) => {
    setNewContact({ ...newContact, [e.target.name]: e.target.value });
  };
  const onEdit = (e) => {
    setEditedContact({ ...editedContact, [e.target.name]: e.target.value });
  };

  const add_Contact = () => {
    createContact(newContact);
    setStep('contactList');
    setNewContact(resetData);
  };

  const viewAContact = (e) => {
    setStep('viewContact');
    setContact2View(e);
  };

  const editAContact = (e) => {
    setStep('editContact');
    setContact2Edit(e);
    setEditedContact(e);
  };

  const editTheContact = () => {
    setStep('editContact');
    setContact2Edit(contact2View);
    setEditedContact(contact2View);
    setContact2View(resetData);
  };

  const deleteAContact = (e) => {
    deleteContact(e);
  };

  const createNewContact = () => {
    setStep('newContact');
  };

  const cancelCreate = (e) => {
    setStep('contactList');
    setContact2View(resetData);
    setContact2Edit(resetData);
    setNewContact(resetData);
  };

  const sortList = (e) => {
    if (sortOrderType === 'asc') {
      setSortOrderType('dsc');
    } else {
      setSortOrderType('asc');
    }
    setDisplayedContacts(displayedContacts.sort(sortedList(e.target.id)));
  };

  const sortedList = (property) => {
    return (a, b) => {
      if (sortOrderType === 'dsc') {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  };

  const search = (e) => {
    setSearchBar(e.target.value);
  };

  const searchContacts = () => {
    if (contacts !== null || !contactsLoading) {
      const searchResults = contacts.filter((contact) => {
        const regex = new RegExp(`${searchBar}`, 'gi');
        return (
          contact.First_Name.match(regex) ||
          contact.Last_Name.match(regex) ||
          contact._id === parseInt(searchBar)
        );
      });
      setDisplayedContacts(searchResults);
    }
  };

  const removeContact = (e) => {
    setRemoveContactId(e);
  };

  const cancelDeletion = () => {
    setRemoveContactId(null);
  };

  switch (step) {
    default:
    case 'contactList':
      return (
        <Fragment>
          <div
            className="modal fade"
            id="contactDeleteConfirmationModal"
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="contactDeleteConfirmationModal"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-danger font-weight-bold">
                    DELETE CONFIRMATION
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p className="font-weight-bold">
                    ARE YOU SURE YOU WANT TO DELETE THIS CONTACT?
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={cancelDeletion}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    CANCEL
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      deleteAContact(removeContactId);
                      setRemoveContactId(null);
                    }}
                    data-dismiss="modal"
                  >
                    CONFRIM
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <h2 className="text-center">PPR CONTACTS</h2>
            <div className="row">
              <div className="col-md-6">
                <form>
                  <div className="form-group">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="SEARCH"
                      onChange={search}
                    ></input>
                  </div>
                </form>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-background font-weight-bold"
                  onClick={createNewContact}
                >
                  Create Contact
                </button>
              </div>
            </div>
            <table className="table table-striped mt-3">
              <thead>
                <tr className="bg-theme text-white">
                  <th
                    scope="col"
                    onClick={sortList}
                    className="pointer"
                    id="First_Name"
                  >
                    FIRST
                  </th>
                  <th
                    scope="col"
                    onClick={sortList}
                    className="pointer"
                    id="Last_Name"
                  >
                    LAST
                  </th>

                  <th
                    scope="col"
                    onClick={sortList}
                    className="pointer"
                    id="Contact_Type"
                  >
                    TYPE
                  </th>
                  <th
                    scope="col"
                    onClick={sortList}
                    className="pointer text-center d-none d-md-table-cell"
                    id="City"
                  >
                    LOCATION
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {displayedContacts !== undefined &&
                !contactsLoading &&
                displayedContacts.length > 0 ? (
                  displayedContacts.map((contact) => (
                    <ContactsListItems
                      contact={contact}
                      key={contact._id}
                      viewAContact={viewAContact}
                      removeContact={removeContact}
                      editAContact={editAContact}
                    />
                  ))
                ) : (
                  <tr>
                    <td>loading</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Fragment>
      );

    case 'newContact':
      return (
        <Fragment>
          <div></div>
          <div className="container">
            <h2 className="text-center">NEW CONTACT</h2>
            <div className="container-fluid bg-container p-2">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card border border-grey">
                    <table className="table">
                      <tbody>
                        <tr className="bg-theme text-light">
                          <th>CONTACT INFORMATION</th>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table">
                      <tbody>
                        <tr>
                          <th scope="row">First Name</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="First_Name"
                              value={First_Name}
                              onChange={onChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Last Name</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Last_Name"
                              value={Last_Name}
                              onChange={onChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Organization</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Organization"
                              value={Organization}
                              onChange={onChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Address 1</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Address_1"
                              value={Address_1}
                              onChange={onChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Address 2</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Address_2"
                              value={Address_2}
                              onChange={onChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">City</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="City"
                              value={City}
                              onChange={onChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">State</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="State"
                              value={State}
                              onChange={onChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Zip</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Zip_Code"
                              value={Zip_Code}
                              onChange={onChange}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card border border-grey">
                    <table className="table">
                      <tbody>
                        <tr className="bg-theme text-light">
                          <th scope="row">CONTACT DETAILS</th>
                          <td></td>
                        </tr>
                        <tr>
                          <th scope="row">Contact Type</th>
                          <td>
                            <select
                              name="Contact_Type"
                              className="form-control"
                              onChange={onChange}
                            >
                              <option value={Contact_Type}>Pick One</option>
                              <option>Foster</option>
                              <option>Processor</option>
                              <option>Processor & Foster</option>
                              <option>Administration</option>
                              <option>Rescue</option>
                              <option>Transporter</option>
                              <option>Vendor</option>
                              <option>Veterinarians Office</option>
                              <option>IT</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Phone</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Phone"
                              value={Phone}
                              onChange={onChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Phone</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Phone2"
                              value={Phone2}
                              onChange={onChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Email</th>
                          <td>
                            <input
                              className="form-control"
                              type="email"
                              name="Email_Address"
                              value={Email_Address}
                              onChange={onChange}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <form>
                      <div className="row col-10 mx-1">
                        <label htmlFor="Notes" className="font-weight-bold">
                          Notes
                        </label>
                      </div>
                      <div className="row">
                        <div className="form-group col-11 mx-2">
                          <textarea
                            className="form-control"
                            type="text"
                            name="Notes"
                            value={Notes}
                            onChange={onChange}
                            rows="8"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8"></div>
              <div className="col-lg-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={cancelCreate}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  onClick={add_Contact}
                  className="btn btn-background ml-2"
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      );
    case 'editContact':
      return (
        <Fragment>
          <div></div>
          <div className="container">
            <h2 className="text-center">EDIT CONTACT</h2>
            <div className="container-fluid bg-container p-2">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card border border-grey">
                    <table className="table">
                      <tbody>
                        <tr className="bg-theme text-light">
                          <th>CONTACT INFORMATION</th>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table">
                      <tbody>
                        <tr>
                          <th scope="row">First Name</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="First_Name"
                              defaultValue={contact2Edit.First_Name}
                              onChange={onEdit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Last Name</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Last_Name"
                              defaultValue={contact2Edit.Last_Name}
                              onChange={onEdit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Organization</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Organization"
                              defaultValue={contact2Edit.Organization}
                              onChange={onEdit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Address 1</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Address_1"
                              defaultValue={contact2Edit.Address_1}
                              onChange={onEdit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Address 2</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Address_2"
                              defaultValue={contact2Edit.Address_2}
                              onChange={onEdit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">City</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="City"
                              defaultValue={contact2Edit.City}
                              onChange={onEdit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">State</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="State"
                              defaultValue={contact2Edit.State}
                              onChange={onEdit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Zip</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Zip_Code"
                              defaultValue={contact2Edit.Zip_Code}
                              onChange={onEdit}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card border border-grey">
                    <table className="table">
                      <tbody>
                        <tr className="bg-theme text-light">
                          <th scope="row">CONTACT DETAILS</th>
                          <td></td>
                        </tr>
                        <tr>
                          <th scope="row">Contact Type</th>
                          <td>
                            <select
                              name="Contact_Type"
                              className="form-control"
                              onChange={onEdit}
                            >
                              <option defaultValue={contact2Edit.Contact_Type}>
                                {contact2Edit.Contact_Type}
                              </option>
                              <option>Foster</option>
                              <option>Processor</option>
                              <option>Processor & Foster</option>
                              <option>Administration</option>
                              <option>Rescue</option>
                              <option>Vendor</option>
                              <option>Veterinarians Office</option>
                              <option>IT</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Phone</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Phone"
                              defaultValue={contact2Edit.Phone}
                              onChange={onEdit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Phone</th>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              name="Phone2"
                              defaultValue={contact2Edit.Phone2}
                              onChange={onEdit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Email</th>
                          <td>
                            <input
                              className="form-control"
                              type="email"
                              name="Email_Address"
                              defaultValue={contact2Edit.Email_Address}
                              onChange={onEdit}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <form>
                      <div className="row col-10 mx-1">
                        <label htmlFor="Notes" className="font-weight-bold">
                          Notes
                        </label>
                      </div>
                      <div className="row">
                        <div className="form-group col-11 mx-2">
                          <textarea
                            className="form-control"
                            type="text"
                            name="Notes"
                            defaultValue={contact2Edit.Notes}
                            onChange={onEdit}
                            rows="8"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8"></div>
              <div className="col-lg-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={cancelCreate}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  onClick={updateContact}
                  className="btn btn-background ml-2"
                >
                  UPDATE
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      );

    case 'viewContact':
      return (
        <Fragment>
          <div></div>
          <div className="container">
            <h2 className="text-center">PPR CONTACT</h2>
            <div className="container-fluid bg-container p-2">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card border border-grey">
                    <table className="table">
                      <tbody>
                        <tr className="bg-theme text-light">
                          <th>CONTACT INFORMATION</th>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table">
                      <tbody>
                        <tr>
                          <th scope="row">Name</th>
                          <td>
                            {contact2View.First_Name} {contact2View.Last_Name}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Organization</th>
                          <td>{contact2View.Organization}</td>
                        </tr>
                        <tr>
                          <th scope="row">Address 1</th>
                          <td>{contact2View.Address_1}</td>
                        </tr>
                        <tr>
                          <th scope="row">Address 2</th>
                          <td>{contact2View.Address_2}</td>
                        </tr>
                        <tr>
                          <th scope="row">City</th>
                          <td>{contact2View.City}</td>
                        </tr>
                        <tr>
                          <th scope="row">State</th>
                          <td>{contact2View.State}</td>
                        </tr>
                        <tr>
                          <th scope="row">Zip</th>
                          <td>{contact2View.Zip_Code}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card border border-grey">
                    <table className="table">
                      <tbody>
                        <tr className="bg-theme text-light">
                          <th scope="row">CONTACT DETAILS</th>
                          <td></td>
                        </tr>
                        <tr>
                          <th scope="row">Contact Type</th>
                          <td>{contact2View.Contact_Type}</td>
                        </tr>
                        <tr>
                          <th scope="row">Phone</th>
                          <td>{contact2View.Phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">Phone</th>
                          <td>{contact2View.Phone2}</td>
                        </tr>
                        <tr>
                          <th scope="row">Email</th>
                          <td>{contact2View.Email_Address}</td>
                        </tr>
                      </tbody>
                    </table>

                    <form>
                      <div className="row col-10 mx-1">
                        <label htmlFor="Notes" className="font-weight-bold">
                          Notes
                        </label>
                      </div>
                      <div className="row">
                        <div className="form-group col-11 mx-2">
                          <textarea
                            className="form-control"
                            type="text"
                            name="Notes"
                            value={contact2View.Notes}
                            rows="4"
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8"></div>
              <div className="col-lg-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={cancelCreate}
                >
                  BACK
                </button>
                <button
                  type="button"
                  onClick={editTheContact}
                  className="btn btn-background ml-2"
                >
                  EDIT
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      );
  }
};

export default ContactsList;
