import { GET_PETS, VIEW_PET } from '../types';

const petfinderReducer = (state, action) => {
  switch (action.type) {
    case GET_PETS:
      return {
        ...state,
        pets: action.payload,
      };
    case VIEW_PET:
      return {
        ...state,
        step: 2,
      };
    default:
      return state;
  }
};

export default petfinderReducer;
