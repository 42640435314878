import React, { Fragment, useContext, useState, useEffect } from 'react';
import TicketContext from '../../../context/tickets/ticketsContext';
import TextEditor from '../../../utils/TextEditor';
import mmddyy from '../../../utils/mmddyy';
import yymmdd from '../../../utils/yymmdd';

const EditTicket = (props) => {
  const ticketContext = useContext(TicketContext);
  const { ticket, editTicket } = ticketContext;
  const [editedTicket, setEditedTicket] = useState([]);
  const [editedTicketBody, setEditedTicketBody] = useState('');
  const { backToList } = props;

  useEffect(() => {
    if (ticket !== null) {
      setEditedTicket(ticket);
      setEditedTicketBody(ticket.Request_Details);
    }
  }, [ticket]);

  const on_change = (e) => {
    setEditedTicket({ ...editedTicket, [e.target.id]: e.target.value });
  };

  const cancelEdit = () => {
    backToList();
    setEditedTicket([]);
    setEditedTicketBody([]);
  };

  const editTheTicket = () => {
    const editTicketData = {
      _id: editedTicket._id,
      Ticket_Type: editedTicket.Ticket_Type,
      Submitter: editedTicket.Submitter,
      Feature_Release: editedTicket.Feature_Release,
      Subject: editedTicket.Subject,
      Request_Details: editedTicketBody,
      Status: editedTicket.Status,
      Completion_Date: editedTicket.Completion_Date,
    };

    editTicket(editTicketData);
    setEditedTicket([]);
    setEditedTicketBody([]);
    backToList();
  };

  const removeCompletionDate = () => {
    setEditedTicket({ ...editedTicket, Completion_Date: null });
    document.getElementById('Completion_Date').value = null;
  };

  if (ticket !== null) {
    return (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="card create_ticket_card rounded shadow-lg">
                <div className="card-body text-dark">
                  <div className="row">
                    <div className="col-lg-11 p-0"></div>
                    <div
                      className="col-lg-1 text-right text-danger p-0 pr-3 pb-2 font-weight-bold pointer "
                      title="BACK TO TICKET LIST"
                      onClick={cancelEdit}
                    >
                      <h5 className="font-weight-bold">X</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2 p-0 pl-3 d-none d-md-block">
                      <h4 className="text-left d-none d-md-block font-weight-bold">
                        SUBJECT:
                      </h4>
                    </div>
                    <div className="col-xs-12 col-sm-10">
                      <form>
                        <div className="form-group ml-3">
                          <input
                            type="text"
                            id="Subject"
                            className="col-12"
                            defaultValue={ticket.Subject}
                            onChange={on_change}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-lg-6">
                      <table className="table table-secondary rounded mt-2 pt-3">
                        <tbody>
                          <tr>
                            <th className="text-left">TICKET #:</th>
                            <td>{ticket._id}</td>
                          </tr>
                          <tr>
                            <th className="text-left">TYPE:</th>
                            <td>
                              <form>
                                <div className="form-group m-0">
                                  <select
                                    id="Ticket_Type"
                                    className="custom-select"
                                    onChange={on_change}
                                    defaultValue={ticket.Ticket_Type}
                                  >
                                    <option>{ticket.Ticket_Type}</option>
                                    <option>ENHANCEMENT</option>
                                    <option>FIX</option>
                                    <option>NEW FEATURE</option>
                                  </select>
                                </div>
                              </form>
                            </td>
                          </tr>
                          <tr>
                            <th className="text-left">SUBMITTER:</th>
                            <td>
                              <form>
                                <div className="form-group m-0">
                                  <input
                                    type="text"
                                    id="Submitter"
                                    className="col-lg-12"
                                    defaultValue={ticket.Submitter}
                                    onChange={on_change}
                                  />
                                </div>
                              </form>
                            </td>
                          </tr>
                          <tr>
                            <th className="text-left">FEATURE RELEASE:</th>
                            <td>
                              <form>
                                <div className="form-group m-0">
                                  <input
                                    type="text"
                                    id="Feature_Release"
                                    className="col-lg-12"
                                    defaultValue={ticket.Feature_Release}
                                    onChange={on_change}
                                  />
                                </div>
                              </form>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-xs-12 col-lg-6">
                      <table className="table table-secondary rounded mt-2 pt-3">
                        <tbody>
                          <tr>
                            <th className="text-left">TICKET STATUS:</th>
                            <td>
                              <form>
                                <div className="form-group m-0">
                                  <select
                                    id="Status"
                                    className="custom-select"
                                    onChange={on_change}
                                    defaultValue={ticket.Status}
                                  >
                                    <option>{ticket.Status}</option>
                                    <option>NEW</option>
                                    <option>OPEN</option>
                                    <option>PENDING RELEASE</option>
                                    <option>CLOSED</option>
                                  </select>
                                </div>
                              </form>
                            </td>
                          </tr>
                          <tr>
                            <th className="text-left">SUBMITTED DATE:</th>
                            <td>{mmddyy(ticket.createdAt)}</td>
                          </tr>
                          <tr>
                            <th className="text-left">MODIFIED DATE:</th>
                            <td>{mmddyy(ticket.updatedAt)}</td>
                          </tr>
                          <tr>
                            <th className="text-left">COMPLETED DATE:</th>
                            <td>
                              <form>
                                <div className="form-group m-0">
                                  <input
                                    type="date"
                                    id="Completion_Date"
                                    className="col-10"
                                    defaultValue={yymmdd(
                                      ticket.Completion_Date
                                    )}
                                    onChange={on_change}
                                  />
                                  <span
                                    className="ml-2 pointer text-danger font-weight-bold"
                                    onClick={removeCompletionDate}
                                  >
                                    X
                                  </span>
                                </div>
                              </form>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <table className="table table-secondary rounded mt-2 pt-3">
                    <tbody>
                      <tr>
                        <td className="p-4">
                          <form>
                            <div className="form-group">
                              <TextEditor
                                textData={editedTicketBody}
                                setTextData={setEditedTicketBody}
                              />
                            </div>
                          </form>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-lg-9"></div>
                    <div className="col-lg-3">
                      <button
                        className="btn btn-danger mr-2"
                        onClick={cancelEdit}
                      >
                        BACK
                      </button>
                      <button
                        className="btn btn-warning text-dark"
                        onClick={editTheTicket}
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default EditTicket;
