import React, { useReducer, useContext } from 'react';
import AdminContext from './adminContext';
import adminReducer from './adminReducer';
import UserContext from '../../context/user/userContext';
import {
  LOAD_BLOG_PAGE,
  LOAD_DASHBOARD,
  LOAD_USER_PAGE,
  LOAD_APPLICATIONS_PAGE,
  LOAD_ADOPTION_LIST_PAGE,
  LOAD_CREATE_BLOG_PAGE,
  LOAD_EDIT_BLOG_PAGE,
  LOAD_BLOG_ID,
  LOAD_CREATE_USER_PAGE,
  LOAD_EDIT_USER_PAGE,
  LOAD_USER_PROFILE,
  LOAD_USER_ID,
  LOAD_APP_ID,
  LOAD_APPLICATION_PAGE,
  LOAD_EMAIL_TEMPLATES_PAGE,
  LOAD_ZOHO_APPLICATIONS_PAGE,
  LOAD_ZOHO_APPLICATION_PAGE,
  LOAD_NEW_APPLICATION_PAGE,
  LOAD_DASHBOARD_PAGE,
  LOAD_CONTACTS_PAGE,
  LOAD_ERROR_LOG_PAGE,
  LOAD_DOGS_PAGE,
  SET_FILTERS,
  LOAD_PAGE,
  CLEAR_FILTERS,
  TOGGLE_EDITING,
} from '../types';

const AdminState = (props) => {
  const userContext = useContext(UserContext);
  const { clearCurrent } = userContext;

  const initialState = {
    step: 'dashboard',
    userID: null,
    blogID: null,
    appID: null,
    currentlyEditing: false,
    filters: {
      Remembered: false,
      Application_Type: '',
      Application_Status: '',
      Application_Owner: '',
      Prefer: '',
      Sort: 'Ascending',
      appsPerPage: 10,
      appsCurrentStatus: 'OPEN',
    },
  };

  const [state, dispatch] = useReducer(adminReducer, initialState);

  // Admin Dashboard

  const adminDashboard = () => {
    dispatch({
      type: LOAD_DASHBOARD,
      payload: 'dashboard',
    });
  };

  const blogPage = () => {
    dispatch({
      type: LOAD_BLOG_PAGE,
      payload: 'blogList',
    });
  };

  const userList = () => {
    clearCurrent();
    dispatch({
      type: LOAD_USER_PAGE,
      payload: 'userList',
    });
  };

  const applicationList = () => {
    dispatch({
      type: LOAD_APPLICATIONS_PAGE,
      payload: 'appList',
    });
  };
  const zapplicationList = () => {
    dispatch({
      type: LOAD_ZOHO_APPLICATIONS_PAGE,
      payload: 'zappListPage',
    });
  };

  const contactList = () => {
    dispatch({
      type: LOAD_CONTACTS_PAGE,
      payload: 'contactList',
    });
  };

  const blogEntryPage = () => {
    dispatch({
      type: LOAD_CREATE_BLOG_PAGE,
      payload: 'blogEntryPage',
    });
  };

  const loadBlogID = (id) => {
    dispatch({
      type: LOAD_BLOG_ID,
      payload: id,
    });
  };

  const blogEditPage = (id) => {
    loadBlogID(id);
    dispatch({
      type: LOAD_EDIT_BLOG_PAGE,
      payload: 'blogEditPage',
    });
  };

  const emailTemplatesList = () => {
    dispatch({
      type: LOAD_EMAIL_TEMPLATES_PAGE,
      payload: 'emailTemplatesPage',
    });
  };

  const dashboard = () => {
    dispatch({
      type: LOAD_DASHBOARD_PAGE,
      payload: 'dashboard',
    });
  };

  const createUserPage = () => {
    dispatch({
      type: LOAD_CREATE_USER_PAGE,
      payload: 'addUserPage',
    });
  };

  const editUserPage = () => {
    dispatch({
      type: LOAD_EDIT_USER_PAGE,
      payload: 'editProfile',
    });
  };

  const loadUserID = (id) => {
    dispatch({
      type: LOAD_USER_ID,
      payload: id,
    });
  };

  const userProfilePage = (id) => {
    loadUserID(id);
    dispatch({
      type: LOAD_USER_PROFILE,
      payload: 'userProfile',
    });
  };

  const loadAppID = (id) => {
    dispatch({
      type: LOAD_APP_ID,
      payload: id,
    });
  };

  const viewApp = (id) => {
    loadAppID(id);
    const appData = {
      page: 'viewApplication',
      app_id: id,
    };
    dispatch({
      type: LOAD_APPLICATION_PAGE,
      // payload: 'viewApplication',
      payload: appData,
    });
  };
  const viewZohoApp = () => {
    // loadAppID(id);
    dispatch({
      type: LOAD_ZOHO_APPLICATION_PAGE,
      payload: 'viewZohoApplication',
    });
  };
  const newApplicationPage = () => {
    loadAppID();
    dispatch({
      type: LOAD_NEW_APPLICATION_PAGE,
      payload: 'enterNewApplication',
    });
  };
  const errorLogPage = () => {
    loadAppID();
    dispatch({
      type: LOAD_ERROR_LOG_PAGE,
      payload: 'errorsLog',
    });
  };
  const adoptionListPage = () => {
    dispatch({
      type: LOAD_ADOPTION_LIST_PAGE,
      payload: 'adoption list',
    });
  };

  const setFilters = (filter) => {
    dispatch({
      type: SET_FILTERS,
      payload: filter,
    });
  };
  const dogsPage = () => {
    dispatch({
      type: LOAD_DOGS_PAGE,
      payload: 'dogs',
    });
  };
  const kbPage = () => {
    dispatch({
      type: LOAD_PAGE,
      payload: 'knowledgeBase',
    });
  };
  const notesPage = () => {
    dispatch({
      type: LOAD_PAGE,
      payload: 'meeting notes',
    });
  };
  const ticketsList = () => {
    dispatch({
      type: LOAD_PAGE,
      payload: 'tickets',
    });
  };

  const loadLogPage = () => {
    dispatch({
      type: LOAD_PAGE,
      payload: 'logs',
    });
  };
  const loadMedicationList = () => {
    dispatch({
      type: LOAD_PAGE,
      payload: 'medicationList',
    });
  };

  const doneEditing = () => {
    dispatch({
      type: TOGGLE_EDITING,
      payload: false,
    });
  };
  const startEditing = () => {
    dispatch({
      type: TOGGLE_EDITING,
      payload: true,
    });
  };

  const clearFilters = (role, id) => {
    if (role === 'User') {
      const filter = {
        Remembered: false,
        Application_Type: '',
        Application_Status: '',
        Application_Owner: id,
        Prefer: '',
        Sort: 'Ascending',
        appsCurrentStatus: 'OPEN',
        appsPerPage: 10,
      };
      dispatch({
        type: CLEAR_FILTERS,
        payload: filter,
      });
    } else {
      const filter = {
        Remembered: false,
        Application_Type: '',
        Application_Status: '',
        Application_Owner: '',
        Prefer: '',
        Sort: 'Ascending',
        appsCurrentStatus: 'OPEN',
        appsPerPage: 10,
      };
      dispatch({
        type: CLEAR_FILTERS,
        payload: filter,
      });
    }
  };
  return (
    <AdminContext.Provider
      value={{
        step: state.step,
        userID: state.userID,
        blogID: state.blogID,
        appID: state.appID,
        filters: state.filters,
        currentlyEditing: state.currentlyEditing,
        setFilters,
        clearFilters,
        adminDashboard,
        blogPage,
        userList,
        applicationList,
        blogEntryPage,
        createUserPage,
        editUserPage,
        userProfilePage,
        zapplicationList,
        loadUserID,
        blogEditPage,
        loadBlogID,
        newApplicationPage,
        viewApp,
        emailTemplatesList,
        contactList,
        viewZohoApp,
        dashboard,
        errorLogPage,
        dogsPage,
        adoptionListPage,
        kbPage,
        notesPage,
        startEditing,
        doneEditing,
        ticketsList,
        loadLogPage,
        loadMedicationList,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminState;
