import React from 'react';
import AdminDashApps from './AdminDashApps';
// import OpenAppStatsCard from './OpenAppStatsCard';
import AdminDashTasks from './AdminDashTasks';
import AdminDashMyApps from './AdminDashMyApps';
import AdminDashMyFollowUps from './AdminDashFollowUps';
import AdminDashDif from './AdminDashDif';

const Dashboard = (props) => {
  const { user } = props;

  window.scroll(0, 0);
  // ! ******* ADMIN & MANAGER DASHBOARD VIEW  ********
  if (
    (user !== undefined && user.Role === 'Admin') ||
    user.Role === 'Manager'
  ) {
    return (
      <div className="container-fluid dashboard-container ml-md-3">
        <div className="row p-2">
          <div className="col-lg-6 mt-2 p-0">
            <div className="row mt-2">
              <AdminDashDif />
            </div>
            <div className="row mt-2">
              <AdminDashMyApps user={user} />
            </div>
            <div className="row mt-2">
              <AdminDashTasks user={user} />
            </div>
            <div className="row mt-2">
              <AdminDashMyFollowUps user={user} />
            </div>
          </div>
          <div className="col-lg-1 mt-2"></div>
          <div className="col-lg-5 mt-2 pr-3">
            <div className="row">{/* <OpenAppStatsCard /> */}</div>

            <div className="row mt-2">
              <AdminDashApps />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    // ! ******* USER DASHBOARD VIEW  ********
    return (
      <div className="container-fluid dashboard-container">
        <div className="row p-2">
          <div className="col-lg-5 mt-2 p-0">
            <div className="row">
              <AdminDashMyApps user={user} />
            </div>
            <div className="row mt-2 p-0">
              <AdminDashTasks user={user} />
            </div>
            <div className="row mt-2 p-0">
              <AdminDashMyFollowUps user={user} />
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-5 mt-2 p-0">
            <div className="row mt-2">
              <AdminDashDif />
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    );
  }
};

export default Dashboard;
