import React, { useReducer } from 'react';
import TicketsContext from './ticketsContext';
import ticketsReducer from './ticketsReducer';
import axios from 'axios';

import {
  DELETE_TICKET,
  GET_TICKETS,
  GET_TICKET,
  UPDATE_TICKET,
  UPDATE_TICKET_NOTE,
  GET_TICKET_NOTES,
  GET_TICKET_NOTE,
  DELETE_TICKET_NOTE,
  CREATE_TICKET,
  CREATE_TICKET_NOTE,
  GET_TICKET_FILES,
  UPLOAD_TICKET_FILE,
  DELETE_TICKET_FILE,
} from '../types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const TicketsState = (props) => {
  const initialstate = {
    tickets: null,
    ticket: null,
    tnotes: null,
    tnote: null,
    tfiles: null,
    ticketsLoading: true,
  };

  const [state, dispatch] = useReducer(ticketsReducer, initialstate);

  const createTicket = async (ticketData) => {
    const res = await axios.post('/api/tickets', ticketData, config);
    dispatch({
      type: CREATE_TICKET,
      payload: res.data,
    });
  };

  const createTicketNote = async (ticketData) => {
    const res = await axios.post('/api/tickets/note/', ticketData, config);
    dispatch({
      type: CREATE_TICKET_NOTE,
      payload: res.data,
    });
  };

  const editTicket = async (data) => {
    try {
      const res = await axios.put(`/api/tickets/${data._id}`, data, config);
      dispatch({
        type: UPDATE_TICKET,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const editTicketNote = async (data) => {
    try {
      const res = await axios.put(
        `/api/tickets/note/${data._id}`,
        data,
        config
      );
      dispatch({
        type: UPDATE_TICKET_NOTE,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteTicket = async (id) => {
    await axios.delete(`/api/tickets/${id}`);
    dispatch({
      type: DELETE_TICKET,
      payload: id,
    });
  };

  const deleteTicketNote = async (id) => {
    await axios.delete(`/api/tickets/note/${id}`);
    dispatch({
      type: DELETE_TICKET_NOTE,
      payload: id,
    });
  };

  const getTickets = async (e) => {
    try {
      const res = await axios.get(`/api/tickets/`);
      dispatch({
        type: GET_TICKETS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getTicket = async (e) => {
    try {
      const res = await axios.get(`/api/tickets/${e}`);
      dispatch({
        type: GET_TICKET,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getTicketNotes = async (e) => {
    try {
      const res = await axios.get(`/api/tickets/note/ticket/${e}`);
      dispatch({
        type: GET_TICKET_NOTES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getTicketNote = async (e) => {
    try {
      const res = await axios.get(`/api/tickets/note/${e}`);
      dispatch({
        type: GET_TICKET_NOTE,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getTicketsByStatus = async (status) => {
    try {
      const res = await axios.get('/api/tickets/status/', status, config);
      dispatch({
        type: GET_TICKETS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getTicketFiles = async (id) => {
    try {
      const res = await axios.get(`/api/tickets/files/${id}`);
      dispatch({
        type: GET_TICKET_FILES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const saveFile = async (data) => {
    const formData = new FormData();
    formData.append('file', data.file, data.fileName);
    try {
      await axios
        .post('/api/tickets/files/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const saveDBFile = async (data) => {
    const res = await axios.post('/api/tickets/files/', data, config);
    dispatch({
      type: UPLOAD_TICKET_FILE,
      payload: res.data,
    });
  };

  const removeTicketFile = async (id) => {
    await axios.delete(`/api/tickets/files/${id}`);
    dispatch({
      type: DELETE_TICKET_FILE,
      payload: id,
    });
  };

  const downloadTicketFile = async (e) => {
    axios(`/api/tickets/files/download/${e}`, {
      method: 'GET',
      responseType: 'blob',
    })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers.content_type,
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <TicketsContext.Provider
      value={{
        ticket: state.ticket,
        tickets: state.tickets,
        tnotes: state.tnotes,
        tnote: state.tnote,
        tfiles: state.tfiles,
        ticketsLoading: state.ticketsLoading,
        createTicket,
        createTicketNote,
        editTicket,
        editTicketNote,
        deleteTicket,
        deleteTicketNote,
        getTickets,
        getTicket,
        getTicketNotes,
        getTicketNote,
        getTicketsByStatus,
        getTicketFiles,
        saveFile,
        saveDBFile,
        removeTicketFile,
        downloadTicketFile,
      }}
    >
      {props.children}
    </TicketsContext.Provider>
  );
};

export default TicketsState;
