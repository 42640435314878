import React from 'react';
import ReactHtmlParser from 'react-html-parser';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const AdminZohoNote = (props) => {
  const { Note_ID, Note_Content, createdAt, Note_Owner_ID } = props.note;
  const { removeNote, insertEditNote, zusers, users } = props;

  const fetchOwnerName = (id) => {
    if (id !== null) {
      if (id.includes('zcrm')) {
        const res = zusers.filter((user) => {
          return user.User_ID === id;
        });
        const owner = res[0].First_Name + ' ' + res[0].Last_Name;

        return owner;
      } else if (!id.includes('zcrm') && users !== undefined) {
        const res = users.filter((user) => {
          return user._id === parseInt(id);
        });
        const owner = res[0].First_Name + ' ' + res[0].Last_Name;

        return owner;
      }
    }
  };

  return (
    <div className="card my-2 round shadow">
      <div className="card-body">
        <div className="card-text">
          <div className="row">
            <div className="col-md-3">
              <p>
                <span className="font-weight-bold">DATE: </span>
                {formatDate(createdAt)}
              </p>
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-3 text-right">
              <p className="font-weight-bold">
                AUTHOR:{' '}
                <span className="font-weight-normal">
                  {fetchOwnerName(Note_Owner_ID)}
                </span>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="mx-3 note-text">
              {ReactHtmlParser(Note_Content)}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-9"></div>
            <div className="col-lg-3 text-right">
              <i
                className="fa fa-pencil mr-4 pointer text-warning"
                id={Note_ID}
                onClick={() => insertEditNote(Note_ID)}
              ></i>
              <i
                className="fa fa-trash-o pointer text-danger"
                onClick={() => removeNote(Note_ID)}
                id={Note_ID}
                data-toggle="modal"
                data-target="#noteDeleteConfirmationModal"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminZohoNote;
