import React, { useReducer } from 'react';
import AdoptionContext from './adoptionContext';
import adoptionReducer from './adoptionReducer';
import axios from 'axios';

import {
  GET_ADOPTIONS,
  UPDATE_ADOPTION,
  DELETE_ADOPTION,
  CREATE_ADOPTION,
} from '../types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const AdoptionState = (props) => {
  const initialstate = {
    adoptions: null,
    adoptionsLoading: true,
  };

  const [state, dispatch] = useReducer(adoptionReducer, initialstate);

  const createAdoption = async (e) => {
    const res = await axios.post('/api/adoptions', e, config);
    dispatch({
      type: CREATE_ADOPTION,
      payload: res.data[0],
    });
  };

  const getAllAdoptions = async () => {
    try {
      const res = await axios.get(`/api/adoptions/`);
      dispatch({
        type: GET_ADOPTIONS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAdoptions = async (e) => {
    try {
      const res = await axios.get(`/api/adoptions/app/${e}`);
      dispatch({
        type: GET_ADOPTIONS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateAdoption = async (data) => {
    const res = await axios.put(`/api/adoptions/${data._id}`, data);
    dispatch({
      type: UPDATE_ADOPTION,
      payload: res.data,
    });
  };

  const deleteAdoption = async (id) => {
    try {
      await axios.delete(`/api/adoptions/${id}`);
      dispatch({
        type: DELETE_ADOPTION,
        payload: id,
      });
    } catch (error) {}
  };
  return (
    <AdoptionContext.Provider
      value={{
        adoptions: state.adoptions,
        getAdoptions,
        deleteAdoption,
        updateAdoption,
        createAdoption,
        getAllAdoptions,
      }}
    >
      {props.children}
    </AdoptionContext.Provider>
  );
};

export default AdoptionState;
