import React, { useEffect, useState, useContext } from 'react';
import MeetingNotesContext from '../../../context/meetingNotes/meetingNotesContext';
import MeetingNotesListItems from './MeetingNotesListItems';
import TextEditor from '../../../utils/TextEditor';
import ReactHtmlParser from 'react-html-parser';

const MeetingNotes = (props) => {
  const meetingNotesContext = useContext(MeetingNotesContext);
  const {
    mnotes,
    getMnotes,
    updateMnote,
    createMnote,
    deleteMnote,
  } = meetingNotesContext;
  const [searchData, setSearchData] = useState('');
  const [filteredMnotes, setFilteredMnotes] = useState([]);
  const [pageToDisplay, setPageToDisplay] = useState('list');
  const [editedMnote, setEditedMnote] = useState([]);
  const [editedMnotebody, setEditedMnotebody] = useState('');
  const [newMnotebody, setNewMnotebody] = useState('');
  const [newMnote, setNewMnote] = useState([]);
  const [readOnly, setReadOnly] = useState(true);
  const [removeMnoteID, setRemoveMnoteID] = useState(null);
  const { user } = props;

  useEffect(() => {
    getMnotes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [mnotes]);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [searchData]);

  const createAMnote = () => {
    setPageToDisplay('create');
  };

  const on_change = (e) => {
    setNewMnote({ ...newMnote, [e.target.id]: e.target.value });
  };

  const cancelCreate = () => {
    setPageToDisplay('list');
    setNewMnote([]);
    setNewMnotebody('');
    setReadOnly(true);
    window.scroll(0, 0);
  };

  const save_newMnote = () => {
    const entry = {
      author: user.First_Name + ' ' + user.Last_Name,
      title: newMnote.title,
      body: newMnotebody,
    };
    createMnote(entry);
    setPageToDisplay('list');
    setNewMnote([]);
    setNewMnotebody([]);
    setReadOnly(true);
    window.scroll(0, 0);
  };

  const editMnote = (mnote) => {
    setPageToDisplay('edit');
    setEditedMnote(mnote);
    setEditedMnotebody(mnote.body);
  };

  const on_edit = (e) => {
    setEditedMnote({ ...editedMnote, [e.target.id]: e.target.value });
  };

  const cancelEdit = () => {
    setPageToDisplay('list');
    setEditedMnote([]);
    setEditedMnotebody([]);
    setReadOnly(true);
    window.scroll(0, 0);
  };

  const save_editedMnote = () => {
    const updatedMnote = {
      _id: editedMnote._id,
      title: editedMnote.title,
      author: editedMnote.author,
      body: editedMnotebody,
    };

    updateMnote(updatedMnote);
    setPageToDisplay('list');
    setEditedMnote([]);
    setEditedMnotebody('');
    setReadOnly(true);
    window.scroll(0, 0);
  };

  const toggleEdit = () => {
    setReadOnly(false);
  };

  const removeMnote = (id) => {
    setRemoveMnoteID(id);
  };

  const cancelDeletion = () => {
    setRemoveMnoteID(null);
  };

  const search = () => {
    if (mnotes !== null && mnotes !== undefined) {
      const searchResults = mnotes.filter((mnote) => {
        const regex = new RegExp(`${searchData}`, 'gi');
        return mnote.title.match(regex) || mnote.body.match(regex);
      });
      setFilteredMnotes(searchResults);
    } else {
      setFilteredMnotes(mnotes);
    }
  };

  const searchEntry = (e) => {
    setSearchData(e.target.value);
    search();
  };

  if (pageToDisplay === 'list') {
    return (
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-lg-2">
            <div className="container">
              <button className="btn btn-success" onClick={createAMnote}>
                CREATE
              </button>
              <form>
                <div className="form-group mt-3">
                  <div className="row">
                    <label htmlFor="search" className="font-weight-bold">
                      SEARCH
                    </label>
                  </div>
                  <div className="row">
                    <input
                      type="search"
                      value={searchData}
                      className="form-control"
                      placeholder="SEARCH MEETING NOTES"
                      id="search"
                      autoComplete="off"
                      onChange={searchEntry}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-10">
            <table className="table table-striped table-hover border border-dark">
              <thead>
                <tr className="bg-theme text-white">
                  <th className="text-center" scope="col">
                    TITLE
                  </th>

                  <th className="text-center" scope="col">
                    DATE
                  </th>
                  {user.Role === 'Admin' || user.Role === 'Manager' ? (
                    <th></th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {filteredMnotes !== null
                  ? filteredMnotes.map((mnote) => (
                      <MeetingNotesListItems
                        user={user}
                        key={mnote._id}
                        mnote={mnote}
                        editMnote={editMnote}
                        removeMnote={removeMnote}
                      />
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="modal fade"
          id="NotesDeleteConfirmationModal"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="NotesDeleteConfirmationModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-danger font-weight-bold">
                  DELETE CONFIRMATION
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="font-weight-bold">
                  ARE YOU SURE YOU WANT TO DELETE THIS RECORD?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={cancelDeletion}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    deleteMnote(removeMnoteID);
                    setRemoveMnoteID(null);
                  }}
                  data-dismiss="modal"
                >
                  CONFRIM
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (pageToDisplay === 'edit') {
    return (
      <div className="container mt-3 p-3 bg-white shadow">
        <div></div>
        <form>
          <div className="container">
            <div className="form-group">
              <div className="row">
                <label className="active font-weight-bolder" htmlFor="title">
                  TITLE:
                </label>
              </div>
              <div className="row">
                <input
                  id="title"
                  type="text"
                  onChange={on_edit}
                  className="form-control"
                  defaultValue={editedMnote.title}
                  readOnly={readOnly}
                />
              </div>
              <div className="row mt-2">
                {readOnly ? (
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        {ReactHtmlParser(editedMnotebody)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <TextEditor
                    textData={editedMnotebody}
                    setTextData={setEditedMnotebody}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="button container">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={cancelEdit}
          >
            CANCEL
          </button>
          {readOnly ? (
            <button
              type="button"
              onClick={toggleEdit}
              className="btn btn-warning ml-3"
            >
              EDIT
            </button>
          ) : (
            <button
              type="button"
              onClick={save_editedMnote}
              className="btn btn-background ml-3"
            >
              SAVE
            </button>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="container mt-3 p-3 bg-white shadow">
        <div></div>
        <form>
          <div className="container">
            <h2 className="text-center">CREATE MEETING NOTES</h2>
            <div className="form-group">
              <div className="row">
                <label className="active font-weight-bolder" htmlFor="title">
                  TITLE:
                </label>
              </div>
              <div className="row">
                <input
                  id="title"
                  type="text"
                  className="form-control"
                  onChange={on_change}
                />
              </div>
              <div className="row mt-2">
                <TextEditor
                  textData={newMnotebody}
                  setTextData={setNewMnotebody}
                />
              </div>
            </div>
          </div>
        </form>
        <div></div>
        <div className="button container">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={cancelCreate}
          >
            CANCEL
          </button>
          <button
            type="button"
            onClick={save_newMnote}
            className="btn btn-success ml-3"
          >
            SAVE
          </button>
        </div>
      </div>
    );
  }
};

export default MeetingNotes;
