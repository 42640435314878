import React, { useContext } from 'react';
import formatDate from '../utils/date';
import AdminContext from '../../context/admin/adminContext';
import AppContext from '../../context/app/appContext';

const AdopListItems = (props) => {
  const adminContext = useContext(AdminContext);
  const appContext = useContext(AppContext);
  const { viewApp } = adminContext;
  const { getApp, apps } = appContext;
  const {
    _id,
    Dog_Adopted,
    Adoption_Date,
    Adoption_AppId,
    MicroChip,
    MicroChip_Brand,
    Fixed,
    createdAt,
    updatedAt,
  } = props.adoption;
  const { editAdoption } = props;

  const lookUpAdopter = (id) => {
    if (apps !== undefined) {
      const application = apps.filter((app) => {
        return app._id === id;
      });

      if (application.length > 0) {
        const adoptorsName =
          application[0].First_Name + ' ' + application[0].Last_Name;

        return adoptorsName;
      }
    } else {
      return '<<<< LOOKING UP >>>>>';
    }
  };

  const editTheAdoption = (e) => {
    const adoptionData = {
      _id: _id,
      Dog_Adopted: Dog_Adopted,
      MicroChip: MicroChip,
      MicroChip_Brand: MicroChip_Brand,
      Fixed: Fixed,
      Adoption_AppId: Adoption_AppId,
      Adoption_Date: Adoption_Date,
      createdAt: createdAt,
      updatedAt: updatedAt,
      AdopterName: lookUpAdopter(Adoption_AppId),
    };

    editAdoption(adoptionData);
  };

  const goToApp = () => {
    getApp(Adoption_AppId);
    viewApp(Adoption_AppId);
  };

  return (
    <tr>
      <td className="text-center pointer" onClick={editTheAdoption}>
        {Dog_Adopted}
      </td>
      <td className="text-center pointer" onClick={goToApp}>
        {lookUpAdopter(Adoption_AppId)}
      </td>
      <td className="text-center pointer" onClick={goToApp}>
        {Adoption_AppId}
      </td>
      <td className="text-center">{formatDate(Adoption_Date)}</td>
    </tr>
  );
};

export default AdopListItems;
