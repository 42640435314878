import React from 'react';
import PropTypes from 'prop-types';

function formatDate(string) {
  var options = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const KnowledgeBaseListItems = ({ kb, editKB, removeKb, user }) => {
  const editTheKB = (e) => {
    editKB(kb);
  };

  return (
    <tr>
      <td className="text-center pointer" onClick={editTheKB}>
        {kb.title}
      </td>

      <td className="text-center">{kb.author}</td>
      <td className="text-center">{formatDate(kb.updatedAt)}</td>
      {user.Role === 'Admin' || user.Role === 'Manager' ? (
        <td className="text-right">
          <i
            data-toggle="modal"
            data-target="#KBDeleteConfirmationModal"
            className="fa fa-trash text-danger pointer mr-2"
            onClick={() => removeKb(kb._id)}
            aria-hidden="true"
          ></i>
        </td>
      ) : null}
    </tr>
  );
};

KnowledgeBaseListItems.propTypes = {
  kb: PropTypes.object.isRequired,
};
export default KnowledgeBaseListItems;
