import React, { Fragment, useContext, useEffect, useState } from 'react';
import UserContext from '../../context/user/userContext';
import AdminUsersList from '../layout/AdminUsersList';

const AdminUserList = (props) => {
  const userContext = useContext(UserContext);
  const { users, getUsers, loading, deleteUser } = userContext;
  const [removeUserId, setRemoveUserId] = useState(null);
  const { newUser, currentUser } = props;

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  const createUser = () => {
    newUser();
  };

  const deleteAUser = (e) => {
    deleteUser(e);
  };

  const removeUser = (e) => {
    setRemoveUserId(e);
  };

  const cancelDeletion = () => {
    setRemoveUserId(null);
  };

  return (
    <Fragment>
      <div className="container">
        <h2 className="text-center">USER LIST</h2>
        <div className="text-right">
          <button className="btn btn-dark" onClick={createUser}>
            Create User
          </button>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col" className="d-none d-md-table-cell">
                Email
              </th>
              <th scope="col">Role</th>
              <th scope="col" className="d-none d-md-table-cell">
                Creation Date
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {users !== null && !loading && users !== undefined ? (
              users.map((user) => (
                <AdminUsersList
                  user={user}
                  currentUser={currentUser}
                  removeUser={removeUser}
                  key={user._id}
                />
              ))
            ) : (
              <tr>
                <td>loading</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div
        className="modal fade"
        id="userDeleteConfirmationModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="userDeleteConfirmationModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger font-weight-bold">
                DELETE CONFIRMATION
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="font-weight-bold">
                ARE YOU SURE YOU WANT TO DELETE THIS USER?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={cancelDeletion}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  deleteAUser(removeUserId);
                  setRemoveUserId(null);
                }}
                data-dismiss="modal"
              >
                CONFRIM
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminUserList;
