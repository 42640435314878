import React, { useReducer, useContext } from 'react';
import ContactsContext from './contactsContext';
import axios from 'axios';
import contactsReducer from './contactsReducer';
import AlertContext from '../alert/alertContext';

import {
  GET_CONTACTS,
  CREATE_CONTACT,
  GET_CONTACT,
  EDIT_CONTACT,
  DELETE_CONTACT,
} from '../types';

const ContactsState = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialstate = {
    contacts: null,
    contact: null,
    contactsLoading: true,
  };

  const [state, dispatch] = useReducer(contactsReducer, initialstate);

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Get Contacts
  const getContacts = async () => {
    try {
      const res = await axios.get(`/api/contact/`);
      dispatch({
        type: GET_CONTACTS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Create Contact
  const createContact = async (data) => {
    const res = await axios.post('/api/contact', data, config);
    dispatch({
      type: CREATE_CONTACT,
      payload: res.data,
    });
    setAlert('Contact Created', 'success');
  };

  const getContact = async (e) => {
    try {
      const res = await axios.get(`/api/contact/${e}`);

      const updatedData = {
        _id: res.data._id,
        First_Name: res.data.First_Name,
        Last_Name: res.data.Last_Name,
        Role: res.data.Role,
      };
      dispatch({
        type: GET_CONTACT,
        payload: updatedData,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const editContact = (e) => {
    const updateData = {
      First_Name: e.First_Name,
      Last_Name: e.Last_Name,
      Organization: e.Organization,
      Contact_Type: e.Contact_Type,
      Email_Address: e.Email_Address,
      Phone: e.Phone,
      Phone2: e.Phone2,
      Notes: e.Notes,
      Address_1: e.Address_1,
      Address_2: e.Address_2,
      City: e.City,
      State: e.State,
      Zip_Code: e.Zip_Code,
    };

    try {
      axios.put(`/api/contact/${e._id}`, updateData, config);
      dispatch({
        type: EDIT_CONTACT,
        payload: e,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const deleteContact = (e) => {
    try {
      axios.delete(`/api/contact/${e}`);
      dispatch({
        type: DELETE_CONTACT,
        payload: e,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ContactsContext.Provider
      value={{
        contacts: state.contacts,
        contact: state.contact,
        contactsLoading: state.contactsLoading,
        createContact,
        editContact,
        deleteContact,
        getContact,
        getContacts,
      }}
    >
      {props.children}
    </ContactsContext.Provider>
  );
};

export default ContactsState;
