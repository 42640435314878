import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import AppContext from '../../../context/app/appContext';
import AuthContext from '../../../context/auth/authContext';
import FileContext from '../../../context/files/fileContext';
import axios from 'axios';
import './DropZone.css';
import AlertContext from '../../../context/alert/alertContext';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'America/New_York',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const AdminAppFiles = () => {
  const appContext = useContext(AppContext);
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const fileContext = useContext(FileContext);
  const { app } = appContext;
  const { setAlert } = alertContext;
  const { user } = authContext;
  const {
    files,
    getAppFiles,
    deleteFile,
    uploadTheFile,
    fileToDB,
  } = fileContext;
  const [fileDescription, setFileDescription] = useState('');
  // ********************** DROP ZONE  CODE  ******************************
  const fileInputRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [filesReceived, setFilesReceived] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState(null);

  useEffect(() => {
    getAppFiles(app._id);
    // eslint-disable-next-line
  }, []);

  const submitUploadFile = async (e) => {
    e.preventDefault();

    const date = Date.now();
    const fileName = `App${app._id}uploadId${date}`;
    const lastIndex = selectedFiles.name.lastIndexOf('.') + 1;
    const fileExtension = selectedFiles.name.slice(lastIndex);
    const data = {
      file: selectedFiles,
      fileName: fileName,
      fileExtension: fileExtension,
    };

    await uploadTheFile(data);

    const dbData = {
      File_Name: fileName + '.' + fileExtension,
      File_Description: fileDescription,
      File_AppID: app._id,
      File_Size: selectedFiles.size,
      File_Location: `/mnt/fileStorage/ppr/uploads/`,
      Uploaded_By: user.First_Name + ' ' + user.Last_Name,
    };

    await fileToDB(dbData);
    setFilesReceived(false);
    setSelectedFiles([]);
    setFileDescription('');
  };

  const validateUpload = (e) => {
    e.preventDefault();
    if (filesReceived && fileDescription) {
      submitUploadFile(e);
    } else {
      setAlert('ERROR --- ENTER A FILE DESCRIPTION & SELECT FILE', 'danger');
      setFilesReceived(false);
      setSelectedFiles([]);
      setFileDescription('');
    }
  };

  const on_change = (e) => {
    setFileDescription(e.target.value);
  };

  const cancelUpload = () => {
    setSelectedFiles([]);
    setFileDescription('');
    setFilesReceived(false);
  };

  const deleteThisFile = () => {
    deleteFile(deleteFileId);
    setDeleteFileId(null);
  };

  const confirmDeleteId = (e) => {
    setDeleteFileId(e.target.id);
  };

  const cancelDeletion = () => {
    setDeleteFileId(null);
  };

  const downloadThisFile = async (e) => {
    axios(`/api/upload/${e.target.id}`, {
      method: 'GET',
      responseType: 'blob',
    })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers.content_type,
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ********************** DROP ZONE  CODE  ******************************

  const preventDefault = (e) => {
    e.preventDefault();
    // e.stopPropagation();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length > 1) {
      setErrorMessage('ONLY ONE FILE CAN BE UPLOADED AT A TIME');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    } else {
      if (validateFile(files[0])) {
        setSelectedFiles(files[0]);
        setFilesReceived(true);
      } else {
        setErrorMessage('File type not permitted');
      }
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length > 1) {
      setErrorMessage('ONLY ONE FILE CAN BE UPLOADED AT A TIME');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    } else {
      if (validateFile(fileInputRef.current.files[0])) {
        setSelectedFiles(fileInputRef.current.files[0]);
        setFilesReceived(true);
      } else {
        setErrorMessage('File type not permitted');
      }
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const validateFile = (file) => {
    console.log(file.type);
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon',
      'application/doc',
      'application/docx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/msword',
      'video/mp4',
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  };

  const fileSize = (size) => {
    if (size === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const fileType = (fileName) => {
    return (
      fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
      fileName
    );
  };

  const removeFile = (name) => {
    setSelectedFiles([]);
    setFilesReceived(false);
  };

  //  ********************* DROP ZONE CODE END ***************************

  return (
    <Fragment>
      <div className="container">
        <h4 className="text-center align-middle mt-3">
          APPLICATION FILES (APP # {app._id}) - {app.First_Name} {app.Last_Name}{' '}
        </h4>
        <div className="row">
          <button
            type="button"
            className="btn btn-background font-weight-bold"
            data-toggle="modal"
            data-target="#staticBackdrop"
          >
            Upload File
          </button>
        </div>
        <div className="container border border-dark mt-3 p-0">
          <table className="table table-hover text-center">
            <thead className="bg-foster-theme">
              <tr>
                <th className="text-left" scope="col">
                  FILE{' '}
                </th>
                <th scople="col">DATE UPLOADED</th>
                <th scope="col" className="d-none d-md-table-cell">
                  UPLOADED BY
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {files !== null ? (
                files.map((file) => (
                  <tr key={file._id} id={file._id}>
                    <td
                      className="text-left pointer"
                      onClick={downloadThisFile}
                    >
                      {file.File_Description}
                    </td>
                    <td>{formatDate(file.Upload_Date)}</td>
                    <td className="d-none d-md-table-cell">
                      {file.Uploaded_By}
                    </td>
                    <td>
                      <i
                        className="fa fa-download mr-3 pointer text-success"
                        aria-hidden="true"
                        title="Download File"
                        id={file._id}
                        onClick={downloadThisFile}
                      ></i>
                      <i
                        className="fa fa-trash-o pointer text-danger"
                        onClick={confirmDeleteId}
                        id={file._id}
                        title="Delete File"
                        data-toggle="modal"
                        data-target="#fileDeleteConfirmationModal"
                        aria-hidden="true"
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="spinner-grow text-primary" role="status">
                  <td>Loading...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* *********** UPLOAD FILE MODAL ************* */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog gt-modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                UPLOAD FILE
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* *********************MODAL FILE FORM ****************** */}
            <div className="modal-body">
              <div className="container">
                <form>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          className="active font-weight-bolder"
                          htmlFor="fileDescription"
                        >
                          FILE DESCRIPTION:
                        </label>
                      </div>
                      <div className="col-md-7">
                        <input
                          id="fileDescription"
                          type="text"
                          className="form-control"
                          value={fileDescription}
                          onChange={on_change}
                          maxLength="100"
                        />
                      </div>
                    </div>
                  </div>

                  {/* ********************* FILE DROP *********************** */}
                  {filesReceived ? (
                    <div className="file-display-container">
                      <div className="row">
                        <span className="file-error-message">
                          {errorMessage}
                        </span>
                      </div>
                      <div className="file-type-logo"></div>
                      <div className="file-type">
                        {fileType(selectedFiles.name)}
                      </div>
                      <span className={`file-name`}>{selectedFiles.name}</span>
                      <span className="file-size">
                        ({fileSize(selectedFiles.size)})
                      </span>

                      <div
                        className="file-remove"
                        onClick={() => removeFile(selectedFiles.name)}
                      >
                        REMOVE
                      </div>
                    </div>
                  ) : (
                    <div
                      className="drop-container text-center"
                      onDragOver={dragOver}
                      onDragEnter={dragEnter}
                      onDragLeave={dragLeave}
                      onDrop={fileDrop}
                      id="uploadFile"
                      onClick={fileInputClicked}
                    >
                      <span className="file-error-message">{errorMessage}</span>

                      <div className="drop-message">
                        <div className="upload-icon"></div>
                        Drag & Drop file here or click to select file from
                        computer
                      </div>
                      <input
                        ref={fileInputRef}
                        className="file-input"
                        type="file"
                        multiple
                        onChange={filesSelected}
                      />
                    </div>
                  )}
                </form>
              </div>
            </div>

            {/* ****************** FILE DROP END ********************** */}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={cancelUpload}
              >
                CANCEL
              </button>
              <button
                type="button"
                onClick={validateUpload}
                className="btn btn-background"
                data-dismiss="modal"
              >
                UPLOAD
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* DELETE CONFIRMATION */}
      <div
        className="modal fade"
        id="fileDeleteConfirmationModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="fileDeleteConfirmationModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger font-weight-bold">
                DELETE CONFIRMATION
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="font-weight-bold">
                ARE YOU SURE YOU WANT TO DELETE THIS TEMPLATE?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={cancelDeletion}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteThisFile}
                data-dismiss="modal"
              >
                CONFRIM
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminAppFiles;
