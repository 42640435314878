import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/routing/PrivateRoute';
import Alerts from './components/layout/Alerts';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Admin from './components/pages/Admin';
import PetfinderState from './context/petfinder/PetfinderState';
import AppState from './context/app/AppState';
import AuthState from './context/auth/AuthState';
import BlogState from './context/blog/BlogState';
import UserState from './context/user/UserState';
import ContactsState from './context/contacts/ContactsState';
import TaskState from './context/tasks/TaskState';
import FollowUpsState from './context/follow-ups/FollowUpsState';
import NotesState from './context/notes/NotesState';
import LogState from './context/logs/LogState';
import KnowledgeBaseState from './context/knowledgeBase/KnowledgeBaseState';
import MeetingNotesState from './context/meetingNotes/MeetingNotesState';
import AlertState from './context/alert/AlertState';
import DogsState from './context/dogs/DogsState';
import TicketsState from './context/tickets/TicketsState';
import AdminState from './context/admin/AdminState';
import EmailState from './context/email/EmailState';
import FileState from './context/files/FileState';
import ZohoState from './context/zoho/ZohoState';
import ErrorsState from './context/errors/ErrorsState';
import AdoptionState from './context/adoption/AdoptionState';
import MedicationListState from './context/medication_list/MedicationListState';
import MedicationReminderState from './context/medication_reminders/Medication_ReminderState';
import MedicationsState from './context/medications/MedicationsState';
import EmailTemplatesState from './context/emailTemplates/EmailTemplatesState';
import FormBlogEdit from './components/forms/FormBlogEdit';
import Login from './components/auth/Login';
import setAuthToken from './utils/setAuthToken';
import UserProfile from './components/layout/UserProfile';
import ForgotPassword from './components/auth/ForgotPassword';
import NewPassword from './components/auth/NewPassword';
import './css/App.css';

if (localStorage.auth_token) {
  setAuthToken(localStorage.auth_token);
}

const App = () => {
  return (
    <AlertState>
      <AuthState>
        <UserState>
          <ZohoState>
            <EmailTemplatesState>
              <AdoptionState>
                <FileState>
                  <EmailState>
                    <LogState>
                      <MeetingNotesState>
                        <KnowledgeBaseState>
                          <DogsState>
                            <TaskState>
                              <FollowUpsState>
                                <NotesState>
                                  <TicketsState>
                                    <BlogState>
                                      <MedicationListState>
                                        <MedicationReminderState>
                                          <MedicationsState>
                                            <AdminState>
                                              <ErrorsState>
                                                <PetfinderState>
                                                  <AppState>
                                                    <ContactsState>
                                                      <BlogState>
                                                        <Router>
                                                          <div id="backDrop"></div>
                                                          <div className="App bg-light">
                                                            <Navbar />
                                                            <Alerts />
                                                            <Switch>
                                                              <Route
                                                                exact
                                                                path="/"
                                                                component={
                                                                  Login
                                                                }
                                                              />
                                                              <Route
                                                                exact
                                                                path="/forgotPassword"
                                                                component={
                                                                  ForgotPassword
                                                                }
                                                              />
                                                              <Route
                                                                exact
                                                                path="/newPassword"
                                                                component={
                                                                  NewPassword
                                                                }
                                                              />
                                                              <Route
                                                                exact
                                                                path="/login"
                                                                component={
                                                                  Login
                                                                }
                                                              />
                                                              <Route
                                                                exact
                                                                path="/reset/:token"
                                                                component={
                                                                  NewPassword
                                                                }
                                                              />
                                                              <PrivateRoute
                                                                exact
                                                                path="/admin"
                                                                component={
                                                                  Admin
                                                                }
                                                              />
                                                              <PrivateRoute
                                                                path="/admin/blog/:id"
                                                                component={
                                                                  FormBlogEdit
                                                                }
                                                              />
                                                              <PrivateRoute
                                                                path="/admin/user/:id"
                                                                component={
                                                                  UserProfile
                                                                }
                                                              />
                                                            </Switch>
                                                            <Footer />
                                                          </div>
                                                        </Router>
                                                      </BlogState>
                                                    </ContactsState>
                                                  </AppState>
                                                </PetfinderState>
                                              </ErrorsState>
                                            </AdminState>
                                          </MedicationsState>
                                        </MedicationReminderState>
                                      </MedicationListState>
                                    </BlogState>
                                  </TicketsState>
                                </NotesState>
                              </FollowUpsState>
                            </TaskState>
                          </DogsState>
                        </KnowledgeBaseState>
                      </MeetingNotesState>
                    </LogState>
                  </EmailState>
                </FileState>
              </AdoptionState>
            </EmailTemplatesState>
          </ZohoState>
        </UserState>
      </AuthState>
    </AlertState>
  );
};

export default App;
