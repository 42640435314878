import React, { Fragment, useContext, useEffect, useState } from 'react';
import AppContext from '../../../context/app/appContext';
import TaskContext from '../../../context/tasks/taskContext';
import AuthContext from '../../../context/auth/authContext';
import UserContext from '../../../context/user/userContext';
import MOMENT from 'moment';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'America/New_York',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const AdminAppTasks = () => {
  const appContext = useContext(AppContext);
  const authContext = useContext(AuthContext);
  const taskContext = useContext(TaskContext);
  const userContext = useContext(UserContext);
  const { app } = appContext;
  const {
    editTask,
    getTasks,
    tasks,
    createTask,
    taskLoading,
    completeTask,
    deleteTask,
    notifyAssignment,
    clearTasks,
  } = taskContext;
  const { user } = authContext;
  const { users, loading, getUsers } = userContext;
  const [check, setCheck] = useState([]);
  const [newTask, setNewTask] = useState({
    Task_Description: '',
    Task_Status: 'Pending',
    Assigned_To: 9999,
    Completed_By: '',
  });
  const [editedTask, setEditedTask] = useState({
    Edited_Task_Description: '',
    Edited_Assigned_To: 9999,
  });

  const [editedTaskId, setEditedTaskId] = useState();
  const [notify, setNotify] = useState(false);
  const [removeTaskId, setRemoveTaskId] = useState(null);

  useEffect(() => {
    if (app !== null) {
      getTasks(app._id);
      getUsers();
    }

    return function cleanUp() {
      clearTasks();
    };
    // eslint-disable-next-line
  }, [app, check]);

  const on_change = (e) => {
    setNewTask({ ...newTask, [e.target.id]: e.target.value });
  };

  const on_update = (e) => {
    setEditedTask({ ...editedTask, [e.target.id]: e.target.value });
  };

  const notifyTaskAssignment = (e) => {
    if (!notify) {
      setNotify(true);
    } else {
      setNotify(false);
    }
  };

  const checked = (e) => {
    if (!check) {
      setCheck(true);
    } else {
      setCheck(false);
    }

    const status = tasks.filter((task) => {
      if (typeof task._id === 'string') {
        return task._id === e.target.id;
      } else {
        return task._id === parseInt(e.target.id);
      }
    });

    if (status[0].Task_Status === 'Pending') {
      const taskUpdate = {
        _id: parseInt(e.target.id),
        Task_Description: status[0].Task_Description,
        Assigned_To: status[0].Assigned_To,
        App_Id: status[0].App_Id,
        Completed_By: `${user.First_Name} ${user.Last_Name}`,
        Completed_Date: MOMENT().format('YYYY-MM-DD  HH:mm:ss.000'),
        updatedAt: MOMENT().format('YYYY-MM-DD  HH:mm:ss.000'),
        Task_Status: 'Complete',
        Complete_Checkbox: 'checked',
      };
      completeTask(taskUpdate);
    } else {
      const taskUpdate = {
        _id: parseInt(e.target.id),
        Task_Description: status[0].Task_Description,
        Assigned_To: status[0].Assigned_To,
        App_Id: status[0].App_Id,
        Completed_By: ``,
        updatedAt: MOMENT().format('YYYY-MM-DD  HH:mm:ss.000'),
        Completed_Date: null,
        Task_Status: 'Pending',
        Complete_Checkbox: '',
      };
      completeTask(taskUpdate);
    }
  };

  const cancelEdit = () => {
    setNewTask({
      Task_Description: '',
      Task_Status: 'Pending',
      Assigned_To: 9999,
    });
    setNotify(false);
  };

  const onChange = (e) => {
    setNewTask({
      ...newTask,
      Assigned_To: e.target.value,
    });
  };

  const add_Task = (e) => {
    const data = {
      Task_Description: newTask.Task_Description,
      Task_Status: 'Pending',
      Assigned_To: newTask.Assigned_To,
      App_Id: app._id,
      Complete_Checkbox: '',
    };
    createTask(data);

    if (notify && parseInt(newTask.Assigned_To) !== 9999) {
      const owner = users.filter((user) => {
        return user._id === parseInt(newTask.Assigned_To);
      });
      const email = {
        To: owner[0].Username,
        From: user.Username,
        Subject: 'PPR Application task assigned to you',
        Body: `
        <br>
        <b>The following Task has been assigned to you:</b> <br>
        <b>Application ID:</b> ${app._id}<br>
        <b>Applicant Name:</b> ${app.First_Name} ${app.Last_Name}<br>
        <b>Task:</b> ${newTask.Task_Description}<br>
        <br>
        Go To https://portal.puppypawsrescue.org <br>

         `,
      };
      notifyAssignment(email);
      setNotify(false);
    } else if (parseInt(newTask.Assigned_To) === 9999) {
      alert(
        'Must choose a user to notify. Cannot notify UnAssigned. Please choose a user to notify'
      );
    }

    setNotify(false);
    setNewTask({
      Task_Description: '',
      Task_Status: 'Pending',
      Assigned_To: 9999,
      Completed_By: '',
    });
  };

  const insertEditTask = (e) => {
    const oldTaskData = tasks.filter(
      (task) => task._id === parseInt(e.target.id)
    );
    setEditedTask({
      ...editedTask,
      Edited_Task_Description: oldTaskData[0].Task_Description,
      Edited_Assigned_To: oldTaskData[0].Assigned_To,
      _id: oldTaskData[0]._id,
      App_Id: oldTaskData[0].App_Id,
      Completed_By: oldTaskData[0].Completed_By,
      Completed_Date: oldTaskData[0].Completed_Date,
      Task_Status: oldTaskData[0].Task_Status,
      Complete_Checkbox: oldTaskData[0].Complete_Checkbox,
    });
    setEditedTaskId(parseInt(e.target.id));
  };

  const update_task = (e) => {
    const data = {
      _id: editedTaskId,
      Task_Description: editedTask.Edited_Task_Description,
      Assigned_To: editedTask.Edited_Assigned_To,
      App_Id: editedTask.App_Id,
      Completed_By: editedTask.Completed_By,
      Completed_Date: editedTask.Completed_Date,
      Task_Status: editedTask.Task_Status,
      Complete_Checkbox: editedTask.Complete_Checkbox,
    };
    editTask(data);
    if (notify && parseInt(editedTask.Edited_Assigned_To) !== 9999) {
      const owner = users.filter((user) => {
        return user._id === parseInt(editedTask.Edited_Assigned_To);
      });
      const email = {
        To: owner[0].Username,
        From: user.Username,
        Subject: 'PPR Application task assigned to you',
        Body: `
        <br>
        <b>The following Task has been assigned to you:</b> <br>
        <b>Application ID:</b> ${app._id}<br>
        <b>Applicant Name:</b> ${app.First_Name} ${app.Last_Name}<br>
        <b>Task:</b> ${editedTask.Edited_Task_Description}<br>
        <br>
        Go To https://portal.puppypawsrescue.org <br>

         `,
      };
      notifyAssignment(email);
      setNotify(false);
    } else if (parseInt(editedTask.Edited_Assigned_To) === 9999) {
      alert(
        'Must choose a user to notify. Cannot notify UnAssigned. Please choose a user to notify'
      );
    }

    setNotify(false);

    setEditedTaskId('');
    setEditedTask({
      Edited_Task_Description: '',
      Edited_Assigned_To: 9999,
    });
  };

  const fetchOwnerName = (id) => {
    if (id === null || parseInt(id) === 9999) {
      return 'UnAssigned';
    } else if (users !== undefined) {
      const owner = users.filter((user) => {
        return user._id === parseInt(id);
      });
      return owner[0].First_Name + ' ' + owner[0].Last_Name;
    }
  };

  formatDate(tasks.Completed_Date);

  const deleteATask = (e) => {
    deleteTask(e);
  };

  const removeTask = (e) => {
    setRemoveTaskId(e.target.id);
  };

  const cancelDeletion = () => {
    setRemoveTaskId(null);
  };

  return (
    <Fragment>
      <div className="container-fluid mb-3">
        {/* <h4 className="text-center align-middle">
          APPLICATION TASK LIST (APP # {app._id}) - {app.First_Name}{' '}
          {app.Last_Name}{' '}
        </h4> */}
        <div className="row">
          <div className="col-md-10"></div>
          <div className="col-md-2">
            <button
              type="button"
              className="btn btn-warning text-dark font-weight-bold"
              data-toggle="modal"
              data-target="#staticBackdropTask"
            >
              CREATE TASK
            </button>
          </div>
        </div>
        <div className="container-fluid border border-dark mt-3 p-0">
          <table className="table table-hover text-center">
            <thead className="bg-foster-theme">
              <tr className="d-flex">
                <th scope="col" className="col-1">
                  DONE
                </th>
                <th scope="col" className="col text-center">
                  TASK DESCRIPTION
                </th>
                <th scople="col" className="col  text-center">
                  ASSIGNED TO
                </th>
                <th scope="col" className="col text-center d-none d-md-block">
                  COMPLETED BY
                </th>
                <th scope="col" className="col text-center d-none d-md-block">
                  LAST MODIFIED
                </th>
                <th scope="col" className="col text-center d-none d-md-block">
                  COMPLETION DATE
                </th>
                <th scope="col" className="col-1"></th>
              </tr>
            </thead>
            <tbody>
              {tasks !== null && !taskLoading ? (
                tasks.map((task) => (
                  <tr key={task._id} id={task._id} className="d-flex">
                    <td className="text-center col-1">
                      <div className="form-check ">
                        <input
                          className="form-check-input pointer"
                          type="checkbox"
                          id={task._id}
                          checked={task.Complete_Checkbox}
                          onChange={checked}
                        />
                      </div>
                    </td>
                    <td
                      className="text-left text-center pointer col"
                      data-toggle="modal"
                      data-target="#staticBackdropTask2"
                      id={task._id}
                      onClick={insertEditTask}
                    >
                      {task.Task_Description}
                    </td>
                    <td
                      className=" text-center col"
                      data-toggle="modal"
                      data-target="#staticBackdropTask2"
                      id={task._id}
                      onClick={insertEditTask}
                    >
                      {fetchOwnerName(task.Assigned_To)}
                    </td>
                    <td className="col  text-center d-none d-md-block">
                      {task.Completed_Date !== null
                        ? task.Completed_By
                        : task.Task_Status}
                    </td>
                    <td className="col text-center d-none d-md-block">
                      {task.updatedAt !== null
                        ? formatDate(task.updatedAt)
                        : task.Task_Status}
                    </td>
                    <td className="col text-center d-none d-md-block">
                      {task.Completed_Date !== null
                        ? formatDate(task.Completed_Date)
                        : task.Task_Status}
                    </td>
                    <td className="col-1">
                      <i
                        className="fa fa-trash-o pointer text-danger"
                        onClick={removeTask}
                        data-toggle="modal"
                        data-target="#taskDeleteConfirmationModal"
                        id={task._id}
                        aria-hidden="true"
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="spinner-grow text-primary" role="status">
                  <td>Loading...</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* *********** CREATE TASK MODAL ************* */}
        <div
          className="modal fade"
          id="staticBackdropTask"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdropTaskLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropTaskLabel">
                  NEW TASK
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label
                      className="active font-weight-bolder"
                      htmlFor="Task_Description"
                    >
                      TASK:
                    </label>
                  </div>
                  <div className="col-md-10">
                    <input
                      id="Task_Description"
                      type="text"
                      className="form-control"
                      value={newTask.Task_Description}
                      onChange={on_change}
                      maxLength="50"
                    />
                  </div>
                  <div className="col-md-10">
                    {' '}
                    <div className="form-group">
                      <label htmlFor="Assigned_To" className="font-weight-bold">
                        ASSIGNED TO
                      </label>
                      <select
                        className="form-control"
                        onChange={onChange}
                        id="Assigned_To"
                        value={newTask.Assigned_To}
                      >
                        <option value={parseInt('9999')}>UnAssigned</option>
                        {users !== undefined && !loading ? (
                          users.map((user) => (
                            <option key={user._id} value={user._id}>
                              {user.First_Name} {user.Last_Name}
                            </option>
                          ))
                        ) : (
                          <option>loading</option>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    <div className="row">
                      <div className="col-4">
                        <label
                          className="active font-weight-bolder text-right"
                          htmlFor="Notify_Owner"
                        >
                          NOTIFY OWNER
                        </label>
                      </div>
                      <div className="col-1">
                        <div className="form-check text-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={notify}
                            id="Notify_Owner"
                            onChange={notifyTaskAssignment}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={cancelEdit}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  onClick={add_Task}
                  className="btn btn-background"
                  data-dismiss="modal"
                >
                  ADD TASK
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* *********** EDIT TASK MODAL ************* */}
        <div
          className="modal fade"
          id="staticBackdropTask2"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdropTask2Label"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropTask2Label">
                  EDIT TASK
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label
                      className="active font-weight-bolder"
                      htmlFor="Task_Description"
                    >
                      TASK:
                    </label>
                  </div>
                  <div className="col-md-10">
                    <input
                      id="Edited_Task_Description"
                      type="text"
                      className="form-control"
                      value={editedTask.Edited_Task_Description}
                      onChange={on_update}
                      maxLength="50"
                    />
                  </div>
                  <div className="col-md-10">
                    {' '}
                    <div className="form-group">
                      <label htmlFor="Assigned_To" className="font-weight-bold">
                        ASSIGNED TO
                      </label>
                      <select
                        className="form-control"
                        onChange={on_update}
                        id="Edited_Assigned_To"
                        value={editedTask.Edited_Assigned_To}
                      >
                        <option value={parseInt('9999')}>UnAssigned</option>
                        {users !== undefined && !loading ? (
                          users.map((user) => (
                            <option key={user._id} value={user._id}>
                              {user.First_Name} {user.Last_Name}
                            </option>
                          ))
                        ) : (
                          <option>loading</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <div className="row">
                      <div className="col-4">
                        <label
                          className="active font-weight-bolder"
                          htmlFor="Notify_Owner"
                        >
                          NOTIFY OWNER
                        </label>
                      </div>
                      <div className="col-1">
                        <div className="form-check text-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={notify}
                            id="Notify_Owner - edit"
                            onChange={notifyTaskAssignment}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={cancelEdit}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  onClick={update_task}
                  className="btn btn-background"
                  data-dismiss="modal"
                >
                  UPDATE TASK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="taskDeleteConfirmationModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="taskDeleteConfirmationModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger font-weight-bold">
                DELETE CONFIRMATION
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="font-weight-bold">
                ARE YOU SURE YOU WANT TO DELETE THIS TASK?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={cancelDeletion}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  deleteATask(removeTaskId);
                  setRemoveTaskId(null);
                }}
                data-dismiss="modal"
              >
                CONFRIM
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminAppTasks;
