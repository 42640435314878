import React, { useState, useContext, useEffect } from 'react';
import AlertContext from '../../context/alert/alertContext';
import AuthContext from '../../context/auth/authContext';
import PasswordValidator from '../../utils/PasswordValidator';

const Register = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const { cancelUserEntry } = props;
  const { setAlert } = alertContext;
  const { register, error, clearErrors } = authContext;

  useEffect(() => {
    if (error === 'User Already exists') {
      setAlert(error, 'danger');
      clearErrors();
    }

    // eslint-disable-next-line
  }, [error]);

  const [user, setUser] = useState({
    First_Name: '',
    Last_Name: '',
    Username: '',
    Role: '',
    email_signature: '',
    Password: '',
    Password2: '',
  });

  const {
    First_Name,
    Last_Name,
    Role,
    Username,
    Password,
    Password2,
    email_signature,
  } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (Password !== Password2) {
      setAlert("PASSWORDS DON'T MATCH", 'danger');
    } else {
      if (PasswordValidator(Password)) {
        register({ First_Name, Last_Name, Role, Username, Password });
        setAlert(`User Named ${First_Name} created successfully`, 'success');
        setTimeout(() => {
          cancelUserEntry();
        }, 500);
      } else {
        setAlert('Invalid Password - See Password rules');
      }
    }
  };

  const cancel = () => {
    cancelUserEntry();
  };

  return (
    <div className="container">
      <h1>ADD USER</h1>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="First_Name">First Name</label>
          <input
            className="form-control"
            type="text"
            name="First_Name"
            value={First_Name}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="Last_Name">Last Name</label>
          <input
            className="form-control"
            type="text"
            name="Last_Name"
            value={Last_Name}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="Username">Email Address</label>
          <input
            className="form-control"
            type="Username"
            name="Username"
            value={Username}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="Role">Role</label>
          <select name="Role" className="form-control" onChange={onChange}>
            <option value={Role}></option>
            <option>Admin</option>
            <option>User</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="email_signature">Email Signature</label>
          <input
            type="text"
            name="email_signature"
            className="form-control"
            value={email_signature}
            onChange={onChange}
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="Password">Password</label>
          <input
            className="form-control"
            type="Password"
            name="Password"
            value={Password}
            onChange={onChange}
            required
            minLength="8"
          />
        </div>
        <div className="form-group">
          <label htmlFor="Password2">Confirm Password</label>
          <input
            className="form-control"
            type="Password"
            name="Password2"
            value={Password2}
            onChange={onChange}
            required
          />
        </div>

        <p>
          Password Rules: Atleast 8 Characters Uppercase Character(s) Lowercase
          Character(s) Number(s) Special Character
        </p>
        <div className="my-4">
          <input
            type="submit"
            value="Register"
            className="btn btn-primary mr-3"
          />
          <button className="btn btn-danger mr-3" onClick={cancel}>
            CANCEL
          </button>
        </div>
      </form>
    </div>
  );
};

export default Register;
