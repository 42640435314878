import React from 'react';

const TicketListItem = (props) => {
  const { ticket, editTicket, viewTicket, removeTicket } = props;

  const formDate = (string) => {
    if (string === null) {
      return 'Pending';
    } else {
      var d = new Date(string);
      var mm = ('0' + (d.getMonth() + 1)).slice(-2);
      var dd = ('0' + d.getDate()).slice(-2);
      var yy = d.getFullYear();

      var myDateString = mm + '-' + dd + '-' + yy; //(US)

      return myDateString;
    }
  };

  return (
    <tr>
      <td className=" text-center d-none d-md-table-cell">{ticket._id}</td>
      <td className=" text-center d-none d-md-table-cell">
        {ticket.Ticket_Type}
      </td>
      <td
        className=" text-center font-weight-bold pointer"
        onClick={() => viewTicket(ticket._id)}
      >
        {ticket.Subject}
      </td>
      <td className=" text-center d-none d-md-table-cell">
        {ticket.Submitter}
      </td>
      <td className="text-center d-none d-md-table-cell">
        {ticket.Feature_Release}
      </td>
      <td className=" text-center">{ticket.Status} </td>
      <td className=" text-center d-none d-md-table-cell">
        {formDate(ticket.createdAt)}
      </td>
      <td className="text-center d-none d-md-table-cell">
        {formDate(ticket.Completion_Date)}
      </td>
      <td className="text-right">
        <span
          className="pointer text-success mr-3 font-weight-bold"
          onClick={() => viewTicket(ticket._id)}
          title="View Ticket"
        >
          VIEW
        </span>
        <span
          className="pointer text-dark mr-3 font-weight-bold"
          onClick={() => editTicket(ticket._id)}
          title="Edit Ticket"
        >
          EDIT
        </span>
        <span
          className="text-danger font-weight-bold pointer"
          title="Delete Ticket"
          onClick={removeTicket}
          id={ticket._id}
          data-toggle="modal"
          data-target="#ticketDeleteConfirmationModal"
          aria-hidden="true"
        >
          DELETE
        </span>
      </td>
    </tr>
  );
};

export default TicketListItem;
