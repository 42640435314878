import React, { useReducer } from 'react';
import FollowUpsContext from './followUpsContext';
import FollowUpsReducer from './followUpsReducer';
import axios from 'axios';
import moment from 'moment';

import {
  CREATE_FOLLOWUP,
  EDIT_FOLLOWUP,
  GET_FOLLOWUPS,
  GET_FOLLOWUP,
  CLEAR_FOLLOWUPS,
  TOGGLE_COMPLETE_FOLLOWUP,
  DELETE_FOLLOWUP,
} from '../types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const FollowUpsState = (props) => {
  const initialstate = {
    followUps: [],
    followUp: null,
    fuLoading: true,
    subscribeUserFollowUps: true,
  };

  const workflowFollowUp = [
    {
      Description: 'Spay/Neuter F/U',
      Owner: 1045,
      Due_Date: moment().add(120, 'days').toDate(),
    },
  ];

  const [state, dispatch] = useReducer(FollowUpsReducer, initialstate);

  const createInitialFollowUps = (id, date) => {
    workflowFollowUp.map(async (followUp) => {
      const FollowUpData = {
        Follow_Up_Description: followUp.Description,
        Follow_Up_Owner: followUp.Owner,
        Follow_Up_Due_Date: date,
        Follow_Up_App_Id: id,
      };
      await axios
        .post('/api/follow-ups/duplicate', FollowUpData, config)
        .then(async (response) => {
          if (response.data.length === 0) {
            const createFollowUp = async (FollowUpData) => {
              const res = await axios.post(
                '/api/follow-ups',
                FollowUpData,
                config
              );
              dispatch({
                type: CREATE_FOLLOWUP,
                payload: res.data,
              });
            };
            createFollowUp(FollowUpData);
          } else {
            await axios.put(
              `/api/follow-ups/${response.data[0]._id}`,
              FollowUpData
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });

      return null;
    });
  };

  const createFollowUp = async (data) => {
    try {
      const res = await axios.post('/api/follow-ups', data, config);
      dispatch({
        type: CREATE_FOLLOWUP,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const completeFollowUp = async (e) => {
    const data = {
      Completed_By: e.Completed_By,
      Completed_Date: e.Completed_Date,
      Follow_Up_Status: e.Follow_Up_Status,
      Complete_Checkbox: e.Complete_Checkbox,
    };
    try {
      const res = await axios.put(`/api/follow-ups/${e._id}`, data, config);
      dispatch({
        type: TOGGLE_COMPLETE_FOLLOWUP,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const editFollowUp = async (data) => {
    try {
      const res = await axios.put(`/api/follow-ups/${data._id}`, data, config);
      dispatch({
        type: EDIT_FOLLOWUP,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteFollowUp = async (id) => {
    await axios.delete(`/api/follow-ups/${id}`);
    dispatch({
      type: DELETE_FOLLOWUP,
      payload: id,
    });
  };
  const getFollowUps = async (e) => {
    try {
      const res = await axios.get(`/api/follow-ups/app/${e}`);
      dispatch({
        type: GET_FOLLOWUPS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getFollowUp = async (e) => {
    const res = await axios.get(`/api/follow-ups/${e}`);
    dispatch({
      type: GET_FOLLOWUP,
      payload: res.data,
    });
  };
  const clearFollowUps = () => {
    dispatch({
      type: CLEAR_FOLLOWUPS,
    });
  };

  const notifyAssignment = async (email) => {
    await axios.post('/api/sendEmail/task/', email, config);
  };

  return (
    <FollowUpsContext.Provider
      value={{
        followUps: state.followUps,
        follupUp: state.followUp,
        fuLoading: state.fuLoading,
        subscribeUserFollowUps: state.subscribeUserFollowUps,
        createFollowUp,
        editFollowUp,
        deleteFollowUp,
        getFollowUps,
        getFollowUp,
        createInitialFollowUps,
        completeFollowUp,
        notifyAssignment,
        clearFollowUps,
      }}
    >
      {props.children}
    </FollowUpsContext.Provider>
  );
};

export default FollowUpsState;
