import {
  SAVE_EMAIL,
  EDIT_EMAIL_SEND,
  EDIT_EMAIL,
  DELETE_EMAIL,
  DELETE_EMAIL_ATTACHMENT,
  DELETE_EMAIL_ATTACHMENTS,
  GET_EMAILS,
  SEND_EMAIL,
  GET_EMAIL_ATTACHMENTS,
  SAVE_ATTACHMENTS,
} from '../types';

const emailReducer = (state, action) => {
  switch (action.type) {
    case SEND_EMAIL:
      return {
        ...state,
        emails: [action.payload, ...state.emails],
        emailLoading: false,
      };
    case GET_EMAILS:
      return {
        ...state,
        emails: action.payload,
        emailLoading: false,
      };
    case GET_EMAIL_ATTACHMENTS:
      return {
        ...state,
        emailAttachments: action.payload,
      };
    case SAVE_ATTACHMENTS:
      return {
        ...state,
        emailAttachments: [action.payload, ...state.emailAttachments],
      };

    case EDIT_EMAIL:
      return {
        ...state,
        emails: [
          action.payload,
          ...state.emails.filter((email) => email._id !== action.payload._id),
        ],
        emailLoading: false,
      };
    case DELETE_EMAIL_ATTACHMENTS:
      return {
        ...state,
        emailAttachments: state.emailAttachments.filter(
          (attachment) => attachment.email_id !== parseInt(action.payload)
        ),
      };
    case DELETE_EMAIL_ATTACHMENT:
      return {
        ...state,
        emailAttachments: state.emailAttachments.filter(
          (attachment) => attachment._id !== parseInt(action.payload)
        ),
      };
    case DELETE_EMAIL:
      return {
        ...state,
        emails: state.emails.filter(
          (email) => email._id !== parseInt(action.payload)
        ),
      };

    case SAVE_EMAIL:
      return {
        ...state,
        emails: [action.payload, ...state.emails],
        emailLoading: false,
      };
    case EDIT_EMAIL_SEND:
      return {
        emailLoading: false,
      };
    default:
      return state;
  }
};

export default emailReducer;
