import { saveAs } from 'file-saver';
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Footer,
  HeadingLevel,
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  WidthType,
  BorderStyle,
  UnderlineType,
} from 'docx';

export function CreateAdoptionContract(app, contractData) {
  function formatDate(date) {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    const formatedDate = `${month}-${day}-${year}`;

    return formatedDate;
  }

  let hwTreatedLanguage = new Paragraph({
    children: [
      new TextRun({
        text: 'Health:  Your dog has been heart-worm treated. Dogs that have undergone treatment need to be slowly introduced to full activity over a period of a few weeks to months. Most dogs will test positive for up to 6 months after heart-worm treatment.  It is of utmost importance that you keep your dog on monthly heart-worm prevention lifelong. A link to learn about heart-worms from the American Heart-worm Society follows: https://www.heartwormsociety.org/pet-owner-resources',
        color: '000000',
        font: 'Times New Roman',
        size: 19,
      }),
    ],
    alignment: AlignmentType.LEFT,

    spacing: {
      before: 200,
    },
  });
  let notAlteredLanguage = new Paragraph({
    children: [
      new TextRun({
        text: 'I agree that I will have the dog spayed/neutered before the age or date written at the bottom of this contract, and I will provide Puppy Paws Rescue with proof immediately following the procedure.',
        color: '000000',
        font: 'Times New Roman',
        size: 19,
      }),
    ],
    alignment: AlignmentType.LEFT,
    bullet: {
      level: 0,
    },
    spacing: {
      before: 200,
    },
  });

  let spayNeuterParagraph = new Paragraph({
    children: [
      new TextRun({
        text: `The puppy will be spayed/neutered by ${formatDate(
          contractData.AlteredDueDate
        )}. A ${
          contractData.AlteredRefund
        } contribution will be made by PPR upon receipt of proof of alteration within the time frame. This contribution will not be equivalent to the total cost for the spay/neuter. A copy of the alteration certificate or receipt should be emailed to pawspup2@gmail.com. I understand that the dog will be returned to or picked up by PPR if we do not comply with spay/neuter requirements.`,
        color: '000000',
        font: 'Times New Roman',
        size: 19,
      }),
    ],
    alignment: AlignmentType.LEFT,
    spacing: {
      before: 300,
    },
  });

  let spayNeuterSignature = new Paragraph({
    children: [
      new TextRun({
        text: ` ____________________________________________	`,
        color: '000000',
        font: 'Times New Roman',
        size: 19,
      }),
      new TextRun({
        text: `(SIGN HERE)`,
        color: '000000',
        font: 'Times New Roman',
        size: 19,
      }),
    ],
    alignment: AlignmentType.LEFT,
    spacing: {
      before: 400,
    },
  });

  const createContract = (delivery) => {
    const doc = new Document({
      creator: 'Puppy Paws Rescue',
      description: 'Adoption Agreement',
      title: 'Adoption Agreement',
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: 1000,
                right: 1000,
                bottom: 1500,
                left: 1000,
              },
            },
          },

          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      font: 'Times New Roman',
                      size: 19,
                      children: [
                        'Puppy Paws Animal Adoption Contract, 10-01-2022',
                      ],
                    }),
                  ],
                }),
              ],
            }),
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Puppy Paws Rescue',
                  alignment: AlignmentType.CENTER,
                  heading: HeadingLevel.HEADING_1,
                  bold: true,
                  color: '000000',
                }),
              ],
              alignment: AlignmentType.CENTER,
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'P.O. Box 45',
                  bold: true,
                  color: '000000',
                }),
              ],
              alignment: AlignmentType.CENTER,
              heading: HeadingLevel.HEADING_5,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Huntingtown, MD 20639',
                  bold: true,
                  color: '000000',
                }),
              ],
              alignment: AlignmentType.CENTER,
              heading: HeadingLevel.HEADING_5,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Pawspuppy2013@gmail.com',
                  bold: true,
                  color: '000000',
                  hyperlink: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
              heading: HeadingLevel.HEADING_5,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Tax ID 46-6714235',
                  bold: true,
                  color: '000000',
                }),
              ],
              alignment: AlignmentType.CENTER,
              heading: HeadingLevel.HEADING_5,
            }),
            // ************ DOCUMENT BODY *********************
            new Paragraph({
              children: [
                new TextRun({
                  text: 'ADOPTION AGREEMENT',
                  bold: true,
                  color: '000000',
                }),
              ],
              alignment: AlignmentType.CENTER,
              heading: HeadingLevel.HEADING_3,
              spacing: {
                before: 200,
                after: 400,
              },
            }),
            new Table({
              width: {
                size: 9000,
                type: WidthType.DXA,
              },
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      width: {
                        size: 4250,
                        type: WidthType.DXA,
                      },

                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `Animal's Name: ${contractData.dog}`,
                              alignment: AlignmentType.LEFT,
                              font: 'Times New Roman',
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                      },
                    }),

                    new TableCell({
                      width: {
                        size: 1000,
                        type: WidthType.DXA,
                      },
                      children: [],

                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                      },
                    }),
                    new TableCell({
                      width: {
                        size: 3750,
                        type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `MicroChip Brand: ${contractData.ChipBrand}`,
                              alignment: AlignmentType.LEFT,
                              font: 'Times New Roman',
                              size: 22,
                            }),
                          ],
                        }),
                      ],

                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                      },
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      width: {
                        size: 4250,
                        type: WidthType.DXA,
                      },

                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `Sex: ${contractData.Sex}`,
                              alignment: AlignmentType.LEFT,
                              font: 'Times New Roman',
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                      },
                    }),
                    new TableCell({
                      width: {
                        size: 1000,
                        type: WidthType.DXA,
                      },
                      children: [],

                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                      },
                    }),
                    new TableCell({
                      width: {
                        size: 3750,
                        type: WidthType.DXA,
                      },
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `MicroChip #: ${contractData.Chip}`,
                              alignment: AlignmentType.LEFT,
                              font: 'Times New Roman',
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                      },
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      width: {
                        size: 4250,
                        type: WidthType.DXA,
                      },

                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `Birthday: ${contractData.BirthDay}`,
                              alignment: AlignmentType.LEFT,
                              font: 'Times New Roman',
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                      },
                    }),
                    new TableCell({
                      width: {
                        size: 1000,
                        type: WidthType.DXA,
                      },
                      children: [],

                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                      },
                    }),
                    new TableCell({
                      width: {
                        size: 3750,
                        type: WidthType.DXA,
                      },

                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `Breed: ${contractData.Breed}`,
                              alignment: AlignmentType.LEFT,
                              font: 'Times New Roman',
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                      },
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      width: {
                        size: 4250,
                        type: WidthType.DXA,
                      },

                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `Color/Description: ${contractData.Description}`,
                              alignment: AlignmentType.LEFT,
                              font: 'Times New Roman',
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                      },
                    }),
                    new TableCell({
                      width: {
                        size: 500,
                        type: WidthType.DXA,
                      },
                      children: [],

                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                      },
                    }),
                    new TableCell({
                      width: {
                        size: 3750,
                        type: WidthType.DXA,
                      },

                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `Altered: ${contractData.Altered}`,
                              alignment: AlignmentType.LEFT,
                              font: 'Times New Roman',
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                      },
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      width: {
                        size: 4250,
                        type: WidthType.DXA,
                      },

                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: `Medical Information: ${contractData.Medical_Info}`,
                              alignment: AlignmentType.LEFT,
                              font: 'Times New Roman',
                              size: 22,
                            }),
                          ],
                        }),
                      ],
                      borders: {
                        top: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        bottom: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        left: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                        right: {
                          style: BorderStyle.NONE,
                          size: 0,
                          color: 'FFFFFF',
                        },
                      },
                    }),
                  ],
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "ADOPTER'S AGREEMENT",
                  bold: true,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 22,
                }),
              ],
              alignment: AlignmentType.LEFT,
              spacing: {
                before: 300,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'I agree to be bound by and comply with the following terms and conditions of this contract:',
                  bold: true,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 22,
                }),
              ],
              alignment: AlignmentType.LEFT,
              spacing: {
                before: 200,
              },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: 'I agree to care for the animal in a humane manner and be a responsible animal guardian. This includes supplying adequate food, water, shelter, attention, and medical care.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 0,
              },
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'I will take the animal to a licensed veterinarian when immunizations are due and for examination at least annually. I will provide all required and/or needed veterinary care, including (initial each): ',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 0,
              },
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'The puppy/dog will be seen by a vet within 7-10 days from adoption to receive their next set of shots (if needed), a fecal exam that includes Giardia testing and a prescription for heartworm prevention ',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
                new TextRun({
                  text: '___________.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                  highlight: 'yellow',
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 1,
              },
              spacing: {
                before: 100,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Rabies vaccination as required every one or three years',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
                new TextRun({
                  text: '___________.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                  highlight: 'yellow',
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 1,
              },
              spacing: {
                before: 100,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'DHPP (canine distemper, adenovirus type II parainfluenza, and parvovirus) vaccination as required every one or three years',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
                new TextRun({
                  text: '___________.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                  highlight: 'yellow',
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 1,
              },
              spacing: {
                before: 100,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Yearly fecal checks for internal parasites (worms)',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
                new TextRun({
                  text: '___________.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                  highlight: 'yellow',
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 1,
              },
              spacing: {
                before: 100,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Yearly heartworm test ',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
                new TextRun({
                  text: '___________.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                  highlight: 'yellow',
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 1,
              },
              spacing: {
                before: 100,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Year-round heartworm prevention',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
                new TextRun({
                  text: '___________.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                  highlight: 'yellow',
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 1,
              },
              spacing: {
                before: 100,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Prompt treatment by a licensed veterinarian for any illness or injury',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
                new TextRun({
                  text: '___________.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                  highlight: 'yellow',
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 1,
              },
              spacing: {
                before: 100,
              },
            }),

            //************ CONDITIONAL LANGUAGE ***********
            contractData.Altered === 'no' ? notAlteredLanguage : {},
            // ********************************************
            new Paragraph({
              children: [
                new TextRun({
                  text: 'I agree that the animal is being adopted for myself and will ',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
                new TextRun({
                  text: 'not be sold, adopted, or given to another party, even as a gift to a friend, neighbor, or family member without providing Puppy Paws Rescue notice. Puppy Paws Rescue retains the right to have the animal returned before it is transferred to another party or to concur with the adopters proposed transfer.  I will neither take the animal to a shelter nor abandon the animal',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                  bold: true,
                  underline: {
                    type: UnderlineType.SINGLE,
                    color: '000000',
                  },
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 0,
              },
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'I agree that if at any point I cannot keep the animal, I will notify Puppy Paws Rescue and allow reasonable time for Puppy Paws Rescue to find another home for the animal or place the animal in an approved foster home. I must notify Puppy Paws Rescue of any behavioral problems that have occurred at any time before I return the animal.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 0,
              },
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'I will ensure proper licensing of the animal and will attach the appropriate license tags, rabies tag, and personal identification tags to a non-choke collar to be worn at all times. I will ensure compliance with all applicable local and state statutes.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 0,
              },
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'I agree that this dog is to be a companion animal, not a guard dog. I will not have the dog attack-trained nor will I use it for any purpose other than companionship',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 0,
              },
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'I will not have the dog’s ears cropped nor will I have its tail docked. ',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 0,
              },
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'I will never allow any physical, mental, or emotional abuse of the dog. ',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 0,
              },
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'The animal will live inside my home, not outdoors. I agree that the animal will not be allowed outdoors without proper supervision. I will never let my dog run loose or roam, keep my dog chained or tied up, keep it continuously in a yard, garage, patio, balcony, or pen',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 0,
              },
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "I understand and agree that Puppy Paws Rescue makes no guarantees about the animal's health, condition, training, behavior, or temperament and is not responsible for future damages or injuries caused by the animal.",
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 0,
              },
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'I affirm that no member of my household has been convicted of an animal welfare law violation such as neglect, cruelty, abandonment, etc.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 0,
              },
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'I give Puppy Paws Rescue permission to make inquiry about and enforce any of the above conditions and requirements at any time after adoption. This can include visits to the adopter’s home and contact with the adopter’s vet. I understand that failure to comply with any of the above provisions will result in forfeiture of animal to Puppy Paws Rescue.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 0,
              },
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'If I am in breach of this contract, I will be responsible for any cost that may occur from potential legal proceedings, or that may be incurred by PPR.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              bullet: {
                level: 0,
              },
              spacing: {
                before: 200,
              },
            }),
            // ************** Conditional Language **************
            contractData.treatedHw === 'yes' ? hwTreatedLanguage : {},
            // **************************************************
            new Paragraph({
              children: [
                new TextRun({
                  text: 'I agree that all statements I have made on this form are true. If it is found that any statements, I have made on this form are not true, the adopted animal can be confiscated.',
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'ADOPTER INFORMATION',
                  color: '000000',
                  font: 'Times New Roman',
                  bold: true,
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Application ID: ${contractData.AppID}`,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              spacing: {
                before: 50,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: contractData.Name,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: contractData.Address,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
                new TextRun({
                  text: ` (county: ${contractData.County})`,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Phone: ${contractData.Phone} Email: ${contractData.Email}`,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Pay it Forward for the Next Pup in Need`,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              spacing: {
                before: 300,
              },
              alignment: AlignmentType.LEFT,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Adoption Donation Request:  ${contractData.Fee}`,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
                new TextRun({
                  text: `            Donations are not Refundable!`,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              spacing: {
                before: 200,
              },
              alignment: AlignmentType.LEFT,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Can be via check made to Puppy Paws Rescue or PayPal to address pawspuppy2013@gmail.com (Note on PayPal: please transfer from bank account and select “friends and family” or agree to pay fees if you choose credit card to ensure we receive the full donation amount.) `,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                  bold: true,
                }),
                new TextRun({
                  text: ` (friends and family to avoid fees on our end) `,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 16,
                  bold: true,
                }),
              ],
              spacing: {
                before: 200,
              },
              alignment: AlignmentType.LEFT,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Payment received by:  Check________ (Check # _________ ) `,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Checks returned by the issuer’s bank due to lack of funds are subject to any bank charges incurred by Puppy Paws Rescue and a processing fee of $25.00, which are the responsibility of the adopter. `,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              spacing: {
                before: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Adopter's /New Owner Signature:`,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                  highlight: 'yellow',
                }),
                new TextRun({
                  text: ` ______________________________	Date: ______________ 		`,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              spacing: {
                before: 200,
              },
            }),

            new Paragraph({
              children: [
                new TextRun({
                  text: `Puppy Paws Representative:`,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
                new TextRun({
                  text: ` ${contractData.pprSignature}      `,
                  color: '000000',
                  font: 'Brush Script MT',
                  size: 32,
                }),
                new TextRun({
                  text: `                                   Date: ${contractData.DateSent}	`,
                  color: '000000',
                  font: 'Times New Roman',
                  size: 19,
                }),
              ],
              alignment: AlignmentType.LEFT,
              spacing: {
                before: 200,
              },
            }),

            contractData.Altered === 'no' && contractData.treatedHw === 'no'
              ? spayNeuterParagraph
              : {},
            contractData.Altered === 'no' && contractData.treatedHw === 'no'
              ? spayNeuterSignature
              : {},
            contractData.Altered === 'no' && contractData.treatedHw === 'yes'
              ? spayNeuterParagraph
              : {},
            contractData.Altered === 'no' && contractData.treatedHw === 'yes'
              ? spayNeuterSignature
              : {},
          ],
        },
      ],
    });
    Packer.toBlob(doc).then(async (blob) => {
      saveAs(blob, 'PPR Adoption Agreement-' + app.Last_Name + '.docx');
    });
  };
  createContract();
}
