import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AdminContext from '../../../context/admin/adminContext';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  };
  return new Date(string).toLocaleDateString('en-US', options);
}

const AdminZohoAppList = ({ app, loadZohoApp }) => {
  const adminContext = useContext(AdminContext);
  const { viewZohoApp } = adminContext;
  const {
    First_Name,
    Last_Name,
    Application_Status,
    Application_Type,
    createdAt,
    Dog_Adopted,
    Adoption_Date,
  } = app;

  const viewTheApp = () => {
    loadZohoApp(app);
    viewZohoApp();
  };

  return (
    <tr>
      <td onClick={viewTheApp} className="pointer">
        {First_Name} {Last_Name}
      </td>
      <td onClick={viewTheApp} className="pointer">
        {Application_Type}
      </td>
      <td onClick={viewTheApp} className="pointer">
        {Application_Status}
      </td>
      <td>{formatDate(createdAt)}</td>
      {!Adoption_Date ? (
        <td></td>
      ) : (
        <td onClick={viewTheApp} className="pointer">
          {formatDate(Adoption_Date)}
        </td>
      )}
      <td onClick={viewTheApp} className="pointer">
        {Dog_Adopted}
      </td>
    </tr>
  );
};

AdminZohoAppList.propTypes = {
  app: PropTypes.object.isRequired,
};
export default AdminZohoAppList;
