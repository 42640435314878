import React, { useReducer } from 'react';
import ZohoContext from './zohoContext';
import axios from 'axios';
import zohoReducer from './zohoReducer';

import {
  EDIT_ZOHO_APP,
  GET_ZOHO_APPS,
  GET_ZOHO_APP,
  CLEAR_ZOHO_APP,
  GET_ZOHO_APP_NOTES,
  GET_ZOHO_USERS,
  GET_ZOHO_APP_FILES,
  CREATE_ZOHO_APP_NOTE,
  UPDATE_ZOHO_APP_NOTE,
  DELETE_ZOHO_APP_NOTE,
  CREATE_ZOHO_APP_FILE,
  DELETE_ZOHO_APP_FILE,
  CREATE_ZOHO_APP_FOLLOWUP,
  UPDATE_ZOHO_APP_FOLLOWUP,
  DELETE_ZOHO_APP_FOLLOWUP,
  CLEAR_ZOHO_APP_FOLLOWUPS,
  GET_ZOHO_APP_FOLLOWUPS,
  GET_ZOHO_APP_FOLLOWUP,
  TOGGLE_COMPLETE_ZOHO_FOLLOWUP,
} from '../types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const ZohoState = (props) => {
  const initialstate = {
    zapps: null,
    zapp: null,
    znotes: null,
    zfiles: null,
    zemails: null,
    zfus: null,
    zfu: null,
    zusers: null,
    appCount: null,
    zappsloading: true,
    zfollowUpsSubscription: true,
  };

  const [state, dispatch] = useReducer(zohoReducer, initialstate);

  // Get Zoho Apps
  const getZohoApps = async (id) => {
    try {
      const res = await axios.get(`/api/zohoapplication/`);
      dispatch({
        type: GET_ZOHO_APPS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  // Get Filtered Zoho Apps
  const getFilteredZohoApps = async (request) => {
    try {
      const res = await axios.post(
        `/api/zohoapplication/filtered/`,
        request,
        config
      );
      dispatch({
        type: GET_ZOHO_APPS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const loadZohoApp = (app) => {
    dispatch({
      type: GET_ZOHO_APP,
      payload: app,
    });
  };

  // Get A Zoho App
  const getZohoApp = async (id) => {
    try {
      const res = await axios.get(`/api/zohoApplication/${id}`);
      dispatch({
        type: GET_ZOHO_APP,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Edit A Zoho App
  const editZohoApp = async (id, data) => {
    try {
      const res = await axios.put(`/api/zohoApplication/${id}`, data);
      dispatch({
        type: EDIT_ZOHO_APP,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Clear current App
  const clearApp = () => {
    dispatch({
      type: CLEAR_ZOHO_APP,
    });
  };

  // Get Zoho Notes List
  const getAppNotes = async (id) => {
    const res = await axios.get(`/api/zohoNotes/${id}`);
    dispatch({
      type: GET_ZOHO_APP_NOTES,
      payload: res.data.rows,
    });
  };

  // Get Zoho Users List
  const getZohoUsers = async () => {
    const res = await axios.get(`/api/zohoUsers`);
    dispatch({
      type: GET_ZOHO_USERS,
      payload: res.data,
    });
  };

  // Get ZOHO APP Files List

  const getAppFiles = async (id) => {
    const res = await axios.get(`/api/zohoFiles/app/${id}`);
    dispatch({
      type: GET_ZOHO_APP_FILES,
      payload: res.data,
    });
  };

  const deleteAFile = async (id) => {
    await axios.delete(`/api/zohoFiles/${id}`);
    dispatch({
      type: DELETE_ZOHO_APP_FILE,
      payload: id,
    });
  };

  const uploadTheFile = async (data) => {
    const formData = new FormData();
    formData.append(
      'file',
      data.file,
      data.fileName + '.' + data.fileExtension
    );

    try {
      await axios.post('/api/zohoFiles/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fileToDB = async (fileInfo) => {
    const res = await axios.post(`/api/zohoFiles/`, fileInfo, config);

    console.log(res.data);

    dispatch({
      type: CREATE_ZOHO_APP_FILE,
      payload: res.data,
    });
  };

  // Convert Zoho App To Application Module

  const transferApplication = async (data, zappID) => {
    const dbResults = await axios.post('/api/database', data, config);

    // NOTES TRANSFER
    try {
      const znotes = await axios.get(`/api/zohoNotes/${zappID}`);
      const notes = znotes.data.rows;
      notes.forEach(async (note) => {
        const res = await axios.get(`/api/zohoUsers/${note.Note_Owner_ID}`);
        const noteData = {
          NoteApp_Id: dbResults.data._id,
          createdAt: note.createdAt,
          updatedAt: note.createdAt,
          Note: note.Note_Content,
          Author: res.data.First_Name + ' ' + res.data.Last_Name,
        };
        await axios.post('/api/notes/', noteData, config);
      });
    } catch (err) {
      console.log(err);
    }

    //  FILES TRANSFER
    try {
      const zfiles = await axios.get(`/api/zohoFiles/app/${zappID}`);
      const files = zfiles.data;
      files.forEach(async (file) => {
        const res = await axios.get(
          `/api/zohoUsers/${file.Attachment_Owner_ID}`
        );
        const fileData = {
          File_AppID: dbResults.data._id,
          File_Description: file.File_Name,
          File_Name: file.Attachment_ID,
          File_Location: `/mnt/fileStorage/ppr/zoho_attachments/`,
          File_Size: file.Size,
          createdAt: file.createdAt,
          Uploaded_By: res.data.First_Name + ' ' + res.data.Last_Name,
        };
        await axios.post('/api/upload/db', fileData, config);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const createNote = async (data) => {
    const res = await axios.post(`/api/zohoNotes/`, data, config);

    dispatch({
      type: CREATE_ZOHO_APP_NOTE,
      payload: res.data,
    });
  };

  const editNote = async (data) => {
    const res = await axios.put(`/api/zohoNotes/${data.Note_ID}`, data, config);
    dispatch({
      type: UPDATE_ZOHO_APP_NOTE,
      payload: res.data,
    });
  };

  const deleteNote = async (id) => {
    try {
      await axios.delete(`/api/zohoNotes/${id}`);

      dispatch({
        type: DELETE_ZOHO_APP_NOTE,
        payload: id,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const createFollowUp = async (data) => {
    try {
      const res = await axios.post('/api/zohofollowups', data, config);
      dispatch({
        type: CREATE_ZOHO_APP_FOLLOWUP,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const completeFollowUp = async (e) => {
    const data = {
      Completed_By: e.Completed_By,
      Completed_Date: e.Completed_Date,
      Follow_Up_Status: e.Follow_Up_Status,
      Complete_Checkbox: e.Complete_Checkbox,
    };
    try {
      const res = await axios.put(`/api/zohofollowups/${e._id}`, data, config);
      dispatch({
        type: TOGGLE_COMPLETE_ZOHO_FOLLOWUP,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const editFollowUp = async (data) => {
    try {
      const res = await axios.put(
        `/api/zohofollowups/${data._id}`,
        data,
        config
      );
      dispatch({
        type: UPDATE_ZOHO_APP_FOLLOWUP,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteFollowUp = async (id) => {
    await axios.delete(`/api/zohofollowups/${id}`);
    dispatch({
      type: DELETE_ZOHO_APP_FOLLOWUP,
      payload: id,
    });
  };
  const getFollowUps = async (e) => {
    try {
      const res = await axios.get(`/api/zohofollowups/app/${e}`);
      dispatch({
        type: GET_ZOHO_APP_FOLLOWUPS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getFollowUp = async (e) => {
    const res = await axios.get(`/api/zohofollowups/${e}`);
    dispatch({
      type: GET_ZOHO_APP_FOLLOWUP,
      payload: res.data,
    });
  };
  const clearFollowUps = () => {
    dispatch({
      type: CLEAR_ZOHO_APP_FOLLOWUPS,
    });
  };

  const notifyAssignment = async (email) => {
    await axios.post('/api/sendEmail/task/', email, config);
  };

  return (
    <ZohoContext.Provider
      value={{
        zapps: state.zapps,
        zemails: state.zemails,
        zapp: state.zapp,
        znotes: state.znotes,
        zfus: state.zfus,
        zusers: state.zusers,
        zfiles: state.zfiles,
        appCount: state.appCount,
        zappsloading: state.zappsloading,
        zfollowUpsSubscription: state.zfollowUpsSubscription,
        getFilteredZohoApps,
        editZohoApp,
        getZohoApp,
        getZohoApps,
        clearApp,
        getAppNotes,
        getZohoUsers,
        getAppFiles,
        transferApplication,
        loadZohoApp,
        createNote,
        editNote,
        deleteNote,
        deleteAFile,
        uploadTheFile,
        fileToDB,
        createFollowUp,
        completeFollowUp,
        editFollowUp,
        deleteFollowUp,
        getFollowUps,
        getFollowUp,
        clearFollowUps,
        notifyAssignment,
      }}
    >
      {props.children}
    </ZohoContext.Provider>
  );
};

export default ZohoState;
