import React, { Fragment, useContext, useEffect, useState } from 'react';
import AppContext from '../../../context/app/appContext';
import AdoptionContext from '../../../context/adoption/adoptionContext';
import FollowUpsContext from '../../../context/follow-ups/followUpsContext';
import formatDateDashed from '../../utils/formatDateDashed';

function formTheDate(date) {
  if (date === '') {
    return '';
  } else {
    const theDate = new Date(date);
    const a = theDate.toISOString();
    const yyyy = a.slice(0, 4);
    const mm = a.slice(5, 7);
    const dd = a.slice(8, 10);

    const formatedDate = `${mm}-${dd}-${yyyy}`;

    return formatedDate;
  }
}

const AdminAppAdoptions = () => {
  const appContext = useContext(AppContext);
  const followUpsContext = useContext(FollowUpsContext);
  const adoptionContext = useContext(AdoptionContext);
  const { createInitialFollowUps, clearFollowUps } = followUpsContext;
  const {
    adoptions,
    adoptionsLoading,
    deleteAdoption,
    getAdoptions,
    updateAdoption,
    createAdoption,
  } = adoptionContext;
  const { app } = appContext;
  const [newAdoption, setNewAdoption] = useState({
    Dog_Adopted: '',
    MicroChip: '',
    MicroChip_Brand: '',
    Adoption_Date: formatDateDashed(Date.now()),
    Fixed: 'Select',
    Adoption_AppId: null,
    snDueDate: '',
  });

  const [editedAdoption, setEditedAdoption] = useState({
    Dog_Adopted: '',
    MicroChip: '',
    MicroChip_Brand: '',
    Adoption_Date: '',
    _id: '',
    snDueDate: '',
  });

  const [removeAdoptionId, setRemoveAdoptionId] = useState(null);
  const [snDueDateValid, setSnDueDateValid] = useState(true);

  useEffect(() => {
    if (app !== null) {
      getAdoptions(app._id);
    }
    return () => {
      clearFollowUps();
    };
    // eslint-disable-next-line
  }, []);

  const on_change = (e) => {
    setNewAdoption({ ...newAdoption, [e.target.id]: e.target.value });
  };

  const on_edit = (e) => {
    setEditedAdoption({ ...editedAdoption, [e.target.id]: e.target.value });
  };

  const insertEditAdoption = (e) => {
    openModal('staticBackdrop2');
    const adoption = adoptions.filter(
      (adoption) => adoption._id === parseInt(e.target.id)
    );
    setEditedAdoption({
      _id: adoption[0]._id,
      Dog_Adopted: adoption[0].Dog_Adopted,
      MicroChip: adoption[0].MicroChip,
      MicroChip_Brand: adoption[0].MicroChip_Brand,
      Adoption_Date: formatDateDashed(adoption[0].Adoption_Date),
      Fixed: adoption[0].Fixed,
      Adoption_AppId: adoption[0].Adoption_AppId,
      snDueDate: adoption[0].snDueDate,
    });
  };

  const cancelEdit = (e) => {
    closeModal('staticBackdrop2');
    setEditedAdoption({
      Dog_Adopted: '',
      MicroChip: '',
      MicroChip_Brand: '',
      Adoption_Date: '',
      snDueDate: '',
    });
    setSnDueDateValid(true);
  };

  const save_adoption = () => {
    if (
      newAdoption.Fixed === 'NO' &&
      (newAdoption.snDueDate === '' || newAdoption.snDueDate === null)
    ) {
      setSnDueDateValid(false);
      return;
    } else {
      setSnDueDateValid(true);
      closeModal('staticBackdrop');
    }
    createAdoption(newAdoption);
    if (newAdoption.Fixed === 'NO') {
      const date = new Date(newAdoption.snDueDate);
      createInitialFollowUps(app._id, date);
    }
    setNewAdoption({
      Dog_Adopted: '',
      MicroChip: '',
      MicroChip_Brand: '',
      Adoption_Date: '',
      Fixed: 'Select',
      Adoption_AppId: null,
      snDueDate: app.snDueDate,
    });
  };

  const cancelCreate = () => {
    closeModal('staticBackdrop');
    setNewAdoption({
      Dog_Adopted: '',
      MicroChip: '',
      MicroChip_Brand: '',
      Adoption_Date: '',
      Fixed: 'Select',
      Adoption_AppId: null,
      snDueDate: app.snDueDate,
    });
    setSnDueDateValid(true);
  };
  const enterNewAdoption = () => {
    setNewAdoption({
      ...newAdoption,
      Adoption_AppId: app._id,
      snDueDate: app.snDueDate,
    });
    openModal('staticBackdrop');
  };

  const save_editedAdoption = () => {
    if (editedAdoption.Fixed === 'NO' && editedAdoption.snDueDate === '') {
      setSnDueDateValid(false);
      return;
    } else {
      setSnDueDateValid(true);
      closeModal('staticBackdrop2');
    }
    updateAdoption(editedAdoption);
    if (editedAdoption.Fixed === 'NO') {
      const date = new Date(editedAdoption.snDueDate);
      createInitialFollowUps(app._id, date);
    }
    setEditedAdoption({
      Dog_Adopted: '',
      MicroChip: '',
      MicroChip_Brand: '',
      Adoption_Date: '',
      snDueDate: '',
    });
  };

  // DELETE CONFIRMATION

  const deleteAAdoption = (e) => {
    deleteAdoption(e);
  };

  const removeAdoption = (e) => {
    setRemoveAdoptionId(e.target.id);
  };

  const cancelDeletion = () => {
    setRemoveAdoptionId(null);
  };

  function openModal(id) {
    document.getElementById('backdrop').style.display = 'block';
    document.getElementById(id).style.display = 'block';
    document.getElementById(id).classList.add('show');
  }
  function closeModal(id) {
    document.getElementById('backdrop').style.display = 'none';
    document.getElementById(id).style.display = 'none';
    document.getElementById(id).classList.remove('show');
  }
  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function (event) {
    if (event.target.id === 'staticBackdrop') {
      closeModal('staticBackdrop');
    }
    if (event.target.id === 'staticBackdrop2') {
      closeModal('staticBackdrop2');
    }
  };

  return (
    <Fragment>
      <div className="container">
        <div className="row mt-2 text-right">
          <button
            type="button"
            onClick={enterNewAdoption}
            className="btn btn-background font-weight-bold"
          >
            CREATE ADOPTION
          </button>
        </div>
        {adoptions !== null && !adoptionsLoading ? (
          adoptions.map((adoption) => (
            <div className="row m-3" key={adoption._id}>
              <table className="table table-hover m-0 ">
                <thead className="bg-theme text-white">
                  <tr>
                    <th>DOG ADOPTED</th>
                    <th> ADOPTION DATE</th>
                    <th className="d-none d-md-table-cell"> MICROCHIP #</th>
                    <th className="d-none d-md-table-cell">CHIP BRAND:</th>
                    <th className="d-none d-md-table-cell">FIXED</th>
                    <th className="d-none d-md-table-cell">S/N DUE DATE</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{adoption.Dog_Adopted}</td>
                    <td> {formTheDate(adoption.Adoption_Date)}</td>
                    <td className="d-none d-md-table-cell">
                      {adoption.MicroChip}
                    </td>
                    <td className="d-none d-md-table-cell">
                      {adoption.MicroChip_Brand}
                    </td>
                    <td className="d-none d-md-table-cell">{adoption.Fixed}</td>
                    <td className="d-none d-md-table-cell">
                      {adoption.Fixed === 'Select' ? 'Not Answered' : null}
                      {adoption.Fixed === 'NO'
                        ? formTheDate(adoption.snDueDate)
                        : null}
                    </td>
                    <td className="text-right">
                      {' '}
                      <i
                        className="fa fa-pencil mr-4 text-warning pointer"
                        aria-hidden="true"
                        id={adoption._id}
                        onClick={insertEditAdoption}
                      ></i>
                      <i
                        className="fa fa-trash-o text-danger pointer"
                        onClick={removeAdoption}
                        data-toggle="modal"
                        data-target="#adoptionDeleteConfirmationModal"
                        id={adoption._id}
                        aria-hidden="true"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))
        ) : (
          <h4>Loading</h4>
        )}
      </div>
      {/* *********** CREATE ADOPTION MODAL ************* */}
      <div
        className="modal fade show"
        id="staticBackdrop"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        style={{ display: 'none' }}
      >
        <div className="modal-dialog gt-modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                ADOPTION RECORD
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* *********************MODAL FORM ****************** */}
            <div className="modal-body">
              <form>
                <div className="container">
                  <div className="form-group">
                    <div className="row">
                      <label
                        className="active font-weight-bolder"
                        htmlFor="Dog_Adopted"
                      >
                        DOG ADOPTED:
                      </label>
                    </div>
                    <div className="row">
                      <input
                        id="Dog_Adopted"
                        type="text"
                        className="form-control"
                        value={newAdoption.Dog_Adopted}
                        onChange={on_change}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <label
                        className="active font-weight-bolder"
                        htmlFor="MicroChip"
                      >
                        MICROCHIP #
                      </label>
                    </div>
                    <div className="row">
                      <input
                        id="MicroChip"
                        type="text"
                        className="form-control"
                        value={newAdoption.MicroChip}
                        onChange={on_change}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <label
                        className="active font-weight-bolder"
                        htmlFor="MicroChip_Brand"
                      >
                        MICROCHIP BRAND:
                      </label>
                    </div>
                    <div className="row">
                      <input
                        id="MicroChip_Brand"
                        type="text"
                        className="form-control"
                        value={newAdoption.MicroChip_Brand}
                        onChange={on_change}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <label
                        className="active font-weight-bolder"
                        htmlFor="Fixed"
                      >
                        FIXED:
                      </label>
                      <select
                        id="Fixed"
                        className="form-control"
                        value={newAdoption.Fixed}
                        onChange={on_change}
                      >
                        <option>Select</option>
                        <option>YES</option>
                        <option>NO</option>
                      </select>
                    </div>
                  </div>
                  {newAdoption.Fixed === 'NO' && (
                    <div className="form-group">
                      <div className="row">
                        <label
                          className="active font-weight-bolder"
                          htmlFor="Adoption_Date"
                        >
                          S/N DUE DATE:
                        </label>
                        <input
                          id="snDueDate"
                          type="date"
                          className="form-control"
                          defaultValue={app.snDueDate}
                          onChange={on_change}
                          maxLength="100"
                          style={{ borderColor: !snDueDateValid && 'red' }}
                        />
                      </div>
                      <label style={{ color: 'red', fontWeight: 'bold' }}>
                        {!snDueDateValid && 'REQUIRED S/N DUE DATE'}
                      </label>
                    </div>
                  )}

                  <div className="form-group">
                    <div className="row">
                      <label
                        className="active font-weight-bolder"
                        htmlFor="Adoption_Date"
                      >
                        ADOPTION DATE:
                      </label>
                      <input
                        id="Adoption_Date"
                        type="date"
                        className="form-control"
                        value={newAdoption.Adoption_Date}
                        onChange={on_change}
                        maxLength="100"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                // data-dismiss="modal"
                onClick={cancelCreate}
              >
                CANCEL
              </button>
              <button
                type="button"
                onClick={save_adoption}
                className="btn btn-background"
                // data-dismiss="modal"
              >
                SAVE ADOPTION
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ************************* EDIT NOTE MODAL ************** */}
      <div
        className="modal fade"
        id="staticBackdrop2"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop2Label"
        aria-hidden="true"
      >
        <div className="modal-dialog gt-modal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdrop2Label">
                EDIT ADOPTION
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="container">
                  <div className="form-group">
                    <div className="row">
                      <label
                        className="active font-weight-bolder"
                        htmlFor="Dog_Adopted"
                      >
                        DOG ADOPTED:
                      </label>
                    </div>
                    <div className="row">
                      <input
                        id="Dog_Adopted"
                        type="text"
                        className="form-control"
                        value={editedAdoption.Dog_Adopted}
                        onChange={on_edit}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <label
                        className="active font-weight-bolder"
                        htmlFor="MicroChip"
                      >
                        MICROCHIP #
                      </label>
                    </div>
                    <div className="row">
                      <input
                        id="MicroChip"
                        type="text"
                        className="form-control"
                        value={editedAdoption.MicroChip}
                        onChange={on_edit}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <label
                        className="active font-weight-bolder"
                        htmlFor="MicroChip_Brand"
                      >
                        MICROCHIP BRAND:
                      </label>
                    </div>
                    <div className="row">
                      <input
                        id="MicroChip_Brand"
                        type="text"
                        className="form-control"
                        value={editedAdoption.MicroChip_Brand}
                        onChange={on_edit}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <label
                        className="active font-weight-bolder"
                        htmlFor="Fixed"
                      >
                        FIXED:
                      </label>
                      <select
                        id="Fixed"
                        className="form-control"
                        value={editedAdoption.Fixed}
                        onChange={on_edit}
                      >
                        <option>Select</option>
                        <option>YES</option>
                        <option>NO</option>
                      </select>
                    </div>
                  </div>
                  {editedAdoption.Fixed === 'NO' && (
                    <div className="form-group">
                      <div className="row">
                        <label
                          className="active font-weight-bolder"
                          htmlFor="Adoption_Date"
                        >
                          S/N DUE DATE:
                        </label>
                        <input
                          id="snDueDate"
                          type="date"
                          className="form-control"
                          defaultValue={editedAdoption.snDueDate}
                          onChange={on_edit}
                          maxLength="100"
                          style={{ borderColor: !snDueDateValid && 'red' }}
                        />
                      </div>
                      <label style={{ color: 'red', fontWeight: 'bold' }}>
                        {!snDueDateValid && 'REQUIRED S/N DUE DATE'}
                      </label>
                    </div>
                  )}
                  <div className="form-group">
                    <div className="row">
                      <label
                        className="active font-weight-bolder"
                        htmlFor="Adoption_Date"
                      >
                        ADOPTION DATE:
                      </label>
                      <input
                        id="Adoption_Date"
                        type="date"
                        className="form-control"
                        value={editedAdoption.Adoption_Date}
                        onChange={on_edit}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={cancelEdit}
              >
                CANCEL
              </button>
              <button
                type="button"
                onClick={save_editedAdoption}
                className="btn btn-background"
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="adoptionDeleteConfirmationModal"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="adoptionDeleteConfirmationModal"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-danger font-weight-bold">
                DELETE CONFIRMATION
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="font-weight-bold">
                ARE YOU SURE YOU WANT TO DELETE THIS ADOPTION?
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={cancelDeletion}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  deleteAAdoption(removeAdoptionId);
                  setRemoveAdoptionId(null);
                }}
                data-dismiss="modal"
              >
                CONFRIM
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade show"
        id="backdrop"
        style={{ display: 'none' }}
      ></div>
    </Fragment>
  );
};

export default AdminAppAdoptions;
