import React, { useReducer } from 'react';
import MedicationListContext from './medicationListContext';
import axios from 'axios';
import medicationListReducer from './medicationListReducer';

import { GET_MEDS, CREATE_MED, GET_MED, EDIT_MED, DELETE_MED } from '../types';

const MedicationListState = (props) => {
  const initialstate = {
    meds: null,
    med: null,
  };

  const [state, dispatch] = useReducer(medicationListReducer, initialstate);

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Get Meds
  const getMeds = async () => {
    try {
      const res = await axios.get(`/api/medicationList`);
      dispatch({
        type: GET_MEDS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Create Med
  const createMed = async (data) => {
    try {
      const res = await axios.post('/api/medicationList', data, config);
      dispatch({
        type: CREATE_MED,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getMed = async (id) => {
    try {
      const res = await axios.get(`/api/medicationList/${id}`);
      dispatch({
        type: GET_MED,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const editMed = async (id, data) => {
    try {
      const res = await axios.put(`/api/medicationList/${id}`, data, config);

      dispatch({
        type: EDIT_MED,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteMed = (id) => {
    try {
      axios.delete(`/api/medicationList/${id}`);
      dispatch({
        type: DELETE_MED,
        payload: id,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MedicationListContext.Provider
      value={{
        meds: state.meds,
        med: state.med,
        createMed,
        editMed,
        deleteMed,
        getMed,
        getMeds,
      }}
    >
      {props.children}
    </MedicationListContext.Provider>
  );
};

export default MedicationListState;
