import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AdminDashTaskList from '../dashboard/AdminDashTaskList';

const AdminDashTasks = (props) => {
  const { user } = props;
  const [taskList, setTaskList] = useState();

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    axios
      .get(`/api/tasks/user/${user._id}`, {
        cancelToken: source.token,
      })
      .then((response) => {
        if (mounted) {
          setTaskList(response.data);
          mounted = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => {
      source.cancel('Component got unmounted - My Application Task Lists');
    };

    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   let userTasksListRaw = [];
  //   let userTasksList = [];
  //   let userTasksListWithAppName = [];

  //   if (!openAppsloading && userTasks !== undefined) {
  //     const applicationLookup = (id) => {
  //       const application = openApps.filter((app) => {
  //         return app._id === id;
  //       });
  //       return application[0];
  //     };

  //     if (!taskLoading) {
  //       userTasksListRaw = userTasks.map((task) => {
  //         const application = applicationLookup(task.App_Id);
  //         if (application !== undefined) {
  //           const data = {
  //             _id: task._id,
  //             App_Id: task.App_Id,
  //             Assigned_To: task.Assigned_To,
  //             Complete_Checkbox: task.Complete_Checkbox,
  //             Complete_By: task.Complete_By,
  //             Task_Description: task.Task_Description,
  //             Task_Status: task.Task_Status,
  //             AppName: application.First_Name + ' ' + application.Last_Name,
  //           };
  //           return data;
  //         }
  //         return application;
  //       });
  //     }
  //     userTasksListRaw.map((e) => {
  //       if (e !== undefined) {
  //         userTasksList.push({ e });
  //       }
  //       return userTasksList;
  //     });

  //     userTasksList.map((task) => {
  //       userTasksListWithAppName.push(task.e);
  //       return userTasksListWithAppName;
  //     });
  //     setTaskList(userTasksListWithAppName);
  //   }
  //   // eslint-disable-next-line
  // }, [openApps, userTasks]);

  return (
    <div className="card shadow col-12 ">
      <h5 className="card-title text-center font-weight-bold mt-2">
        MY ASSIGNED TASKS
      </h5>
      <div className="card-body">
        <table className="table  table-hover table-striped table-borderless table-dark">
          <thead>
            <tr className="d-flex">
              <th className="text-center col-lg-4" scope="col">
                APPLICANT
              </th>
              <th className="text-center col-lg-6" scope="col">
                TASK
              </th>
              <th className="text-center col-lg-2" scope="col">
                ID
              </th>
            </tr>
          </thead>

          <tbody>
            {taskList ? (
              taskList.map((task) => (
                <AdminDashTaskList task={task} key={task._id} />
              ))
            ) : (
              <tr>
                <td></td>
                <td className="text-center">No Tasks Currently Assigned</td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default AdminDashTasks;
