import {
  LOAD_BLOG_PAGE,
  LOAD_DASHBOARD,
  LOAD_USER_PAGE,
  LOAD_APPLICATIONS_PAGE,
  LOAD_CREATE_BLOG_PAGE,
  LOAD_CREATE_USER_PAGE,
  LOAD_EDIT_BLOG_PAGE,
  LOAD_BLOG_ID,
  LOAD_EDIT_USER_PAGE,
  LOAD_USER_PROFILE,
  LOAD_USER_ID,
  LOAD_APPLICATION_PAGE,
  LOAD_EMAIL_TEMPLATES_PAGE,
  LOAD_ZOHO_APPLICATIONS_PAGE,
  LOAD_ZOHO_APPLICATION_PAGE,
  LOAD_NEW_APPLICATION_PAGE,
  LOAD_ADOPTION_LIST_PAGE,
  LOAD_ERROR_LOG_PAGE,
  LOAD_CONTACTS_PAGE,
  LOAD_DOGS_PAGE,
  LOAD_APP_ID,
  LOAD_DASHBOARD_PAGE,
  CLEAR_FILTERS,
  SET_FILTERS,
  LOAD_PAGE,
  TOGGLE_EDITING,
} from '../types';

const adminReducer = (state, action) => {
  switch (action.type) {
    case LOAD_EDIT_USER_PAGE:
    case LOAD_BLOG_PAGE:
    case LOAD_DASHBOARD:
    case LOAD_USER_PAGE:
    case LOAD_APPLICATIONS_PAGE:
    case LOAD_CREATE_BLOG_PAGE:
    case LOAD_CREATE_USER_PAGE:
    case LOAD_EMAIL_TEMPLATES_PAGE:
      return {
        ...state,
        step: action.payload,
        userID: null,
        blogID: null,
        appID: null,
      };
    case LOAD_USER_PROFILE:
    case LOAD_CONTACTS_PAGE:
    case LOAD_DOGS_PAGE:
    case LOAD_EDIT_BLOG_PAGE:
    case LOAD_ZOHO_APPLICATIONS_PAGE:
    case LOAD_ZOHO_APPLICATION_PAGE:
    case LOAD_NEW_APPLICATION_PAGE:
    case LOAD_DASHBOARD_PAGE:
    case LOAD_ERROR_LOG_PAGE:
    case LOAD_ADOPTION_LIST_PAGE:
    case LOAD_PAGE:
      return {
        ...state,
        step: action.payload,
      };
    case LOAD_APPLICATION_PAGE:
      return {
        ...state,
        step: action.payload.page,
        appID: action.payload.app_id,
      };

    case LOAD_USER_ID:
      return {
        ...state,
        userID: action.payload,
      };
    case LOAD_BLOG_ID:
      return {
        ...state,
        blogID: action.payload,
      };
    case LOAD_APP_ID:
      return {
        ...state,
        appID: action.payload,
      };
    case SET_FILTERS:
    case CLEAR_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case TOGGLE_EDITING:
      return {
        ...state,
        currentlyEditing: action.payload,
      };

    default:
      return state;
  }
};

export default adminReducer;
