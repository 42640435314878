import { DELETE_FILE, GET_APP_FILES, UPLOAD_FILE } from '../types';

const fileReducer = (state, action) => {
  switch (action.type) {
    case GET_APP_FILES:
      return {
        ...state,
        files: action.payload,
      };
    case UPLOAD_FILE:
      return {
        ...state,
        files: [action.payload, ...state.files],
      };
    case DELETE_FILE:
      return {
        ...state,
        files: state.files.filter(
          (file) => file._id !== parseInt(action.payload)
        ),
      };
    default:
      return state;
  }
};

export default fileReducer;
