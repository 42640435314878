import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AdminDashFollowUpsList from './AdminDashFollowUpsList';
import zohoContext from '../../../context/zoho/zohoContext';
import adminContext from '../../../context/admin/adminContext';

const AdminDashFollowUps = (props) => {
  const { getZohoApp } = useContext(zohoContext);
  const { viewZohoApp, viewApp } = useContext(adminContext);
  const [showFollowUp, setShowFollowUp] = useState([]);
  const [showZohoFollowUp, setShowZohoFollowUp] = useState([]);
  const { user } = props;

  const viewFollowUp = (id) => {
    viewApp(id);
    window.scroll(0, 0);
  };

  const viewZohoFollowUp = (id) => {
    getZohoApp(id);
    viewZohoApp();
    window.scroll(0, 0);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    axios
      .get(`/api/follow-ups/user/${user._id}`, {
        cancelToken: source.token,
      })

      .then((response) => {
        if (mounted) {
          setShowFollowUp(response.data);
          mounted = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      source.cancel('Component got unmounted - My Application Follow UPs');
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let mounted = true;
    axios
      .get(`/api/zohofollowups/user/${user._id}`, {
        cancelToken: source.token,
      })
      .then((response) => {
        if (mounted) {
          setShowZohoFollowUp(response.data);
          mounted = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      source.cancel(
        'Component got unmounted -  My Zoho Application Follow UPs'
      );
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="card shadow col-12 ">
      <h5 className="card-title text-center font-weight-bold mt-2">
        MY FOLLOW UPS
      </h5>
      <div className="card-body">
        <table className="table table-hover table-striped table-borderless table-dark">
          <thead>
            <tr className="d-flex">
              <th className="text-center col-lg-3" scope="col">
                APPLICANT
              </th>
              <th className="text-center col-lg-6" scope="col">
                FOLLOW UP
              </th>
              <th className="text-center col-lg-3" scope="col">
                DUE DATE
              </th>
            </tr>
          </thead>

          <tbody>
            {showFollowUp
              ? showFollowUp.map((fu) => (
                  <AdminDashFollowUpsList
                    key={fu._id}
                    viewFollowUp={viewFollowUp}
                    viewZohoFollowUp={viewZohoFollowUp}
                    fu={fu}
                  />
                ))
              : null}
            {showZohoFollowUp
              ? showZohoFollowUp.map((fu) => (
                  <AdminDashFollowUpsList
                    key={fu._id}
                    viewFollowUp={viewFollowUp}
                    viewZohoFollowUp={viewZohoFollowUp}
                    fu={fu}
                  />
                ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminDashFollowUps;
