import React from 'react';

const MedicationListEdit = ({ med, onchange, editMedication }) => {
  if (med !== null) {
    return (
      <div className="newMedicationContainer">
        <label className="font-weight-bold m-3" htmlFor="medication_name">
          Medication Name:
        </label>
        <input
          type="text"
          list="medsList"
          id="medication_name"
          onChange={onchange}
          autoComplete="off"
          defaultValue={med.medication_name}
        />
        <button
          className="btn btn-warning ml-3"
          onClick={() => editMedication(med._id)}
        >
          UPDATE
        </button>
      </div>
    );
  } else {
    return null;
  }
};

export default MedicationListEdit;
