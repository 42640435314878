import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AdminContext from '../../../context/admin/adminContext';
import AppContext from '../../../context/app/appContext';
import TaskContext from '../../../context/tasks/taskContext';

const AdminDashTaskList = ({ task }) => {
  const adminContext = useContext(AdminContext);
  const appContext = useContext(AppContext);
  const taskContext = useContext(TaskContext);
  const { viewApp } = adminContext;
  const { getApp } = appContext;
  const { clearTasks } = taskContext;

  const viewTheTask = () => {
    viewApp(task.App_Id);
    getApp(task._id);
    clearTasks();
    window.scroll(0, 0);
  };

  return (
    <tr className="d-flex">
      <td onClick={viewTheTask} className="text-center pointer col-lg-4">
        {task.AppName}
      </td>
      <td onClick={viewTheTask} className="text-center pointer col-lg-6">
        {task.Task_Description}
      </td>
      <td onClick={viewTheTask} className="text-center pointer col-lg-2">
        {task.App_Id}
      </td>
    </tr>
  );
};

AdminDashTaskList.propTypes = {
  task: PropTypes.object.isRequired,
};
export default AdminDashTaskList;
