import React from 'react';
import PropTypes from 'prop-types';

const AdminDashDifList = ({ dog, editDogInFoster, getDog, getDogFiles }) => {
  const editDif = (e) => {
    editDogInFoster();
    getDog(dog);
    getDogFiles(dog._id);
  };
  return (
    <tr onClick={editDif}>
      <td className="text-center pointer">{dog.dogName}</td>
      <td className="text-center pointer">{dog.foster}</td>
      <td className="text-center pointer">{dog.processor}</td>
      <td className="text-center pointer d-none d-md-block">{dog.location}</td>
    </tr>
  );
};

AdminDashDifList.propTypes = {
  dog: PropTypes.object.isRequired,
};
export default AdminDashDifList;
